export const isAuthenticated = () => getAccessToken() || null;

export const getAccessToken = () => {
  const token = localStorage.getItem("auth-token");
  return token ? token : null;
};

export const getAccessToken2 = () => {
  const token = localStorage.getItem("userAccessToken");
  return token ? token : null;
};

export const setAccessToken = (accessToken) =>
  localStorage.setItem("auth-token", accessToken);
export const isAdmin = () => localStorage.getItem("isAdmin");

export const clearStorage = () => localStorage.clear();
