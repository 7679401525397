import "./OrderItemDetails.scss";

import { useTranslation } from "react-i18next";
import React from "react";
import moment from "moment";
import { RequestHandler } from "../../../../Components/RequestHandler/RequestHandler";

export const OrderItemDetails = ({ orderDetails, isLoading }) => {

  const { t, i18n } = useTranslation();

  const priceWithCurrency = (price) => {
    return `${orderDetails?.currency} ${Number(price).toFixed(2)}`;
  };
  return (
    <div className="order-details d-flex flex-lg-row flex-column ">
      <RequestHandler withAutoHeight isLoading={isLoading}>
        <div className="order-details-delivery ml-lg-5 ml-0 mb-lg-0 mb-4">
          <div className="order-details-delivery-title mb-4">{t("orderInformation")}</div>
          <div className="order-details-delivery-date mb-4">
            <div className="order-details-delivery-date-title mb-2">{t("orderDate")}</div>
            <div className="order-details-delivery-date-info">
              {moment(new Date(orderDetails?.orderedDate + "Z"))
                .locale(i18n.language === "en" ? "en" : "uk")
                .format("DD MMMM YYYY HH:mm")}
            </div>
          </div>
          <div className="order-details-delivery-address mb-4">
            <div className="order-details-delivery-address-title  mb-2">
              {orderDetails?.deliveryMetadata?.type}
            </div>
            <div className="order-details-delivery-address-info">
              {orderDetails?.deliveryMetadata?.address}
            </div>
          </div>
          <div className="order-details-delivery-address mb-4">
            <div className="order-details-delivery-address-title  mb-2">
              {`${t("workSchedule")}:`}
            </div>
            <div className="order-details-delivery-address-info">
              {orderDetails?.workSchedule}
            </div>
          </div>
          <div className="order-details-delivery-address">
            <div className="order-details-delivery-address-title  mb-2">
              {orderDetails?.sellerMetadata?.name}
            </div>
            <div className="order-details-delivery-address-info">
              {orderDetails?.sellerMetadata?.phone}
            </div>
            <div className="order-details-delivery-address-info">
              {orderDetails?.sellerMetadata?.email}
            </div>
          </div>
        </div>
        <div className="order-details-product ml-lg-3 ml-0">
          <div className="order-details-delivery-title d-flex mb-4">
            <div>{t("products")}</div>
            <a
              href={`/${orderDetails?.sellerUsername}/`}
              className="order-seller-name ml-1"
            >
              {orderDetails?.sellerUsername}
            </a>
          </div>
          <div className="order-details-product-info d-flex flex-lg-row flex-column mb-4">
            <div className="order-details-product-info-left">
              <div className="d-flex">
                <div className="order-details-product-info-img">
                  <img src={orderDetails?.productImage} alt="product" />
                </div>
                <div className="order-details-product-info-name-wrapper ml-2">
                  <div className="order-details-product-info-name">
                    {orderDetails?.productName}
                  </div>
                  <div className="order-details-product-info-meta">
                    {orderDetails?.productMetadata}
                  </div>
                </div>
              </div>
              <div className="order-details-product-info-col ml-2">
                <div className="order-details-product-info-grey-title">{t("price")}</div>
                <div className="order-details-product-info-black-value">
                  {priceWithCurrency(orderDetails?.price)}
                </div>
              </div>
            </div>
            <div className="order-details-product-info-right d-flex flex-lg-row flex-column">
              <div className="order-details-product-info-col ml-lg-2 ml-0  mt-lg-0 mt-2">
                <div className="order-details-product-info-grey-title">
                  {t("quantity")}
                </div>
                <div className="order-details-product-info-black-value">
                  {orderDetails?.quantity}
                </div>
              </div>
              <div className="order-details-product-info-col ml-lg-2 ml-0  mt-lg-0 mt-2">
                <div className="order-details-product-info-grey-title">{t("amount")}</div>
                <div className="order-details-product-info-black-value">
                  {priceWithCurrency(orderDetails?.price * orderDetails?.quantity)}
                </div>
              </div>
            </div>
          </div>
          <div className="order-details-payment">
            <div className="order-details-payment-row d-flex flex-lg-row flex-column mb-4">
              <div className="order-details-payment-row-title">{t("payment")}</div>
              <div className="order-details-payment-row-value">
                {orderDetails?.orderStatusMetadata?.paymentStatus}
              </div>
            </div>
            <div className="order-details-payment-row d-flex flex-lg-row flex-column mb-4">
              <div className="order-details-payment-row-title">{t("delivery")}</div>
              <div className="order-details-payment-row-value">
                {orderDetails?.orderStatusMetadata?.deliveryStatus}
              </div>
            </div>
            <div className="order-details-payment-row d-flex flex-lg-row flex-column mb-4">
              <div className="order-details-payment-row-title">{t("inAll")}</div>
              <div className="order-details-payment-row-value__inAll">
                {priceWithCurrency(orderDetails?.price * orderDetails?.quantity)}
              </div>
            </div>
          </div>
        </div>
      </RequestHandler>
    </div>
  );
};
