import {
  GET_ORDER_DETAILS_FAILURE,
  GET_ORDER_DETAILS_REQUEST_SENT,
  GET_ORDER_DETAILS_SUCCESSFUL,
  GET_ORDERS_BY_USER_ID_FAILURE,
  GET_ORDERS_BY_USER_ID_REQUEST_SENT,
  GET_ORDERS_BY_USER_ID_SUCCESSFUL,
} from "./constants";

const initialState = {
  isOrdersLoading: false,
  orderIdDetailsLoading: false,
  errorMessage: null,
  successMessage: null,
  ordersList: null,
};

const myOrdersReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ORDERS_BY_USER_ID_REQUEST_SENT:
      return {
        ...state,
        isOrdersLoading: true,
        errorMessage: null,
      };
    case GET_ORDERS_BY_USER_ID_SUCCESSFUL:
      return {
        ...state,
        isOrdersLoading: false,
        errorMessage: null,
        ordersList: action.payload,
      };
    case GET_ORDERS_BY_USER_ID_FAILURE:
      return {
        ...state,
        isOrdersLoading: false,
        errorMessage: action.message,
      };
    case GET_ORDER_DETAILS_REQUEST_SENT:
      return {
        ...state,
        orderIdDetailsLoading: action?.orderId,
        errorMessage: null,
      };
    case GET_ORDER_DETAILS_SUCCESSFUL:
      return {
        ...state,
        orderIdDetailsLoading: null,
        errorMessage: null,
        ordersList: state?.ordersList.map((el) => {
          return {
            date: el.date,
            orders: el.orders.map((order) => {
              return +order.orderId === +action.payload.orderId
                ? { ...order, details: action.payload }
                : order;
            }),
          };
        }),
      };
    case GET_ORDER_DETAILS_FAILURE:
      return {
        ...state,
        orderIdDetailsLoading: null,
        errorMessage: action.message,
      };
    default:
      return state;
  }
};

export default myOrdersReducer;
