import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { RequestHandler } from "../../../../Components/RequestHandler/RequestHandler";
import {
  useLazyGetSellsgramFollowingsQuery,
} from "../../../../Api/subscriptions";
import SubscribeToggleButton from './SubscribeToggleButton';
import { xor } from "lodash";

const getUrl = () => {
  if (window.location.href.includes(process.env.REACT_APP_URL_DEVELOPMENT))
    return process.env.REACT_APP_URL_DEVELOPMENT;
  else if (window.location.href.includes(process.env.REACT_APP_URL_PRODUCTION))
    return "https://" + process.env.REACT_APP_URL_PRODUCTION;
  return process.env.REACT_APP_URL_LOCALHOST;
};

const SubscriptionsModalComponent = ({ showAddModal, setShowAddModal, userId }) => {
  const [
    getSellsgramFollowings,
    { data: sellsgramFollowingsData, isFetching, isLoading },
  ] = useLazyGetSellsgramFollowingsQuery();
  const [unsubscribed, setUnsubscribed] = useState([]);
  const [searchedTxt, setSearchedTxt] = useState("");

  const [searchedUsers, setSearchedUsers] = useState([]);

  useEffect(() => {
    if (userId) {
      getSellsgramFollowings(userId);
    }
  }, [userId]);
  const { t } = useTranslation();

  const onSearchChange = (e) => {
    setSearchedTxt(e.target.value);
    setSearchedUsers(
      sellsgramFollowingsData.filter((user) =>
        user.userName.toLowerCase().includes(e.target.value.toLowerCase()),
      ),
    );
  };

  const renderGroups = sellsgramFollowingsData?.length ? (
    ((searchedUsers.length && searchedUsers) || sellsgramFollowingsData).map(
      (data, key) => (
        <div key={key} className="row-spacebtwn">
          <div className="flex-row">
            <a href={`${getUrl()}/${data.userName}/`}>
              <img
                alt="#"
                src={data.profileImageLink}
                className="subscription-group-img"
              />
            </a>
            <div>
              <a href={`${getUrl()}/${data.userName}/`}>
                <p className="subscription-group-name">{data.userName}</p>{" "}
              </a>
            </div>
          </div>
          <SubscribeToggleButton
            unsubscribed={unsubscribed.includes(data.followingUserID)}
            userId={userId}
            id={data.followingUserID}
            toggleSubscribe={() => setUnsubscribed((followingIDs) => xor(followingIDs, [data.followingUserID]))}
          />
        </div>
      ),
    )
  ) : (
    <p className="noGroupsTxt">There are no groups</p>
  );

  return (
    <Modal
      show={showAddModal}
      contentClassName="group-add-modal"
      onHide={setShowAddModal}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <button className="close" onClick={() => setShowAddModal(false)}>
          <img src="/assets/images/icon-cross.svg" alt="" aria-hidden="true" />
        </button>
        <RequestHandler isLoading={isFetching || isLoading} withAutoHeight>
          <h2>{t("subscriptions")}</h2>
          <input
            placeholder={t("searchYourSubscription")}
            className="search-boxinput"
            value={searchedTxt}
            onChange={onSearchChange}
          />
          <div className="groups-container">{renderGroups}</div>
        </RequestHandler>
      </Modal.Body>
    </Modal>
  );
};

export default SubscriptionsModalComponent;
