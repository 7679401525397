import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { toast } from "react-toastify";

import {
  addProductAdditionalOptions,
  editProductAdditionalOptions,
} from "../../../Redux/Products/actions";
import SketchHelper from "./utils/SketchHelper";

const AdditionalPropertiesForm = ({
  addProductAdditionalOptions,
  productAdditionalOptions,
  productId,
  getAdditionalOptionsByProduct,
  setToggleTableContent,
  setProductAdditionalOptions,
  editProductAdditionalOptions,
  additionalOption = undefined,
  updatable = undefined,
}) => {
  const [materialName, setMaterialName] = useState(
    additionalOption ? additionalOption.materialName : "",
  );
  const [sizeName, setSizeName] = useState(
    additionalOption ? additionalOption.sizeName : "",
  );
  const [colorScheme, setColorScheme] = useState(
    additionalOption ? additionalOption.colorScheme : "",
  );
  const [price, setColorPrice] = useState(additionalOption ? additionalOption.price : "");
  const [quantity, setColorQuantity] = useState(
    additionalOption ? additionalOption.quantity : "",
  );
  const { t } = useTranslation();
  const submitAdd = useCallback(() => {
    const payload = { productId, colorScheme, materialName, sizeName, price, quantity };
    if (setProductAdditionalOptions) {
      if (
        !productAdditionalOptions.find(
          (item) =>
            item.sizeName === sizeName &&
            item.colorScheme === colorScheme &&
            item.materialName === materialName,
        )
      )
        setProductAdditionalOptions((prev) => [...prev, payload]);
      else toast.warning(t("youAlreadyHaveThisOption"));

      setToggleTableContent(false);
    } else {
      addProductAdditionalOptions(payload, getAdditionalOptionsByProduct, productId);
    }
  }, [materialName, sizeName, colorScheme, price, quantity]);

  const submitEdit = useCallback(() => {
    const payload = {
      ...additionalOption,
      colorScheme,
      materialName,
      sizeName,
      price,
      quantity,
    };
    if (setProductAdditionalOptions) {      
      setProductAdditionalOptions((prev) =>
      prev.map((o, i) => (i === updatable ? payload : o)),
    );
      setToggleTableContent(false);
    } else {
      editProductAdditionalOptions(
        additionalOption.productOptionId,
        payload,
        getAdditionalOptionsByProduct,
        productId,
      ).then(() => setToggleTableContent(false));
    }
  }, [materialName, sizeName, colorScheme, price, quantity]);

  return (
    <tr>
      <td>
        <SketchHelper
          updatable={updatable !== undefined}
          setColorScheme={setColorScheme}
          defColor={colorScheme}
        />
      </td>
      <td>
        <input
          name="name"
          type={"textMaterial"}
          value={materialName}
          onChange={(e) => setMaterialName(e.target.value)}
        />
      </td>
      <td>
        <input
          name="name"
          type={"textSize"}
          value={sizeName}
          onChange={(e) => setSizeName(e.target.value)}
        />
      </td>
      <td>
        <input
          name="price"
          type={"number"}
          value={price}
          onChange={(e) => setColorPrice(e.target.value)}
        />
      </td>
      <td>
        <input
          name="quantity"
          type={"number"}
          value={quantity}
          onChange={(e) => setColorQuantity(e.target.value)}
        />
      </td>
      <td>
        <img
          style={{ cursor: "pointer" }}
          onClick={() => setToggleTableContent(false)}
          src="/assets/images/icon-cross.svg"
          alt=""
        />
      </td>
      <td>
        <img
          style={{ cursor: "pointer", width: "1.2em" }}
          onClick={() => (additionalOption ? submitEdit() : submitAdd())}
          src={`/assets/images/${
            additionalOption ? "check-circle.png" : "icon-add-other.svg"
          }`}
          alt=""
        />
      </td>
    </tr>
  );
};

AdditionalPropertiesForm.propTypes = {};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {
  addProductAdditionalOptions,
  editProductAdditionalOptions,
})(AdditionalPropertiesForm);
