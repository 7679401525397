import React from "react";

export const EditGroupIcon = () => {
  return (
    <svg
      width="112"
      height="112"
      viewBox="0 0 112 112"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_430_5837)">
        <circle cx="56" cy="32" r="15.5" fill="#EDF1F7" stroke="#DDDDDD" />
        <path
          d="M63.4596 24.54C62.9005 23.9809 62.1421 23.6667 61.3513 23.6667C60.5605 23.6667 59.8021 23.9809 59.243 24.54L49.283 34.5C48.9443 34.8388 48.7063 35.2649 48.5955 35.7309L47.683 39.565C47.6583 39.6689 47.6606 39.7774 47.6897 39.8801C47.7188 39.9828 47.7738 40.0764 47.8493 40.1518C47.9249 40.2273 48.0185 40.2822 48.1212 40.3112C48.224 40.3402 48.3325 40.3424 48.4363 40.3175L52.2705 39.4042C52.7365 39.2933 53.1626 39.0553 53.5013 38.7167L63.4596 28.7567C64.0188 28.1975 64.3329 27.4391 64.3329 26.6484C64.3329 25.8576 64.0188 25.0992 63.4596 24.54ZM60.1263 25.4234C60.2872 25.2625 60.4782 25.1349 60.6883 25.0478C60.8985 24.9608 61.1238 24.916 61.3513 24.916C61.5788 24.916 61.8041 24.9608 62.0143 25.0478C62.2245 25.1349 62.4154 25.2625 62.5763 25.4234C62.7372 25.5842 62.8648 25.7752 62.9519 25.9854C63.0389 26.1956 63.0837 26.4209 63.0837 26.6484C63.0837 26.8759 63.0389 27.1011 62.9519 27.3113C62.8648 27.5215 62.7372 27.7125 62.5763 27.8734L61.833 28.6159L59.383 26.1667L60.1263 25.425V25.4234ZM58.4996 27.0517L60.9496 29.5L52.6163 37.8334C52.4413 38.0084 52.2213 38.1309 51.9805 38.1884L49.1338 38.8659L49.8113 36.0192C49.8688 35.7792 49.9921 35.5584 50.1671 35.3834L58.4996 27.05V27.0517Z"
          fill="#808488"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_430_5837"
          x="0"
          y="0"
          width="112"
          height="112"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="24" />
          <feGaussianBlur stdDeviation="20" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.294118 0 0 0 0 0.521569 0 0 0 0 0.862745 0 0 0 0.16 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_430_5837"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_430_5837"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};
