export const GET_BUYER_GENERAL_INFO_REQUEST_SENT = "GET_BUYER_GENERAL_INFO_REQUEST_SENT";
export const GET_BUYER_GENERAL_INFO_SUCCESSFUL = "GET_BUYER_GENERAL_INFO_SUCCESSFUL";
export const GET_BUYER_GENERAL_INFO_FAILURE = "GET_BUYER_GENERAL_INFO_FAILURE";

export const GET_BUYER_FEED_REQUEST_SENT = "GET_BUYER_FEED_REQUEST_SENT";
export const GET_BUYER_FEED_SUCCESSFUL = "GET_BUYER_FEED_SUCCESSFUL";
export const GET_BUYER_FEED_FAILURE = "GET_BUYER_FEED_FAILURE";

export const GET_BUYER_SWITCH_TO_SELLER_REQUEST_SENT =
  "GET_BUYER_SWITCH_TO_SELLER_REQUEST_SENT";
export const GET_BUYER_SWITCH_TO_SELLER_SUCCESSFUL =
  "GET_BUYER_SWITCH_TO_SELLER_SUCCESSFUL";
export const GET_BUYER_SWITCH_TO_SELLER_FAILURE = "GET_BUYER_SWITCH_TO_SELLER_FAILURE";

export const SWITCH_BUYER_TO_SELLER_SUCCESSFUL = "SWITCH_BUYER_TO_SELLER_SUCCESSFUL";
export const SWITCH_BUYER_TO_SELLER_FAILURE = "SWITCH_BUYER_TO_SELLER_FAILURE";
export const SWITCH_BUYER_TO_SELLER_REQUEST_SENT = "SWITCH_BUYER_TO_SELLER_REQUEST_SENT";
