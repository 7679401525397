import axios from "axios";
import { toast } from "react-toastify";

import { BASEURL } from "../../Configs/constants";
import {
  ADD_PRODUCT_COLOR_FAILURE,
  ADD_PRODUCT_COLOR_REQUEST_SENT,
  ADD_PRODUCT_COLOR_SUCCESSFUL,
  ADD_PRODUCT_IMAGES_FAILURE,
  ADD_PRODUCT_IMAGES_REQUEST_SENT,
  ADD_PRODUCT_IMAGES_SUCCESSFUL,
  ADD_PRODUCT_MATERIALS_FAILURE,
  ADD_PRODUCT_MATERIALS_REQUEST_SENT,
  ADD_PRODUCT_MATERIALS_SUCCESSFUL,
  ADD_PRODUCT_OTHER_FAILURE,
  ADD_PRODUCT_OTHER_REQUEST_SENT,
  ADD_PRODUCT_OTHER_SUCCESSFUL,
  ADD_PRODUCT_SIZE_FAILURE,
  ADD_PRODUCT_SIZE_REQUEST_SENT,
  ADD_PRODUCT_SIZE_SUCCESSFUL,
  CREATE_PRODUCT_FAILURE,
  CREATE_PRODUCT_REQUEST_SENT,
  CREATE_PRODUCT_SUCCESSFUL,
  DELETE_PRODUCT_COLOR_FAILURE,
  DELETE_PRODUCT_COLOR_REQUEST_SENT,
  DELETE_PRODUCT_COLOR_SUCCESSFUL,
  DELETE_PRODUCT_FAILURE,
  DELETE_PRODUCT_IMAGE_FAILURE,
  DELETE_PRODUCT_IMAGE_REQUEST_SENT,
  DELETE_PRODUCT_IMAGE_SUCCESSFUL,
  DELETE_PRODUCT_MATERIAL_FAILURE,
  DELETE_PRODUCT_MATERIAL_REQUEST_SENT,
  DELETE_PRODUCT_MATERIAL_SUCCESSFUL,
  DELETE_PRODUCT_OTHER_FAILURE,
  DELETE_PRODUCT_OTHER_REQUEST_SENT,
  DELETE_PRODUCT_OTHER_SUCCESSFUL,
  DELETE_PRODUCT_REQUEST_SENT,
  DELETE_PRODUCT_SIZE_FAILURE,
  DELETE_PRODUCT_SIZE_REQUEST_SENT,
  DELETE_PRODUCT_SIZE_SUCCESSFUL,
  DELETE_PRODUCT_SUCCESSFUL,
  EDIT_PRODUCT_COLOR_FAILURE,
  EDIT_PRODUCT_COLOR_REQUEST_SENT,
  EDIT_PRODUCT_COLOR_SUCCESSFUL,
  EDIT_PRODUCT_MATERIAL_FAILURE,
  EDIT_PRODUCT_MATERIAL_REQUEST_SENT,
  EDIT_PRODUCT_MATERIAL_SUCCESSFUL,
  EDIT_PRODUCT_OTHER_FAILURE,
  EDIT_PRODUCT_OTHER_REQUEST_SENT,
  EDIT_PRODUCT_OTHER_SUCCESSFUL,
  EDIT_PRODUCT_SIZE_FAILURE,
  EDIT_PRODUCT_SIZE_REQUEST_SENT,
  EDIT_PRODUCT_SIZE_SUCCESSFUL,
  GET_COLORS_BY_PRODUCT_FAILURE,
  GET_COLORS_BY_PRODUCT_REQUEST_SENT,
  GET_COLORS_BY_PRODUCT_SUCCESSFUL,
  GET_MATERIALS_BY_PRODUCT_FAILURE,
  GET_MATERIALS_BY_PRODUCT_REQUEST_SENT,
  GET_MATERIALS_BY_PRODUCT_SUCCESSFUL,
  GET_OTHERS_BY_PRODUCT_FAILURE,
  GET_OTHERS_BY_PRODUCT_REQUEST_SENT,
  GET_OTHERS_BY_PRODUCT_SUCCESSFUL,
  GET_PRODUCT_IMAGES_FAILURE,
  GET_PRODUCT_IMAGES_REQUEST_SENT,
  GET_PRODUCT_IMAGES_SUCCESSFUL,
  GET_PRODUCT_STATIC_COLORS_FAILURE,
  GET_PRODUCT_STATIC_COLORS_REQUEST_SENT,
  GET_PRODUCT_STATIC_COLORS_SUCCESSFUL,
  GET_PRODUCT_STATIC_MATERIALS_FAILURE,
  GET_PRODUCT_STATIC_MATERIALS_REQUEST_SENT,
  GET_PRODUCT_STATIC_MATERIALS_SUCCESSFUL,
  GET_PRODUCT_STATIC_SIZES_FAILURE,
  GET_PRODUCT_STATIC_SIZES_REQUEST_SENT,
  GET_PRODUCT_STATIC_SIZES_SUCCESSFUL,
  GET_PRODUCTS_FAILURE,
  GET_PRODUCTS_REQUEST_SENT,
  GET_PRODUCTS_SUCCESSFUL,
  GET_SIZES_BY_PRODUCT_FAILURE,
  GET_SIZES_BY_PRODUCT_REQUEST_SENT,
  GET_SIZES_BY_PRODUCT_SUCCESSFUL,
  GET_USERS_PRODUCT_FAILURE,
  GET_USERS_PRODUCT_REQUEST_SENT,
  GET_USERS_PRODUCT_SUCCESSFUL,
  UPDATE_PRODUCT_FAILURE,
  UPDATE_PRODUCT_REQUEST_SENT,
  UPDATE_PRODUCT_SUCCESSFUL,
  GET_ADDITIONAL_OPTIONS_BY_PRODUCT_FAILURE,
  GET_ADDITIONAL_OPTIONS_BY_PRODUCT_REQUEST_SENT,
  GET_ADDITIONAL_OPTIONS_BY_PRODUCT_SUCCESSFUL,
  DELETE_ADDITIONAL_OPTIONS_BY_PRODUCT_FAILURE,
  DELETE_ADDITIONAL_OPTIONS_BY_PRODUCT_SUCCESSFUL,
  DELETE_ADDITIONAL_OPTIONS_BY_PRODUCT_REQUEST_SENT,
  ADD_ADDITIONAL_OPTIONS_BY_PRODUCT_SUCCESSFUL,
  ADD_ADDITIONAL_OPTIONS_BY_PRODUCT_FAILURE,
  ADD_ADDITIONAL_OPTIONS_BY_PRODUCT_REQUEST_SENT,
  EDIT_ADDITIONAL_OPTIONS_BY_PRODUCT_REQUEST_SENT,
  EDIT_ADDITIONAL_OPTIONS_BY_PRODUCT_SUCCESSFUL,
  EDIT_ADDITIONAL_OPTIONS_BY_PRODUCT_FAILURE,
} from "./constants";
import { getAccessToken2 } from "../../Helpers/auth";

export const getProducts =
  (userId, fromUserId, productStatus = 0) =>
  async (dispatch) => {
    const countryId = localStorage.getItem("language") === "ua" ? 1 : 217;

    try {
      dispatch({
        type: GET_PRODUCTS_REQUEST_SENT,
      });
      const resp = await axios.get(
        `${BASEURL}/api/web/sellsgram/product/all/by-user/${userId}?FromUserId=${fromUserId}&CountryID=${countryId}&ProductStatus=${productStatus} `,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: getAccessToken2(),
          },
        },
      );
      dispatch({
        type: GET_PRODUCTS_SUCCESSFUL,
        payload: resp.data,
      });
    } catch (err) {
      dispatch({
        type: GET_PRODUCTS_FAILURE,
        message: err.response?.data ? err.response.data.errors.id[0] : err.message,
      });
    }
  };

export const getProductImages = (productId) => async (dispatch) => {
  try {
    dispatch({
      type: GET_PRODUCT_IMAGES_REQUEST_SENT,
    });
    const resp = await axios.get(
      `${BASEURL}/api/product/ProductImages/all/${productId}`,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: getAccessToken2(),
        },
      },
    );
    dispatch({
      type: GET_PRODUCT_IMAGES_SUCCESSFUL,
      payload: resp.data,
    });
  } catch (err) {
    dispatch({
      type: GET_PRODUCT_IMAGES_FAILURE,
      message: err.response?.data ? err.response.data.errors.id[0] : err.message,
    });
  }
};

export const getProductStaticColors = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_PRODUCT_STATIC_COLORS_REQUEST_SENT,
    });
    const resp = await axios.get(`${BASEURL}/api/metadata/product/_Color`, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: getAccessToken2(),
      },
    });
    dispatch({
      type: GET_PRODUCT_STATIC_COLORS_SUCCESSFUL,
      payload: resp.data,
    });
  } catch (err) {
    dispatch({
      type: GET_PRODUCT_STATIC_COLORS_FAILURE,
      message: err.response?.data ? err.response.data.errors.id[0] : err.message,
    });
  }
};

export const getProductStaticMaterials = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_PRODUCT_STATIC_MATERIALS_REQUEST_SENT,
    });
    const resp = await axios.get(`${BASEURL}/api/metadata/product/_Material`, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: getAccessToken2(),
      },
    });
    dispatch({
      type: GET_PRODUCT_STATIC_MATERIALS_SUCCESSFUL,
      payload: resp.data,
    });
  } catch (err) {
    dispatch({
      type: GET_PRODUCT_STATIC_MATERIALS_FAILURE,
      message: err.response?.data ? err.response.data.errors.id[0] : err.message,
    });
  }
};

export const getProductStaticSizes = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_PRODUCT_STATIC_SIZES_REQUEST_SENT,
    });
    const resp = await axios.get(`${BASEURL}/api/metadata/product/_Size`, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: getAccessToken2(),
      },
    });
    dispatch({
      type: GET_PRODUCT_STATIC_SIZES_SUCCESSFUL,
      payload: resp.data,
    });
  } catch (err) {
    dispatch({
      type: GET_PRODUCT_STATIC_SIZES_FAILURE,
      message: err.response?.data ? err.response.data.errors.id[0] : err.message,
    });
  }
};

export const addProductColor = (payload, getColorsByProduct, id) => async (dispatch) => {
  try {
    dispatch({
      type: ADD_PRODUCT_COLOR_REQUEST_SENT,
    });
    const resp = await axios.post(
      `${BASEURL}/api/web/sellsgram/product/colors/`,
      payload,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: getAccessToken2(),
        },
      },
    );

    //console.log(resp);
    dispatch({
      type: ADD_PRODUCT_COLOR_SUCCESSFUL,
      payload: resp.data,
    });
    getColorsByProduct(id);
  } catch (err) {
    toast.error(err.response.data);

    dispatch({
      type: ADD_PRODUCT_COLOR_FAILURE,
      message: err.response?.data ? err.response.data : err.message,
    });
  }
};

export const addProductMaterial =
  (payload, getMaterialsByProduct, id) => async (dispatch) => {
    try {
      dispatch({
        type: ADD_PRODUCT_MATERIALS_REQUEST_SENT,
      });
      const resp = await axios.post(
        `${BASEURL}/api/web/sellsgram/product/materials`,
        payload,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: getAccessToken2(),
          },
        },
      );

      //console.log(resp);
      dispatch({
        type: ADD_PRODUCT_MATERIALS_SUCCESSFUL,
        payload: resp.data,
      });
      getMaterialsByProduct(id);
    } catch (err) {
      toast.error(err.response.data);

      dispatch({
        type: ADD_PRODUCT_MATERIALS_FAILURE,
        message: err.response?.data ? err.response.data.errors : err.message,
      });
    }
  };

export const addProductSize = (payload, getSizesByProduct, id) => async (dispatch) => {
  try {
    dispatch({
      type: ADD_PRODUCT_SIZE_REQUEST_SENT,
    });
    const resp = await axios.post(`${BASEURL}/api/web/sellsgram/product/sizes`, payload, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: getAccessToken2(),
      },
    });

    dispatch({
      type: ADD_PRODUCT_SIZE_SUCCESSFUL,
      payload: resp.data,
    });
    getSizesByProduct(id);
  } catch (err) {
    toast.error(err.response.data);

    dispatch({
      type: ADD_PRODUCT_SIZE_FAILURE,
      message: err.response?.data ? err.response.data.errors : err.message,
    });
  }
};

export const addProductOther = (payload, getOthersByProduct, id) => async (dispatch) => {
  try {
    dispatch({
      type: ADD_PRODUCT_OTHER_REQUEST_SENT,
    });
    const resp = await axios.post(
      `${BASEURL}/api/web/sellsgram/product/others`,
      payload,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: getAccessToken2(),
        },
      },
    );

    dispatch({
      type: ADD_PRODUCT_OTHER_SUCCESSFUL,
      payload: resp.data,
    });
    getOthersByProduct(id);
  } catch (err) {
    toast.error(err.response.data);

    dispatch({
      type: ADD_PRODUCT_OTHER_FAILURE,
      message: err.response?.data ? err.response.data.errors : err.message,
    });
  }
};

export const editProductOther =
  (othersId, payload, getOthersByProduct, productId) => async (dispatch) => {
    try {
      dispatch({
        type: EDIT_PRODUCT_OTHER_REQUEST_SENT,
      });
      const resp = await axios.put(
        `${BASEURL}/api/web/sellsgram/product/others/${othersId}`,
        payload,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: getAccessToken2(),
          },
        },
      );

      dispatch({
        type: EDIT_PRODUCT_OTHER_SUCCESSFUL,
        payload: resp.data,
      });
      getOthersByProduct(productId);
    } catch (err) {
      toast.error(err.response.data);

      dispatch({
        type: EDIT_PRODUCT_OTHER_FAILURE,
        message: err.response?.data ? err.response.data.errors : err.message,
      });
    }
  };

export const editProductMaterial =
  (materialId, payload, getMaterialsByProduct, productId) => async (dispatch) => {
    try {
      dispatch({
        type: EDIT_PRODUCT_MATERIAL_REQUEST_SENT,
      });
      const resp = await axios.put(
        `${BASEURL}/api/web/sellsgram/product/materials/${materialId}`,
        payload,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: getAccessToken2(),
          },
        },
      );

      dispatch({
        type: EDIT_PRODUCT_MATERIAL_SUCCESSFUL,
        payload: resp.data,
      });
      getMaterialsByProduct(productId);
    } catch (err) {
      toast.error(err.response.data);

      dispatch({
        type: EDIT_PRODUCT_MATERIAL_FAILURE,
        message: err.response?.data ? err.response.data.errors : err.message,
      });
    }
  };

export const editProductColor =
  (colorId, payload, getColorsByProduct, productId) => async (dispatch) => {
    try {
      dispatch({
        type: EDIT_PRODUCT_COLOR_REQUEST_SENT,
      });
      const resp = await axios.put(
        `${BASEURL}/api/web/sellsgram/product/colors/${colorId}`,
        payload,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: getAccessToken2(),
          },
        },
      );

      dispatch({
        type: EDIT_PRODUCT_COLOR_SUCCESSFUL,
        payload: resp.data,
      });
      await getColorsByProduct(productId);
    } catch (err) {
      toast.error(err.response.data);

      dispatch({
        type: EDIT_PRODUCT_COLOR_FAILURE,
        message: err.response?.data ? err.response.data.errors : err.message,
      });
    }
  };

export const editProductSize =
  (sizeId, payload, getSizesByProduct, productId) => async (dispatch) => {
    try {
      dispatch({
        type: EDIT_PRODUCT_SIZE_REQUEST_SENT,
      });
      const resp = await axios.put(
        `${BASEURL}/api/web/sellsgram/product/sizes/${sizeId}`,
        payload,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: getAccessToken2(),
          },
        },
      );

      dispatch({
        type: EDIT_PRODUCT_SIZE_SUCCESSFUL,
        payload: resp.data,
      });
      getSizesByProduct(productId);
    } catch (err) {
      toast.error(err.response.data);

      dispatch({
        type: EDIT_PRODUCT_SIZE_FAILURE,
        message: err.response?.data ? err.response.data.errors : err.message,
      });
    }
  };

export const addProductURLImages =
  ({ productId, imageURL }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: ADD_PRODUCT_IMAGES_REQUEST_SENT,
      });
      const resp = await axios.post(
        `${BASEURL}/api/product/ProductImages/upload-url?url=${encodeURIComponent(imageURL)}&productId=${productId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: getAccessToken2(),
          },
        },
      );
      dispatch({
        type: ADD_PRODUCT_IMAGES_SUCCESSFUL,
        payload: resp.data,
      });
    } catch (err) {
      dispatch({
        type: ADD_PRODUCT_IMAGES_FAILURE,
        message: err.response?.data ? err.response.data.errors : err.message,
      });
    }
  };

export const addProductImages =
  ({ productId, image }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: ADD_PRODUCT_IMAGES_REQUEST_SENT,
      });
      const resp = await axios.post(
        `${BASEURL}/api/product/ProductImages/UploadFile?ProductID=${productId}`,
        image,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: getAccessToken2(),
          },
        },
      );
      dispatch({
        type: ADD_PRODUCT_IMAGES_SUCCESSFUL,
        payload: resp.data,
      });
    } catch (err) {
      dispatch({
        type: ADD_PRODUCT_IMAGES_FAILURE,
        message: err.response?.data ? err.response.data.errors : err.message,
      });
    }
  };

export const createProduct = (payload) => async (dispatch) => {
  try {
    dispatch({
      type: CREATE_PRODUCT_REQUEST_SENT,
    });
    const resp = await axios.post(`${BASEURL}/api/web/sellsgram/product`, payload, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: getAccessToken2(),
      },
    });
    dispatch({
      type: CREATE_PRODUCT_SUCCESSFUL,
      payload: resp.data,
    });
    return resp.data;
  } catch (err) {
    dispatch({
      type: CREATE_PRODUCT_FAILURE,
      message: err.response?.data ? err.response.data.errors : err.message,
    });
  }
};

export const updateProduct = (id, payload, navigation, t) => async (dispatch) => {
  try {
    dispatch({
      type: UPDATE_PRODUCT_REQUEST_SENT,
    });
    const resp = await axios.put(`${BASEURL}/api/web/sellsgram/product/${id}`, payload, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: getAccessToken2(),
      },
    });

    toast.success(t("product-updated"));

    dispatch({
      type: UPDATE_PRODUCT_SUCCESSFUL,
      payload: resp.data,
    });

    navigation.push("/");
  } catch (err) {
    toast.error(err.response.data);

    dispatch({
      type: UPDATE_PRODUCT_FAILURE,
      message: err.response?.data ? err.response.data.errors : err.message,
    });
  }
};

export const getUsersProduct = (userId) => async (dispatch) => {
  try {
    dispatch({
      type: GET_USERS_PRODUCT_REQUEST_SENT,
    });
    const resp = await axios.get(`${BASEURL}/api/web/sellsgram/product/${userId}`, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: getAccessToken2(),
      },
    });
    dispatch({
      type: GET_USERS_PRODUCT_SUCCESSFUL,
      payload: resp.data,
    });
  } catch (err) {
    dispatch({
      type: GET_USERS_PRODUCT_FAILURE,
      message: err.response?.data ? err.response.data.errors : err.message,
    });
  }
};

export const getColorsByProduct = (productId) => async (dispatch) => {
  try {
    dispatch({
      type: GET_COLORS_BY_PRODUCT_REQUEST_SENT,
    });
    const resp = await axios.get(
      `${BASEURL}/api/web/sellsgram/product/colors/by-product/${productId}`,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: getAccessToken2(),
        },
      },
    );
    dispatch({
      type: GET_COLORS_BY_PRODUCT_SUCCESSFUL,
      payload: resp.data,
    });
  } catch (err) {
    dispatch({
      type: GET_COLORS_BY_PRODUCT_FAILURE,
      message: err.response?.data ? err.response.data.errors : err.message,
    });
  }
};
export const editProductAdditionalOptions =
  (productOptionId, payload, getAdditionalOptionsByProduct, productId) =>
  async (dispatch) => {
    try {
      dispatch({
        type: EDIT_ADDITIONAL_OPTIONS_BY_PRODUCT_REQUEST_SENT,
      });
      const resp = await axios.put(
        `${BASEURL}/api/web/sellsgram/product-options/${productOptionId}`,
        payload,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: getAccessToken2(),
          },
        },
      );

      dispatch({
        type: EDIT_ADDITIONAL_OPTIONS_BY_PRODUCT_SUCCESSFUL,
        payload: resp.data,
      });
      await getAdditionalOptionsByProduct(productId);
    } catch (err) {
      toast.error(err.response.data);

      dispatch({
        type: EDIT_ADDITIONAL_OPTIONS_BY_PRODUCT_FAILURE,
        message: err.response?.data ? err.response.data.errors : err.message,
      });
    }
  };
export const addProductAdditionalOptions =
  (payload, getAdditionalOptionsByProduct, productId) => async (dispatch) => {
    try {
      dispatch({
        type: ADD_ADDITIONAL_OPTIONS_BY_PRODUCT_REQUEST_SENT,
      });
      const resp = await axios.post(
        `${BASEURL}/api/web/sellsgram/product-options/${productId}`,
        payload,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: getAccessToken2(),
          },
        },
      );

      dispatch({
        type: ADD_ADDITIONAL_OPTIONS_BY_PRODUCT_SUCCESSFUL,
        payload: resp.data,
      });
      getAdditionalOptionsByProduct(productId);
    } catch (err) {
      toast.error(err.response.data);
      dispatch({
        type: ADD_ADDITIONAL_OPTIONS_BY_PRODUCT_FAILURE,
        message: err.response?.data ? err.response.data : err.message,
      });
    }
  };
export const getAdditionalOptionsByProduct = (productId) => async (dispatch) => {
  try {
    dispatch({
      type: GET_ADDITIONAL_OPTIONS_BY_PRODUCT_REQUEST_SENT,
    });
    const resp = await axios.get(
      `${BASEURL}/api/web/sellsgram/product-options/${productId}`,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: getAccessToken2(),
        },
      },
    );
    dispatch({
      type: GET_ADDITIONAL_OPTIONS_BY_PRODUCT_SUCCESSFUL,
      payload: resp.data,
    });
  } catch (err) {
    toast.error(err.response.data);
    dispatch({
      type: GET_ADDITIONAL_OPTIONS_BY_PRODUCT_FAILURE,
      message: err.response?.data ? err.response.data.errors : err.message,
    });
  }
};
export const deleteAdditionalOptions =
  (productOptionId, getAdditionalOptionsByProduct, productId) => async (dispatch) => {
    try {
      dispatch({
        type: DELETE_ADDITIONAL_OPTIONS_BY_PRODUCT_REQUEST_SENT,
      });
      const resp = await axios.delete(
        `${BASEURL}/api/web/sellsgram/product-options/${productOptionId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: getAccessToken2(),
          },
        },
      );
      dispatch({
        type: DELETE_ADDITIONAL_OPTIONS_BY_PRODUCT_SUCCESSFUL,
        payload: resp.data,
      });
      getAdditionalOptionsByProduct(productId);
    } catch (err) {
      toast.error(err.response.data);
      dispatch({
        type: DELETE_ADDITIONAL_OPTIONS_BY_PRODUCT_FAILURE,
        message: err.response?.data ? err.response.data.errors : err.message,
      });
    }
  };
export const getMaterialsByProduct = (productId) => async (dispatch) => {
  try {
    dispatch({
      type: GET_MATERIALS_BY_PRODUCT_REQUEST_SENT,
    });
    const resp = await axios.get(
      `${BASEURL}/api/web/sellsgram/product/materials/by-product/${productId}`,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: getAccessToken2(),
        },
      },
    );
    dispatch({
      type: GET_MATERIALS_BY_PRODUCT_SUCCESSFUL,
      payload: resp.data,
    });
  } catch (err) {
    dispatch({
      type: GET_MATERIALS_BY_PRODUCT_FAILURE,
      message: err.response?.data ? err.response.data.errors : err.message,
    });
  }
};

export const getSizesByProduct = (productId) => async (dispatch) => {
  try {
    dispatch({
      type: GET_SIZES_BY_PRODUCT_REQUEST_SENT,
    });
    const resp = await axios.get(
      `${BASEURL}/api/web/sellsgram/product/sizes/by-product/${productId}`,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: getAccessToken2(),
        },
      },
    );
    dispatch({
      type: GET_SIZES_BY_PRODUCT_SUCCESSFUL,
      payload: resp.data,
    });
  } catch (err) {
    dispatch({
      type: GET_SIZES_BY_PRODUCT_FAILURE,
      message: err.response?.data ? err.response.data.errors : err.message,
    });
  }
};

export const getOthersByProduct = (productId) => async (dispatch) => {
  try {
    dispatch({
      type: GET_OTHERS_BY_PRODUCT_REQUEST_SENT,
    });
    const resp = await axios.get(
      `${BASEURL}/api/web/sellsgram/product/others/by-product/${productId}`,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: getAccessToken2(),
        },
      },
    );
    dispatch({
      type: GET_OTHERS_BY_PRODUCT_SUCCESSFUL,
      payload: resp.data,
    });
  } catch (err) {
    dispatch({
      type: GET_OTHERS_BY_PRODUCT_FAILURE,
      message: err.response?.data ? err.response.data.errors : err.message,
    });
  }
};

export const deleteProductImage = (id) => async (dispatch) => {
  try {
    dispatch({
      type: DELETE_PRODUCT_IMAGE_REQUEST_SENT,
    });
    const resp = await axios.delete(`${BASEURL}/api/product/ProductImages/${id}`, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: getAccessToken2(),
      },
    });
    dispatch({
      type: DELETE_PRODUCT_IMAGE_SUCCESSFUL,
      payload: resp.data,
    });
  } catch (err) {
    dispatch({
      type: DELETE_PRODUCT_IMAGE_FAILURE,
      message: err.response?.data ? err.response.data.errors : err.message,
    });
  }
};

export const deleteProductColor =
  (id, getColorsByProduct, colorId) => async (dispatch) => {
    try {
      dispatch({
        type: DELETE_PRODUCT_COLOR_REQUEST_SENT,
      });
      const resp = await axios.delete(
        `${BASEURL}/api/web/sellsgram/product/colors/${id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: getAccessToken2(),
          },
        },
      );
      dispatch({
        type: DELETE_PRODUCT_COLOR_SUCCESSFUL,
        payload: resp.data,
      });
      getColorsByProduct(colorId);
    } catch (err) {
      dispatch({
        type: DELETE_PRODUCT_COLOR_FAILURE,
        message: err.response?.data ? err.response.data.errors : err.message,
      });
    }
  };

export const deleteProductSize = (id, getSizesByProduct, sizeId) => async (dispatch) => {
  try {
    dispatch({
      type: DELETE_PRODUCT_SIZE_REQUEST_SENT,
    });
    const resp = await axios.delete(`${BASEURL}/api/web/sellsgram/product/sizes/${id}`, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: getAccessToken2(),
      },
    });
    dispatch({
      type: DELETE_PRODUCT_SIZE_SUCCESSFUL,
      payload: resp.data,
    });
    getSizesByProduct(sizeId);
  } catch (err) {
    dispatch({
      type: DELETE_PRODUCT_SIZE_FAILURE,
      message: err.response?.data ? err.response.data.errors : err.message,
    });
  }
};

export const deleteProductOther =
  (id, getOthersByProduct, productId) => async (dispatch) => {
    try {
      dispatch({
        type: DELETE_PRODUCT_OTHER_REQUEST_SENT,
      });
      const resp = await axios.delete(
        `${BASEURL}/api/web/sellsgram/product/others/${id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: getAccessToken2(),
          },
        },
      );
      dispatch({
        type: DELETE_PRODUCT_OTHER_SUCCESSFUL,
        payload: resp.data,
      });
      getOthersByProduct(productId);
    } catch (err) {
      dispatch({
        type: DELETE_PRODUCT_OTHER_FAILURE,
        message: err.response?.data ? err.response.data.errors : err.message,
      });
    }
  };

export const deleteProductMaterial =
  (id, getSizesByProduct, materialId) => async (dispatch) => {
    try {
      dispatch({
        type: DELETE_PRODUCT_MATERIAL_REQUEST_SENT,
      });
      const resp = await axios.delete(
        `${BASEURL}/api/web/sellsgram/product/materials/${id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: getAccessToken2(),
          },
        },
      );
      dispatch({
        type: DELETE_PRODUCT_MATERIAL_SUCCESSFUL,
        payload: resp.data,
      });
      getSizesByProduct(materialId);
    } catch (err) {
      dispatch({
        type: DELETE_PRODUCT_MATERIAL_FAILURE,
        message: err.response?.data ? err.response.data.errors : err.message,
      });
    }
  };

export const deleteProduct = (id, navigation, t) => async (dispatch) => {
  try {
    dispatch({
      type: DELETE_PRODUCT_REQUEST_SENT,
    });
    const resp = await axios.delete(`${BASEURL}/api/web/sellsgram/product/${id}`, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: getAccessToken2(),
      },
    });
    toast.success(t("product-deleted"));

    dispatch({
      type: DELETE_PRODUCT_SUCCESSFUL,
      payload: resp.data,
    });

    navigation.push("/");
  } catch (err) {
    toast.error(err.response.data);

    dispatch({
      type: DELETE_PRODUCT_FAILURE,
      message: err.response?.data ? err.response.data.errors : err.message,
    });
  }
};
