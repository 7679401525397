import React from "react";
import { useTranslation } from "react-i18next";

const Delivery = () => {
  const { t } = useTranslation();
  return (
    <div className="help__content">
      <p dangerouslySetInnerHTML={{ __html: t("deliveryAndPayment-text-1") }}></p>
      <p dangerouslySetInnerHTML={{ __html: t("deliveryAndPayment-text-2") }}></p>
      <p dangerouslySetInnerHTML={{ __html: t("deliveryAndPayment-text-3") }}></p>
      <ul>
        <li dangerouslySetInnerHTML={{ __html: t("deliveryAndPayment-text-4") }}></li>
        <li dangerouslySetInnerHTML={{ __html: t("deliveryAndPayment-text-5") }}></li>
        <li dangerouslySetInnerHTML={{ __html: t("deliveryAndPayment-text-6") }}></li>
      </ul>
      <p dangerouslySetInnerHTML={{ __html: t("deliveryAndPayment-text-7") }}></p>
      <p dangerouslySetInnerHTML={{ __html: t("deliveryAndPayment-text-8") }}></p>
      <p dangerouslySetInnerHTML={{ __html: t("deliveryAndPayment-text-9") }}></p>
    </div>
  );
};

export default Delivery;
