import "./ProductProperties.css";

import React, { useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";

import {
  deleteProductMaterial,
  getMaterialsByProduct,
} from "../../../Redux/Products/actions";
import MaterialForm from "../PropertyForms/MaterialForm";

const MaterialTable = ({
  materialsByProduct,
  getMaterialsByProduct,
  deleteProductMaterial,
  productId,
  setProductMaterials,
  productMaterials,
}) => {
  const [updatable, setUpdatable] = useState();
  const [toggleTableContent, setToggleTableContent] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    getMaterialsByProduct(productId);
  }, []);

  return (
    <Table className="gv">
      <thead>
        <tr>
          <th>{t("name")}</th>
          <th>{t("price")}</th>
          <th>{t("qty")}</th>
          <th />
          <th />
        </tr>
      </thead>
      <tbody>
        {productMaterials?.length
          ? productMaterials.map((material, i) =>
              i === updatable ? (
                <MaterialForm
                  key={updatable}
                  updatable={updatable}
                  material={material}
                  productId={productId}
                  getMaterialsByProduct={getMaterialsByProduct}
                  setToggleTableContent={setUpdatable}
                  setProductMaterials={setProductMaterials}
                />
              ) : (
                <tr key={material.productMaterialId}>
                  <td>{material.materialName}</td>
                  <td>{material.materialPrice}</td>
                  <td>{material.materialQuantity}</td>
                  <td>
                    <img
                      onClick={() => setUpdatable(i)}
                      style={{ cursor: "pointer", width: "1.2em" }}
                      src="/assets/images/icon-edit.svg"
                      alt="edit icon"
                    />
                  </td>
                  <td>
                    <img
                      onClick={() =>
                        setProductMaterials((prev) => prev.filter((_, j) => j !== i))
                      }
                      style={{ cursor: "pointer", width: "1.2em" }}
                      src="/assets/images/bin.png"
                      alt="delete icon"
                    />
                  </td>
                </tr>
              ),
            )
          : !productMaterials
          ? materialsByProduct?.length > 0 &&
            materialsByProduct.map((material) =>
              material.productMaterialId === updatable ? (
                <MaterialForm
                  key={updatable}
                  updatable={updatable}
                  material={material}
                  productId={productId}
                  getMaterialsByProduct={getMaterialsByProduct}
                  setToggleTableContent={setUpdatable}
                  setProductMaterials={setProductMaterials}
                />
              ) : (
                <tr key={material.productMaterialId}>
                  <td>{material.materialName}</td>
                  <td>{material.materialPrice}</td>
                  <td>{material.materialQuantity}</td>
                  <td>
                    <img
                      onClick={() => setUpdatable(material.productMaterialId)}
                      style={{ cursor: "pointer", width: "1.2em" }}
                      src="/assets/images/icon-edit.svg"
                      alt="edit icon"
                    />
                  </td>
                  <td>
                    <img
                      onClick={() =>
                        deleteProductMaterial(
                          material.productMaterialId,
                          getMaterialsByProduct,
                          productId,
                        )
                      }
                      style={{ cursor: "pointer", width: "1.2em" }}
                      src="/assets/images/bin.png"
                      alt="delete icon"
                    />
                  </td>
                </tr>
              ),
            )
          : ""}
        {!toggleTableContent ? (
          <tr>
            <td colSpan={5}>
              <button
                onClick={() => setToggleTableContent(true)}
                className="btn btn-add-other d-flex align-items-center w-100 justify-content-center"
                style={{ marginBottom: 10 }}
              >
                {t("material")}
                <img src="/assets/images/icon-add-other.svg" alt="" />
              </button>
            </td>
          </tr>
        ) : (
          <MaterialForm
            productId={productId}
            getMaterialsByProduct={getMaterialsByProduct}
            setToggleTableContent={setToggleTableContent}
            setProductMaterials={setProductMaterials}
          />
        )}
      </tbody>
    </Table>
  );
};

const mapStateToProps = (state) => ({
  materialsByProduct: state.products.materialsByProduct,
});

export default connect(mapStateToProps, {
  getMaterialsByProduct,
  deleteProductMaterial,
})(MaterialTable);
