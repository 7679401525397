import React from "react";
import { useTranslation } from "react-i18next";
import { toLower } from "lodash";

export default ({
  orderDetails: { orderId, payedByCard, currency, productName, subtotal },
}) => {
  const { t } = useTranslation();
  const payedByCardFormat = (payedByCard) =>
    toLower(payedByCard) === "yes" ? "PaymentSuccessful" : "PaymentFailed";
  return (
    <div className="order-tab">
      <div className="tab-header">
        <div className="tab-header_title">
          <h4>{t("orderDetailsOrder", { number: orderId })}</h4>
          {productName && <p>{productName}</p>}
        </div>
      </div>
      <div className="tab-body">
        <div className="body-column">
          <p className="column-title">{t("payedByCard")}</p>
          <p className="column-info">
            {payedByCard ? t(payedByCardFormat(payedByCard)) : "-"}
          </p>
        </div>
        <div className="body-column">
          <p className="column-title">{t("amount")}</p>
          <p className="column-info">
            {subtotal} {currency === "UAH" ? "₴" : "$"}
          </p>
        </div>
      </div>
    </div>
  );
};
