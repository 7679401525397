import React, { useState } from "react";
import { SketchPicker } from "react-color";
import reactCSS from "reactcss";

const SketchHelper = ({
  defColor,
  setColorScheme = undefined,
  updatable = undefined,
}) => {
  const [displayColorPicker, setDisplayColorPicker] = useState();
  const [color, setColor] = useState(defColor);

  const handleClick = () => {
    if (updatable) setDisplayColorPicker(!displayColorPicker);
  };

  const handleClose = () => {
    setDisplayColorPicker(false);
  };

  const handleChange = (c) => {
    setColorScheme(c.hex);
    setColor(c.hex);
  };

  const styles = reactCSS({
    default: {
      color: {
        maxWidth: "36px",
        width: "100%",
        height: "14px",
        borderRadius: "2px",
        background: color,
      },
      swatch: {
        padding: "5px",
        background: "#fff",
        borderRadius: "1px",
        width: "100%",
        maxWidth: "46px",
        boxShadow: "0 0 0 1px rgba(0,0,0,.1)",
        display: "inline-block",
        cursor: "pointer",
        pointerEvents: defColor && !updatable ? "none" : "auto",
      },
      popover: {
        position: "absolute",
        zIndex: "2",
        bottom: "250px",
      },
      cover: {
        position: "fixed",
        top: "0px",
        right: "0px",
        bottom: "0px",
        left: "0px",
      },
    },
  });

  return (
    <div>
      <div style={styles.swatch} onClick={handleClick}>
        <div style={styles.color} />
      </div>
      {displayColorPicker && (
        <div style={styles.popover}>
          <div style={styles.cover} onClick={handleClose} />
          <SketchPicker color={color} onChange={handleChange} />
        </div>
      )}
    </div>
  );
};

export default SketchHelper;
