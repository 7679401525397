import "./BuyerInfo.scss";

import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import SubscriptionsModalComponent from "./SubscriptionsModal/SubscriptionsModalComponent";
import { useDispatch, useSelector } from "react-redux";
import StoryModal from "./StoryModal";
import { setCheckStory } from "../../../Redux/Auth/actions";

const BuyerInfo = (props) => {
  const {
    buyerInfo,
    imageUrl,
    customImgProfile,
    setSettingsModal,
    handleChangeText,
    searchText,
    handleSearchByText,
  } = props;

  const { t } = useTranslation();
  const { userId, checkedStory } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const inputReference = useRef(null);
  const [showSubscriptionModal, setShowSubscriptionModal] = useState(false);
  const [showInstaStoryModal, setShowInstaStoryModal] = useState(false);
  const mobileInputReference = useRef(null);
  const [imgProfile, setImgProfile] = useState(customImgProfile);
  const [showSearch, setShowSearch] = useState(false);
  const history = useHistory();
  const changeViewSearch = () => {
    setShowSearch((prev) => !prev);
  };

  useEffect(() => {
    if (showSearch) {
      const isMobile = window.matchMedia("(max-width: 576px)").matches;
      isMobile ? mobileInputReference.current.focus() : inputReference.current.focus();
    }
  }, [showSearch]);

  useEffect(() => {
    const imageUrl = buyerInfo?.profileImageLink
      ? `${buyerInfo?.profileImageLink}?${Date.now()}`
      : customImgProfile;
    setImgProfile(imageUrl);
  }, [buyerInfo?.profileImageLink]);

  const onShowStory = () => {
    setShowInstaStoryModal(true);
    if (!checkedStory) {
      dispatch(setCheckStory(true));
    }
  };

  const Image = React.memo(
    function Image({ src }) {
      return (
        <div className={`instagram-container ${checkedStory ? "" : "active"}`}>
          <img
            src={src}
            alt="profile img"
            onClick={onShowStory}
            onError={() => {
              const imageErrorUrl = buyerInfo?.profileImageLink
                ? `${buyerInfo?.profileImageLink}?${Date.now()}`
                : customImgProfile;
              setImgProfile(imageErrorUrl);
            }}
          />
        </div>
      );
    },
    [imageUrl],
  );
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleSearchByText();
    }
  };
  const setIsOpenModal = () => {
    setSettingsModal(true);
  };

  return (
    <div className="buyer-profile-topbar-wrapper">
      <StoryModal
        showStoryModal={showInstaStoryModal}
        setShowStoryModal={setShowInstaStoryModal}
      />
      {showSubscriptionModal && (
        <SubscriptionsModalComponent
          userId={userId}
          showAddModal={showSubscriptionModal}
          setShowAddModal={setShowSubscriptionModal}
        />
      )}
      <div className="buyer-profile-topbar  flex-row">
        <div className="buyer-profile-text">
          <div
            className="show-subscriptions-button"
            onClick={() => history.push("/my-orders/")}
          >
            <h4>₴{buyerInfo?.spendings}</h4>
            <p>{t("spent")}</p>
          </div>
          <img
            className="settings-image"
            src="/assets/images/icon-setting.svg"
            alt=""
            onClick={setIsOpenModal}
          />
        </div>
        <div className="buyer-img-wrapper d-flex flex-column justify-content-center align-items-center">
          <div className="buyer-profile-img">
            <Image src={imgProfile} />
          </div>
          <h4> {buyerInfo?.username}</h4>
          <p className="userNameAlign">{t("username")}</p>
        </div>
        <div className="buyer-profile-text">
          <div
            className="show-subscriptions-button"
            onClick={() => setShowSubscriptionModal(true)}
          >
            <h4>{buyerInfo?.subscritpions}</h4>
            <p>{t("subscriptions")}</p>
          </div>
          <div className={`search-wrapper-buyer-info ${showSearch ? "show-input" : ""}`}>
            <div className={showSearch ? "search-container" : ""}>
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                onClick={changeViewSearch}
              >
                <path
                  d="M21 21L16.514 16.506M19 10.5C19 12.7543 18.1045 14.9163 16.5104 16.5104C14.9163 18.1045 12.7543 19 10.5 19C8.24566 19 6.08365 18.1045 4.48959 16.5104C2.89553 14.9163 2 12.7543 2 10.5C2 8.24566 2.89553 6.08365 4.48959 4.48959C6.08365 2.89553 8.24566 2 10.5 2C12.7543 2 14.9163 2.89553 16.5104 4.48959C18.1045 6.08365 19 8.24566 19 10.5V10.5Z"
                  stroke={`${showSearch ? "#4B85DC" : "#808488"}`}
                  strokeWidth="2"
                  strokeLinecap="round"
                />
              </svg>

              {showSearch ? (
                <>
                  <div className="input-wrap-buyer-info">
                    <input
                      placeholder={t("product-search")}
                      value={searchText}
                      onKeyDown={handleKeyDown}
                      ref={inputReference}
                      onChange={handleChangeText}
                    />
                    <button className="search-button" onClick={handleSearchByText}>
                      {t("search")}
                    </button>
                  </div>
                  <img
                    onClick={changeViewSearch}
                    src="/assets/images/icon-cross.svg"
                    alt="clear"
                    aria-hidden="true"
                    className="clear-input"
                  />
                </>
              ) : null}
            </div>
          </div>
          {showSearch ? (
            <div
              className={`search-wrapper-buyer-info-mobile ${
                showSearch ? "show-input" : ""
              }`}
            >
              <div className="search-container-mobile">
                <div className="input-wrap-buyer-info-mobile">
                  <input
                    placeholder={t("product-search")}
                    value={searchText}
                    ref={mobileInputReference}
                    onKeyDown={handleKeyDown}
                    onChange={handleChangeText}
                  />
                  <button className="search-button" onClick={handleSearchByText}>
                    {t("search")}
                  </button>
                  <img
                    onClick={changeViewSearch}
                    src="/assets/images/icon-cross.svg"
                    alt=""
                    aria-hidden="true"
                    className="clear-input"
                  />
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default BuyerInfo;
