import {
  ADD_PRODUCT_COLOR_FAILURE,
  ADD_PRODUCT_COLOR_REQUEST_SENT,
  ADD_PRODUCT_COLOR_SUCCESSFUL,
  ADD_PRODUCT_IMAGES_FAILURE,
  ADD_PRODUCT_IMAGES_REQUEST_SENT,
  ADD_PRODUCT_IMAGES_SUCCESSFUL,
  ADD_PRODUCT_MATERIALS_FAILURE,
  ADD_PRODUCT_MATERIALS_REQUEST_SENT,
  ADD_PRODUCT_MATERIALS_SUCCESSFUL,
  ADD_PRODUCT_OTHER_FAILURE,
  ADD_PRODUCT_OTHER_REQUEST_SENT,
  ADD_PRODUCT_OTHER_SUCCESSFUL,
  ADD_PRODUCT_SIZE_FAILURE,
  ADD_PRODUCT_SIZE_REQUEST_SENT,
  ADD_PRODUCT_SIZE_SUCCESSFUL,
  CREATE_PRODUCT_FAILURE,
  CREATE_PRODUCT_REQUEST_SENT,
  CREATE_PRODUCT_SUCCESSFUL,
  DELETE_PRODUCT_COLOR_FAILURE,
  DELETE_PRODUCT_COLOR_REQUEST_SENT,
  DELETE_PRODUCT_COLOR_SUCCESSFUL,
  DELETE_PRODUCT_FAILURE,
  DELETE_PRODUCT_IMAGE_FAILURE,
  DELETE_PRODUCT_IMAGE_REQUEST_SENT,
  DELETE_PRODUCT_IMAGE_SUCCESSFUL,
  DELETE_PRODUCT_MATERIAL_FAILURE,
  DELETE_PRODUCT_MATERIAL_REQUEST_SENT,
  DELETE_PRODUCT_MATERIAL_SUCCESSFUL,
  DELETE_PRODUCT_OTHER_FAILURE,
  DELETE_PRODUCT_OTHER_REQUEST_SENT,
  DELETE_PRODUCT_OTHER_SUCCESSFUL,
  DELETE_PRODUCT_REQUEST_SENT,
  DELETE_PRODUCT_SIZE_FAILURE,
  DELETE_PRODUCT_SIZE_REQUEST_SENT,
  DELETE_PRODUCT_SIZE_SUCCESSFUL,
  DELETE_PRODUCT_SUCCESSFUL,
  EDIT_PRODUCT_COLOR_FAILURE,
  EDIT_PRODUCT_COLOR_REQUEST_SENT,
  EDIT_PRODUCT_COLOR_SUCCESSFUL,
  EDIT_PRODUCT_MATERIAL_FAILURE,
  EDIT_PRODUCT_MATERIAL_REQUEST_SENT,
  EDIT_PRODUCT_MATERIAL_SUCCESSFUL,
  EDIT_PRODUCT_OTHER_FAILURE,
  EDIT_PRODUCT_OTHER_REQUEST_SENT,
  EDIT_PRODUCT_OTHER_SUCCESSFUL,
  EDIT_PRODUCT_SIZE_FAILURE,
  EDIT_PRODUCT_SIZE_REQUEST_SENT,
  EDIT_PRODUCT_SIZE_SUCCESSFUL,
  GET_COLORS_BY_PRODUCT_FAILURE,
  GET_COLORS_BY_PRODUCT_REQUEST_SENT,
  GET_COLORS_BY_PRODUCT_SUCCESSFUL,
  GET_MATERIALS_BY_PRODUCT_FAILURE,
  GET_MATERIALS_BY_PRODUCT_REQUEST_SENT,
  GET_MATERIALS_BY_PRODUCT_SUCCESSFUL,
  GET_OTHERS_BY_PRODUCT_FAILURE,
  GET_OTHERS_BY_PRODUCT_REQUEST_SENT,
  GET_OTHERS_BY_PRODUCT_SUCCESSFUL,
  GET_PRODUCT_IMAGES_FAILURE,
  GET_PRODUCT_IMAGES_REQUEST_SENT,
  GET_PRODUCT_IMAGES_SUCCESSFUL,
  GET_PRODUCT_STATIC_COLORS_FAILURE,
  GET_PRODUCT_STATIC_COLORS_REQUEST_SENT,
  GET_PRODUCT_STATIC_COLORS_SUCCESSFUL,
  GET_PRODUCT_STATIC_MATERIALS_FAILURE,
  GET_PRODUCT_STATIC_MATERIALS_REQUEST_SENT,
  GET_PRODUCT_STATIC_MATERIALS_SUCCESSFUL,
  GET_PRODUCT_STATIC_SIZES_FAILURE,
  GET_PRODUCT_STATIC_SIZES_REQUEST_SENT,
  GET_PRODUCT_STATIC_SIZES_SUCCESSFUL,
  GET_PRODUCTS_FAILURE,
  GET_PRODUCTS_REQUEST_SENT,
  GET_PRODUCTS_SUCCESSFUL,
  GET_SIZES_BY_PRODUCT_FAILURE,
  GET_SIZES_BY_PRODUCT_REQUEST_SENT,
  GET_SIZES_BY_PRODUCT_SUCCESSFUL,
  GET_USERS_PRODUCT_FAILURE,
  GET_USERS_PRODUCT_REQUEST_SENT,
  GET_USERS_PRODUCT_SUCCESSFUL,
  UPDATE_PRODUCT_FAILURE,
  UPDATE_PRODUCT_REQUEST_SENT,
  UPDATE_PRODUCT_SUCCESSFUL,
  GET_ADDITIONAL_OPTIONS_BY_PRODUCT_REQUEST_SENT,
  GET_ADDITIONAL_OPTIONS_BY_PRODUCT_FAILURE,
  GET_ADDITIONAL_OPTIONS_BY_PRODUCT_SUCCESSFUL,
} from "./constants";

const initialState = {
  loading: false,
  imagesLoading: false,
  errorMessage: null,
  allProducts: [],
  allColors: [],
  allMaterials: [],
  allSizes: [],
  usersProduct: null,
  colorsByProduct: [],
  additionalOptionsByProduct: [],
  materialsByProduct: [],
  sizesByProduct: [],
  othersByProduct: [],
  createdProduct: null,
  productImages: [],
};

const productsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ADDITIONAL_OPTIONS_BY_PRODUCT_REQUEST_SENT:
      return {
        ...state,
        loading: true,
        errorMessage: null
      };
    case GET_ADDITIONAL_OPTIONS_BY_PRODUCT_SUCCESSFUL:
      return {
        ...state,
        loading: false,
        errorMessage: null,
        additionalOptionsByProduct: action.payload,
      }
    case GET_ADDITIONAL_OPTIONS_BY_PRODUCT_FAILURE:
      return {
        ...state,
        loading: false,
        errorMessage: action.message,
      };
    case GET_PRODUCTS_REQUEST_SENT:
      return {
        ...state,
        loading: true,
        errorMessage: null,
      };
    case GET_PRODUCTS_SUCCESSFUL:
      return {
        ...state,
        loading: false,
        errorMessage: null,
        allProducts: action.payload,
      };
    case GET_PRODUCTS_FAILURE:
      return {
        ...state,
        loading: false,
        errorMessage: action.message,
      };
    case GET_PRODUCT_IMAGES_REQUEST_SENT:
      return {
        ...state,
        imagesLoading: true,
        errorMessage: null,
      };
    case GET_PRODUCT_IMAGES_SUCCESSFUL:
      return {
        ...state,
        imagesLoading: false,
        errorMessage: null,
        productImages: action.payload,
      };
    case GET_PRODUCT_IMAGES_FAILURE:
      return {
        ...state,
        imagesLoading: false,
        errorMessage: action.message,
      };
    case GET_PRODUCT_STATIC_COLORS_REQUEST_SENT:
      return {
        ...state,
        loading: true,
        errorMessage: null,
      };
    case GET_PRODUCT_STATIC_COLORS_SUCCESSFUL:
      return {
        ...state,
        loading: false,
        errorMessage: null,
        allColors: action.payload,
      };
    case GET_PRODUCT_STATIC_COLORS_FAILURE:
      return {
        ...state,
        loading: false,
        errorMessage: action.message,
      };
    case GET_PRODUCT_STATIC_MATERIALS_REQUEST_SENT:
      return {
        ...state,
        loading: true,
        errorMessage: null,
      };
    case GET_PRODUCT_STATIC_MATERIALS_SUCCESSFUL:
      return {
        ...state,
        loading: false,
        errorMessage: null,
        allMaterials: action.payload,
      };
    case GET_PRODUCT_STATIC_MATERIALS_FAILURE:
      return {
        ...state,
        loading: false,
        errorMessage: action.message,
      };
    case GET_PRODUCT_STATIC_SIZES_REQUEST_SENT:
      return {
        ...state,
        loading: true,
        errorMessage: null,
      };
    case GET_PRODUCT_STATIC_SIZES_SUCCESSFUL:
      return {
        ...state,
        loading: false,
        errorMessage: null,
        allSizes: action.payload,
      };
    case GET_PRODUCT_STATIC_SIZES_FAILURE:
      return {
        ...state,
        loading: false,
        errorMessage: action.message,
      };
    case ADD_PRODUCT_COLOR_REQUEST_SENT:
      return {
        ...state,
        loading: true,
        errorMessage: null,
      };
    case ADD_PRODUCT_COLOR_SUCCESSFUL:
      return {
        ...state,
        loading: false,
        errorMessage: null,
      };
    case ADD_PRODUCT_COLOR_FAILURE:
      return {
        ...state,
        loading: false,
        errorMessage: action.message,
      };
    case ADD_PRODUCT_MATERIALS_REQUEST_SENT:
      return {
        ...state,
        loading: true,
        errorMessage: null,
      };
    case ADD_PRODUCT_MATERIALS_SUCCESSFUL:
      return {
        ...state,
        loading: false,
        errorMessage: null,
      };
    case ADD_PRODUCT_MATERIALS_FAILURE:
      return {
        ...state,
        loading: false,
        errorMessage: action.message,
      };
    case ADD_PRODUCT_SIZE_REQUEST_SENT:
      return {
        ...state,
        loading: true,
        errorMessage: null,
      };
    case ADD_PRODUCT_SIZE_SUCCESSFUL:
      return {
        ...state,
        loading: false,
        errorMessage: null,
      };
    case ADD_PRODUCT_SIZE_FAILURE:
      return {
        ...state,
        loading: false,
        errorMessage: action.message,
      };
    case ADD_PRODUCT_OTHER_REQUEST_SENT:
      return {
        ...state,
        loading: true,
        errorMessage: null,
      };
    case ADD_PRODUCT_OTHER_SUCCESSFUL:
      return {
        ...state,
        loading: false,
        errorMessage: null,
      };
    case ADD_PRODUCT_OTHER_FAILURE:
      return {
        ...state,
        loading: false,
        errorMessage: action.message,
      };
    case CREATE_PRODUCT_REQUEST_SENT:
      return {
        ...state,
        loading: true,
        errorMessage: null,
      };
    case CREATE_PRODUCT_SUCCESSFUL:
      return {
        ...state,
        loading: false,
        errorMessage: null,
        productSuccess: "Product created successfully",
        createdProduct: action.payload,
      };
    case CREATE_PRODUCT_FAILURE:
      return {
        ...state,
        loading: false,
        errorMessage: action.message,
      };
    case UPDATE_PRODUCT_REQUEST_SENT:
      return {
        ...state,
        loading: true,
        errorMessage: null,
      };
    case UPDATE_PRODUCT_SUCCESSFUL:
      return {
        ...state,
        loading: false,
        errorMessage: null,
        productSuccess: "Product updated successfully",
      };
    case UPDATE_PRODUCT_FAILURE:
      return {
        ...state,
        loading: false,
        errorMessage: action.message,
      };
    case ADD_PRODUCT_IMAGES_REQUEST_SENT:
      return {
        ...state,
        loading: true,
        errorMessage: null,
      };
    case ADD_PRODUCT_IMAGES_SUCCESSFUL:
      return {
        ...state,
        loading: false,
        errorMessage: null,
        productImages: [...state.productImages, action.payload],
      };
    case ADD_PRODUCT_IMAGES_FAILURE:
      return {
        ...state,
        loading: false,
        errorMessage: action.message,
      };
    case GET_USERS_PRODUCT_REQUEST_SENT:
      return {
        ...state,
        loading: true,
        errorMessage: null,
      };
    case GET_USERS_PRODUCT_SUCCESSFUL:
      return {
        ...state,
        loading: false,
        errorMessage: null,
        usersProduct: action.payload,
      };
    case GET_USERS_PRODUCT_FAILURE:
      return {
        ...state,
        loading: false,
        usersProduct: [],
        errorMessage: action.message,
      };
    case GET_COLORS_BY_PRODUCT_REQUEST_SENT:
      return {
        ...state,
        loading: true,
        errorMessage: null,
      };
    case GET_COLORS_BY_PRODUCT_SUCCESSFUL:
      return {
        ...state,
        loading: false,
        errorMessage: null,
        colorsByProduct: action.payload,
      };
    case GET_COLORS_BY_PRODUCT_FAILURE:
      return {
        ...state,
        loading: false,
        errorMessage: action.message,
      };
    case GET_MATERIALS_BY_PRODUCT_REQUEST_SENT:
      return {
        ...state,
        loading: true,
        errorMessage: null,
      };
    case GET_MATERIALS_BY_PRODUCT_SUCCESSFUL:
      return {
        ...state,
        loading: false,
        errorMessage: null,
        materialsByProduct: action.payload,
      };
    case GET_MATERIALS_BY_PRODUCT_FAILURE:
      return {
        ...state,
        loading: false,
        errorMessage: action.message,
      };
    case GET_SIZES_BY_PRODUCT_REQUEST_SENT:
      return {
        ...state,
        loading: true,
        errorMessage: null,
      };
    case GET_SIZES_BY_PRODUCT_SUCCESSFUL:
      return {
        ...state,
        loading: false,
        errorMessage: null,
        sizesByProduct: action.payload,
      };
    case GET_SIZES_BY_PRODUCT_FAILURE:
      return {
        ...state,
        loading: false,
        errorMessage: action.message,
      };
    case GET_OTHERS_BY_PRODUCT_REQUEST_SENT:
      return {
        ...state,
        loading: true,
        errorMessage: null,
      };
    case GET_OTHERS_BY_PRODUCT_SUCCESSFUL:
      return {
        ...state,
        loading: false,
        errorMessage: null,
        othersByProduct: action.payload,
      };
    case GET_OTHERS_BY_PRODUCT_FAILURE:
      return {
        ...state,
        loading: false,
        errorMessage: action.message,
      };
    case DELETE_PRODUCT_IMAGE_REQUEST_SENT:
      return {
        ...state,
        loading: true,
        errorMessage: null,
      };
    case DELETE_PRODUCT_IMAGE_SUCCESSFUL:
      return {
        ...state,
        loading: false,
        errorMessage: null,
      };
    case DELETE_PRODUCT_IMAGE_FAILURE:
      return {
        ...state,
        loading: false,
        errorMessage: action.message,
      };
    case DELETE_PRODUCT_COLOR_REQUEST_SENT:
      return {
        ...state,
        loading: true,
        errorMessage: null,
      };
    case DELETE_PRODUCT_COLOR_SUCCESSFUL:
      return {
        ...state,
        loading: false,
        errorMessage: null,
      };
    case DELETE_PRODUCT_COLOR_FAILURE:
      return {
        ...state,
        loading: false,
        errorMessage: action.message,
      };
    case DELETE_PRODUCT_SIZE_REQUEST_SENT:
      return {
        ...state,
        loading: true,
        errorMessage: null,
      };
    case DELETE_PRODUCT_SIZE_SUCCESSFUL:
      return {
        ...state,
        loading: false,
        errorMessage: null,
      };
    case DELETE_PRODUCT_SIZE_FAILURE:
      return {
        ...state,
        loading: false,
        errorMessage: action.message,
      };
    case DELETE_PRODUCT_MATERIAL_REQUEST_SENT:
      return {
        ...state,
        loading: true,
        errorMessage: null,
      };
    case DELETE_PRODUCT_MATERIAL_SUCCESSFUL:
      return {
        ...state,
        loading: false,
        errorMessage: null,
      };
    case DELETE_PRODUCT_MATERIAL_FAILURE:
      return {
        ...state,
        loading: false,
        errorMessage: action.message,
      };
    case DELETE_PRODUCT_OTHER_REQUEST_SENT:
      return {
        ...state,
        loading: true,
        errorMessage: null,
      };
    case DELETE_PRODUCT_OTHER_SUCCESSFUL:
      return {
        ...state,
        loading: false,
        errorMessage: null,
      };
    case DELETE_PRODUCT_OTHER_FAILURE:
      return {
        ...state,
        loading: false,
        errorMessage: action.message,
      };
    case DELETE_PRODUCT_REQUEST_SENT:
      return {
        ...state,
        loading: true,
        errorMessage: null,
      };
    case DELETE_PRODUCT_SUCCESSFUL:
      return {
        ...state,
        loading: false,
        errorMessage: null,
      };
    case DELETE_PRODUCT_FAILURE:
      return {
        ...state,
        loading: false,
        errorMessage: action.message,
      };
    case EDIT_PRODUCT_OTHER_REQUEST_SENT:
      return {
        ...state,
        loading: true,
        errorMessage: null,
      };
    case EDIT_PRODUCT_OTHER_SUCCESSFUL:
      return {
        ...state,
        loading: false,
        errorMessage: null,
      };
    case EDIT_PRODUCT_OTHER_FAILURE:
      return {
        ...state,
        loading: false,
        errorMessage: action.message,
      };
    case EDIT_PRODUCT_MATERIAL_REQUEST_SENT:
      return {
        ...state,
        loading: true,
        errorMessage: null,
      };
    case EDIT_PRODUCT_MATERIAL_SUCCESSFUL:
      return {
        ...state,
        loading: false,
        errorMessage: null,
      };
    case EDIT_PRODUCT_MATERIAL_FAILURE:
      return {
        ...state,
        loading: false,
        errorMessage: action.message,
      };
    case EDIT_PRODUCT_SIZE_REQUEST_SENT:
      return {
        ...state,
        loading: true,
        errorMessage: null,
      };
    case EDIT_PRODUCT_SIZE_SUCCESSFUL:
      return {
        ...state,
        loading: false,
        errorMessage: null,
      };
    case EDIT_PRODUCT_SIZE_FAILURE:
      return {
        ...state,
        loading: false,
        errorMessage: action.message,
      };
    case EDIT_PRODUCT_COLOR_REQUEST_SENT:
      return {
        ...state,
        loading: true,
        errorMessage: null,
      };
    case EDIT_PRODUCT_COLOR_SUCCESSFUL:
      return {
        ...state,
        loading: false,
        errorMessage: null,
      };
    case EDIT_PRODUCT_COLOR_FAILURE:
      return {
        ...state,
        loading: false,
        errorMessage: action.message,
      };
    default:
      return state;
  }
};

export default productsReducer;
