import React, { useState, useEffect } from "react";
import "./OrderHistoryStyles.scss";
import PropTypes from "prop-types";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { BASEURL } from "../../../../../Configs/constants";
import { RequestHandler } from "../../../../../Components/RequestHandler/RequestHandler";
import getOrderStatuses from "../../../../../Helpers/getOrderStatuses";
import moment from "moment";
import { getAccessToken2 } from "../../../../../Helpers/auth";

async function getOrderHistory(orderId) {
  return await axios.get(
    `${BASEURL}/api/sellsgram/seller/requested-orders/status/view/${orderId}`,
    {
      headers: {
        Authorization: getAccessToken2(),
      },
    },
  );
}

function OrderHistoryComponent({ handleBackButton, orderId }) {
  const [orderHistory, setOrderHistory] = useState([]);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    if (orderId) {
      setLoading(true);
      getOrderHistory(orderId)
        .then(({ data }) => {
          setOrderHistory(data);
          setLoading(false);
        })
        .catch((e) => {
          setLoading(false);
          console.error(e);
        });
    }
  }, [orderId]);

  moment.locale(localStorage.getItem("language") === "ua" ? "uk" : "en");

  return (
    <div className="order-history-container">
      <div className="order-history-header">
        <button onClick={handleBackButton} className="order-history_back-button">
          <img
            src="/assets/images/icon-back.svg"
            className="d-flex back-arrow-btn"
            alt="back icon"
          />
          {t("back")}
        </button>
      </div>
      <div className="order-history-body">
        <h4>{t("orderHistory")}</h4>
        <RequestHandler isLoading={loading}>
          <div className="order-history-wrap">
            {orderHistory.map(({ date, orderStatuses }, j) => (
              <div key={j}>
                <p className="order-history-date">
                  {moment(new Date(date + "Z")).format("DD MMMM YYYY")}
                </p>
                {orderStatuses.map(({ orderStatusId, dateTime }, i) => (
                  <p className="order-history-status" key={i}>
                    {moment(new Date(dateTime + "Z")).format("HH:mm")}
                    <span
                      style={{
                        color: getOrderStatuses.find(({ id }) => id === orderStatusId)
                          ?.color,
                      }}
                    >
                      {t(getOrderStatuses.find(({ id }) => id === orderStatusId)?.name)}
                    </span>
                  </p>
                ))}
              </div>
            ))}
          </div>
        </RequestHandler>
      </div>
    </div>
  );
}
OrderHistoryComponent.propTypes = {
  handleBackButton: PropTypes.func.isRequired,
  statuses: PropTypes.object.isRequired,
  orderId: PropTypes.number,
};
export default OrderHistoryComponent;
