import "./DeliveryLocationInfo.scss";
import { memoize, debounce, throttle } from "lodash";
import React, { useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import Sentry from "react-activity/dist/Sentry";
import Button from "react-bootstrap/Button";
import Dropdown from "react-bootstrap/Dropdown";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import CustomMenu from "../../../Pages/Delivery/utils/CustomMenu";
import {
  getCitiesbyCountry,
  getLocalWarehouses,
  getSellerLocalDeliveries,
} from "../../../Redux/Cart/actions";
import CustomMenuLocalDelivery from "./CustomMenuLocalDelivery";
import { Form } from "react-bootstrap";

const LocalDeliveryInfo = ({
  loadingCities,

  getSellerLocalDeliveries,
  getLocalWarehouses,
  getCitiesbyCountry,

  totalCitiesByCountry,
  loadingLocalDelivery,
  sellerLocalDeliveries,
  loadingLocalWarehouses,
  totalDeliveryWarehouses,

  countryId,
  sellerUserId,
  delivery,
  onChange,
}) => {
  const { t } = useTranslation();

  const [pageNum, setPageNum] = useState(1);
  const [warehouses, setWarehouses] = useState([]);
  const [lastElement, setLastElement] = useState(null);
  const [filter, setFilter] = useState("");
  const [show, setShow] = useState(false);

  const totalDeliveryWarehousesData = totalDeliveryWarehouses.warehouses;

  const observer = useRef(
    new IntersectionObserver((entries) => {
      const first = entries[0];
      if (first.isIntersecting) {
        setPageNum((no) => no + 1);
      }
    }),
  );

  useEffect(() => {
    const currentElement = lastElement;
    const currentObserver = observer.current;

    if (currentElement) {
      currentObserver.observe(currentElement);
    }

    return () => {
      if (currentElement) {
        currentObserver.unobserve(currentElement);
      }
    };
  }, [lastElement]);

  const firstUpdate = useRef(true);

  useLayoutEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }

    setWarehouses((prev) => [...prev, ...(totalDeliveryWarehousesData || [])]);
  }, [totalDeliveryWarehousesData]);

  const isLastElement = (index) => {
    return (
      index === warehouses?.length - 1 &&
      !loadingLocalWarehouses &&
      totalDeliveryWarehouses.total > warehouses?.length
    );
  };

  const requestCities = (countryId) => {
    setWarehouses([]);
    setPageNum(1);
    getCitiesbyCountry(countryId);
  };
  useEffect(() => {
    if (countryId) {
      requestCities(countryId);
    }
  }, [countryId]);

  useEffect(() => {
    if (!delivery.id || !delivery?.city?.id) return;

    getLocalWarehouses(
      delivery.id,
      delivery.city.id,
      pageNum,
      filter,
    );
  }, [delivery.id, delivery.city.id, pageNum, filter]);

  const requestLocalDeliveries = (sellerUserId, countryId) => {
    setWarehouses([]);
    setPageNum(1);
    getSellerLocalDeliveries(sellerUserId, countryId);
  };

  useEffect(() => {
    if (sellerUserId && countryId) {
      requestLocalDeliveries(sellerUserId, countryId);
    }
  }, [countryId, sellerUserId]);

  const handleChange = (e) => {
    setWarehouses([]);
    setPageNum(1);
    setFilter(e?.target?.value);
  };

  const debouncedResults = debounce(handleChange, 300);
  useEffect(() => {
    return () => {
      debouncedResults.cancel();
    };
  });
  const onToggleDropdown = (isOpen, e, metadata) => {
    setShow(isOpen);
  };

  return (
    <>
      <div className="d-flex flex-column">
        <div className="flex-one">
          <Dropdown
            onSelect={(c) => {
              const { cityId, name } = JSON.parse(c);
              onChange({
                city: {
                  id: cityId,
                  name,
                },
                id: 0,
                name: "",
                warehouse: {},
              });
              setWarehouses([]);
              setPageNum(1);
            }}
          >
            <Dropdown.Toggle
              className="w-100 align-items-center"
              as={Button}
              variant="secondary"
              id="truncateLongTexts"
              disabled={!countryId}
            >
              {totalCitiesByCountry?.find((c) => c.cityId === delivery.city.id)?.name ||
                t("selectCity")}
            </Dropdown.Toggle>

            <Dropdown.Menu as={CustomMenu} disabled={loadingCities}>
              {loadingCities ? (
                <Dropdown.Item>
                  <Sentry color="#4B85DC" size={100} speed={0.5} animating={true} />
                </Dropdown.Item>
              ) : totalCitiesByCountry?.length > 0 ? (
                totalCitiesByCountry?.map((c) => (
                  <Dropdown.Item eventKey={JSON.stringify(c)} key={c.cityId}>
                    {c.name}
                  </Dropdown.Item>
                ))
              ) : (
                <Dropdown.Item active={false}>{t("noShopsFound")}</Dropdown.Item>
              )}
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <div className="flex-one">
          <Dropdown
            onSelect={(d) => {
              const { localDeliveryId, name } = JSON.parse(d);
              onChange({
                id: localDeliveryId,
                name,
                warehouse: {},
              });
            }}
          >
            <Dropdown.Toggle
              className="w-100 align-items-center"
              as={Button}
              variant="secondary"
              id="truncateLongTexts2"
              disabled={!delivery.city.id}
            >
              {sellerLocalDeliveries?.find((d) => d.localDeliveryId === delivery.id)
                ?.name || t("selectDelivery")}
            </Dropdown.Toggle>

            <Dropdown.Menu as={CustomMenu} disabled={loadingLocalDelivery}>
              {loadingLocalDelivery ? (
                <Dropdown.Item>
                  <Sentry color="#4B85DC" size={100} speed={0.5} animating={true} />
                </Dropdown.Item>
              ) : (
                sellerLocalDeliveries?.map((d) => (
                  <Dropdown.Item eventKey={JSON.stringify(d)} key={d.localDeliveryId}>
                    {d.name}
                  </Dropdown.Item>
                ))
              )}
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <div className="flex-one">
          <Dropdown
            onSelect={(w) => {
              const { warehouseId, name } = JSON.parse(w);
              onChange({
                warehouse: {
                  id: warehouseId,
                  name,
                },
              });
            }}
            onToggle={onToggleDropdown}
            show={show}
          >
            <Dropdown.Toggle
              className="w-100 align-items-center local-delivery-dropdown-button"
              as={Button}
              variant="secondary"
              disabled={!delivery.id}
            >
              {warehouses?.find((w) => w.warehouseId === delivery.warehouse.id)?.name ||
                t("selectWarehouse")}
            </Dropdown.Toggle>

            <Dropdown.Menu
              as={CustomMenuLocalDelivery}
              disabled={loadingLocalWarehouses}
              onChange={debouncedResults}
              filter={filter}
              className="local-delivery-dropdown-menu"
            >
              {show && warehouses?.length > 0 ? (
                <>
                  {warehouses.map((s, index) =>
                    isLastElement(index) ? (
                      <div ref={setLastElement} key={s.warehouseId}>
                        <Dropdown.Item eventKey={JSON.stringify(s)}>
                          <label>{s.name}</label>
                          <p>{s.address}</p>
                        </Dropdown.Item>
                      </div>
                    ) : (
                      <div key={s.warehouseId}>
                        <Dropdown.Item eventKey={JSON.stringify(s)}>
                          <label>{s.name}</label>
                          <p>{s.address}</p>
                        </Dropdown.Item>
                      </div>
                    ),
                  )}
                  {loadingLocalWarehouses ? (
                    <Dropdown.Item>
                      <Sentry color="#4B85DC" size={100} speed={0.5} animating={true} />
                    </Dropdown.Item>
                  ) : null}
                </>
              ) : (
                <>
                  {loadingLocalWarehouses ? (
                    <Dropdown.Item>
                      <Sentry color="#4B85DC" size={100} speed={0.5} animating={true} />
                    </Dropdown.Item>
                  ) : (
                    <Dropdown.Item active={false}>{t("noWarehousesFound")}</Dropdown.Item>
                  )}
                </>
              )}
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
    </>
  );
};

LocalDeliveryInfo.propTypes = {};

const mapStateToProps = (state) => ({
  totalCitiesByCountry: state.cart.totalCitiesByCountry,
  loadingCities: state.cart.loadingCities,
  loadingLocalDelivery: state.cart.loadingLocalDelivery,
  sellerLocalDeliveries: state.cart.sellerLocalDeliveries,
  loadingLocalWarehouses: state.cart.loadingLocalWarehouses,
  totalDeliveryWarehouses: state.cart.totalDeliveryWarehouses,
});

export default connect(mapStateToProps, {
  getSellerLocalDeliveries,
  getLocalWarehouses,
  getCitiesbyCountry,
})(LocalDeliveryInfo);
