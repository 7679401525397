import axios from "axios";
import { BASEURL } from "../../Configs/constants";
import { getAccessToken2 } from "../../Helpers/auth";
import { updateAuthUserAccountState } from "../Auth/actions";

import {
  GET_BUYER_FEED_FAILURE,
  GET_BUYER_FEED_REQUEST_SENT,
  GET_BUYER_FEED_SUCCESSFUL,
  GET_BUYER_GENERAL_INFO_FAILURE,
  GET_BUYER_GENERAL_INFO_REQUEST_SENT,
  GET_BUYER_GENERAL_INFO_SUCCESSFUL,
  GET_BUYER_SWITCH_TO_SELLER_FAILURE,
  GET_BUYER_SWITCH_TO_SELLER_REQUEST_SENT,
  GET_BUYER_SWITCH_TO_SELLER_SUCCESSFUL,
  SWITCH_BUYER_TO_SELLER_REQUEST_SENT,
  SWITCH_BUYER_TO_SELLER_SUCCESSFUL,
  SWITCH_BUYER_TO_SELLER_FAILURE,
} from "./constants";

export const getBuyerGeneralInfo = (buyerUserId) => async (dispatch) => {
  try {
    dispatch({
      type: GET_BUYER_GENERAL_INFO_REQUEST_SENT,
    });
    const resp = await axios.get(
      `${BASEURL}/api/web/sellsgram/user/buyer/general/${buyerUserId}`,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: getAccessToken2(),
        },
      },
    );
    dispatch({
      type: GET_BUYER_GENERAL_INFO_SUCCESSFUL,
      payload: resp.data,
    });
  } catch (err) {
    dispatch({
      type: GET_BUYER_GENERAL_INFO_FAILURE,
      message: err.response?.data ? err.response.data.detail : err.message,
    });
  }
};

export const getBuyerFeed = (buyerUserId, page, filter, search) => async (dispatch) => {
  try {
    dispatch({
      type: GET_BUYER_FEED_REQUEST_SENT,
    });
    const resp = await axios.get(
      `${BASEURL}/api/web/sellsgram/user/buyer/feed/${buyerUserId}?page=${page}&filter=${filter}&search=${search}&pagesize=10`,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: getAccessToken2(),
        },
      },
    );
    dispatch({
      type: GET_BUYER_FEED_SUCCESSFUL,
      payload: resp.data,
    });
  } catch (err) {
    dispatch({
      type: GET_BUYER_FEED_FAILURE,
      message: err.response?.data ? err.response.data.detail : err.message,
    });
  }
};

export const getBuyerSwitchToSeller = (buyerUserId) => async (dispatch) => {
  try {
    dispatch({
      type: GET_BUYER_SWITCH_TO_SELLER_REQUEST_SENT,
    });
    const resp = await axios.get(
      `${BASEURL}/api/web/sellsgram/user/buyer/switch-to-seller/${buyerUserId}`,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: getAccessToken2(),
        },
      },
    );
    dispatch({
      type: GET_BUYER_SWITCH_TO_SELLER_SUCCESSFUL,
      payload: resp.data,
    });
    if (resp.data.isExist) {
      dispatch(
        updateAuthUserAccountState({
          accountStateId: 1,
          state: "SELLER",
        }),
      );
    }
    return resp;
  } catch (err) {
    dispatch({
      type: GET_BUYER_SWITCH_TO_SELLER_FAILURE,
      message: err.response?.data ? err.response.data.detail : err.message,
    });
  }
};
export const switchBuyerToSeller = (body) => async (dispatch) => {
  try {
    dispatch({
      type: SWITCH_BUYER_TO_SELLER_REQUEST_SENT,
    });
    const resp = await axios.post(
      `${BASEURL}/api/web/sellsgram/user/buyer/register/seller`,
      body,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: getAccessToken2(),
        },
      },
    );
    dispatch(
      updateAuthUserAccountState({
        accountStateId: 1,
        state: "SELLER",
      }),
    );
    dispatch({
      type: SWITCH_BUYER_TO_SELLER_SUCCESSFUL,
      payload: resp.data,
    });
    return true;
  } catch (err) {
    dispatch({
      type: SWITCH_BUYER_TO_SELLER_FAILURE,
      message: err.response?.data ? err.response.data.detail : err.message,
    });
  }
};
