// import axios from "axios";
import axios from "axios";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { BASEURL } from "../../Configs/constants";

// import { BASEURL } from "../../Configs/constants";

const SingleCartItem = (props) => {
  const {
    product,
    deleteUserCartProduct,
    getUserCartProducts,
    userId,
    updateUserCartProduct,
  } = props;
  const { t } = useTranslation();

  const [count, setCount] = useState(product?.quantity ? product.quantity : 1);

  const updateCount = (count) => {
    setCount(count);
    handleUpdateCartProduct(count);
  };
  const handleUpdateCartProduct = (count) => {
    let payload = {
      userId: parseInt(userId),
      orderId: parseInt(product.orderId),
      productId: parseInt(product.productId),
      price: parseInt(product.price),
      quantity: parseInt(count),
      colorId: parseInt(product.colorId),
      sizeId: parseInt(product.sizeId),
      materialId: parseInt(product.materialId),
      localDeliveryId: parseInt(product.localDeliveryId),
      courierDeliveryCityId: parseInt(product.courierDeliveryCityId),
      selfDeliveryCityId: parseInt(product.selfDeliveryCityId),
      orderedDate: product.orderedDate,
    };

    updateUserCartProduct(payload, product.orderId, getUserCartProducts, userId, t);
  };
  
  let productDescription = product.descritpion || "";
  const splitProductDescription = productDescription.trim().split(/\s+/);
  if (splitProductDescription.length > 10) {
    productDescription = splitProductDescription.slice(0, 10).join(" ") + '...';
  }

  return (
    <div className="cart-item d-flex flex-column" style={{ position: "relative" }}>
      <div className="d-flex align-items-center">
        {/*<div className="cart-item-img-container">*/}
        <div className="cart-item-img-container">
          <Link
            to={{
              pathname: `/p/${product.productLinkName}/`,
              state: { product: product, comingFrom: "Cart" },
            }}
          >
            <img src={product.productImageLink} alt="" className="cart-item-img" />
          </Link>
        </div>
        <div className="cart-item-text">
          <Link
            to={{
              pathname: `/p/${product.productLinkName}/`,
              state: { product: product, comingFrom: "Cart" },
            }}
          >
            <h4>{product.shortName}</h4>
          </Link>
          <p>{productDescription}</p>
        </div>
        <div className="cart-item-price flex-align-center">
          <p>₴{product?.price ? product.price * count : "N/A"}</p>
          <div className="pick-quantity d-flex align-items-center justify-content-between mt-6">
            <div className="quantity-add d-flex align-items-center">
              <button
                type="button"
                className="btn-nostyle"
                onClick={() => count > 1 && updateCount(count - 1)}
              >
                <img src="/assets/images/icon-minus.svg" alt="" />
              </button>
              <p>{count}</p>
              <button
                type="button"
                className="btn-nostyle"
                onClick={() => count < 100 && updateCount(count + 1)}
              >
                <img src="/assets/images/icon-plus.svg" alt="" />
              </button>
            </div>
          </div>
        </div>
      </div>
      {product?.deliveryInfo ? product?.deliveryInfo : null}

      <div className="delete_img">
        <button
          type="button"
          className="delete-btn"
          onClick={() =>
            deleteUserCartProduct(product?.orderId, getUserCartProducts, userId, t)
          }
        >
          <img src="/assets/images/cross-delete-button.png" alt="" />
        </button>
      </div>
    </div>
  );
};

export default SingleCartItem;
