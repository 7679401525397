import axios from "axios";
import jwt_decode from "jwt-decode";

import { BASEURL } from "../../Configs/constants";
import { loginByGoogleEmail } from "../Auth/actions";
import { GOOGLE_LOGIN_FAIL, GOOGLE_LOGIN_SUCCESS, SET_HAS_INSTAGRAM } from "./constants";
import { getAccessToken2, clearStorage } from "../../Helpers/auth";

export const googleLogin = (user, redirect) => async (dispatch) => {
  try {
    const decodedUser = jwt_decode(user.credential);

    dispatch({
      type: GOOGLE_LOGIN_SUCCESS,
      data: decodedUser,
    });

    const verifyTokenResp = await axios.post(
      `${BASEURL}/api/web/sellsgram/user/verify?idtoken=${user?.credential}`,
      {},
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: getAccessToken2(),
        },
      },
    );

    console.log("verifyTokenResp:", verifyTokenResp);

    if (verifyTokenResp?.data?.status === true) {
      localStorage.setItem(
        "userAccessToken",
        JSON.stringify(verifyTokenResp?.data?.token),
      );
      const checkEmailRespInstagram = await axios.get(
        `${BASEURL}/api/instagram/data/check-email`,
        {
          params: {
            email: decodedUser.email,
          },
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: getAccessToken2(),
          },
        },
      );

      console.log("checkEmailRespInstagram", checkEmailRespInstagram);

      dispatch({
        type: SET_HAS_INSTAGRAM,
        data: checkEmailRespInstagram.data.hasInstagram,
      });

      if (checkEmailRespInstagram.data.hasInstagram) {
        dispatch(loginByGoogleEmail(decodedUser.email, redirect));
      }
    }
  } catch (err) {
    dispatch({
      type: GOOGLE_LOGIN_FAIL,
      data: err,
    });
  }
};

export const googleLoginFail = (error) => (dispatch) => {
  dispatch({
    type: GOOGLE_LOGIN_FAIL,
    data: error,
  });
};

export const clearGoogleLoginError = () => (dispatch) => {
  dispatch({
    type: GOOGLE_LOGIN_FAIL,
    data: null,
  });
};
