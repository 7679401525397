import "./DraftLiveSwitcher.scss";

import React from "react";
import Switch from "react-switch";

export const DraftLiveSwitcher = ({ onChange, isChecked, translate }) => {
  return (
    <div className="d-flex switcher-wrapper">
      <p
        onClick={() => onChange(false)}
        className={!isChecked ? "switcher-active-p" : "switcher-passive-p"}
      >
        {translate("draft")}
      </p>
      <div className="d-flex switcher-target">
        <Switch
          onChange={onChange}
          checked={isChecked}
          onColor="#EDF1F7"
          offColor="#EDF1F7"
          height={24}
          width={44}
          handleDiameter={20}
          onHandleColor="#4B85DC"
          offHandleColor="#808488"
          activeBoxShadow="0px 0px 1px 2px #33bbff"
          checkedIcon={false}
          uncheckedIcon={false}
          className="react-switch"
        />
      </div>
      <p
        onClick={() => onChange(true)}
        className={isChecked ? "switcher-active-p" : "switcher-passive-p"}
      >
        {translate("live")}
      </p>
    </div>
  );
};
