import "./EditItemStyle.scss";

import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

import { Layout } from "../../Components/Layout/Layout";
import ColorTable from "../../Components/ProductProperties/PropertyTables/ColorTable";
import MaterialTable from "../../Components/ProductProperties/PropertyTables/MaterialTable";
import OtherTable from "../../Components/ProductProperties/PropertyTables/OtherTable";
import SizeTable from "../../Components/ProductProperties/PropertyTables/SizeTable";
import { DraftLiveSwitcher } from "../../Components/DraftLiveSwitcher/DraftLiveSwitcher";
import { RequestHandler } from "../../Components/RequestHandler/RequestHandler";
import {
  addProductColor,
  addProductImages,
  addProductMaterial,
  addProductSize,
  deleteProduct,
  deleteAdditionalOptions,
  deleteProductColor,
  deleteProductImage,
  deleteProductMaterial,
  deleteProductSize,
  getAdditionalOptionsByProduct,
  getColorsByProduct,
  getMaterialsByProduct,
  getProductImages,
  getSizesByProduct,
  updateProduct,
  editProductAdditionalOptions,
  addProductAdditionalOptions,
} from "../../Redux/Products/actions";
import AdditionalPropertiesTable from "../../Components/ProductProperties/PropertyTables/AdditionalPropertiesTable";

const AddItem = (props) => {
  let {
    updateProduct,
    userId,
    addProductImages,
    getAdditionalOptionsByProduct,
    addProductAdditionalOptions,
    additionalOptionsByProduct,
    imagesLoading,
    getProductImages,
    deleteProductImage,
    deleteProduct,
  } = props;

  const navigation = useHistory();
  const [currProduct, setCurrProduct] = useState(
    props?.location?.state ? props?.location?.state?.product : {},
  );
  const [images, setImages] = useState([]);
  const [productName, setProductName] = useState();
  const [productPrice, setProductPrice] = useState("0");
  const [currencyType, setCurrencyType] = useState(1);
  const [productDescripton, setProductDescription] = useState();
  const [formImage, _] = useState(new FormData());
  const [imagesToDelete, setImagesToDelete] = useState([]);
  const [quantity, setQuantity] = useState(0);
  const [isProductLive, setIsProductLive] = useState(false);

  useEffect(() => {
    if (currProduct) {
      getProductImages(currProduct.productId);
      getAdditionalOptionsByProduct(currProduct.productId);
      if (currProduct?.shortName) setProductName(currProduct.shortName);
      if (currProduct?.price) setProductPrice(String(currProduct.price));
      if (currProduct?.currencyId) setCurrencyType(currProduct.currencyId);
      if (currProduct?.description) setProductDescription(currProduct.description);
      if (currProduct?.quantity) setQuantity(currProduct.quantity);
      setIsProductLive(currProduct?.status === 0);
    }
  }, [currProduct]);

  useEffect(() => {
    if (props.product) setCurrProduct(props.product);
  }, [props.product]);

  const [errors, setErrors] = useState({
    productName: false,
    quantity: false,
    productPrice: null,
    productDescripton: false,
  });
  const [productAdditionalOptions, setProductAdditionalOptions] = useState([]);

  useEffect(() => {
    setProductAdditionalOptions(additionalOptionsByProduct);
  }, [additionalOptionsByProduct]);

  const { t } = useTranslation();

  const re = /^[0-9]+$/;
  const priceRe = /^\d+(?:\.\d{0,3})?$/;

  const onSetQuantity = (e) => {
    if (e.target.value === "" || re.test(e.target.value)) {
      setQuantity(e.target.value);
    }
  };
  const onSetProductPrice = (e) => {
    if (e.target.value === "" || priceRe.test(e.target.value)) {
      setProductPrice(String(e.target.value));
    }
  };

  // update images locally
  useEffect(() => {
    const pis = props?.productImages;
    if (pis) setImages([{ imageLink: currProduct?.instaImageLink }, ...pis]);
  }, [props?.productImages]);

  const onImageChange = (e) => {
    const [file] = e.target.files;
    if (!file.name.match(/\.(jpg|jpeg|png|gif)$/)) {
      toast.error("Please select valid image!");
    } else {
      const fileUrl = URL.createObjectURL(file);

      formImage.append("files", file, fileUrl.split("/").at(-1));
      setImages((prev) => [...prev, { imageLink: fileUrl }]);
    }
  };

  const handleDelete = (img) => {
    const files = formImage.getAll("files");
    const i = files.indexOf(img);
    if (i >= 0) files.splice(i, 1);
    if (img.imageId) {
      setImagesToDelete((prev) => [...prev, img]);
    }
    setImages(images.filter((e) => e.imageLink !== img.imageLink));
  };

  const handleUpdateProduct = async () => {
    let localErrors = {
      productName: false,
      quantity: false,
      productPrice: null,
      productDescripton: false,
    };
    const price =
      productAdditionalOptions && productAdditionalOptions.length > 0
        ? productAdditionalOptions.reduce((previous, current) => {
            return current.price < previous.price ? current : previous;
          }).price
        : productPrice;
    const priceForValidation = parseInt(String(price).replaceAll(".", ""));
    const maxPrice = 100000;
    const maxQuantity = 1000000;
    let newQuantity =
      productAdditionalOptions && productAdditionalOptions.length > 0
        ? productAdditionalOptions.reduce((a, v) => (a = a + Number(v.quantity)), 0)
        : quantity;
    if (!productName) localErrors.productName = true;
    if (!quantity || quantity < 1) {
      localErrors.quantity = t("productQuantityRequired");
    } else if (quantity > maxQuantity) {
      localErrors.quantity = t("productQuantityMax", { maxQuantity });
    }
    if (!priceForValidation || priceForValidation <= 0) {
      localErrors.productPrice = t("productPriceRequired");
    } else if (priceForValidation > maxPrice) {
      localErrors.productPrice = t("productPriceMax", { maxPrice });
    }
    if (!productDescripton) localErrors.productDescripton = true;
    setErrors(localErrors);
    if (Object.values(localErrors).some((v) => v)) return;

    let payload = {
      productId: parseInt(currProduct?.productId),
      userId: parseInt(userId || currProduct?.userId),
      shortName: productName?.trim(),
      price,
      currencyId: currencyType,
      description: productDescripton?.trim(),
      instaImageLink: currProduct?.instaImageLink,
      instaPostId: currProduct?.instaPostId,
      instaPostTimestamp: currProduct?.instaPostTimestamp,
      createdDate: currProduct?.createdDate,
      productImages: props.productImages,
      status: isProductLive ? 0 : 1,
      quantity: parseInt(newQuantity),
    };

    // add images
    if (formImage.getAll("files").length > 0) {
      formImage.forEach((val) => {
        const f = new FormData();
        f.append("files", val);
        addProductImages({ productId: currProduct.productId, image: f });
      });
    }

    // delete images
    if (imagesToDelete.length > 0)
      imagesToDelete.forEach(({ imageId }) => deleteProductImage(imageId));

    addProductAdditionalOptions(
      productAdditionalOptions,
      getAdditionalOptionsByProduct,
      currProduct?.productId,
    );
    updateProduct(currProduct?.productId, payload, navigation, t);
  };

  const compute = () => {
    return images.length < 3;
  };

  return (
    <RequestHandler isLoading={imagesLoading}>
      <Layout
        titleText="editProductInfo"
        onDeleteButtonClick={() => deleteProduct(currProduct?.productId, navigation, t)}
        onSaveButtonClick={handleUpdateProduct}
      >
        <div className="mainInfoContainer">
          <div className="flex-one-no-btn mr-lg-4 mr-0 imgs">
            <div className="product-detail-imgs">
              <div className="product-detail-imgs-Main">
                <img
                  src={images[0] ? images[0].imageLink : "/assets/images/detail-img.png"}
                  alt="img"
                  className="product-detail-imgs-Main"
                />
                {images[0] && (
                  <button
                    type="button"
                    className="insta-icon delete-img-btn"
                    onClick={() => handleDelete(images[0])}
                  >
                    <img src="/assets/images/logo_sellsgram.png" alt="" />
                  </button>
                )}
              </div>

              {images.length < 3 ? (
                <div className="mainInfoItemsWrapper">
                  <form className="addImg">
                    {compute() && (
                      <div className="custom-file">
                        <input
                          type="file"
                          className="custom-file-input"
                          id="customFile"
                          accept="image/*"
                          onChange={onImageChange}
                        />
                        <label className="custom-file-label" htmlFor="customFile">
                          <img src="/assets/images/file-upload.svg" alt="file upload" />
                        </label>
                      </div>
                    )}
                  </form>
                </div>
              ) : null}

              <div className="mainInfoItemsWrapper">
                <div className="product-detail-thumbnail">
                  {images[1] && (
                    <>
                      <img
                        src={images[1].imageLink}
                        alt="img"
                        className="product-detail-thumbnail-img"
                      />
                      <button
                        type="button"
                        className="insta-icon delete-img-btn"
                        onClick={() => handleDelete(images[1])}
                      >
                        <img src="/assets/images/icon-delete-black.svg" alt="bin" />
                      </button>
                    </>
                  )}
                </div>
              </div>

              {images[2] && (
                <div className="mainInfoItemsWrapper">
                  <div className="product-detail-thumbnail">
                    <>
                      <img
                        src={
                          images[2]
                            ? images[2].imageLink
                            : "/assets/images/detail-img.png"
                        }
                        alt="img"
                        className="product-detail-thumbnail-img"
                      />
                      <button
                        type="button"
                        className="insta-icon delete-img-btn"
                        onClick={() => handleDelete(images[2])}
                      >
                        <img src="/assets/images/icon-delete-black.svg" alt="bin" />
                      </button>
                    </>
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className="flex-one mr-lg-4 mr-0">
            <DraftLiveSwitcher
              onChange={setIsProductLive}
              isChecked={isProductLive}
              translate={t}
            />
            <div className="add-product-edit">
              <div className="add-product-field">
                <label htmlFor="">{t("shortName")}</label>
                <input
                  type="text"
                  name="name"
                  maxLength={50}
                  placeholder="Red Flora Flower"
                  className={`form-control ${errors.productName ? "error" : ""}`}
                  value={productName}
                  onChange={(e) => setProductName(e.target.value)}
                />
              </div>
              <div className="add-product-field">
                <label htmlFor="">{t("price")}</label>
                <div className="price-field input-group">
                  {productAdditionalOptions && productAdditionalOptions.length > 0 ? (
                    <input
                      type="text"
                      className={`form-control ${errors.productPrice ? "error" : ""}`}
                      aria-label="Text input with dropdown button"
                      value={
                        productAdditionalOptions && productAdditionalOptions.length > 0
                          ? productAdditionalOptions.reduce((previous, current) => {
                              return current.price < previous.price ? current : previous;
                            }).price
                          : parseInt(productPrice)
                      }
                      placeholder="100"
                      onChange={(e) => onSetProductPrice(e)}
                      readOnly
                      disabled={true}
                    />
                  ) : (
                    <input
                      type="text"
                      className={`form-control ${errors.productPrice ? "error" : ""}`}
                      aria-label="Text input with dropdown button"
                      value={productPrice}
                      placeholder="100"
                      onChange={(e) => onSetProductPrice(e)}
                    />
                  )}

                  <div className="input-group-append position-relative">
                    <button
                      className="btn btn-dropdown dropdown-toggle"
                      type="button"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      {"UAH"}
                    </button>
                    <div className="dropdown-menu">
                      <a className="dropdown-item" onClick={() => setCurrencyType(1)}>
                        UAH
                      </a>
                    </div>
                  </div>
                  {errors.productPrice && (
                    <div className="invalid-feedback d-block">{errors.productPrice}</div>
                  )}
                </div>
              </div>
              <div className="add-product-field add-quantity">
                <label htmlFor="">{t("totalQuantity")}</label>
                {productAdditionalOptions && productAdditionalOptions.length > 0 ? (
                  <input
                    value={productAdditionalOptions.reduce(
                      (a, v) => (a = a + Number(v.quantity)),
                      0,
                    )}
                    onChange={(e) => onSetQuantity(e)}
                    type="text"
                    name="number"
                    className={`form-control ${errors.quantity ? "error" : ""}`}
                    placeholder="100"
                    readOnly
                    disabled={true}
                  />
                ) : (
                  <input
                    value={quantity}
                    onChange={(e) => onSetQuantity(e)}
                    type="text"
                    name="number"
                    className={`form-control ${errors.quantity ? "error" : ""}`}
                    placeholder="100"
                  />
                )}
                {errors.quantity && (
                  <div className="invalid-feedback d-block">{errors.quantity}</div>
                )}
              </div>
              <div className="add-product-field">
                <label htmlFor="">{t("description")}</label>
                <textarea
                  value={productDescripton}
                  onChange={(e) => setProductDescription(e.target.value)}
                  name=""
                  maxLength={500}
                  className={`form-control ${errors.productDescripton ? "error" : ""}`}
                  placeholder={t("descriptionPlaceholder")}
                ></textarea>
                <p>
                  {t("symbolsUsed", {
                    number: productDescripton?.length,
                    maxNumber: 500,
                  })}
                </p>
              </div>
            </div>

            <div className="flex-one mainInfoItem">
              <div className="add-product-edit">
                <AdditionalPropertiesTable
                  productId={currProduct?.productId}
                  setProductAdditionalOptions={setProductAdditionalOptions}
                  productAdditionalOptions={productAdditionalOptions}
                />

                {/* <ColorTable
                productId={currProduct?.productId}
                setProductColors={undefined}
                productColors={undefined}
              /> */}

                {/* <MaterialTable
                productId={currProduct?.productId}
                productMaterials={undefined}
                setProductMaterials={undefined}
              />

              <SizeTable
                productId={currProduct?.productId}
                productSizes={undefined}
                setProductSizes={undefined}
              />

              <OtherTable
                productId={currProduct?.productId}
                productOthers={undefined}
                setProductOthers={undefined}
              /> */}
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </RequestHandler>
  );
};

AddItem.propTypes = {};

const mapStateToProps = (state) => ({
  colorsByProduct: state.products.colorsByProduct,
  additionalOptionsByProduct: state.products.additionalOptionsByProduct,
  materialsByProduct: state.products.materialsByProduct,
  sizesByProduct: state.products.sizesByProduct,
  userId: state.auth.userId,
  productImages: state.products.productImages,
  imagesLoading: state.products.imagesLoading,
});

export default connect(mapStateToProps, {
  addProductColor,
  getColorsByProduct,
  getAdditionalOptionsByProduct,
  editProductAdditionalOptions,
  addProductAdditionalOptions,
  getMaterialsByProduct,
  getSizesByProduct,
  addProductMaterial,
  addProductSize,
  deleteProductImage,
  deleteAdditionalOptions,
  deleteProductColor,
  deleteProductSize,
  deleteProductMaterial,
  updateProduct,
  addProductImages,
  getProductImages,
  deleteProduct,
})(AddItem);
