export const DEFAULT_WEBSITE_TITLE = "Sellsgram";
export const TITLE_SEPARATOR = " - ";
export const DEFAULT_ERROR_RESPONSE =
  "Unexpected error occurred. Please try again later.";

export const DATE_FORMAT = "YYYY/MM/DD";
export const TIME_FORMAT = "HH:mm A";
export const DATE_TIME_FORMAT = "YYYY/MM/DD HH:mm A";

export const BASEURL = "https://sellsgram-api.azurewebsites.net";
export const BASEURL_PAYMENT = "https://sellsgram-liqpay-payment.herokuapp.com";

export const RESPONSE_URL_DOMAIN = "https://sellsgram-payment-redirect.herokuapp.com";
export const SERVER_CALLBACK_URL = `${BASEURL}/api/sellsgram/buyer/payment/callback`;
export const SERVER_CALLBACK_PAYMENT_LINK_URL = `${BASEURL}/api/sellsgram/buyer/payment/callback/payment-link`;

export const INSTAGRAM_REDIRECT_URL = "https://api.instagram.com/oauth/authorize";
export const INSTAGRAM_CLIENT_ID = "1068669570659381";

export const API_HOST = `${BASEURL}/api/web/sellsgram/`;

export const IS_MOBILE_SCREEN = window.innerWidth < 720;
