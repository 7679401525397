import {
  DISPATCH_NULL_ROUTE,
  GET_PAYOUT_FAILURE,
  GET_PAYOUT_REQUEST_SENT,
  GET_PAYOUT_SUCCESSFULL,
  POST_PAYOUT_FAILURE,
  POST_PAYOUT_REQUEST_SENT,
  POST_PAYOUT_SUCCESSFULL,
} from "./constants";

const initialState = {
  loading: false,
  errorMessage: null,
  from: null,
  totalPayoutData: null,
};

const payoutReducer = (state = initialState, action) => {
  switch (action.type) {
    case POST_PAYOUT_REQUEST_SENT:
      return {
        ...state,
        loading: true,
        errorMessage: null,
      };
    case POST_PAYOUT_SUCCESSFULL:
      return {
        ...state,
        loading: false,
        errorMessage: null,
        from: "profile",
      };
    case POST_PAYOUT_FAILURE:
      return {
        ...state,
        loading: false,
        errorMessage: action.message,
      };
    case GET_PAYOUT_FAILURE:
      return {
        ...state,
        totalPayoutData: false,
        errorMessage: action.message,
      };
    case GET_PAYOUT_SUCCESSFULL:
      return {
        ...state,
        loading: false,
        errorMessage: null,
        totalPayoutData: action.payload,
      };
    case GET_PAYOUT_REQUEST_SENT:
      return {
        ...state,
        loading: true,
        errorMessage: null,
      };
    case DISPATCH_NULL_ROUTE:
      return {
        ...state,
        from: null,
      };
    default:
      return state;
  }
};

export default payoutReducer;
