export const LOGIN_REQUEST_SENT = "LOGIN_REQUEST_SENT";
export const LOGIN_SUCCESSFUL = "LOGIN_SUCCESSFUL";
export const LOGIN_FAILURE = "LOGIN_FAILURE";
export const LOGIN_BY_GOOGLE_FAILURE = "LOGIN_BY_GOOGLE_FAILURE";

export const LOGIN_AS_GUEST_REQUEST_SENT = "LOGIN_AS_GUEST_REQUEST_SENT";
export const LOGIN_AS_GUEST_SUCCESSFUL = "LOGIN_AS_GUEST_SUCCESSFUL";
export const LOGIN_AS_GUEST_FAILURE = "LOGIN_AS_GUEST_FAILURE";
export const LOGIN_BY_GOOGLE_EMAIL = "LOGIN_BY_GOOGLE_EMAIL";

export const LOGOUT = "Auth/LOGOUT";

export const LOADING = "LOADING";

export const SET_CHECK_STORY = "SET_CHECK_STORY";

export const UPDATE_ACCOUNT_STATE = "UPDATE_ACCOUNT_STATE";

export const SWITCH_SELLER_TO_BUYER_FAILURE = "SWITCH_SELLER_TO_BUYER_FAILURE";
export const SWITCH_SELLER_TO_BUYER_SUCCESSFUL = "SWITCH_SELLER_TO_BUYER_SUCCESSFUL";
export const SWITCH_SELLER_TO_BUYER_REQUEST_SENT = "SWITCH_SELLER_TO_BUYER_REQUEST_SENT";
