import React from "react";
import Stories from "react-insta-stories";
import { Modal } from "react-bootstrap";

const StoryModal = ({ showStoryModal, setShowStoryModal }) => {
  const storyContent = [
    {
      url: "/assets/videos/insta-short.mp4",
      type: "video",
    },
  ];

  return (
    <Modal
      show={showStoryModal}
      contentClassName="story-modal"
      onHide={setShowStoryModal}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <Stories loop keyboardNavigation stories={storyContent} />
      </Modal.Body>
    </Modal>
  );
};

export default StoryModal;
