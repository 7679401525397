import "react-activity/dist/Sentry.css";

import React, { useEffect, useState, useCallback } from "react";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

import { RequestHandler } from "../../../../Components/RequestHandler/RequestHandler";
import {
  getSellerCourierList,
  getSellerLocalDeliveries,
  getSellerShops,
} from "../../../../Redux/Cart/actions";
import DeliveryCourier from "../../../Delivery/DeliveryCourier";
import DeliveryLocal from "../../../Delivery/DeliveryLocal";
import DeliverySellerLocation from "../../../Delivery/DeliverySellerLocation";
import Dropdown from "react-bootstrap/Dropdown";
import { isEmpty } from "lodash";

const Option = ({ setDeliveryType, methods, deliveryType, deliveryName }) => {
  const { t } = useTranslation();
  return (
    <div className="flex-one">
      <div className="payment-info-list d-flex align-items-center justify-content-between">
        <div className="custom-control custom-radio">
          <input
            type="radio"
            className="custom-control-input"
            id={deliveryName}
            name="example1"
            value="customEx"
            onChange={() => setDeliveryType(deliveryType)}
          />
          <label className="custom-control-label" htmlFor={deliveryName}>
            {deliveryName}
          </label>
        </div>
        <div className="card-icon">
          {methods} {t("available")}
        </div>
      </div>
    </div>
  );
};

const CustomDropdownButton = React.forwardRef(({ onClick, disabled }, ref) => {
  const { t } = useTranslation();
  return (
    <button
      ref={ref}
      disabled={disabled}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
      className="btn btn-add-other d-flex align-items-center w-100 justify-content-center"
    >
      {t("addCountry")}
      <img src="/assets/images/icon-add-other.svg" alt="" />
    </button>
  );
});

const DeliveryStep = ({
  getSellerShops,
  getSellerCourierList,
  getSellerLocalDeliveries,
  sellerShops,
  userId,
  sellerCourierList,
  sellerLocalDeliveries,
  onNextStep,
}) => {
  const { t } = useTranslation();
  const [countries, setCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [deliveryType, setDeliveryType] = useState(0);
  const countiesList = Object.freeze([
    { name: t("usa"), id: 217 },
    { name: t("ukraine"), id: 1 },
  ]);

  const handleCountryClick = useCallback(
    (countyId) => {
      setSelectedCountry(countyId);
      getSellerShops(userId);
      getSellerCourierList(userId);
      getSellerLocalDeliveries(userId, countyId);
    },
    [userId],
  );

  useEffect(() => {
    getSellerShops(userId);

    getSellerCourierList(userId);
    sellerCourierList.forEach(updateCountries);
    sellerLocalDeliveries.forEach(updateCountries);
    sellerShops.forEach(updateCountries);
  }, [sellerShops?.length, sellerLocalDeliveries?.length, sellerCourierList?.length]);

  const updateCountries = ({ countryId }) => {
    const country = countiesList.find(({ id }) => id === countryId);
    setCountries((prev) => {
      if (!prev.find(({ id }) => id === countryId)) {
        if (!selectedCountry) {
          getSellerLocalDeliveries(userId, countryId);
          setSelectedCountry(countryId);
        }
        return [...prev, country];
      } else return prev;
    });
  };

  const filterByCountry = (list) => {
    return list?.filter(({ countryId }) => countryId === selectedCountry);
  };

  const countryFilteredOptions = countiesList.filter(
    ({ id }) => !countries.some((country) => country.id === id),
  );

  const handleFinishBtn = () => {
    if (sellerLocalDeliveries.length || sellerCourierList.length || sellerShops.length) {
      onNextStep();
    } else {
      toast.error(t("deliveryRequired"), {
        position: "top-center",
      });
    }
  };

  const onSetCountries = (id) => {
    const countryId = Number(id);
    const country = countiesList.find(({ id }) => id === countryId);
    setCountries((prev) =>
      prev.some(({ id }) => id === countryId) ? prev : [...prev, country],
    );
    if (!selectedCountry) handleCountryClick(countryId);
  };
  const getCountryNameByID = (countryID) =>
    countiesList.find(({ id }) => id === countryID)?.name;
  return (
    <RequestHandler isLoading={false}>
      <p className="h2">{t("addDeliveryMethod")}</p>
      <div className="d-flex flex-md-row flex-column row">
        <div className="flex-one  mb-4">
          <div className="add-product-edit">
            <label htmlFor="">{t("country")}</label>
            {countries.map((country) => (
              <div key={country.id}>
                <Button
                  className="btn btn-country d-flex align-items-center w-100 justify-content-center"
                  variant="primary"
                  active={country.id === selectedCountry}
                  onClick={() => handleCountryClick(country.id)}
                >
                  {getCountryNameByID(country.id)}
                  <img src="/assets/images/icon-edit.svg" alt="" />
                </Button>
                <br />
              </div>
            ))}
            <Dropdown align="start" onSelect={onSetCountries}>
              <Dropdown.Toggle
                id="dropdown-custom-components"
                variant="none"
                disabled={isEmpty(countryFilteredOptions)}
                as={CustomDropdownButton}
              ></Dropdown.Toggle>
              <Dropdown.Menu>
                {countryFilteredOptions?.map(({ name, id }) => (
                  <Dropdown.Item eventKey={id} key={id}>
                    {name}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
        {selectedCountry && (
          <div className="flex-two mb-4">
            <label htmlFor="">{getCountryNameByID(selectedCountry)}</label>
            <div className="delivery-info delivery-info-scroll delivery-section">
              <Option
                deliveryName={t("selfPickup")}
                deliveryType={1}
                methods={filterByCountry(sellerShops)?.length}
                setDeliveryType={setDeliveryType}
              />
              {deliveryType === 1 && (
                <DeliverySellerLocation
                  countryId={selectedCountry}
                  deliveryType={deliveryType}
                  selfDeliveries={filterByCountry(sellerShops)}
                />
              )}
              <Option
                deliveryName={t("courier")}
                methods={filterByCountry(sellerCourierList)?.length}
                deliveryType={2}
                setDeliveryType={setDeliveryType}
              />
              {deliveryType === 2 && (
                <DeliveryCourier
                  countryId={selectedCountry}
                  deliveryType={deliveryType}
                  courierDeliveries={filterByCountry(sellerCourierList)}
                />
              )}
              <Option
                deliveryName={t("localDelivery")}
                methods={filterByCountry(sellerLocalDeliveries)?.length}
                deliveryType={3}
                setDeliveryType={setDeliveryType}
              />
              {deliveryType === 3 && (
                <DeliveryLocal
                  countryId={selectedCountry}
                  deliveryType={deliveryType}
                  localDeliveries={filterByCountry(sellerLocalDeliveries)}
                />
              )}
            </div>
          </div>
        )}
        <div>
          <button className="btn wizard-button" onClick={handleFinishBtn}>
            {t("next")}
          </button>
        </div>
      </div>
    </RequestHandler>
  );
};

const mapStateToProps = (state) => ({
  userId: state.auth.userId,
  sellerShops: state.cart.sellerShops,
  sellerCourierList: state.cart.sellerCourierList,
  sellerLocalDeliveries: state.cart.sellerLocalDeliveries,
});

export default connect(mapStateToProps, {
  getSellerShops,
  getSellerCourierList,
  getSellerLocalDeliveries,
})(DeliveryStep);
