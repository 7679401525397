import axios from "axios";
import uuid from "react-uuid";

import { BASEURL } from "../../Configs/constants";
import getOrderStatuses from "../../Helpers/getOrderStatuses";
import {
  FOLLOW_FAILURE,
  FOLLOW_REQUEST_SENT,
  FOLLOW_SUCCESSFUL,
  GET_IS_FOLLOWING_FAILURE,
  GET_IS_FOLLOWING_REQUEST_SENT,
  GET_IS_FOLLOWING_SUCCESSFUL,
  GET_REQUESTS_FAILURE,
  GET_REQUESTS_SUCCESSFUL,
  GET_USER_FEED_BY_GROUP_FAILURE,
  GET_USER_FEED_BY_GROUP_REQUEST_SENT,
  GET_USER_FEED_BY_GROUP_SUCCESSFUL,
  GET_USER_FEED_FAILURE,
  GET_USER_FEED_REQUEST_SENT,
  GET_USER_FEED_SUCCESSFUL,
  GET_USER_INFO_FAILURE,
  GET_USER_INFO_REQUEST_SENT,
  GET_USER_INFO_SUCCESSFUL,
  GET_USER_INSTA_POSTS_FAILURE,
  GET_USER_INSTA_POSTS_REQUEST_SENT,
  GET_USER_INSTA_POSTS_SUCCESSFUL,
  UNFOLLOW_FAILURE,
  UNFOLLOW_REQUEST_SENT,
  UNFOLLOW_SUCCESSFUL,
  GET_USER_REQUESTED_ORDERS_SENT,
  GET_USER_REQUESTED_ORDERS_SUCCESSFUL,
  GET_USER_REQUESTED_ORDERS_FAILURE,
  UPDATE_ORDER_STATUS_FAILURE,
  UPDATE_ORDER_STATUS_SUCCESS,
  UPDATE_ORDER_STATUS_SENT,
  BULK_UPDATE_ORDER_STATUS_FAILURE,
  BULK_UPDATE_ORDER_STATUS_SENT,
  BULK_UPDATE_ORDER_STATUS_SUCCESS,
} from "./constants";
import { getAccessToken2 } from "../../Helpers/auth";

export const getUserInfo = (userId, setUserInfoRequestsIds) => async (dispatch) => {
  const countryId = localStorage.getItem("language") === "ua" ? 1 : 217;

  const requestId = uuid();

  try {
    dispatch({
      type: GET_USER_INFO_REQUEST_SENT,
    });
    setUserInfoRequestsIds?.((prevState) => [...prevState, requestId]);
    const resp = await axios.get(
      `${BASEURL}/api/web/sellsgram/user/general/${userId}?CountryID=${countryId}`,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: getAccessToken2(),
        },
      },
    );
    console.log('getUserInfo - resp: (and dispatch resp.data)', resp);
    dispatch({
      type: GET_USER_INFO_SUCCESSFUL,
      payload: resp.data,
    });
    setUserInfoRequestsIds?.((prevState) =>
      [...prevState].filter((item) => item !== requestId),
    );
  } catch (err) {
    dispatch({
      type: GET_USER_INFO_FAILURE,
      message: err.response?.data ? err.response.data.title : err.message,
    });
    setUserInfoRequestsIds?.((prevState) =>
      [...prevState].filter((item) => item !== requestId),
    );
  }
};

export const getUserFeed = (userId) => async (dispatch) => {
  const countryId = localStorage.getItem("language") === "ua" ? 1 : 217;

  try {
    dispatch({
      type: GET_USER_FEED_REQUEST_SENT,
    });
    const resp = await axios.get(
      `${BASEURL}/api/web/sellsgram/user/feed/${userId}?CountryID=${countryId}`,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: getAccessToken2(),
        },
      },
    );
    dispatch({
      type: GET_USER_FEED_SUCCESSFUL,
      payload: resp.data,
    });
  } catch (err) {
    dispatch({
      type: GET_USER_FEED_FAILURE,
      message: err.response?.data ? err.response.data : err.message,
    });
  }
};

export const getUserInstaPosts = (userId) => async (dispatch) => {
  try {
    dispatch({
      type: GET_USER_INSTA_POSTS_REQUEST_SENT,
    });
    const resp = await axios.get(`${BASEURL}/api/instagram/data/posts/${userId}`, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: getAccessToken2(),
      },
    });
    dispatch({
      type: GET_USER_INSTA_POSTS_SUCCESSFUL,
      payload: resp.data,
    });
  } catch (err) {
    dispatch({
      type: GET_USER_INSTA_POSTS_FAILURE,
      message: err.response?.data ? err.response.data.title : err.message,
    });
  }
};

export const getUserFeedByGroup = (groupId, userId) => async (dispatch) => {
  const countryId = localStorage.getItem("language") === "ua" ? 1 : 217;

  dispatch({
    type: GET_USER_FEED_BY_GROUP_REQUEST_SENT,
  });

  try {
    const resp = await axios.post(
      `${BASEURL}/api/web/sellsgram/user/feed/by-groups/${userId}?CountryID=${countryId}`,
      [groupId],
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: getAccessToken2(),
        },
      },
    );
    dispatch({
      type: GET_USER_FEED_BY_GROUP_SUCCESSFUL,
      payload: resp.data,
    });
    return resp.data;
  } catch (err) {
    dispatch({
      type: GET_USER_FEED_BY_GROUP_FAILURE,
      message: err.response?.data ? err.response.data.title : err.message,
    });
  }
};

export const getRequestsCount = (userId, setUserInfoRequestsIds) => async (dispatch) => {
  const countryId = localStorage.getItem("language") === "ua" ? 1 : 217;
  const requestId = uuid();

  try {
    dispatch({
      type: GET_USER_FEED_BY_GROUP_REQUEST_SENT,
    });
    setUserInfoRequestsIds?.((prevState) => [...prevState, requestId]);

    const resp = await axios.get(
      `${BASEURL}/api/sellsgram/seller/requested-orders/count/${userId}?countryID=${countryId}`,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: getAccessToken2(),
        },
      },
    );

    dispatch({
      type: GET_REQUESTS_SUCCESSFUL,
      payload: resp.data,
    });
    setUserInfoRequestsIds?.((prevState) =>
      [...prevState].filter((item) => item !== requestId),
    );
    return resp.data;
  } catch (err) {
    dispatch({
      type: GET_REQUESTS_FAILURE,
      message: err.response?.data ? err.response.data.title : err.message,
    });
    setUserInfoRequestsIds?.((prevState) =>
      [...prevState].filter((item) => item !== requestId),
    );
  }
};

export const getRequests =
  ({ userId, filter, pageSize, statusId, pageNumber }) =>
  async (dispatch) => {
    const countryID = localStorage.getItem("language") === "ua" ? 1 : 217;

    try {
      dispatch({
        type: GET_USER_REQUESTED_ORDERS_SENT,
      });

      const resp = await axios.get(
        `${BASEURL}/api/sellsgram/seller/requested-orders/${userId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: getAccessToken2(),
          },
          params: {
            filter,
            countryID,
            pageSize,
            statusId,
            pageNumber,
          },
        },
      );

      dispatch({
        type: GET_USER_REQUESTED_ORDERS_SUCCESSFUL,
        payload: resp.data,
      });
      return resp.data;
    } catch (err) {
      dispatch({
        type: GET_USER_REQUESTED_ORDERS_FAILURE,
        message: err.response?.data ? err.response.data.title : err.message,
      });
    }
  };

export const getIsFollowing = (userId, fromUserId) => async (dispatch) => {
  try {
    dispatch({
      type: GET_IS_FOLLOWING_REQUEST_SENT,
    });

    const resp = await axios.get(
      `${BASEURL}/api/web/sellsgram/buyer/followings/is-following?userId=${fromUserId}&followingUserId=${userId}`,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: getAccessToken2(),
        },
      },
    );

    dispatch({
      type: GET_IS_FOLLOWING_SUCCESSFUL,
      payload: resp.data,
    });
    return resp.data;
  } catch (err) {
    dispatch({
      type: GET_IS_FOLLOWING_FAILURE,
      message: err.response?.data ? err.response.data.title : err.message,
    });
  }
};

export const follow = (userId, fromUserId) => async (dispatch) => {
  try {
    dispatch({
      type: FOLLOW_REQUEST_SENT,
    });

    const resp = await axios.post(
      `${BASEURL}/api/web/sellsgram/buyer/followings/follow?userId=${fromUserId}&followingUserId=${userId}`,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: getAccessToken2(),
        },
      },
    );

    dispatch({
      type: FOLLOW_SUCCESSFUL,
      payload: resp.data,
    });
    return resp.data;
  } catch (err) {
    dispatch({
      type: FOLLOW_FAILURE,
      message: err.response?.data ? err.response.data.title : err.message,
    });
  }
};

export const unfollow = (userId, fromUserId) => async (dispatch) => {
  try {
    dispatch({
      type: UNFOLLOW_REQUEST_SENT,
    });

    const resp = await axios.delete(
      `${BASEURL}/api/web/sellsgram/buyer/followings/unfollow?userId=${fromUserId}&followingUserId=${userId}`,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: getAccessToken2(),
        },
      },
    );

    dispatch({
      type: UNFOLLOW_SUCCESSFUL,
      payload: resp.data,
    });
    return resp.data;
  } catch (err) {
    dispatch({
      type: UNFOLLOW_FAILURE,
      message: err.response?.data ? err.response.data.title : err.message,
    });
  }
};

export const bulkUpdateOrderStatuses = ({ sellerUserId, orderIds }) => async (dispatch) => {
  try {
    dispatch({
      type: BULK_UPDATE_ORDER_STATUS_SENT,
    });

    const resp = await axios.post(
      `${BASEURL}/api/sellsgram/seller/requested-orders/payout/all/${sellerUserId}`,
      orderIds,      
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: getAccessToken2(),
        },
      },
    );

    dispatch({
      type: BULK_UPDATE_ORDER_STATUS_SUCCESS,
      payload: resp.data,
    });    
    return resp.data;
  } catch (err) {
    dispatch({
      type: BULK_UPDATE_ORDER_STATUS_FAILURE,
      message: err.response?.data ? err.response.data.title : err.message,
    });
  }
};

export const updateOrderStatus =
  ({ sellerUserId, orderId, statusId, message = "", imageLink = "" }) =>
  async (dispatch) => {
    dispatch({
      type: UPDATE_ORDER_STATUS_SENT,
    });
    await axios.put(`${BASEURL}/api/sellsgram/seller/requested-orders/status`, null, {
      params: {
        sellerUserId,
        orderId,
        statusId,
        message,
        imageLink,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: getAccessToken2(),
      },
    });
    const shippedStatus = getOrderStatuses.find(({ name }) => name === "shipped");
    let orderPayoutDate = null;
    if (statusId === shippedStatus?.id) {
      const res = await axios.get(
        `${BASEURL}/api/sellsgram/seller/requested-orders/date/difference/${orderId}`,{
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: getAccessToken2(),
          },
        }
      );
      orderPayoutDate = res.data;
    }
    dispatch({
      type: UPDATE_ORDER_STATUS_SUCCESS,
      payload: { statusId, orderId, orderPayoutDate },
    });
  };
