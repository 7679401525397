import { combineReducers } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import { subscriptionsApi } from "../Api/subscriptions";
import { deliveryApi } from "../Api/deliveryApi";
import { cartApi } from "../Api/cartCountApi";
import authReducer from "./Auth/reducer";
import cartReducer from "./Cart/reducer";
import googleAuthReducer from "./GoogleAuth/reducer";
import myOrdersReducer from "./MyOrders/reducer";
import payoutReducer from "./Payouts/reducer";
import productsReducer from "./Products/reducer";
import profileReducer from "./Profile/reducer";
import userProfileReducer from "./UserProfile/reducer";
import buyerReducer from "./Buyer/reducer";

const persistConfig = {
  key: "crms-root",
  storage,
  blacklist: ["subscriptionsApi", "myOrders", "cartApi", "buyer", "deliveryApi"],
};

const authPersistConfig = {
  key: "auth",
  storage,
  whitelist: ["token", "me", "userId", "authenticated"],
};

const appReducer = combineReducers({
  auth: persistReducer(authPersistConfig, authReducer),
  googleAuth: googleAuthReducer,
  products: productsReducer,
  profile: profileReducer,
  cart: cartReducer,
  userProfile: userProfileReducer,
  payout: payoutReducer,
  myOrders: myOrdersReducer,
  buyer: buyerReducer,
  [subscriptionsApi?.reducerPath]: subscriptionsApi?.reducer,
  [deliveryApi?.reducerPath]: deliveryApi?.reducer,
  [cartApi?.reducerPath]: cartApi?.reducer,
});

const rootReducer = (state, action) => {
  if (action.type === "Auth/LOGOUT") {
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};

export default persistReducer(persistConfig, rootReducer);
