import React from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import "./OrderInfoFooterStyles.scss";

const OrderInfoFooter = ({
  status,
  onUpdateOrderStatus,
  setShowCancelModal,
  setShowShippedModal,
  orderPayoutDate,
}) => {
  const { t } = useTranslation();

  const buttonRender = () => {
    switch (status) {
      case "NEW":
        return (
          <button
            className="in-processing-button info-button-group"
            onClick={() => onUpdateOrderStatus(14)}
          >
            {t("inProcessing")}
          </button>
        );
      case "IN PROCESS":
        return (
          <button
            className="shipped-button info-button-group"
            onClick={() => setShowShippedModal(true)}
          >
            {t("ship")}
            <img src="/assets/images/shipped-icon.svg" alt="shipped-icon" />
          </button>
        );
      case "SHIPPED":
        return orderPayoutDate?.isReadyForPayout ? (
          <button
            className="to-payout-button info-button-group"
            onClick={() => onUpdateOrderStatus(16)}
          >
            {t("ReceivePayment")}
          </button>
        ) : (
          <p className="pending-payout">{t("shipped")}</p>
        );
      case "TO PAYOUT":
      case "PENDING PAYOUT":
        return <p className="pending-payout">{t("pendingPayout")}</p>;
      case "CANCELLED":
        return <p className="cancelled-payout">{t("cancelled")}</p>;
      default:
        return <p className="completed-payout">{t("completed")}</p>;
    }
  };

  return (
    <div className="footer-buttons">
      {buttonRender()}
      {(status === "NEW" || status === "IN PROCESS") && (
        <button
          className="cancel-button mobile info-button-group"
          onClick={() => setShowCancelModal(true)}
        >
          {t("cancelOrder")}
        </button>
      )}
    </div>
  );
};

OrderInfoFooter.propTypes = {
  status: PropTypes.string.isRequired,
  onUpdateOrderStatus: PropTypes.func.isRequired,
  setShowShippedModal: PropTypes.func.isRequired,
  setShowCancelModal: PropTypes.func.isRequired,
  orderPayoutDate: PropTypes.object.isRequired,
};

export default OrderInfoFooter;
