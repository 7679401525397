import "./RequestHandler.scss";

import React from "react";
import Sentry from "react-activity/dist/Sentry";
import PropTypes from "prop-types";

export function RequestHandler({ isLoading, withAutoHeight, children }) {
  if (isLoading) {
    return (
      <div className={withAutoHeight ? "loading-container-h-100" : "loading-container"}>
        <Sentry color="#4B85DC" size={100} speed={0.5} animating={true} />
      </div>
    );
  }

  return <>{children}</>;
}

RequestHandler.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
  withAutoHeight: PropTypes.bool,
};

RequestHandler.defaultProps = {
  withAutoHeight: false,
};
