import axios from "axios";
import uuid from "react-uuid";

import { BASEURL } from "../../Configs/constants";
import { clearStorage, getAccessToken2 } from "../../Helpers/auth";
import { getRequest, putRequest } from "../../Helpers/axios";
import {
  LOADING,
  SET_CHECK_STORY,
  LOGIN_AS_GUEST_FAILURE,
  LOGIN_AS_GUEST_REQUEST_SENT,
  LOGIN_AS_GUEST_SUCCESSFUL,
  LOGIN_BY_GOOGLE_EMAIL,
  LOGIN_BY_GOOGLE_FAILURE,
  LOGIN_FAILURE,
  LOGIN_REQUEST_SENT,
  LOGIN_SUCCESSFUL,
  LOGOUT,
  UPDATE_ACCOUNT_STATE,
  SWITCH_SELLER_TO_BUYER_REQUEST_SENT,
  SWITCH_SELLER_TO_BUYER_SUCCESSFUL,
  SWITCH_SELLER_TO_BUYER_FAILURE,
} from "./constants";
import { SET_HAS_INSTAGRAM } from "../GoogleAuth/constants";
import { getUserInfo } from "../../Redux/Profile/actions";

const getUrl = () => {
  if (window.location.href.includes(process.env.REACT_APP_URL_DEVELOPMENT))
    return process.env.REACT_APP_URL_DEVELOPMENT;
  else if (window.location.href.includes(process.env.REACT_APP_URL_PRODUCTION))
    return "https://" + process.env.REACT_APP_URL_PRODUCTION;
  else return process.env.REACT_APP_URL_LOCALHOST;
};

export const login = (code, setUserInfoRequestsIds, googleEmail) => async (dispatch) => {
  const requestId = uuid();

  try {
    dispatch({
      type: LOGIN_REQUEST_SENT,
    });
    setUserInfoRequestsIds((prevState) => [...prevState, requestId]);

    const resp = await getRequest({
      url: `${BASEURL}/api/instagram/data/auth/url/${code}?redirect_url=${getUrl()}/`,
    });

    console.log("response from Instagram: (use resp.instagramToken?.userId) ", resp);

    getUserInfo(resp.instagramToken?.userId, setUserInfoRequestsIds);

    localStorage.setItem("userAccessToken", JSON.stringify(resp?.userAccessToken));

    dispatch({
      type: LOGIN_SUCCESSFUL,
      userId: resp.instagramToken?.userId,
      username: resp.instagramToken?.instagramUsername,
      token: resp.instagramToken?.instagramCode,
      resp: resp.instagramToken,
      accountState: resp.accountState,
    });

    if (googleEmail) {
      putRequest({
        url: `${BASEURL}/api/web/sellsgram/user/email/${resp.instagramToken?.userId}?username=${resp.instagramToken?.instagramUsername}&email=${googleEmail}`,
      });
    }
    if (resp.instagramToken) {
      dispatch({
        type: SET_HAS_INSTAGRAM,
        data: true,
      });
    }

    setUserInfoRequestsIds((prevState) =>
      [...prevState].filter((item) => item !== requestId),
    );
    return resp.data;
  } catch (err) {
    dispatch({
      type: LOGIN_FAILURE,
      message: err.response?.data ? err.response.data.message : err.message,
    });
    setUserInfoRequestsIds((prevState) =>
      [...prevState].filter((item) => item !== requestId),
    );
  }
};

export const loginByGoogleEmail = (email, redirect) => async (dispatch) => {
  try {
    const resp = await axios.get(`${BASEURL}/api/web/sellsgram/user/email`, {
      params: {
        email,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: getAccessToken2(),
      },
    });
    if (resp.data.isExist) {
      dispatch({
        type: LOGIN_BY_GOOGLE_EMAIL,
        payload: {
          user: resp.data.user,
          accountState: resp.data.accountState,
        },
      });
      if (resp.data.user.username) redirect(`/${resp.data.user.username}/`);
    }
  } catch (err) {
    dispatch({
      type: LOGIN_BY_GOOGLE_FAILURE,
      message: err.response?.data ? err.response.data.message : err.message,
    });
  }
};

export const loginAsGuest = () => async (dispatch) => {
  try {
    dispatch({
      type: LOGIN_AS_GUEST_REQUEST_SENT,
    });
    const resp = await axios.get(`${BASEURL}/api/web/sellsgram/user/temporary_user`, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: getAccessToken2(),
      },
    });

    dispatch({
      type: LOGIN_AS_GUEST_SUCCESSFUL,
      payload: resp.data,
    });
    return resp.data;
  } catch (err) {
    dispatch({
      type: LOGIN_AS_GUEST_FAILURE,
      message: err.response?.data ? err.response.data.message : err.message,
    });
  }
};

export const logout = () => (dispatch) => {
  clearStorage();
  dispatch({
    type: LOGOUT,
  });
};

export const setLoading = (loading) => (dispatch) => {
  dispatch({
    type: LOADING,
    payload: loading,
  });
};

export const setCheckStory = (checked) => (dispatch) => {
  dispatch({
    type: SET_CHECK_STORY,
    payload: checked,
  });
};

export const updateAuthUserAccountState = (payload) => (dispatch) => {
  dispatch({
    type: UPDATE_ACCOUNT_STATE,
    payload,
  });
};

export const switchToBuyer = (sellerUserId) => async (dispatch) => {
  try {
    dispatch({
      type: SWITCH_SELLER_TO_BUYER_REQUEST_SENT,
    });
    const resp = await axios.get(
      `${BASEURL}/api/web/sellsgram/seller/switch-to-buyer/${sellerUserId}`,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: getAccessToken2(),
        },
      },
    );
    dispatch({
      type: UPDATE_ACCOUNT_STATE,
      payload: resp.data?.userBuyer?.accountState,
    });
  } catch (err) {
    dispatch({
      type: SWITCH_SELLER_TO_BUYER_FAILURE,
      message: err.response?.data ? err.response.data.detail : err.message,
    });
  }
};
