import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import baseQuery from "../Configs/baseQuery";

export const cartApi = createApi({
  reducerPath: "cartApi",
  baseQuery: baseQuery,
  endpoints: (builder) => ({
    getCartCountByUserId: builder.query({
      query: (userId) => `cart/count/${userId}`,
    }),
  }),
});

export const { useLazyGetCartCountByUserIdQuery } = cartApi;
