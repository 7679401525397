import "./Footer.scss";

import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import FOOTER_NAVIGATIONS from "../../Configs/footer-navigations";
import SOCIAL_LINKS from "../../Configs/social";
import { MasterCardLogo, VisaLogo } from "../Icons";
import packageJson from '../../../package.json';

const Footer = () => {
  const history = useHistory();

  const { t, i18n } = useTranslation();

  return (
    <footer>
      <div className="payment-social">
        <div className="payment">
          <p>{t("footer-payment-title")}</p>
          <VisaLogo />
          <MasterCardLogo />
        </div>
        <div className="footer-divider" />
        <div className="footer-navigation">
          {FOOTER_NAVIGATIONS.map((navigate, index) => {
            return (
              <div
                key={index}
                style={{ fontWeight: i18n.language === "ua" ? 600 : 500 }}
                className="footer-navigation-link"
                onClick={() => {
                  if (!navigate.isLink) {
                    history.replace({ pathname: navigate.path });
                  } else {
                    window.location.assign(navigate.path);
                  }
                }}
              >
                {t(navigate.name)}
              </div>
            );
          })}
        </div>
        <div className="footer-divider" />
        <div className="social">
          <p>{t("social-media")}</p>
          <div className="social-items">
            {SOCIAL_LINKS.map(({ Icon, link }, index) => {
              return (
                <a
                  key={index}
                  target="_blank"
                  href={link}
                  className="social-item"
                  rel="noreferrer"
                >
                  <Icon />
                </a>
              );
            })}
          </div>
        </div>
      </div>
      <div className="footer-copyright">
        <p>© {new Date().getFullYear()} Sellsgram {process.env.REACT_APP_VERSION}</p>
      </div>
    </footer>
  );
};

export default Footer;
