import "./SearchFilter.scss";

import PropTypes from "prop-types";
import React, { useState } from "react";

import { Filter } from "./icons/Filter";
import { Search } from "./icons/Search";

export const SearchFilter = ({ getOrdersByUserId, myUserId }) => {
  const [showSearch, setShowSearch] = useState(false);

  const changeViewSearch = () => {
    setShowSearch((prev) => !prev);
  };

  return (
    <div className={`searchWrapper ${showSearch ? "showInput" : ""}`}>
      <Search {...{ showSearch, changeViewSearch, getOrdersByUserId, myUserId }} />

      {/* <Filter /> */}
    </div>
  );
};

SearchFilter.propTypes = {
  getOrdersByUserId: PropTypes.func,
  myUserId: PropTypes.number,
};
