import {
  DELETE_USER_CART_PRODUCT_FAILURE,
  DELETE_USER_CART_PRODUCT_REQUEST_SENT,
  DELETE_USER_CART_PRODUCT_SUCCESSFUL,
  DISPATCH_NULL_ROUTE,
  GET_CITIES_BY_COUNTRY_FAILURE,
  GET_CITIES_BY_COUNTRY_SENT,
  GET_CITIES_BY_COUNTRY_SUCCESSFUL,
  GET_CITIES_COURIER_FAILURE,
  GET_CITIES_COURIER_SENT,
  GET_CITIES_COURIER_SUCCESSFUL,
  GET_CITIES_SELLER_SHOPS_FAILURE,
  GET_CITIES_SELLER_SHOPS_SENT,
  GET_CITIES_SELLER_SHOPS_SUCCESSFUL,
  GET_COUNTRIES_COURIER_FAILURE,
  GET_COUNTRIES_COURIER_SENT,
  GET_COUNTRIES_COURIER_SUCCESSFUL,
  GET_COUNTRIES_SELLER_SHOPS_FAILURE,
  GET_COUNTRIES_SELLER_SHOPS_SENT,
  GET_COUNTRIES_SELLER_SHOPS_SUCCESSFUL,
  GET_LIQPAY_FAILURE,
  GET_LIQPAY_REQUEST_SENT,
  GET_LIQPAY_SUCCESSFULL,
  GET_LOCAL_COUNTRIES_FAILURE,
  GET_LOCAL_COUNTRIES_SENT,
  GET_LOCAL_COUNTRIES_SUCCESSFUL,
  GET_LOCAL_WAREHOUSES_FAILURE,
  GET_LOCAL_WAREHOUSES_REQUEST_SENT,
  GET_LOCAL_WAREHOUSES_SUCCESSFULL,
  GET_SELLER_COURIER_FAILURE,
  GET_SELLER_COURIER_REQUEST_SENT,
  GET_SELLER_COURIER_SUCCESSFULL,
  GET_SELLER_COURIER_REQUEST_FINISH,
  GET_SELLER_LOCAL_FAILURE,
  GET_SELLER_LOCAL_REQUEST_SENT,
  GET_SELLER_LOCAL_SUCCESSFULL,
  GET_SELLER_SHOPS_FAILURE,
  GET_SELLER_SHOPS_REQUEST_SENT,
  GET_SELLER_SHOPS_SUCCESSFULL,
  GET_TOTAL_LOCAL_FAILURE,
  GET_TOTAL_LOCAL_REQUEST_SENT,
  GET_TOTAL_LOCAL_SUCCESSFULL,
  GET_USER_CART_PRODUCTS_FAILURE,
  GET_USER_CART_PRODUCTS_REQUEST_SENT,
  GET_USER_CART_PRODUCTS_SUCCESSFUL,
  POST_USERS_CART_ORDER_FAILURE,
  POST_USERS_CART_ORDER_REQUEST_SENT,
  POST_USERS_CART_ORDER_SUCCESSFUL,
  SET_USER_CART_PRODUCTS_DELIVERY,
  SET_DELIVERY_INFORMATION,
  UPDATE_USER_CART_PRODUCT_FAILURE,
  UPDATE_USER_CART_PRODUCT_REQUEST_SENT,
  UPDATE_USER_CART_PRODUCT_SUCCESSFUL,
  GET_HAS_USER_BUYER_DELIVERIES_REQUEST_SENT,
  GET_HAS_USER_BUYER_DELIVERIES_SUCCESSFUL,
  GET_HAS_USER_BUYER_DELIVERIES_FAILURE,
} from "./constants";

const initialState = {
  loading: false,
  errorMessage: null,
  from: null,
  totalCartProducts: [],
  userBuyerDeliveries: [],
  totalCitiesByCountry: [],
  sellerShopsCities: [],
  sellerShopsCountries: [],
  courierCities: [],
  courierCountries: [],
  localDeliveryCities: [],
  localDeliveryCountries: [],
  loadingCities: false,
  loadingSellerShops: false,
  loadingLocalDelivery: false,
  loadingLiqPayData: false,
  loadingCountries: false,
  sellerShops: [],
  sellerCourierList: [],
  totalLocalDelivery: [],
  sellerLocalDeliveries: [],
  loadingLocalWarehouses: false,
  totalDeliveryWarehouses: [],
  totalLiqPayData: [],
};

const cartReducer = (state = initialState, action) => {
  switch (action.type) {
    case POST_USERS_CART_ORDER_REQUEST_SENT:
      return {
        ...state,
        loading: true,
        errorMessage: null,
      };
    case POST_USERS_CART_ORDER_SUCCESSFUL:
      return {
        ...state,
        loading: false,
        errorMessage: null,
        from: "profile",
      };
    case POST_USERS_CART_ORDER_FAILURE:
      return {
        ...state,
        loading: false,
        errorMessage: action.message,
      };
    case GET_USER_CART_PRODUCTS_REQUEST_SENT:
      return {
        ...state,
        loading: true,
        errorMessage: null,
      };
    case GET_USER_CART_PRODUCTS_SUCCESSFUL:
      return {
        ...state,
        loading: false,
        errorMessage: null,
        totalCartProducts: action.payload,
      };
    case SET_USER_CART_PRODUCTS_DELIVERY:
      return {
        ...state,
        totalCartProducts: state.totalCartProducts.map((product) => {
          if (+product.productId === action.payload.productId) {
            return { ...product, deliveryInfo: action.payload.deliveryInfo };
          }
          return product;
        }),
      };

    case SET_DELIVERY_INFORMATION:
      console.log("SET_DELIVERY_INFORMATION:", action.payload);
      return {
        ...state,
        userBuyerDeliveries: [
          ...(state.userBuyerDeliveries || []).filter(
            (delivery) => delivery.orderId !== action.payload.delivery.orderId,
          ),
          action.payload.delivery,
        ],
      };

    case GET_USER_CART_PRODUCTS_FAILURE:
      return {
        ...state,
        loading: false,
        errorMessage: action.message,
      };
    case DELETE_USER_CART_PRODUCT_REQUEST_SENT:
      return {
        ...state,
        loading: true,
        errorMessage: null,
      };
    case DELETE_USER_CART_PRODUCT_SUCCESSFUL:
      return {
        ...state,
        loading: false,
        errorMessage: null,
      };
    case DELETE_USER_CART_PRODUCT_FAILURE:
      return {
        ...state,
        loading: false,
        errorMessage: action.message,
      };
    case UPDATE_USER_CART_PRODUCT_REQUEST_SENT:
      return {
        ...state,
        loading: true,
        errorMessage: null,
      };
    case UPDATE_USER_CART_PRODUCT_SUCCESSFUL:
      return {
        ...state,
        loading: false,
        errorMessage: null,
      };
    case UPDATE_USER_CART_PRODUCT_FAILURE:
      return {
        ...state,
        loading: false,
        errorMessage: action.message,
      };
    case GET_CITIES_BY_COUNTRY_SENT:
      return {
        ...state,
        loadingCities: true,
        errorMessage: null,
      };
    case GET_CITIES_BY_COUNTRY_SUCCESSFUL:
      return {
        ...state,
        loadingCities: false,
        errorMessage: null,
        totalCitiesByCountry: action.payload,
      };
    case GET_CITIES_BY_COUNTRY_FAILURE:
      return {
        ...state,
        loadingCities: false,
        errorMessage: action.message,
      };
    case GET_CITIES_SELLER_SHOPS_SENT:
      return {
        ...state,
        loadingCities: true,
        errorMessage: null,
      };
    case GET_CITIES_SELLER_SHOPS_SUCCESSFUL:
      return {
        ...state,
        loadingCities: false,
        errorMessage: null,
        sellerShopsCities: action.payload,
      };
    case GET_CITIES_SELLER_SHOPS_FAILURE:
      return {
        ...state,
        loadingCities: false,
        errorMessage: action.message,
      };
    case GET_CITIES_COURIER_SENT:
      return {
        ...state,
        loadingCities: true,
        errorMessage: null,
      };
    case GET_CITIES_COURIER_SUCCESSFUL:
      return {
        ...state,
        loadingCities: false,
        errorMessage: null,
        courierCities: action.payload,
      };
    case GET_CITIES_COURIER_FAILURE:
      return {
        ...state,
        loadingCities: false,
        errorMessage: action.message,
      };
    case GET_SELLER_SHOPS_REQUEST_SENT:
      return {
        ...state,
        loadingSellerShops: true,
        errorMessage: null,
      };
    case GET_SELLER_SHOPS_SUCCESSFULL:
      return {
        ...state,
        loadingSellerShops: false,
        errorMessage: null,
        sellerShops: action.payload,
      };
    case GET_SELLER_SHOPS_FAILURE:
      return {
        ...state,
        loadingSellerShops: false,
        errorMessage: action.message,
      };
    case GET_SELLER_COURIER_REQUEST_SENT:
      return {
        ...state,
        loadingSellerCourier: true,
        errorMessage: null,
      };
    case GET_SELLER_COURIER_REQUEST_FINISH:
      return {
        ...state,
        loadingSellerCourier: false
      };
    case GET_SELLER_COURIER_SUCCESSFULL:
      return {
        ...state,
        loadingSellerCourier: false,
        errorMessage: null,
        sellerCourierList: action.payload,
      };
    case GET_SELLER_COURIER_FAILURE:
      return {
        ...state,
        loadingSellerCourier: false,
        errorMessage: action.message,
      };
    case GET_SELLER_LOCAL_REQUEST_SENT:
      return {
        ...state,
        loadingLocalDelivery: true,
        errorMessage: null,
      };
    case GET_SELLER_LOCAL_SUCCESSFULL:
      return {
        ...state,
        loadingLocalDelivery: false,
        errorMessage: null,
        sellerLocalDeliveries: action.payload,
      };
    case GET_SELLER_LOCAL_FAILURE:
      return {
        ...state,
        loadingLocalDelivery: false,
        errorMessage: action.message,
      };
    case GET_TOTAL_LOCAL_REQUEST_SENT:
      return {
        ...state,
        loadingLocalDelivery: true,
        errorMessage: null,
      };
    case GET_TOTAL_LOCAL_SUCCESSFULL:
      return {
        ...state,
        loadingLocalDelivery: false,
        errorMessage: null,
        totalLocalDelivery: action.payload,
      };
    case GET_TOTAL_LOCAL_FAILURE:
      return {
        ...state,
        loadingLocalDelivery: false,
        errorMessage: action.message,
      };
    case GET_LOCAL_WAREHOUSES_REQUEST_SENT:
      return {
        ...state,
        loadingLocalWarehouses: true,
        errorMessage: null,
      };
    case GET_LOCAL_WAREHOUSES_SUCCESSFULL:
      return {
        ...state,
        loadingLocalWarehouses: false,
        errorMessage: null,
        totalDeliveryWarehouses: action.payload,
      };
    case GET_LOCAL_WAREHOUSES_FAILURE:
      return {
        ...state,
        loadingLocalWarehouses: false,
        errorMessage: action.message,
      };
    case GET_LIQPAY_FAILURE:
      return {
        ...state,
        loadingLiqPayData: false,
        errorMessage: action.message,
      };
    case GET_LIQPAY_SUCCESSFULL:
      return {
        ...state,
        loadingLiqPayData: false,
        errorMessage: null,
        totalLiqPayData: action.payload,
      };
    case GET_LIQPAY_REQUEST_SENT:
      return {
        ...state,
        loadingLiqPayData: true,
        errorMessage: null,
      };
    case GET_COUNTRIES_SELLER_SHOPS_SENT:
      return {
        ...state,
        loadingCountries: true,
        errorMessage: null,
      };
    case GET_COUNTRIES_SELLER_SHOPS_SUCCESSFUL:
      return {
        ...state,
        loadingCountries: false,
        errorMessage: null,
        sellerShopsCountries: action.payload,
      };
    case GET_COUNTRIES_SELLER_SHOPS_FAILURE:
      return {
        ...state,
        loadingCountries: false,
        errorMessage: action.message,
      };
    case GET_COUNTRIES_COURIER_SENT:
      return {
        ...state,
        loadingCountries: true,
        errorMessage: null,
      };
    case GET_COUNTRIES_COURIER_SUCCESSFUL:
      return {
        ...state,
        loadingCountries: false,
        errorMessage: null,
        courierCountries: action.payload,
      };
    case GET_COUNTRIES_COURIER_FAILURE:
      return {
        ...state,
        loadingCountries: false,
        errorMessage: action.message,
      };
    case GET_LOCAL_COUNTRIES_SENT:
      return {
        ...state,
        loadingCountries: true,
        errorMessage: null,
      };
    case GET_LOCAL_COUNTRIES_SUCCESSFUL:
      return {
        ...state,
        loadingCountries: false,
        errorMessage: null,
        localDeliveryCountries: action.payload,
      };
    case GET_LOCAL_COUNTRIES_FAILURE:
      return {
        ...state,
        loadingCountries: false,
        errorMessage: action.message,
      };
    case GET_HAS_USER_BUYER_DELIVERIES_REQUEST_SENT:
      return {
        ...state,
        loading: true,
        errorMessage: null,
      };
    case GET_HAS_USER_BUYER_DELIVERIES_SUCCESSFUL:
      return {
        ...state,
        loading: false,
        errorMessage: null,
        hasUserBuyerDeliveries: action.payload,
      };
    case GET_HAS_USER_BUYER_DELIVERIES_FAILURE:
      return {
        ...state,
        loading: false,
        errorMessage: action.message,
      };

    case DISPATCH_NULL_ROUTE:
      return {
        ...state,
        from: null,
      };
    default:
      return state;
  }
};

export default cartReducer;
