import "../../Components/PayoutScreen/PayoutScreen.scss";
import "./PaymentsPageStyle.scss";

import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";

import { Layout } from "../../Components/Layout/Layout";
import PayoutScreen from "../../Components/PayoutScreen/index";
import { getSellerPayout, postSellerPayout } from "../../Redux/Payouts/actions";
import axios from "axios";
import { BASEURL } from "../../Configs/constants";
import { getAccessToken2 } from "../../Helpers/auth";

const PaymentsPage = ({ userId, getSellerPayout, postSellerPayout, totalPayoutData }) => {
  const history = useHistory();

  const [initialState, setInitialState] = useState({
    cardNumber: "#### #### #### ####",
    cardHolder: "FULL NAME",
    cardMonth: "",
    cardYear: "",
    cardCvv: "",
    isCardFlipped: false,
  });

  const handleFinishClick = useCallback(() => {
    postSellerPayout({
      sellerUserId: userId,
      cardNumber: initialState.cardNumber,
      cardName: initialState.cardHolder,
    });
    return history.replace({ pathname: "/" });
  });

  useEffect(() => {
    getSellerPayout(userId);
  }, []);

  useEffect(() => {
    if (!totalPayoutData) return;

    setInitialState({
      cardNumber: totalPayoutData.cardNumber,
      cardHolder: totalPayoutData.cardName,
      cardMonth: "",
      cardYear: "",
      cardCvv: "",
      isCardFlipped: false,
    });
  }, [totalPayoutData]);


  useEffect(() => {
    (async () => {
      const response = await axios.get(
        `${BASEURL}/api/sellsgram/seller/payout/check?sellerUserID=${userId}`,
        {
          headers: {
            Authorization: getAccessToken2(),
          }
        }
      );
      console.log('check cards response:', (response));
      setIsHasSellerPayouts(response?.data?.status);
    })();
  }, []);


  const [isHasSellerPayouts, setIsHasSellerPayouts] = useState(false);


  // const isHasSellerPayouts = history?.location?.state?.isHasSellerPayouts;
  // console.log('isHasSellerPayouts', isHasSellerPayouts);

  return (
    <Layout
      saveBtnText="update"
      onSaveButtonClick={handleFinishClick}
      titleText={isHasSellerPayouts ? "myCards" : "myCardsStart"}
    >
      {totalPayoutData && (
        <PayoutScreen state={initialState} setState={setInitialState} />
      )}
    </Layout>
  );
};

PaymentsPage.propTypes = {};

const mapStateToProps = (state) => ({
  userId: state.auth.userId,
  totalPayoutData: state.payout.totalPayoutData,
});

export default connect(mapStateToProps, {
  getSellerPayout,
  postSellerPayout,
})(PaymentsPage);
