import React from "react";
import { useTranslation } from "react-i18next";
import Linkify from "react-linkify";

const TermsOfUse = () => {
  const { t } = useTranslation();

  return (
    <div className="help__content">
      <h3 className="mb-10">{t("termsOfUse-text-1")}</h3>
      <p>
        <Linkify>{t("termsOfUse-text-2")}</Linkify>
      </p>
      <h3 className="mb-20">{t("termsOfUse-text-3")}</h3>
      <p>
        <Linkify>{t("termsOfUse-text-4")}</Linkify>
      </p>
      <p>
        <Linkify>{t("termsOfUse-text-5")}</Linkify>
      </p>
      <p>
        <Linkify>{t("termsOfUse-text-6")}</Linkify>
      </p>
      <p>
        <Linkify>{t("termsOfUse-text-7")}</Linkify>
      </p>
      <p>
        <Linkify>{t("termsOfUse-text-8")}</Linkify>
      </p>
      <p>
        <Linkify>{t("termsOfUse-text-9")}</Linkify>
      </p>
      <h3 className="mb-20">{t("termsOfUse-text-10")}</h3>
      <p>
        <Linkify>{t("termsOfUse-text-11")}</Linkify>
      </p>
      <p>
        <Linkify>{t("termsOfUse-text-12")}</Linkify>
      </p>
      <h3 className="mb-20">{t("termsOfUse-text-13")}</h3>
      <p>
        <Linkify>{t("termsOfUse-text-14")}</Linkify>
      </p>
      <p>
        <Linkify>{t("termsOfUse-text-15")}</Linkify>
      </p>
      <p>
        <Linkify>{t("termsOfUse-text-16")}</Linkify>
      </p>
      <p>
        <Linkify>{t("termsOfUse-text-17")}</Linkify>
      </p>
      <p>
        <Linkify>{t("termsOfUse-text-18")}</Linkify>
      </p>
      <p>
        <Linkify>{t("termsOfUse-text-19")}</Linkify>
      </p>
      <p>
        <Linkify>{t("termsOfUse-text-20")}</Linkify>
      </p>
      <p>
        <Linkify>{t("termsOfUse-text-21")}</Linkify>
      </p>
      <p>
        <Linkify>{t("termsOfUse-text-22")}</Linkify>
      </p>
      <p>
        <Linkify>{t("termsOfUse-text-23")}</Linkify>
      </p>
      <p>
        <Linkify>{t("termsOfUse-text-24")}</Linkify>
      </p>
      <p>
        <Linkify>{t("termsOfUse-text-25")}</Linkify>
      </p>
      <p>
        <Linkify>{t("termsOfUse-text-26")}</Linkify>
      </p>
      <p>
        <Linkify>{t("termsOfUse-text-27")}</Linkify>
      </p>
      <h3 className="mb-20">{t("termsOfUse-text-28")}</h3>
      <p>
        <Linkify>{t("termsOfUse-text-29")}</Linkify>
      </p>
      <p>
        <Linkify>{t("termsOfUse-text-30")}</Linkify>
      </p>
      <p>
        <Linkify>{t("termsOfUse-text-31")}</Linkify>
      </p>
      <p>
        <Linkify>{t("termsOfUse-text-32")}</Linkify>
      </p>
      <p>
        <Linkify>{t("termsOfUse-text-33")}</Linkify>
      </p>
      <p>
        <Linkify>{t("termsOfUse-text-34")}</Linkify>
      </p>
      <p>
        <Linkify>{t("termsOfUse-text-35")}</Linkify>
      </p>
      <p>
        <Linkify>{t("termsOfUse-text-36")}</Linkify>
      </p>
      <p>
        <Linkify>{t("termsOfUse-text-37")}</Linkify>
      </p>
      <h3 className="mb-20">{t("termsOfUse-text-38")}</h3>
      <p>
        <Linkify>{t("termsOfUse-text-39")}</Linkify>
      </p>
      <p>
        <Linkify>{t("termsOfUse-text-40")}</Linkify>
      </p>
      <p>
        <Linkify>{t("termsOfUse-text-41")}</Linkify>
      </p>
      <p>
        <Linkify>{t("termsOfUse-text-42")}</Linkify>
      </p>
      <p>
        <Linkify>{t("termsOfUse-text-43")}</Linkify>
      </p>
      <p>
        <Linkify>{t("termsOfUse-text-44")}</Linkify>
      </p>
      <p>
        <Linkify>{t("termsOfUse-text-45")}</Linkify>
      </p>
      <p>
        <Linkify>{t("termsOfUse-text-46")}</Linkify>
      </p>
      <h3 className="mb-20">{t("termsOfUse-text-47")}</h3>
      <p>
        <Linkify>{t("termsOfUse-text-48")}</Linkify>
      </p>
      <p>
        <Linkify>{t("termsOfUse-text-49")}</Linkify>
      </p>
      <p>
        <Linkify>{t("termsOfUse-text-50")}</Linkify>
      </p>
      <p>
        <Linkify>{t("termsOfUse-text-51")}</Linkify>
      </p>
      <p>
        <Linkify>{t("termsOfUse-text-52")}</Linkify>
      </p>
      <p>
        <Linkify>{t("termsOfUse-text-53")}</Linkify>
      </p>
      <p>
        <Linkify>{t("termsOfUse-text-54")}</Linkify>
      </p>
      <p>
        <Linkify>{t("termsOfUse-text-55")}</Linkify>
      </p>
      <h3 className="mb-20">{t("termsOfUse-text-56")}</h3>
      <p>
        <Linkify>{t("termsOfUse-text-57")}</Linkify>
      </p>
      <p>
        <Linkify>{t("termsOfUse-text-58")}</Linkify>
      </p>
      <p>
        <Linkify>{t("termsOfUse-text-59")}</Linkify>
      </p>
      <p>
        <Linkify>{t("termsOfUse-text-60")}</Linkify>
      </p>
      <h3 className="mb-20">{t("termsOfUse-text-61")}</h3>
      <p>
        <Linkify>{t("termsOfUse-text-62")}</Linkify>
      </p>
      <p>
        <Linkify>{t("termsOfUse-text-63")}</Linkify>
      </p>
      <p>
        <Linkify>{t("termsOfUse-text-64")}</Linkify>
      </p>
      <p>
        <Linkify>{t("termsOfUse-text-65")}</Linkify>
      </p>
      <p>
        <Linkify>{t("termsOfUse-text-66")}</Linkify>
      </p>
      <p>
        <Linkify>{t("termsOfUse-text-67")}</Linkify>
      </p>
      <p>
        <Linkify>{t("termsOfUse-text-68")}</Linkify>
      </p>
      <h3 className="mb-20">{t("termsOfUse-text-69")}</h3>
      <p>
        <Linkify>{t("termsOfUse-text-70")}</Linkify>
      </p>
      <p>
        <Linkify>{t("termsOfUse-text-71")}</Linkify>
      </p>
      <h3 className="mb-20">{t("termsOfUse-text-72")}</h3>
      <p>
        <Linkify>{t("termsOfUse-text-73")}</Linkify>
      </p>
      <p>
        <Linkify>{t("termsOfUse-text-74")}</Linkify>
      </p>
      <p>
        <Linkify>{t("termsOfUse-text-75")}</Linkify>
      </p>
      <h3 className="mb-20 mt-20 text-align-left">{t("termsOfUse-text-76")}</h3>
      <h4 className="mb-10">{t("termsOfUse-text-77")}</h4>
      <h4 className="mb-10">{t("termsOfUse-text-78")}</h4>
      <p className="no-offset-p">
        <Linkify>{t("termsOfUse-text-79")}</Linkify>
      </p>
      <p className="no-offset-p">
        <Linkify>{t("termsOfUse-text-80")}</Linkify>
      </p>
    </div>
  );
};

export default TermsOfUse;
