import "./AddItemStyle.css";
import "react-activity/dist/Sentry.css";

import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

import { Layout } from "../../Components/Layout/Layout";
import AddOptionsModal from "../../Components/Modals/AddOptionsModal";
import ColorTable from "../../Components/ProductProperties/PropertyTables/ColorTable";
import MaterialTable from "../../Components/ProductProperties/PropertyTables/MaterialTable";
import OtherTable from "../../Components/ProductProperties/PropertyTables/OtherTable";
import SizeTable from "../../Components/ProductProperties/PropertyTables/SizeTable";
import { DraftLiveSwitcher } from "../../Components/DraftLiveSwitcher/DraftLiveSwitcher";
import { RequestHandler } from "../../Components/RequestHandler/RequestHandler";
import {
  addProductAdditionalOptions,
  addProductColor,
  addProductImages,
  addProductMaterial,
  addProductOther,
  addProductSize,
  addProductURLImages,
  createProduct,
  deleteAdditionalOptions,
  getAdditionalOptionsByProduct,
  getProductStaticColors,
  getProductStaticMaterials,
  getProductStaticSizes,
} from "../../Redux/Products/actions";
import { BASEURL } from "../../Configs/constants";
import axios from "axios";
import { getAccessToken2 } from "../../Helpers/auth";
import AdditionalPropertiesTable from "../../Components/ProductProperties/PropertyTables/AdditionalPropertiesTable";
import { isEmpty } from "lodash";

const AddItem = (props) => {
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const {
    getProductStaticColors,
    getProductStaticMaterials,
    getProductStaticSizes,
    addProductColor,
    addProductMaterial,
    getAdditionalOptionsByProduct,
    addProductAdditionalOptions,
    addProductSize,
    addProductOther,
    createProduct,
    userId,
    addProductImages,
    addProductURLImages,
    loading,
  } = props;
  const currProduct = props.location?.state?.product || {};
  const products = props.location?.state?.instaImgs || [];
  const productsImageIds = props.location?.state?.instaIds || [];

  const [productAdditionalOptions, setProductAdditionalOptions] = useState([]);
  const [isProductLive, setIsProductLive] = useState(true);

  const [productName, setProductName] = useState(
    currProduct?.shortName ? currProduct?.shortName : "",
  );
  const [productPrice, setProductPrice] = useState(
    currProduct?.price ? currProduct?.price : "",
  );
  const [currencyType, setCurrencyType] = useState(1);
  // currProduct.currencyId
  //   ? currProduct.currencyId
  //   : i18n.language === "en"
  //     ? 217
  //     : 1

  const [productDescripton, setProductDescription] = useState(
    currProduct?.description || "",
  );
  const [productNumber, setProductNumber] = useState(1);
  const [additionalImages, setAdditionalImages] = useState([]);
  const [quantity, setQuantity] = useState();
  const [errors, setErrors] = useState({
    productName: false,
    quantity: false,
    productPrice: null,
    productDescripton: false,
  });

  const [isHasSellerDeliveryMethods, setIsHasSellerDeliveryMethods] = useState(false);
  const [isHasSellerPayouts, setIsHasSellerPayouts] = useState(false);
  useEffect(() => {
    if (!isEmpty(productsImageIds)) {
      setProductDescription(
        productsImageIds[productNumber - 1].caption || productDescripton,
      );
      if (productsImageIds[productNumber - 1].media_type == "CAROUSEL_ALBUM")
        (async () => {
          const response = await axios.get(
            `${BASEURL}/api/instagram/data/media/children/${
              productsImageIds[productNumber - 1].id
            }?userId=${userId}`,
            {
              headers: {
                Authorization: getAccessToken2(),
              },
            },
          );
          if (response && response.data && response.data.length > 0) {
            setAdditionalImages(response.data);
          }
        })();
    }
  }, [productNumber]);

  useEffect(() => {
    (async () => {
      if (isEmpty(productsImageIds) && isEmpty(products)) {
        return history.push("/");
      } else {
        const requestDeliveryCheck = axios.get(
          `${BASEURL}/api/sellsgram/seller/delivery/local/check?sellerUserID=${userId}`,
          {
            headers: {
              Authorization: getAccessToken2(),
            },
          },
        );
        const requestPayoutCheck = axios.get(
          `${BASEURL}/api/sellsgram/seller/payout/check?sellerUserID=${userId}`,
          {
            headers: {
              Authorization: getAccessToken2(),
            },
          },
        );

        const [responseDeliveryCheck, responsePayoutCheck] = await Promise.all([
          requestDeliveryCheck,
          requestPayoutCheck,
        ]);

        if (responseDeliveryCheck?.data?.status === true) {
          setIsHasSellerDeliveryMethods(true);
        } else {
          setIsHasSellerDeliveryMethods(false);
        }

        if (responsePayoutCheck?.data?.status === true) {
          setIsHasSellerPayouts(true);
        } else {
          setIsHasSellerPayouts(false);
        }
      }
    })();
  }, []);

  const re = /^[0-9]+$/;
  const priceRe = /^\d+(?:\.\d{0,3})?$/;
  const onSetQuantity = (e) => {
    if (e.target.value === "" || re.test(e.target.value)) {
      setQuantity(e.target.value.replace(/[^0-9.]/g, "").replace(/(\..*?)\..*/g, "$1"));
    }
  };
  const onSetProductPrice = (e) => {
    if (e.target.value === "" || priceRe.test(e.target.value)) {
      setProductPrice(String(e.target.value));
    }
  };
  const onImageChange = (e) => {
    const [file] = e.target.files;
    if (!file.name.match(/\.(jpg|jpeg|png|gif)$/)) {
      toast.error("Please select valid image!");
    } else {
      setAdditionalImages([
        ...additionalImages,
        { file, preview: URL.createObjectURL(file) },
      ]);
    }
  };
  const handleDelete = (img) =>
    setAdditionalImages(additionalImages.filter((e) => e !== img));

  const handleCreateProduct = () => {
    const maxQuantity = 1000000;
    const maxPrice = 100000;
    let price =
      productAdditionalOptions && productAdditionalOptions.length > 0
        ? productAdditionalOptions.reduce((previous, current) => {
            return current.price < previous.price ? current : previous;
          }).price
        : productPrice;
    const priceForValidation = parseInt(String(price).replaceAll(".", ""));
    let newQuantity =
      productAdditionalOptions && productAdditionalOptions.length > 0
        ? productAdditionalOptions.reduce((a, v) => (a = a + Number(v.quantity)), 0)
        : quantity;
    let newDate = new Date();
    let payload = {
      // productId: currProduct?.productId,
      userId: parseInt(userId),
      shortName: productName?.trim(),
      price: price,
      currencyId: currencyType,
      description: productDescripton ? productDescripton?.trim() : "Empty string",
      instaImageLink: products[productNumber - 1]
        ? products[productNumber - 1]
        : "Empty string",
      instaPostId: currProduct?.instaPostId ? currProduct?.instaPostId : "Empty string",
      instaPostTimestamp: currProduct?.instaPostTimestamp
        ? currProduct?.instaPostTimestamp
        : newDate,
      createdDate: currProduct?.createdDate ? currProduct.createdDate : newDate,
      quantity: parseInt(newQuantity),
      status: isProductLive ? 0 : 1,
    };
    let localErrors = {
      productName: false,
      quantity: false,
      productPrice: null,
      productDescripton: false,
    };
    if (!productName) localErrors.productName = true;
    if (!newQuantity || newQuantity < 1) {
      localErrors.quantity = t("productQuantityRequired");
    } else if (newQuantity > maxQuantity) {
      localErrors.quantity = t("productQuantityMax", { maxQuantity });
    }
    if (!priceForValidation || priceForValidation <= 0) {
      localErrors.productPrice = t("productPriceRequired");
    } else if (priceForValidation > maxPrice) {
      localErrors.productPrice = t("productPriceMax", { maxPrice });
    }
    if (!productDescripton) localErrors.productDescripton = true;
    setErrors(localErrors);
    if (Object.values(localErrors).some((v) => v)) return;

    createProduct(payload).then((res) => {
      const productId = res.productId;
      Promise.all([
        additionalImages.map((image) => {
          if (
            image.preview.includes("localhost") ||
            image.preview.includes("sellsgram")
          ) {
            const requiredFormat = new FormData();
            requiredFormat.append("files", image.file);
            return addProductImages({ image: requiredFormat, productId });
          } else {
            return addProductURLImages({ productId, imageURL: image.preview });
          }
        }),
        addProductAdditionalOptions(
          productAdditionalOptions,
          getAdditionalOptionsByProduct,
          productId,
        ),
        // productColors.map((pc) => addProductColor({ ...pc, productId })),
        // productMaterials.map((pm) => addProductMaterial({ ...pm, productId })),
        // productSizes.map((ps) => addProductSize({ ...ps, productId })),
        // productOthers.map((po) => addProductOther({ ...po, productId })),
      ]).then(() => {
        if (productNumber === products.length) {
          if (!isHasSellerDeliveryMethods) {
            return history.push(
              { pathname: "/delivery-page/" },
              { isHasSellerDeliveryMethods, isHasSellerPayouts },
            );
          } else if (!isHasSellerPayouts) {
            return history.push(
              { pathname: "/payouts/" },
              { isHasSellerDeliveryMethods, isHasSellerPayouts },
            );
          } else {
            return history.replace({ pathname: "/" });
          }
        }

        setProductName("");
        setIsProductLive(true);
        setProductPrice("");
        setCurrencyType(1);
        setProductDescription(
          productsImageIds.length > productNumber
            ? productsImageIds[productNumber].caption
            : "",
        );
        setAdditionalImages([]);
        setProductNumber(productNumber + 1);
      });
    });
  };

  return (
    <RequestHandler isLoading={loading}>
      <Layout
        titleText="addProductInfo"
        onSaveButtonClick={handleCreateProduct}
        saveBtnText={
          productNumber === products.length
            ? undefined
            : `${t("next")} - ${productNumber} ${t("outOf")} ${products.length}`
        }
      >
        <div className="mainInfoContainer">
          <div className="flex-one-no-btn mr-lg-4 mr-0 imgs">
            <div className="product-detail-imgs">
              <div className="product-detail-imgs-Main">
                <img
                  src={
                    products[productNumber - 1]
                      ? products[productNumber - 1]
                      : "/assets/images/detail-img.png"
                  }
                  alt="img"
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null;
                    currentTarget.src = "/assets/images/product-1.png";
                  }}
                />
              </div>
              {additionalImages.length < 2 ? (
                <div className="mainInfoItemsWrapper">
                  <form className="addImg">
                    {additionalImages.length < 2 && (
                      <div className="custom-file">
                        <input
                          type="file"
                          className="custom-file-input"
                          id="customFile"
                          accept="image/*"
                          onChange={onImageChange}
                        />
                        <label className="custom-file-label" htmlFor="customFile">
                          <img src="/assets/images/file-upload.svg" alt="file upload" />
                        </label>
                      </div>
                    )}
                  </form>
                </div>
              ) : null}
              <div className="mainInfoItemsWrapper">
                <div className="product-detail-thumbnail">
                  {additionalImages[0] && (
                    <>
                      <img
                        src={additionalImages[0]?.preview}
                        alt="img"
                        className="product-detail-thumbnail-img"
                      />
                      <button
                        type="button"
                        className="insta-icon delete-img-btn"
                        onClick={() => handleDelete(additionalImages[0])}
                      >
                        <img src="/assets/images/icon-delete-black.svg" alt="bin" />
                      </button>
                    </>
                  )}
                </div>
              </div>
              {additionalImages[1] && (
                <div className="mainInfoItemsWrapper">
                  <div className="product-detail-thumbnail">
                    <>
                      <img
                        src={additionalImages[1]?.preview}
                        alt="img"
                        className="product-detail-thumbnail-img"
                      />
                      <button
                        type="button"
                        className="insta-icon delete-img-btn"
                        onClick={() => handleDelete(additionalImages[1])}
                      >
                        <img src="/assets/images/icon-delete-black.svg" alt="bin" />
                      </button>
                    </>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="flex-one mr-lg-4 mr-0">
            <DraftLiveSwitcher
              onChange={setIsProductLive}
              isChecked={isProductLive}
              translate={t}
            />
            <div className="add-product-edit">
              <div className="add-product-field">
                <label htmlFor="">{t("shortName")}</label>
                <input
                  type="text"
                  value={productName}
                  maxLength={50}
                  name="name"
                  className={`form-control ${errors.productName ? "error" : ""}`}
                  placeholder="Red Flora Flower"
                  onChange={(e) => setProductName(e.target.value)}
                />
              </div>
              <div className="add-product-field">
                <label htmlFor="">{t("price")}</label>
                <div className="price-field input-group">
                  <div className="price-field input-group">
                    {productAdditionalOptions && productAdditionalOptions.length > 0 ? (
                      <input
                        type="text"
                        className={`form-control ${errors.productPrice ? "error" : ""}`}
                        aria-label="Text input with dropdown button"
                        value={
                          productAdditionalOptions && productAdditionalOptions.length > 0
                            ? productAdditionalOptions.reduce((previous, current) => {
                                return current.price < previous.price
                                  ? current
                                  : previous;
                              }).price
                            : parseInt(productPrice)
                        }
                        placeholder="100"
                        onChange={(e) => onSetProductPrice(e)}
                        readOnly
                        disabled={true}
                      />
                    ) : (
                      <input
                        type="text"
                        className={`form-control ${errors.productPrice ? "error" : ""}`}
                        aria-label="Text input with dropdown button"
                        value={productPrice}
                        placeholder="100"
                        onChange={(e) => onSetProductPrice(e)}
                      />
                    )}
                    <div className="input-group-append position-relative">
                      <button
                        className="btn btn-dropdown dropdown-toggle"
                        type="button"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        {currencyType === 217 ? "USD" : "UAH"}
                      </button>
                      <div className="dropdown-menu">
                        <a className="dropdown-item" onClick={() => setCurrencyType(1)}>
                          UAH
                        </a>
                      </div>
                    </div>
                    {errors.productPrice && (
                      <div className="invalid-feedback d-block">
                        {errors.productPrice}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="add-product-field add-quantity">
                <label htmlFor="">{t("totalQuantity")}</label>
                {productAdditionalOptions && productAdditionalOptions.length > 0 ? (
                  <input
                    value={productAdditionalOptions.reduce(
                      (a, v) => (a = a + Number(v.quantity)),
                      0,
                    )}
                    onChange={(e) => onSetQuantity(e)}
                    type="text"
                    name="number"
                    className={`form-control ${errors.quantity ? "error" : ""}`}
                    placeholder="100"
                    readOnly
                    disabled={true}
                  />
                ) : (
                  <input
                    value={quantity}
                    onChange={(e) => onSetQuantity(e)}
                    type="text"
                    name="number"
                    className={`form-control ${errors.quantity ? "error" : ""}`}
                    placeholder="100"
                  />
                )}
                {errors.quantity && (
                  <div className="invalid-feedback d-block">{errors.quantity}</div>
                )}
              </div>
              <div className="add-product-field">
                <label htmlFor="">{t("description")}</label>
                <textarea
                  value={productDescripton}
                  onChange={(e) => setProductDescription(e.target.value)}
                  name=""
                  maxLength={500}
                  className={`form-control ${errors.productDescripton ? "error" : ""}`}
                  placeholder={t("descriptionPlaceholder")}
                ></textarea>
                <p>
                  {t("symbolsUsed", {
                    number: productDescripton?.length,
                    maxNumber: 500,
                  })}
                </p>
              </div>
            </div>
            <div className="flex-one mainInfoItem">
              <div className="add-product-edit">
                <AdditionalPropertiesTable
                  // productId={currProduct?.productId}
                  setProductAdditionalOptions={setProductAdditionalOptions}
                  productAdditionalOptions={productAdditionalOptions}
                />

                {/* <ColorTable
                setProductColors={setProductColors}
                productColors={productColors}
              />
              <MaterialTable
                setProductMaterials={setProductMaterials}
                productMaterials={productMaterials}
              />
              <SizeTable setProductSizes={setProductSizes} productSizes={productSizes} />
              <OtherTable
                setProductOthers={setProductOthers}
                productOthers={productOthers}
              /> */}
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </RequestHandler>
  );
};

AddItem.propTypes = {};

const mapStateToProps = (state) => ({
  allColors: state.products.allColors,
  allMaterials: state.products.allMaterials,
  allSizes: state.products.allSizes,
  userId: state.auth.userId,
});

export default connect(mapStateToProps, {
  getProductStaticColors,
  addProductAdditionalOptions,
  getAdditionalOptionsByProduct,
  deleteAdditionalOptions,
  getProductStaticMaterials,
  getProductStaticSizes,
  addProductColor,
  addProductMaterial,
  addProductSize,
  addProductOther,
  createProduct,
  addProductImages,
  addProductURLImages,
})(AddItem);
