import React, { useEffect, useState } from "react";
import Sentry from "react-activity/dist/Sentry";
import { Button, Dropdown, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";

import {
  deleteDeliveryLocal,
  getCitiesbyCountry,
  getLocalDeliveriesByCountry,
  getSellerLocalDeliveries,
  postSellerLocalDelivery,
} from "../../Redux/Cart/actions";
import CustomMenu from "./utils/CustomMenu";
import { isEmpty } from "lodash";

const DeliveryLocal = ({
  localDeliveries,
  totalLocalDelivery,
  loadingLocalDelivery,
  countryId,
  deliveryType,
  sellerCourierList,
  postSellerLocalDelivery,
  getLocalDeliveriesByCountry,
  userId,
  deleteDeliveryLocal,
  getSellerLocalDeliveries,
}) => {
  const { t } = useTranslation();
  const [deliveries, setDeliveries] = useState(localDeliveries);

  useEffect(() => {
    if (countryId) {
      getLocalDeliveriesByCountry(countryId);
    }
  }, [countryId]);

  useEffect(() => {
    setDeliveries(localDeliveries);
  }, [localDeliveries]);

  const newDeliveryLocation = async () => {
    localDeliveries
      ?.filter(
        (d) =>
          !deliveries?.find(
            ({ localDeliveryId }) => localDeliveryId === d.localDeliveryId,
          ),
      )
      .forEach(({ localDeliveryId, countryId }) => {
        deleteDeliveryLocal(localDeliveryId, userId, countryId, getSellerLocalDeliveries);
      });

    postSellerLocalDelivery(
      deliveries
        ?.filter((d) => !localDeliveries?.includes(d))
        .map(({ localDeliveryId }) => ({
          localDeliveryId,
          countryId,
          sellerUserId: userId,
        })),
      getSellerLocalDeliveries,
    );
  };

  const addLocalDelivery = (delivery) => {
    delivery = JSON.parse(delivery);
    if (
      deliveries?.filter((d) => d.localDeliveryId === delivery.localDeliveryId).length ===
      0
    )
      setDeliveries((prev) => [...prev, delivery]);
  };

  const deleteLocalDelivery = (dId) => {
    setDeliveries((prev) => {
      const cur = prev.filter(({ localDeliveryId }) => dId !== localDeliveryId);

      return cur;
    });
  };

  return (
    <div className="mb-2">
      <div className="d-flex flex-md-row flex-column">
        <div className="flex-one">
          <Dropdown onSelect={addLocalDelivery}>
            <Dropdown.Toggle
              as={Button}
              variant="secondary"
              className="align-items-center"
            >
              {t("selectDeliveries")}
            </Dropdown.Toggle>

            <Dropdown.Menu as={CustomMenu} disabled={loadingLocalDelivery}>
              {loadingLocalDelivery ? (
                <Dropdown.Item>
                  <Sentry color="#4B85DC" size={100} speed={0.5} animating={true} />
                </Dropdown.Item>
              ) : (
                totalLocalDelivery?.map((delivery) => (
                  <Dropdown.Item
                    eventKey={JSON.stringify(delivery)}
                    key={delivery.localDeliveryId}
                  >
                    {delivery.name}
                  </Dropdown.Item>
                ))
              )}
            </Dropdown.Menu>
          </Dropdown>
        </div>

        <div className="flex-one">
          <Button
            variant="none"
            onClick={newDeliveryLocation}
            // disabled={
            //   deliveries?.filter((d) => localDeliveries?.includes(d)).length ===
            //     deliveries?.length &&
            //   localDeliveries?.filter((d) => deliveries?.includes(d)).length ===
            //     localDeliveries?.length
            // }
          >
            {t("update")}
          </Button>
        </div>
      </div>
      <div className="d-flex flex-md-row flex-column row">
        {deliveries?.map((d) => (
          <div className="flex-one" key={d.localDeliveryId}>
            <Button
              className="btn btn-add-other d-flex align-items-center w-100 justify-content-center"
              variant="none"
              id="truncateLongTexts"
            >
              {d.name}
              <img
                src="/assets/images/bin.png"
                alt=""
                style={{ cursor: "pointer", width: "1.2em" }}
                onClick={() => deleteLocalDelivery(d.localDeliveryId)}
              />
            </Button>
          </div>
        ))}
      </div>
    </div>
  );
};

DeliveryLocal.propTypes = {};

const mapStateToProps = (state) => ({
  totalLocalDelivery: state.cart.totalLocalDelivery,
  loadingLocalDelivery: state.cart.loadingLocalDelivery,
  userId: state.auth.userId,
  sellerCourierList: state.cart.sellerCourierList,
});

export default connect(mapStateToProps, {
  getCitiesbyCountry,
  getLocalDeliveriesByCountry,
  postSellerLocalDelivery,
  deleteDeliveryLocal,
  getSellerLocalDeliveries,
})(DeliveryLocal);
