import React, { useCallback, useState } from "react";
import { connect } from "react-redux";

import { addProductSize, editProductSize } from "../../../Redux/Products/actions";

const SizeForm = ({
  addProductSize,
  productId,
  getSizesByProduct,
  setToggleTableContent,
  setProductSizes,
  editProductSize,
  size = undefined,
  updatable = undefined,
}) => {
  const [sizeName, setSizeName] = useState(size ? size.sizeName : "");
  const [sizePrice, setSizePrice] = useState(size ? size.sizePrice : "");
  const [sizeQuantity, setSizeQuantity] = useState(size ? size.sizeQuantity : "");

  const submitAdd = useCallback(() => {
    const payload = { sizeName, sizePrice, sizeQuantity, productId };
    setProductSizes
      ? setProductSizes((prev) => [...prev, payload])
      : addProductSize(payload, getSizesByProduct, productId);
    setToggleTableContent(false);
  }, [sizeName, sizePrice, sizeQuantity]);

  const submitEdit = useCallback(() => {
    const payload = { ...size, sizeName, sizePrice, sizeQuantity };
    console.log(payload);
    setProductSizes
      ? setProductSizes((prev) => prev.map((o, i) => (i === updatable ? payload : o)))
      : editProductSize(size.productSizeId, payload, getSizesByProduct, productId);
    setToggleTableContent(false);
  }, [sizeName, sizePrice, sizeQuantity]);

  return (
    <tr>
      <td>
        <input
          name="name"
          type={"text"}
          value={sizeName}
          onChange={(e) => setSizeName(e.target.value)}
        />
      </td>
      <td>
        <input
          name="price"
          type={"number"}
          value={sizePrice}
          onChange={(e) => setSizePrice(e.target.value)}
        />
      </td>
      <td>
        <input
          name="quantity"
          type={"number"}
          value={sizeQuantity}
          onChange={(e) => setSizeQuantity(e.target.value)}
        />
      </td>
      <td>
        <img
          style={{ cursor: "pointer" }}
          onClick={() => setToggleTableContent(false)}
          src="/assets/images/icon-cross.svg"
          alt=""
        />
      </td>
      <td>
        <img
          style={{ cursor: "pointer", width: "1.2em" }}
          onClick={() => (size ? submitEdit() : submitAdd())}
          src={`/assets/images/${size ? "check-circle.png" : "icon-add-other.svg"}`}
          alt=""
        />
      </td>
    </tr>
  );
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {
  addProductSize,
  editProductSize,
})(SizeForm);
