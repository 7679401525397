import "./style.css";

import React, { useState } from "react";

const ModalSingleProduct = (props) => {
  const {
    count,
    setCount,
    post,
    setSelectedImages,
    selectedImages,
    setSelectedImagesIds,
    selectedImagesIds,
  } = props;
  const [checked, setChecked] = useState(false);

  const handleClick = (post) => {
    setChecked(!checked);
    if (checked) {
      setCount(count - 1);
      setSelectedImages(selectedImages.filter((img) => img !== post.media_url));
      setSelectedImagesIds(selectedImagesIds.filter((id) => id !== post.id));
    } else {
      setCount(count + 1);
      setSelectedImages([...selectedImages, post.media_url]);
      setSelectedImagesIds([...selectedImagesIds, post]);
    }
  };

  return (
    <>
      <div className="modal-single-product" onClick={() => handleClick(post)}>
        <img
          src={post.media_url ? post.media_url : "/assets/images/item1.png"}
          height="105"
          width="105"
          alt=""
        />
        {checked ? (
          <img src="/assets/images/check-circle.png" className="check-circle" />
        ) : null}
      </div>
    </>
  );
};

export default ModalSingleProduct;
