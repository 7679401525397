import React from "react";
import { useTranslation } from "react-i18next";

const About = () => {
  const { t } = useTranslation();
  return (
    <div className="help__content">
      <p className="italic text-align-rigth mb-20">{t("about-text-2")}</p>
      <p dangerouslySetInnerHTML={{ __html: t("about-text-4") }}></p>
      <p dangerouslySetInnerHTML={{ __html: t("about-text-5") }}></p>
      <p dangerouslySetInnerHTML={{ __html: t("about-text-6") }}></p>
      <p dangerouslySetInnerHTML={{ __html: t("about-text-7") }}></p>
      <p dangerouslySetInnerHTML={{ __html: t("about-text-8") }}></p>
      <p dangerouslySetInnerHTML={{ __html: t("about-text-9") }}></p>
      <p dangerouslySetInnerHTML={{ __html: t("about-text-10") }}></p>
      <p dangerouslySetInnerHTML={{ __html: t("about-text-11") }}></p>
      <p dangerouslySetInnerHTML={{ __html: t("about-text-12") }}></p>
      <p dangerouslySetInnerHTML={{ __html: t("about-text-13") }}></p>
      <p dangerouslySetInnerHTML={{ __html: t("about-text-14") }}></p>
      <ul>
        <p dangerouslySetInnerHTML={{ __html: t("about-text-15") }}></p>
        <li dangerouslySetInnerHTML={{ __html: t("about-text-16") }}></li>
        <li dangerouslySetInnerHTML={{ __html: t("about-text-17") }}></li>
        <li dangerouslySetInnerHTML={{ __html: t("about-text-18") }}></li>
        <li dangerouslySetInnerHTML={{ __html: t("about-text-19") }}></li>
      </ul>
      <p dangerouslySetInnerHTML={{ __html: t("about-text-20") }}></p>
      <p dangerouslySetInnerHTML={{ __html: t("about-text-21") }}></p>
      <ul>
        <p dangerouslySetInnerHTML={{ __html: t("about-text-22") }}></p>
        <p dangerouslySetInnerHTML={{ __html: t("about-text-23") }}></p>
        <p dangerouslySetInnerHTML={{ __html: t("about-text-24") }}></p>
      </ul>
      <p dangerouslySetInnerHTML={{ __html: t("about-text-25") }}></p>
      <p dangerouslySetInnerHTML={{ __html: t("about-text-26") }}></p>
      <p dangerouslySetInnerHTML={{ __html: t("about-text-27") }}></p>
      <p dangerouslySetInnerHTML={{ __html: t("about-text-28") }}></p>
      <p dangerouslySetInnerHTML={{ __html: t("about-text-29") }}></p>
      <p dangerouslySetInnerHTML={{ __html: t("about-text-30") }}></p>
      <p dangerouslySetInnerHTML={{ __html: t("about-text-31") }}></p>
      <p dangerouslySetInnerHTML={{ __html: t("about-text-32") }}></p>
    </div>
  );
};

export default About;
