import {
  GET_USER_BY_USERNAME_FAILURE,
  GET_USER_BY_USERNAME_REQUEST_SENT,
  GET_USER_BY_USERNAME_SUCCESSFUL,
} from "./constants";

const initialState = {
  loading: false,
  errorMessage: null,
  successMessage: null,
  userId: null,
  username: null,
  profileLink: null,
  createdDate: null,
};

const userProfileReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_USER_BY_USERNAME_REQUEST_SENT:
      return {
        ...state,
        loading: true,
        errorMessage: null,
      };
    case GET_USER_BY_USERNAME_SUCCESSFUL:
      return {
        ...state,
        loading: false,
        errorMessage: null,
        ...action.payload,
      };
    case GET_USER_BY_USERNAME_FAILURE:
      return {
        ...state,
        loading: false,
        errorMessage: action.message,
      };
    default:
      return state;
  }
};

export default userProfileReducer;
