import { createApi } from "@reduxjs/toolkit/query/react";

import { fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";

import { BASEURL } from "../Configs/constants";

export const deliveryApi = createApi({
  reducerPath: "deliveryApi",
  baseQuery: fetchBaseQuery({
    baseUrl: BASEURL,
    prepareHeaders: (headers) => {
      headers.set("Accept", "application/json");
      headers.set("Content-Type", "application/json");
      return headers;
    },
  }),
  endpoints: (build) => ({
    getAvailableDeliveryCountries: build.query({
      query: () => {
        const languageId = localStorage.getItem("language") === "ua" ? 1 : 217;
        return {
          url: `/api/metadata/delivery/countries/available`,
          params: {
            languageId,
          },
        };
      },
    }),
  }),
});

export const {useGetAvailableDeliveryCountriesQuery} = deliveryApi;
