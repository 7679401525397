import React from "react";

export const InstagramAuthContext = React.createContext({
  isOpen: true,
  handleClose: () => {},
  setUserInfoRequestsIds: () => [],
  userInfoRequestsIds: [],
  setIsVisiblePrivateBlock: (isVisiblePrivateBlock) => {},
  isVisiblePrivateBlock: true,
});

export function useInstagramAuthContext() {
  const modalContext = React.useContext(InstagramAuthContext);

  if (!modalContext) {
    throw new Error(
      "useInstagramAuthContext must be used within a InstagramAuthProvider",
    );
  }
  return modalContext;
}
