import React, { useState, useEffect, useRef } from "react";
import "./DropdownComponentStyles.scss";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { isNull } from "lodash";

export default function DropdownComponent({ list, onSelectChange }) {
  const [selectedItem, setSelectedItem] = useState(null);
  const [isListOpen, setIsListOpen] = useState(false);
  const { t } = useTranslation();
  const ref = useRef(null);
  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setIsListOpen(false);
    }
  };
  
  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, [ref]);

  const handleSelectChange = (id) => {
    onSelectChange(id);
    setSelectedItem(id);
    setIsListOpen(false);
  };

  return (
    <div className="dd-wrapper" ref={ref}>
      <button
        type="button"
        className="dd-header"
        onClick={() => setIsListOpen(!isListOpen)}
      >
        <div className="dd-header-title">
          {selectedItem
            ? t(list.find(({ id }) => id === selectedItem)?.name)
            : t("allTimeSearch")}
        </div>
        <div className={`angle-icon ${isListOpen ? "up" : "down"}`}></div>
      </button>
      {isListOpen && (
        <div className="dd-list">
          <div
            className={`dd-list-item ${isNull(selectedItem) ? "selected" : ""}`}
            onClick={() => handleSelectChange(null)}
          >
            {t("allTimeSearch")}
          </div>
          {list.map(({ id, name }) => (
            <div
              className={`dd-list-item ${id === selectedItem ? "selected" : ""}`}
              key={id}
              onClick={() => handleSelectChange(id)}
            >
              {t(name)}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

DropdownComponent.propTypes = {
  list: PropTypes.array.isRequired,
  onSelectChange: PropTypes.func.isRequired,
};
