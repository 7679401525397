import "./SearchFilter.scss";
import { throttle } from "lodash";
import SearchIcon from "./SearchIcon";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import React, { useCallback, useState } from "react";

function SearchFilter({ onInputChange }) {
  const { t } = useTranslation();
  const [inputText, setInputText] = useState("");

  const throttleChangeHandler = useCallback(throttle(onInputChange, 300), []);

  const handleInputChange = (e) => {
    setInputText(e?.target?.value);
  };
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      sendInputChange();
    }
  };

  const sendInputChange = () => {
    throttleChangeHandler(inputText);
  };

  return (
    <div className="search-wrapper">
      <div className="search-input-wrap">
        <input
          placeholder={t("searchFilterPlaceholder")}
          className="search-input"
          value={inputText}
          onKeyDown={handleKeyDown}
          onChange={handleInputChange}
        />
        <SearchIcon onClick={sendInputChange} style={{ cursor: "pointer" }} />
      </div>
    </div>
  );
}

SearchFilter.propTypes = {
  onInputChange: PropTypes.func.isRequired,
};

export default SearchFilter;
