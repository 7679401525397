export const POST_USERS_CART_ORDER_REQUEST_SENT = "POST_USERS_CART_ORDER_REQUEST_SENT";
export const POST_USERS_CART_ORDER_SUCCESSFUL = "POST_USERS_CART_ORDER_SUCCESSFUL";
export const POST_USERS_CART_ORDER_FAILURE = "POST_USERS_CART_ORDER_FAILURE";

export const GET_USER_CART_PRODUCTS_REQUEST_SENT = "GET_USER_CART_PRODUCTS_REQUEST_SENT";
export const GET_USER_CART_PRODUCTS_SUCCESSFUL = "GET_USER_CART_PRODUCTS_SUCCESSFUL";
export const GET_USER_CART_PRODUCTS_FAILURE = "GET_USER_CART_PRODUCTS_FAILURE";

export const DELETE_USER_CART_PRODUCT_REQUEST_SENT =
  "DELETE_USER_CART_PRODUCT_REQUEST_SENT";
export const DELETE_USER_CART_PRODUCT_SUCCESSFUL = "DELETE_USER_CART_PRODUCT_SUCCESSFUL";
export const DELETE_USER_CART_PRODUCT_FAILURE = "DELETE_USER_CART_PRODUCT_FAILURE";

export const UPDATE_USER_CART_PRODUCT_REQUEST_SENT =
  "UPDATE_USER_CART_PRODUCT_REQUEST_SENT";
export const UPDATE_USER_CART_PRODUCT_SUCCESSFUL = "UPDATE_USER_CART_PRODUCT_SUCCESSFUL";
export const UPDATE_USER_CART_PRODUCT_FAILURE = "UPDATE_USER_CART_PRODUCT_FAILURE";

export const GET_CITIES_BY_COUNTRY_SENT = "GET_CITIES_BY_COUNTRY_SENT";
export const GET_CITIES_BY_COUNTRY_SUCCESSFUL = "GET_CITIES_BY_COUNTRY_SUCCESSFUL";
export const GET_CITIES_BY_COUNTRY_FAILURE = "GET_CITIES_BY_COUNTRY_FAILURE";

export const GET_SELLER_SHOPS_REQUEST_SENT = "GET_SELLER_SHOPS_REQUEST_SENT";
export const GET_SELLER_SHOPS_SUCCESSFULL = "GET_SELLER_SHOPS_SUCCESSFULL";
export const GET_SELLER_SHOPS_FAILURE = "GET_SELLER_SHOPS_FAILURE";

export const GET_SELLER_COURIER_REQUEST_SENT = "GET_SELLER_COURIER_REQUEST_SENT";
export const GET_SELLER_COURIER_SUCCESSFULL = "GET_SELLER_COURIER_SUCCESSFULL";
export const GET_SELLER_COURIER_FAILURE = "GET_SELLER_COURIER_FAILURE";
export const GET_SELLER_COURIER_REQUEST_FINISH = "GET_SELLER_COURIER_REQUEST_FINISH";

export const GET_SELLER_LOCAL_REQUEST_SENT = "GET_SELLER_LOCAL_REQUEST_SENT";
export const GET_SELLER_LOCAL_SUCCESSFULL = "GET_SELLER_LOCAL_SUCCESSFULL";
export const GET_SELLER_LOCAL_FAILURE = "GET_SELLER_LOCAL_FAILURE";

export const GET_TOTAL_LOCAL_REQUEST_SENT = "GET_TOTAL_LOCAL_REQUEST_SENT";
export const GET_TOTAL_LOCAL_SUCCESSFULL = "GET_TOTAL_LOCAL_SUCCESSFULL";
export const GET_TOTAL_LOCAL_FAILURE = "GET_TOTAL_LOCAL_FAILURE";

export const GET_LOCAL_WAREHOUSES_REQUEST_SENT = "GET_LOCAL_WAREHOUSES_REQUEST_SENT";
export const GET_LOCAL_WAREHOUSES_SUCCESSFULL = "GET_LOCAL_WAREHOUSES_SUCCESSFULL";
export const GET_LOCAL_WAREHOUSES_FAILURE = "GET_LOCAL_WAREHOUSES_FAILURE";

export const GET_LIQPAY_FAILURE = "GET_LIQPAY_FAILURE";
export const GET_LIQPAY_SUCCESSFULL = "GET_LIQPAY_SUCCESSFULL";
export const GET_LIQPAY_REQUEST_SENT = "GET_LIQPAY_REQUEST_SENT";

export const GET_CITIES_SELLER_SHOPS_SENT = "GET_CITIES_SELLER_SHOPS_SENT";
export const GET_CITIES_SELLER_SHOPS_SUCCESSFUL = "GET_CITIES_SELLER_SHOPS_SUCCESSFUL";
export const GET_CITIES_SELLER_SHOPS_FAILURE = "GET_CITIES_SELLER_SHOPS_FAILURE";

export const GET_CITIES_COURIER_SENT = "GET_CITIES_COURIER_SENT";
export const GET_CITIES_COURIER_SUCCESSFUL = "GET_CITIES_COURIER_SUCCESSFUL";
export const GET_CITIES_COURIER_FAILURE = "GET_CITIES_COURIER_FAILURE";

export const GET_COUNTRIES_SELLER_SHOPS_SENT = "GET_COUNTRIES_SELLER_SHOPS_SENT";
export const GET_COUNTRIES_SELLER_SHOPS_SUCCESSFUL =
  "GET_COUNTRIES_SELLER_SHOPS_SUCCESSFUL";
export const GET_COUNTRIES_SELLER_SHOPS_FAILURE = "GET_COUNTRIES_SELLER_SHOPS_FAILURE";

export const GET_COUNTRIES_COURIER_SENT = "GET_COUNTRIES_COURIER_SENT";
export const GET_COUNTRIES_COURIER_SUCCESSFUL = "GET_COUNTRIES_COURIER_SUCCESSFUL";
export const GET_COUNTRIES_COURIER_FAILURE = "GET_COUNTRIES_COURIER_FAILURE";

export const GET_LOCAL_COUNTRIES_SENT = "GET_LOCAL_COUNTRIES_SENT";
export const GET_LOCAL_COUNTRIES_SUCCESSFUL = "GET_LOCAL_COUNTRIES_SUCCESSFUL";
export const GET_LOCAL_COUNTRIES_FAILURE = "GET_LOCAL_COUNTRIES_FAILURE";

export const DISPATCH_NULL_ROUTE = "DISPATCH_NULL_ROUTE";

export const SET_USER_CART_PRODUCTS_DELIVERY = "SET_USER_CART_PRODUCTS_DELIVERY";

export const SET_DELIVERY_INFORMATION = "SET_DELIVERY_INFORMATION";

export const GET_HAS_USER_BUYER_DELIVERIES_REQUEST_SENT =
  "GET_HAS_USER_BUYER_DELIVERIES_REQUEST_SENT";
export const GET_HAS_USER_BUYER_DELIVERIES_SUCCESSFUL =
  "GET_HAS_USER_BUYER_DELIVERIES_SUCCESSFUL";
export const GET_HAS_USER_BUYER_DELIVERIES_FAILURE = "GET_HAS_USER_BUYER_DELIVERIES_FAILURE";
