import React from "react";
import ReactDOM from "react-dom";
import "./ModalWidnowStyles.scss";

const ModalContainer = ({ onClose, children }) =>
  ReactDOM.createPortal(
    <>
      <div className="custom-modal-shadow" onClick={onClose} />
      <div className="custom-modal">
        <div className="custom-modal-content">{children}</div>
      </div>
    </>,
    document.getElementById("app-modal"),
  );
export default ModalContainer;
