import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { BlurImageLoad } from "../../BlurImageLoad";
import "./ProductFeedCardStyle.scss";

const ProductFeedCard = ({ product, from, userId }) => {
  const { t } = useTranslation();
  const productSold = !product.quantity;
  return (
    <div className="product-card">
      <Link
        className={`product-card-link ${
          productSold && userId !== product.userId ? "disabled" : ""
        }`}
        to={{
          pathname: `/p/${product.productLinkName}/`,
          state: { product: product, comingFrom: from || "Subscriptions" },
        }}
      >
        <div className="product-card-img">
          <BlurImageLoad src={product.instaImageLink} alt={product.shortName} />
        </div>
        <div className={`product-card-text ${productSold ? "product-sold" : ""}`}>
          <h4>
            {product.shortName.length > 49
              ? `${product.shortName.substring(0, 49)}...`
              : product.shortName}
          </h4>
          <p>
            {product.currencyId === 1 ? "₴" : "$"}
            {product.price}
          </p>
        </div>
        {productSold ? (
          <div className="product-sold-bloc">
            <span>{t("outStock")}</span>
          </div>
        ) : null}
      </Link>
    </div>
  );
};

const mapStateToProps = (state) => ({
  userId: state.auth.userId,
});

export default connect(mapStateToProps, {})(ProductFeedCard);
