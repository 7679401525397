export const GET_PRODUCTS_REQUEST_SENT = "GET_PRODUCTS_REQUEST_SENT";
export const GET_PRODUCTS_SUCCESSFUL = "GET_PRODUCTS_SUCCESSFUL";
export const GET_PRODUCTS_FAILURE = "GET_PRODUCTS_FAILURE";

export const GET_PRODUCT_STATIC_COLORS_REQUEST_SENT =
  "GET_PRODUCT_STATIC_COLORS_REQUEST_SENT";
export const GET_PRODUCT_STATIC_COLORS_SUCCESSFUL =
  "GET_PRODUCT_STATIC_COLORS_SUCCESSFUL";
export const GET_PRODUCT_STATIC_COLORS_FAILURE = "GET_PRODUCT_STATIC_COLORS_FAILURE";

export const GET_PRODUCT_STATIC_MATERIALS_REQUEST_SENT =
  "GET_PRODUCT_STATIC_MATERIALS_REQUEST_SENT";
export const GET_PRODUCT_STATIC_MATERIALS_SUCCESSFUL =
  "GET_PRODUCT_STATIC_MATERIALS_SUCCESSFUL";
export const GET_PRODUCT_STATIC_MATERIALS_FAILURE =
  "GET_PRODUCT_STATIC_MATERIALS_FAILURE";

export const GET_PRODUCT_STATIC_SIZES_REQUEST_SENT =
  "GET_PRODUCT_STATIC_SIZES_REQUEST_SENT";
export const GET_PRODUCT_STATIC_SIZES_SUCCESSFUL = "GET_PRODUCT_STATIC_SIZES_SUCCESSFUL";
export const GET_PRODUCT_STATIC_SIZES_FAILURE = "GET_PRODUCT_STATIC_SIZES_FAILURE";

export const GET_PRODUCT_IMAGES_REQUEST_SENT = "GET_PRODUCT_IMAGES_REQUEST_SENT";
export const GET_PRODUCT_IMAGES_SUCCESSFUL = "GET_PRODUCT_IMAGES_SUCCESSFUL";
export const GET_PRODUCT_IMAGES_FAILURE = "GET_PRODUCT_IMAGES_FAILURE";

export const ADD_PRODUCT_COLOR_REQUEST_SENT = "ADD_PRODUCT_COLOR_REQUEST_SENT";
export const ADD_PRODUCT_COLOR_SUCCESSFUL = "ADD_PRODUCT_COLOR_SUCCESSFUL";
export const ADD_PRODUCT_COLOR_FAILURE = "ADD_PRODUCT_COLOR_FAILURE";

export const ADD_PRODUCT_MATERIALS_REQUEST_SENT = "ADD_PRODUCT_MATERIALS_REQUEST_SENT";
export const ADD_PRODUCT_MATERIALS_SUCCESSFUL = "ADD_PRODUCT_MATERIALS_SUCCESSFUL";
export const ADD_PRODUCT_MATERIALS_FAILURE = "ADD_PRODUCT_MATERIALS_FAILURE";

export const ADD_PRODUCT_SIZE_REQUEST_SENT = "ADD_PRODUCT_SIZE_REQUEST_SENT";
export const ADD_PRODUCT_SIZE_SUCCESSFUL = "ADD_PRODUCT_SIZE_SUCCESSFUL";
export const ADD_PRODUCT_SIZE_FAILURE = "ADD_PRODUCT_SIZE_FAILURE";

export const ADD_PRODUCT_OTHER_REQUEST_SENT = "ADD_PRODUCT_OTHER_REQUEST_SENT";
export const ADD_PRODUCT_OTHER_SUCCESSFUL = "ADD_PRODUCT_OTHER_SUCCESSFUL";
export const ADD_PRODUCT_OTHER_FAILURE = "ADD_PRODUCT_OTHER_FAILURE";

export const CREATE_PRODUCT_REQUEST_SENT = "CREATE_PRODUCT_REQUEST_SENT";
export const CREATE_PRODUCT_SUCCESSFUL = "CREATE_PRODUCT_SUCCESSFUL";
export const CREATE_PRODUCT_FAILURE = "CREATE_PRODUCT_FAILURE";

export const ADD_PRODUCT_IMAGES_REQUEST_SENT = "ADD_PRODUCT_IMAGES_REQUEST_SENT";
export const ADD_PRODUCT_IMAGES_SUCCESSFUL = "ADD_PRODUCT_IMAGES_SUCCESSFUL";
export const ADD_PRODUCT_IMAGES_FAILURE = "ADD_PRODUCT_IMAGES_FAILURE";

export const GET_USERS_PRODUCT_REQUEST_SENT = "GET_USERS_PRODUCT_REQUEST_SENT";
export const GET_USERS_PRODUCT_SUCCESSFUL = "GET_USERS_PRODUCT_SUCCESSFUL";
export const GET_USERS_PRODUCT_FAILURE = "GET_USERS_PRODUCT_FAILURE";

export const GET_COLORS_BY_PRODUCT_REQUEST_SENT = "GET_COLORS_BY_PRODUCT_REQUEST_SENT";
export const GET_COLORS_BY_PRODUCT_SUCCESSFUL = "GET_COLORS_BY_PRODUCT_SUCCESSFUL";
export const GET_COLORS_BY_PRODUCT_FAILURE = "GET_COLORS_BY_PRODUCT_FAILURE";

export const GET_MATERIALS_BY_PRODUCT_REQUEST_SENT =
  "GET_MATERIALS_BY_PRODUCT_REQUEST_SENT";
export const GET_MATERIALS_BY_PRODUCT_SUCCESSFUL = "GET_MATERIALS_BY_PRODUCT_SUCCESSFUL";
export const GET_MATERIALS_BY_PRODUCT_FAILURE = "GET_MATERIALS_BY_PRODUCT_FAILURE";

export const GET_SIZES_BY_PRODUCT_REQUEST_SENT = "GET_SIZES_BY_PRODUCT_REQUEST_SENT";
export const GET_SIZES_BY_PRODUCT_SUCCESSFUL = "GET_SIZES_BY_PRODUCT_SUCCESSFUL";
export const GET_SIZES_BY_PRODUCT_FAILURE = "GET_SIZES_BY_PRODUCT_FAILURE";

export const GET_ADDITIONAL_OPTIONS_BY_PRODUCT_REQUEST_SENT = "GET_ADDITIONAL_OPTIONS_BY_PRODUCT_REQUEST_SENT";
export const GET_ADDITIONAL_OPTIONS_BY_PRODUCT_SUCCESSFUL = "GET_ADDITIONAL_OPTIONS_BY_PRODUCT_SUCCESSFUL";
export const GET_ADDITIONAL_OPTIONS_BY_PRODUCT_FAILURE = "GET_ADDITIONAL_OPTIONS_BY_PRODUCT_FAILURE";

export const ADD_ADDITIONAL_OPTIONS_BY_PRODUCT_REQUEST_SENT = "ADD_ADDITIONAL_OPTIONS_BY_PRODUCT_REQUEST_SENT";
export const ADD_ADDITIONAL_OPTIONS_BY_PRODUCT_SUCCESSFUL = "ADD_ADDITIONAL_OPTIONS_BY_PRODUCT_SUCCESSFUL";
export const ADD_ADDITIONAL_OPTIONS_BY_PRODUCT_FAILURE = "ADD_ADDITIONAL_OPTIONS_BY_PRODUCT_FAILURE";

export const DELETE_ADDITIONAL_OPTIONS_BY_PRODUCT_REQUEST_SENT = "DELETE_ADDITIONAL_OPTIONS_BY_PRODUCT_REQUEST_SENT";
export const DELETE_ADDITIONAL_OPTIONS_BY_PRODUCT_SUCCESSFUL = "DELETE_ADDITIONAL_OPTIONS_BY_PRODUCT_SUCCESSFUL";
export const DELETE_ADDITIONAL_OPTIONS_BY_PRODUCT_FAILURE = "DELETE_ADDITIONAL_OPTIONS_BY_PRODUCT_FAILURE";

export const EDIT_ADDITIONAL_OPTIONS_BY_PRODUCT_REQUEST_SENT = "EDIT_ADDITIONAL_OPTIONS_BY_PRODUCT_REQUEST_SENT";
export const EDIT_ADDITIONAL_OPTIONS_BY_PRODUCT_SUCCESSFUL = "EDIT_ADDITIONAL_OPTIONS_BY_PRODUCT_SUCCESSFUL";
export const EDIT_ADDITIONAL_OPTIONS_BY_PRODUCT_FAILURE = "EDIT_ADDITIONAL_OPTIONS_BY_PRODUCT_FAILURE";


export const GET_OTHERS_BY_PRODUCT_REQUEST_SENT = "GET_OTHERS_BY_PRODUCT_REQUEST_SENT";
export const GET_OTHERS_BY_PRODUCT_SUCCESSFUL = "GET_OTHERS_BY_PRODUCT_SUCCESSFUL";
export const GET_OTHERS_BY_PRODUCT_FAILURE = "GET_OTHERS_BY_PRODUCT_FAILURE";

export const DELETE_PRODUCT_IMAGE_REQUEST_SENT = "DELETE_PRODUCT_IMAGE_REQUEST_SENT";
export const DELETE_PRODUCT_IMAGE_SUCCESSFUL = "DELETE_PRODUCT_IMAGE_SUCCESSFUL";
export const DELETE_PRODUCT_IMAGE_FAILURE = "DELETE_PRODUCT_IMAGE_FAILURE";

export const DELETE_PRODUCT_COLOR_REQUEST_SENT = "DELETE_PRODUCT_COLOR_REQUEST_SENT";
export const DELETE_PRODUCT_COLOR_SUCCESSFUL = "DELETE_PRODUCT_COLOR_SUCCESSFUL";
export const DELETE_PRODUCT_COLOR_FAILURE = "DELETE_PRODUCT_COLOR_FAILURE";

export const DELETE_PRODUCT_SIZE_REQUEST_SENT = "DELETE_PRODUCT_SIZE_REQUEST_SENT";
export const DELETE_PRODUCT_SIZE_SUCCESSFUL = "DELETE_PRODUCT_SIZE_SUCCESSFUL";
export const DELETE_PRODUCT_SIZE_FAILURE = "DELETE_PRODUCT_SIZE_FAILURE";

export const DELETE_PRODUCT_OTHER_REQUEST_SENT = "DELETE_PRODUCT_OTHER_REQUEST_SENT";
export const DELETE_PRODUCT_OTHER_SUCCESSFUL = "DELETE_PRODUCT_OTHER_SUCCESSFUL";
export const DELETE_PRODUCT_OTHER_FAILURE = "DELETE_PRODUCT_OTHER_FAILURE";

export const DELETE_PRODUCT_MATERIAL_REQUEST_SENT =
  "DELETE_PRODUCT_MATERIAL_REQUEST_SENT";
export const DELETE_PRODUCT_MATERIAL_SUCCESSFUL = "DELETE_PRODUCT_MATERIAL_SUCCESSFUL";
export const DELETE_PRODUCT_MATERIAL_FAILURE = "DELETE_PRODUCT_MATERIAL_FAILURE";

export const DELETE_PRODUCT_REQUEST_SENT = "DELETE_PRODUCT_REQUEST_SENT";
export const DELETE_PRODUCT_SUCCESSFUL = "DELETE_PRODUCT_SUCCESSFUL";
export const DELETE_PRODUCT_FAILURE = "DELETE_PRODUCT_FAILURE";

export const UPDATE_PRODUCT_REQUEST_SENT = "UPDATE_PRODUCT_REQUEST_SENT";
export const UPDATE_PRODUCT_SUCCESSFUL = "UPDATE_PRODUCT_SUCCESSFUL";
export const UPDATE_PRODUCT_FAILURE = "UPDATE_PRODUCT_FAILURE";

export const EDIT_PRODUCT_OTHER_REQUEST_SENT = "EDIT_PRODUCT_OTHER_REQUEST_SENT";
export const EDIT_PRODUCT_OTHER_SUCCESSFUL = "EDIT_PRODUCT_OTHER_SUCCESSFUL";
export const EDIT_PRODUCT_OTHER_FAILURE = "EDIT_PRODUCT_OTHER_FAILURE";

export const EDIT_PRODUCT_MATERIAL_REQUEST_SENT = "EDIT_PRODUCT_MATERIAL_REQUEST_SENT";
export const EDIT_PRODUCT_MATERIAL_SUCCESSFUL = "EDIT_PRODUCT_MATERIAL_SUCCESSFUL";
export const EDIT_PRODUCT_MATERIAL_FAILURE = "EDIT_PRODUCT_MATERIAL_FAILURE";

export const EDIT_PRODUCT_SIZE_REQUEST_SENT = "EDIT_PRODUCT_SIZE_REQUEST_SENT";
export const EDIT_PRODUCT_SIZE_SUCCESSFUL = "EDIT_PRODUCT_SIZE_SUCCESSFUL";
export const EDIT_PRODUCT_SIZE_FAILURE = "EDIT_PRODUCT_SIZE_FAILURE";

export const EDIT_PRODUCT_COLOR_REQUEST_SENT = "EDIT_PRODUCT_COLOR_REQUEST_SENT";
export const EDIT_PRODUCT_COLOR_SUCCESSFUL = "EDIT_PRODUCT_COLOR_SUCCESSFUL";
export const EDIT_PRODUCT_COLOR_FAILURE = "EDIT_PRODUCT_COLOR_FAILURE";
