import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

const currentYear = new Date().getFullYear();
const monthsArr = Array.from({ length: 12 }, (x, i) => {
  const month = i + 1;
  return month <= 9 ? "0" + month : month;
});
const yearsArr = Array.from({ length: 9 }, (_x, i) => currentYear + i);
const toDashedSurname = (value) => {
  let result = "";

  const SPECIAL_CHARS = "[`!@#$%^&*()_+-=[]{};':\"\\|,.<>/?~1234567890]";
  const SUB_SPECIAL_CHARS = SPECIAL_CHARS + " ";

  for (let i = 0; i < value.length; i++) {
    if (
      i > 0 &&
      i + 1 < value.length &&
      value[i] === "-" &&
      !SUB_SPECIAL_CHARS.includes(value[i - 1]) &&
      !SUB_SPECIAL_CHARS.includes(value[i + 1])
    ) {
      result += value[i];
    } else if (
      i > 0 &&
      i === value.length - 1 &&
      value[i] === "-" &&
      !SUB_SPECIAL_CHARS.includes(value[i - 1])
    ) {
      result += value[i];
    } else if (!SPECIAL_CHARS.includes(value[i])) {
      result += value[i];
    }
  }
  return result;
};
export default function CForm({
  cardMonth,
  cardYear,
  onUpdateState,
  cardNumberRef,
  cardHolderRef,
  cardDateRef,
  onCardInputFocus,
  onCardInputBlur,
  cardCvv,
  children,
}) {
  const isHidden = true;
  const [cardNumber, setCardNumber] = useState("");
  const { t } = useTranslation();

  const handleFormChange = (event) => {
    let { name, value } = event.target;  
    var format = /[`!@#$%^№&*()_+\=\[\]{};':"\\|,.<>\/?~1234567890☺☻♥♦♣♠•◘○◙♂♀♪♫☼►◄↕‼¶§▬↨↑↓→←∟↔▲▼~Δ€‚ƒ„…†‡ˆ‰Š‹ŒŽ‘’“”•–—˜™š›œžŸ¡¢£¤¥¦§¨©ª«¬®¯°±²³´µ¶·¸¹º»¼½¾¿×÷øæ¢₴£¥₧ƒáíóúñÑªº¿⌐¬½¼¡«»░▒▓│┤╡╢╖╕╣║╗╝╜╛┐└┴┬├─┼╞╟╚╔╩╦╠═╬╧╨╤╥╙╘╒╓╫╪┘┌█▄▌▐▀αßΓπΣσµτΦΘΩδ∞φε∩≡±≥≤⌠⌡÷≈°∙·√ⁿ²■]/;    
    value = toDashedSurname(value.replace(format, ""));
    event.target.value = value;
    if (format.test(value) || value.split(" ").length > 4) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      onUpdateState(name, value);
    }
  };

  // TODO: We can improve the regex check with a better approach like in the card component.
  const onCardNumberChange = (event) => {
    let { value, name } = event.target;
    let cardNumber = value;
    value = value.replace(/\D/g, "");
    if (/^3[47]\d{0,13}$/.test(value)) {
      cardNumber = value.replace(/(\d{4})/, "$1 ").replace(/(\d{4}) (\d{6})/, "$1 $2 ");
    } else if (/^3(?:0[0-5]|[68]\d)\d{0,11}$/.test(value)) {
      // diner's club, 14 digits
      cardNumber = value.replace(/(\d{4})/, "$1 ").replace(/(\d{4}) (\d{6})/, "$1 $2 ");
    } else if (/^\d{0,16}$/.test(value)) {
      // regular cc number, 16 digits
      cardNumber = value
        .replace(/(\d{4})/, "$1 ")
        .replace(/(\d{4}) (\d{4})/, "$1 $2 ")
        .replace(/(\d{4}) (\d{4}) (\d{4})/, "$1 $2 $3 ");
    }

    setCardNumber(cardNumber.trimRight());
    onUpdateState(name, cardNumber);
  };

  const onCvvFocus = (event) => {
    onUpdateState("isCardFlipped", true);
  };

  const onCvvBlur = (event) => {
    onUpdateState("isCardFlipped", false);
  };

  // NOTE: Currently the cursor on the card number field gets reset if we remove a number, the code bellow was used
  // in class components, need to transform this to work with functional components.
  // getSnapshotBeforeUpdate() {
  //     return this.props.cardNumberRef.current.selectionStart;
  // }

  // const componentDidUpdate = function (prevProps, prevState, cursorIdx) {
  //     const node = cardNumberRef.current;
  //     const { cardNumber: cardNum } = state;
  //     const { cardNumber: prevCardNum } = prevState;
  //     if (
  //         cardNum.length > prevCardNum.length &&
  //         cardNum[cursorIdx - 1] === ' '
  //     ) {
  //         cursorIdx += 1;
  //     } else if (prevCardNum[cursorIdx - 1] === ' ') {
  //         cursorIdx -= 1;
  //     }
  //     node.selectionStart = node.selectionEnd = cursorIdx;
  // };

  return (
    <div className="card-form">
      <div className="card-list">{children}</div>
      <div className="card-form__inner">
        <div className="card-input">
          <label htmlFor="cardNumber" className="card-input__label">
            {t("CardNumber")}
          </label>
          <input
            type="tel"
            name="cardNumber"
            className="card-input__input"
            autoComplete="off"
            onChange={onCardNumberChange}
            // onPaste={(e) => {
            //   e.preventDefault();
            //   return false;
            // }}
            onCopy={(e) => {
              e.preventDefault();
              return false;
            }}
            maxLength="19"
            ref={cardNumberRef}
            onFocus={(e) => onCardInputFocus(e, "cardNumber")}
            onBlur={onCardInputBlur}
            value={cardNumber}
          />
        </div>

        <div className="card-input">
          <label htmlFor="cardName" className="card-input__label">
            {t("CardHolder")}
          </label>
          <input
            type="text"
            className="card-input__input"
            autoComplete="off"
            name="cardHolder"
            onChange={handleFormChange}
            onPaste={(e) => {
              e.preventDefault();
              return false;
            }}
            onCopy={(e) => {
              e.preventDefault();
              return false;
            }}
            ref={cardHolderRef}
            onFocus={(e) => onCardInputFocus(e, "cardHolder")}
            onBlur={onCardInputBlur}
          />
        </div>
      </div>
    </div>
  );
}
