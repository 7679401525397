import axios from "axios";
import {  getAccessToken2 } from "../Helpers/auth";

// axios.defaults.baseURL = process.env.BASE_URL
// axios.defaults.headers.post['Content-Type'] = 'application/json'
// axios.defaults.headers.put['Content-Type'] = 'application/json'

export const getRequest = ({ url, headers = {} }) =>
  new Promise((resolve, reject) => {
    try {
      axios
        .get(url, {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: getAccessToken2(),
            ...headers,
          },
        })
        .then((resp) => {
          resolve(resp.data);
        })
        .catch((err) => {
          if (err.response.status === 401) unAuthenticatedAccess();
          reject(err);
        });
    } catch (err) {
      reject(err);
    }
  });

export const postRequest = ({ url, payload, headers }) =>
  new Promise((resolve, reject) => {
    try {
      axios
        .post(url, payload, {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: getAccessToken2(),
            ...headers,
          },
        })
        .then((resp) => {
          resolve(resp.data);
        })
        .catch((err) => {
          if (err.response.status === 401) unAuthenticatedAccess();
          reject(err);
        });
    } catch (err) {
      reject(err);
    }
  });

export const putRequest = ({ url, payload = {}, headers = {} }) =>
  new Promise((resolve, reject) => {
    try {
      axios
        .put(url, payload, {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: getAccessToken2(),
            ...headers,
          },
        })
        .then((resp) => {
          resolve(resp.data);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (err) {
      reject(err);
    }
  });

export const deleteRequest = ({ url, headers }) =>
  new Promise((resolve, reject) => {
    try {
      if (!headers) headers = {};
      axios
        .delete(url, {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: getAccessToken2(),
            ...headers,
          },
        })
        .then((resp) => {
          resolve(resp.data);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (err) {
      reject(err);
    }
  });

const unAuthenticatedAccess = () => {
  // if (!['login', 'register', 'forgot-password']
  //   .includes(window.location.href.split('/').at(-1))) {
  //   localStorage.setItem('post-login-url', window.location.href)
  //   window.location.href = '/login'
  // }
};
