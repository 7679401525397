export const REQUEST_INPROGRESS = "myOrders/REQUEST_INPROGRESS";
export const REQUEST_FAILURE = "myOrders/REQUEST_FAILURE";

export const GET_ORDERS_BY_USER_ID_REQUEST_SENT = "GET_ORDERS_BY_USER_ID_REQUEST_SENT";
export const GET_ORDERS_BY_USER_ID_SUCCESSFUL = "GET_ORDERS_BY_USER_ID_SUCCESSFUL";
export const GET_ORDERS_BY_USER_ID_FAILURE = "GET_ORDERS_BY_USER_ID_FAILURE";

export const GET_ORDER_DETAILS_REQUEST_SENT = "GET_ORDER_DETAILS_REQUEST_SENT";
export const GET_ORDER_DETAILS_SUCCESSFUL = "GET_ORDER_DETAILS_SUCCESSFUL";
export const GET_ORDER_DETAILS_FAILURE = "GET_ORDER_DETAILS_FAILURE";
