import React, { useState } from "react";

export const BlurImageLoad = ({ src, alt }) => {
  const [isLoad, setIsLoad] = useState(true);

  return (
    <img
      onLoad={() => setIsLoad(false)}
      style={{
        filter: isLoad ? "blur(20px)" : "none",
        transition: isLoad ? "none" : "filter 0.3s ease-out",
        background: "linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(104,114,116,1) 100%)",
      }}
      src={src ? src : "/assets/images/product-1.png"}
      onError={({ currentTarget }) => {
        currentTarget.onerror = null;
        currentTarget.src = "/assets/images/product-1.png";
      }}
      alt={alt}
    />
  );
};
