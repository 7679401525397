import React from "react";

import { BackWithTitle } from "../../Components/BackWithTitle/BackWithTitle";
import OrdersTable from "./OrdersTable/OrdersTable";

export default () => (
  <div className="my-orders">
    <BackWithTitle titleText="back" />
    <OrdersTable />
  </div>
);
