import "./BuyerPage.scss";
import "react-activity/dist/Sentry.css";

import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { Layout } from "../../Components/Layout/Layout";
import { connect } from "react-redux";
import {
  getBuyerFeed,
  getBuyerGeneralInfo,
  getBuyerSwitchToSeller,
} from "../../Redux/Buyer/actions";
import { useTranslation } from "react-i18next";
import BuyerProductCardItem from "./BuyerPageComponents/BuyerProductCardItem";
import { loginAsGuest, logout } from "../../Redux/Auth/actions";
import { postUsersCartOrder } from "../../Redux/Cart/actions";
import { Dropdown, Modal } from "react-bootstrap";
import { CustomToggle } from "./utils/CustomToggle";
import { CustomMenuBuyer } from "./utils/CustomMenuBuyer";
import BuyerInfo from "./BuyerPageComponents/BuyerInfo";
import OnboardingWizard from "./OnboardingWizard/OnboardingWizard";
import { isEmpty } from "lodash";
import { INSTAGRAM_CLIENT_ID, INSTAGRAM_REDIRECT_URL } from "../../Configs/constants";
import { toast } from "react-toastify";
import { Sentry } from "react-activity";

const customImgProfile = "/assets/images/profile-icon2.png";

const getUrl = () => {
  if (window.location.href.includes(process.env.REACT_APP_URL_DEVELOPMENT))
    return process.env.REACT_APP_URL_DEVELOPMENT;
  else if (window.location.href.includes(process.env.REACT_APP_URL_PRODUCTION))
    return "https://" + process.env.REACT_APP_URL_PRODUCTION;
  return process.env.REACT_APP_URL_LOCALHOST;
};

const BuyerPage = (props) => {
  const {
    history,
    authenticated,
    logout,
    buyerInfo,
    buyerFeed,
    userId,
    hasInstagram,
    loading,
    loginAsGuest,
    googleUser,
    postUsersCartOrder,
    buyerSwitchToSeller,
    getBuyerSwitchToSeller,
    setPage,
    filter,
    setFilter,
    setSearchText,
    debouncedGetBuyerFeed,
    searchText,
  } = props;
  const { t } = useTranslation();
  const buyerFeedData = buyerFeed?.buyerFeed;
  const [lastElement, setLastElement] = useState(null);
  const [feed, setFeed] = useState([]);
  const [settingsModal, setSettingsModal] = useState(false);
  const [showOnboardingWizard, onShowOnboardingWizard] = useState(false);
  const prevBuyerFeedData = useRef();

  const imageUrl = buyerInfo?.profileImageLink
    ? `${buyerInfo?.profileImageLink}?${Date.now()}`
    : customImgProfile;

  const observer = useRef(
    new IntersectionObserver((entries) => {
      const first = entries[0];
      if (first.isIntersecting) {
        setPage((page) => page + 1);
      }
    }),
  );

  useEffect(() => {
    const currentElement = lastElement;
    const currentObserver = observer.current;

    if (currentElement) {
      currentObserver.observe(currentElement);
    }

    return () => {
      if (currentElement) {
        currentObserver.unobserve(currentElement);
      }
    };
  }, [lastElement]);

  useLayoutEffect(() => {
    if (buyerFeedData) {
      const newBuyerFeedData = JSON.stringify(buyerFeedData);
      if (prevBuyerFeedData.current === newBuyerFeedData) {
        setFeed(buyerFeedData);
      } else {
        setFeed((prev) => [...prev, ...buyerFeedData]);
        prevBuyerFeedData.current = newBuyerFeedData;
      }
    }
  }, [buyerFeedData]);
  const onHideSettingsModal = () => setSettingsModal(false);
  const handleSearchByText = () => {
    setFeed([]);
    setFilter(0);
    setPage(1);
    prevBuyerFeedData.current = null;
    debouncedGetBuyerFeed();
  };

  const handleChangeText = (e) => {
    setSearchText(e?.target?.value);
  };

  const onDropdownSelect = (value) => {
    if (value !== filter) {
      setFeed([]);
      setFilter(value);
      setPage(1);
      prevBuyerFeedData.current = null;
    }
  };
  const redirect = () => {
    window.location.assign(
      `${INSTAGRAM_REDIRECT_URL}?client_id=${INSTAGRAM_CLIENT_ID}&redirect_uri=${getUrl()}/&scope=user_profile,user_media&response_type=code`,
    );
  };
  const isLastElement = (index) => {
    return index === feed?.length - 1 && !loading && buyerFeed.total > feed?.length;
  };
  const authenticatedWithGoogle = !isEmpty(googleUser);

  const openInNewTab = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };

  const onLogout = () => {
    logout();
    loginAsGuest().then(() => {
      onHideSettingsModal();
      history.push(`/${buyerInfo?.username}/`);
      history.go();
    });
  };

  const onSwitchToSeller = async () => {
    if (!authenticated) {
      onHideSettingsModal();
      toast.error(t("loginToBecomeASeller"));
      return;
    }
    const { data } = await getBuyerSwitchToSeller(userId);
    onHideSettingsModal();
    if (data.isExist) {
      history.push(`/${buyerInfo?.username}/`);
    } else if (!data.hasInstagram) {
      toast.error(t("instagramRequiredBecomeSeller"));
    } else {
      onShowOnboardingWizard(true);
    }
  };
  if (showOnboardingWizard) {
    return (
      <Layout>
        <OnboardingWizard buyerData={buyerSwitchToSeller} />
      </Layout>
    );
  }
  return (
    <Layout>
      <div className="buyer-page-layout">
        <BuyerInfo
          searchText={searchText}
          buyerInfo={buyerInfo}
          imageUrl={imageUrl}
          customImgProfile={customImgProfile}
          setSettingsModal={setSettingsModal}
          handleChangeText={handleChangeText}
          handleSearchByText={handleSearchByText}
        />
        {!hasInstagram && authenticatedWithGoogle && !authenticated && (
          <button className="btn btn-connect-inst" onClick={redirect}>
            <img
              src="/assets/images/inst-icon.svg"
              alt="instagram"
              style={{ marginRight: "10px" }}
            />
            {t("connectAccount")}
          </button>
        )}
        <div>
          <div className="seller-products-title-wrapper d-flex justify-content-between align-items-center">
            <h2 className="seller-products-title">{t("recommendations")}</h2>
            <Dropdown onSelect={onDropdownSelect}>
              <Dropdown.Toggle
                as={CustomToggle}
                className="w-100"
                variant="secondary"
                id="recommendations"
              >
                <img
                  className="settings-image m-0"
                  src="/assets/images/icon-filter.png"
                  alt=""
                />
              </Dropdown.Toggle>
              <Dropdown.Menu as={CustomMenuBuyer} className="dropdown-menu-buyer">
                <Dropdown.Item eventKey={1}>{t("newOnes")}</Dropdown.Item>
                <Dropdown.Item eventKey={2}>{t("popular")}</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <>
            {feed?.length ? (
              feed.map((productFeed, index) =>
                isLastElement(index) ? (
                  <div key={productFeed.product.productId} ref={setLastElement}>
                    <BuyerProductCardItem
                      {...props}
                      productFeed={productFeed}
                      loginAsGuest={loginAsGuest}
                      postUsersCartOrder={postUsersCartOrder}
                      userId={userId}
                      username={buyerInfo?.username}
                    />
                  </div>
                ) : (
                  <div key={productFeed.product.productId}>
                    <BuyerProductCardItem
                      {...props}
                      productFeed={productFeed}
                      loginAsGuest={loginAsGuest}
                      postUsersCartOrder={postUsersCartOrder}
                      userId={userId}
                      username={buyerInfo?.username}
                    />
                  </div>
                ),
              )
            ) : (
              <p className="noGroupsTxt">{t("noUserFeed")}</p>
            )}
            {loading ? (
              <div className="loading-container">
                <Sentry color="#4B85DC" size={100} speed={0.5} animating={true} />
              </div>
            ) : null}
          </>
        </div>
      </div>
      <Modal
        contentClassName="setting-buttons-modal"
        show={settingsModal}
        onHide={() => setSettingsModal(false)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body style={{ padding: 0 }}>
          <div className="setting-buttons-container">
            <button
              className="insta-btn"
              onClick={() => history.replace({ pathname: "/my-orders/" })}
            >
              {t("myOrders")}
            </button>
            <button className="insta-btn" onClick={onSwitchToSeller}>
              {t("switchToSeller")}
            </button>
            <button
              className="insta-btn"
              onClick={() => openInNewTab("https://t.me/sellsgram_bot")}
            >
              {t("support")}
            </button>
            <button
              className="insta-btn"
              onClick={() => history.replace({ pathname: "/info/p/" })}
            >
              {t("info")}
            </button>
            <button className="insta-btn" onClick={onHideSettingsModal}>
              {t("cancel")}
            </button>
            {(authenticated || authenticatedWithGoogle) && (
              <button onClick={onLogout} className="insta-btn text-danger">
                {t("logout")}
              </button>
            )}
          </div>
        </Modal.Body>
      </Modal>
    </Layout>
  );
};

const mapStateToProps = (state) => ({
  userId: state.auth.userId,
  buyerInfo: state.buyer.buyerInfo,
  buyerFeed: state.buyer.buyerFeed,
  loading: state.buyer.loading,
  googleUser: state.googleAuth.googleUser,
  buyerSwitchToSeller: state.buyer.buyerSwitchToSeller,
  hasInstagram: state.googleAuth.hasInstagram,
  authenticated: state.auth.authenticated
});

export default connect(mapStateToProps, {
  getBuyerGeneralInfo,
  getBuyerFeed,
  loginAsGuest,
  logout,
  postUsersCartOrder,
  getBuyerSwitchToSeller,
})(BuyerPage);
