import "./Checkbox.css";

import React from "react";

export const Checkbox = ({ ...rest }) => {
  return (
    <label className="checkbox-container">
      <input type="checkbox" {...rest} />
      <span className="checkmark"></span>
    </label>
  );
};
