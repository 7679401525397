import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import {
  useRemoveGroupMutation,
  useRemoveUserFromGroupMutation,
  useSaveUserGroupFollowingsMutation,
} from "../../Api/subscriptions";
import { Checkbox } from "../../Components/Common/Checkbox/Checkbox";

const getUrl = () => {
  if (window.location.href.includes(process.env.REACT_APP_URL_DEVELOPMENT))
    return process.env.REACT_APP_URL_DEVELOPMENT;
  else if (window.location.href.includes(process.env.REACT_APP_URL_PRODUCTION))
    return "https://" + process.env.REACT_APP_URL_PRODUCTION;
  return process.env.REACT_APP_URL_LOCALHOST;
};

const GroupEditModal = ({
  groupInfo,
  sellsgramFollowingsData,
  showEditModal,
  setShowEditModal,
  groupsRefetch,
  isGroupsFetching,
}) => {
  const [saveUserGroupFollowings, { isLoading: isSaveGroupLoading }] =
    useSaveUserGroupFollowingsMutation();

  const [removeUserFromGroup, { isLoading: isRemoveUserLoading }] =
    useRemoveUserFromGroupMutation();

  const [removeGroup, { isLoading: isRemoveGroupLoading }] = useRemoveGroupMutation();

  const saveGroupLoading = isSaveGroupLoading || isRemoveUserLoading;

  const [selectedUsers, setSelectedUsers] = useState(
    groupInfo.map((item) => item.followedUserId),
  );
  const groupId = groupInfo[0]?.groupId;
  const groupName = groupInfo[0]?.groupName;
  const [searchedTxt, setSearchedTxt] = useState("");
  const [searchedUsers, setSearchedUsers] = useState([]);
  const [initialSelectedUsers] = useState(selectedUsers);
  const { t } = useTranslation();

  const toAdd = selectedUsers.filter((item) => !initialSelectedUsers.includes(item));
  const toDelete = initialSelectedUsers.filter((item) => !selectedUsers.includes(item));

  const handleSave = async () => {
    const toAddPromises = toAdd.map((id) =>
      saveUserGroupFollowings({ followedUserId: id, groupId }),
    );

    const toDeletePromises = toDelete.map((id) =>
      removeUserFromGroup({ groupId, userId: id }),
    );

    Promise.all([...toAddPromises, ...toDeletePromises])
      .then(() => groupsRefetch())
      .then(() => setShowEditModal(false));
  };

  const handleDeleteGroup = () =>
    removeGroup(groupId)
      .then(() => groupsRefetch())
      .then(() => setShowEditModal(false));

  const onChangeSelection = (e, id) => {
    if (e.target.checked) {
      setSelectedUsers([...selectedUsers, id]);
    } else {
      setSelectedUsers(selectedUsers.filter((val) => val !== id));
    }
  };

  const onSearchChange = (e) => {
    setSearchedTxt(e.target.value);
    setSearchedUsers(
      sellsgramFollowingsData.filter((user) =>
        user.userName.toLowerCase().includes(e.target.value.toLowerCase()),
      ),
    );
  };

  const renderGroups = sellsgramFollowingsData?.length ? (
    ((searchedUsers.length && searchedUsers) || sellsgramFollowingsData).map(
      (data, key) => (
        <div key={key} className="row-spacebtwn">
          <div className="flex-row">
            <a href={`${getUrl()}/${data.userName}/`}>
              <img
                alt="#"
                src={data.profileImageLink}
                className="subscription-group-img"
              />
            </a>
            <div>
              <a href={`${getUrl()}/${data.userName}/`}>
                <p className="subscription-group-name">{data.userName}</p>{" "}
              </a>
              {/* <p className="subscription-group-category">cozy and amazing!</p> */}
            </div>
          </div>
          <Checkbox
            key={data.followingUserID}
            defaultChecked={selectedUsers?.some((item) => item === data.followingUserID)}
            onChange={(e) => onChangeSelection(e, +data.followingUserID)}
          />
        </div>
      ),
    )
  ) : (
    <p className="noGroupsTxt">There are no groups</p>
  );

  return (
    <Modal
      show={showEditModal}
      onHide={setShowEditModal}
      contentClassName="group-add-modal"
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <h2>{groupName}</h2>
        <input
          placeholder={t("searchYourSubscription")}
          className="search-boxinput"
          value={searchedTxt}
          onChange={onSearchChange}
        />
        <div className="groups-container">{renderGroups}</div>
        <div className="d-flex edit-group-modal-buttons">
          <button
            type="button"
            className="btn w-50 justify-content-center modal-save-btn"
            disabled={
              !(toAdd.length || toDelete.length) || saveGroupLoading || isGroupsFetching
            }
            onClick={handleSave}
          >
            {saveGroupLoading || isGroupsFetching ? (
              <span
                className="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
            ) : (
              t("save")
            )}
          </button>
          <button
            type="button"
            className="btn w-50 justify-content-center modal-delete-btn"
            onClick={handleDeleteGroup}
            disabled={isRemoveGroupLoading || isGroupsFetching}
          >
            {isRemoveGroupLoading || isGroupsFetching ? (
              <span
                className="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
            ) : (
              t("deleteGroup")
            )}
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default GroupEditModal;
