import React from "react";
import { useTranslation } from "react-i18next";

export default ({
  orderDetails: { orderId, deliveryType, address, productName, proof },
}) => {
  const { t } = useTranslation();
  return (
    <div className="order-tab">
      <div className="tab-header">
        <div className="tab-header_title">
          <h4>{t("orderDetailsOrder", { number: orderId })}</h4>
          {productName && <p>{productName}</p>}
        </div>
      </div>
      <div className="tab-body">
        <div className="body-column">
          <p className="column-title">{t("deliveryType")}</p>
          <p className="column-info">
            {t("deliveryType")}: {deliveryType || "-"}
          </p>
        </div>
        <div className="body-column">
          <p className="column-title">{t("shipmentDetails")}</p>
          <p className="column-info">
            {t("address")}: {address || "-"}
          </p>
        </div>
        <div className="body-column">
          <p className="column-title">{t("proofDeliveryMessage")}</p>
          <p className="column-info">
            {t("details")}: {proof.proofMessage || "-"}
          </p>
        </div>
        <div className="body-column">
          <p className="column-title">{t("imageProofDelivery")}</p>
          <p className="column-info">            
            <img src={proof.proofImageLink}  alt="" className="rounded" />
          </p>
        </div>
      </div>
    </div>
  );
};
