import axios from "axios";

import { BASEURL } from "../../Configs/constants";

export async function changeOrderStatusAll(userId, arrayOrderIds, statusId) {
  const changePayemntObject = {
    changeOrdersStatus: {
      userId: userId,
      orderIds: arrayOrderIds,
      statusId: statusId,
    },
  };
  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    // Authorization: "Bearer my-token",
    // "My-Custom-Header": "foobar",
  };
  await axios
    .put(
      `${BASEURL}/api/sellsgram/seller/requested-orders/status/all`,
      changePayemntObject,
      { headers },
    )
    .then((response) => console.error("Success!", response))
    .catch((error) => {
      console.error("There was an error!", error);
    });
}
