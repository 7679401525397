import "./ProductProperties.css";

import React, { useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";

import { deleteProductColor, getColorsByProduct } from "../../../Redux/Products/actions";
import ColorForm from "../PropertyForms/ColorForm";
import SketchHelper from "../PropertyForms/utils/SketchHelper";

const ColorTable = ({
  colorsByProduct,
  getColorsByProduct,
  deleteProductColor,
  productId,
  setProductColors,
  productColors,
}) => {
  const [toggleTableContent, setToggleTableContent] = useState(false);
  const [updatable, setUpdatable] = useState();
  const { t } = useTranslation();
  console.log(productColors);
  useEffect(() => {
    if (productId) getColorsByProduct(productId);
  }, []);

  return (
    <Table className="gv">
      <thead>
        <tr>
          <th>{t("name")}</th>
          <th>{t("scheme")}</th>
          <th>{t("price")}</th>
          <th>{t("qty")}</th>
          <th />
          <th />
        </tr>
      </thead>
      <tbody>
        {productColors?.length
          ? productColors.map((color, i) =>
              i === updatable ? (
                <ColorForm
                  key={updatable}
                  updatable={updatable}
                  color={color}
                  productId={productId}
                  getColorsByProduct={getColorsByProduct}
                  setToggleTableContent={setUpdatable}
                  setProductColors={setProductColors}
                />
              ) : (
                <tr key={color?.productColorId}>
                  <td>{color?.colorName}</td>
                  <td>
                    <SketchHelper defColor={color?.colorScheme} />
                  </td>
                  <td>{color?.colorPrice}</td>
                  <td>{color?.colorQuantity}</td>
                  <td>
                    <img
                      onClick={() => setUpdatable(i)}
                      style={{ cursor: "pointer", width: "1.2em" }}
                      src="/assets/images/icon-edit.svg"
                      alt="edit icon"
                    />
                  </td>
                  <td>
                    <img
                      onClick={() => {
                        setProductColors((prev) => prev.filter((_, j) => j !== i));
                      }}
                      style={{ cursor: "pointer", width: "1.2em" }}
                      src="/assets/images/bin.png"
                      alt="delete icon"
                    />
                  </td>
                </tr>
              ),
            )
          : !productColors
          ? colorsByProduct?.length > 0 &&
            colorsByProduct.map((color) =>
              color.productColorId === updatable ? (
                <ColorForm
                  key={updatable}
                  updatable={updatable}
                  color={color}
                  productId={productId}
                  getColorsByProduct={getColorsByProduct}
                  setToggleTableContent={setUpdatable}
                  setProductColors={setProductColors}
                />
              ) : (
                <tr key={color.productColorId}>
                  <td>{color.colorName}</td>
                  <td>
                    <SketchHelper
                      updatable={updatable !== undefined}
                      defColor={color.colorScheme}
                    />
                  </td>
                  <td>{color.colorPrice}</td>
                  <td>{color.colorQuantity}</td>
                  <td>
                    <img
                      onClick={() => setUpdatable(color.productColorId)}
                      style={{ cursor: "pointer", width: "1.2em" }}
                      src="/assets/images/icon-edit.svg"
                      alt="edit icon"
                    />
                  </td>
                  <td>
                    <img
                      onClick={() =>
                        deleteProductColor(
                          color.productColorId,
                          getColorsByProduct,
                          productId,
                        )
                      }
                      style={{ cursor: "pointer", width: "1.2em" }}
                      src="/assets/images/bin.png"
                      alt="delete icon"
                    />
                  </td>
                </tr>
              ),
            )
          : ""}
        {!toggleTableContent ? (
          <tr>
            <td colSpan={5}>
              <button
                onClick={() => setToggleTableContent(true)}
                className="btn btn-add-other d-flex align-items-center w-100 justify-content-center"
                style={{ marginBottom: 10 }}
              >
                {t("color")}
                <img src="/assets/images/icon-add-other.svg" alt="" />
              </button>
            </td>
          </tr>
        ) : (
          <ColorForm
            updatable={true}
            productId={productId}
            getColorsByProduct={getColorsByProduct}
            setToggleTableContent={setToggleTableContent}
            setProductColors={setProductColors}
          />
        )}
      </tbody>
    </Table>
  );
};

const mapStateToProps = (state) => ({
  colorsByProduct: state.products.colorsByProduct,
});

export default connect(mapStateToProps, {
  getColorsByProduct,
  deleteProductColor,
})(ColorTable);
