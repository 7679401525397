import {
  GET_BUYER_FEED_FAILURE,
  GET_BUYER_FEED_REQUEST_SENT,
  GET_BUYER_FEED_SUCCESSFUL,
  GET_BUYER_GENERAL_INFO_FAILURE,
  GET_BUYER_GENERAL_INFO_REQUEST_SENT,
  GET_BUYER_GENERAL_INFO_SUCCESSFUL,
  GET_BUYER_SWITCH_TO_SELLER_FAILURE,
  GET_BUYER_SWITCH_TO_SELLER_REQUEST_SENT,
  GET_BUYER_SWITCH_TO_SELLER_SUCCESSFUL,
  SWITCH_BUYER_TO_SELLER_FAILURE,
  SWITCH_BUYER_TO_SELLER_SUCCESSFUL,
  SWITCH_BUYER_TO_SELLER_REQUEST_SENT,
} from "./constants";

const initialState = {
  loading: false,
  errorMessage: null,
  buyerInfo: null,
  buyerFeed: null,
  buyerSwitchToSeller: null,
};

const buyerReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_BUYER_GENERAL_INFO_REQUEST_SENT:
    case SWITCH_BUYER_TO_SELLER_REQUEST_SENT:
      return {
        ...state,
        loading: true,
        errorMessage: null,
      };
    case GET_BUYER_GENERAL_INFO_SUCCESSFUL:
      return {
        ...state,
        loading: false,
        errorMessage: null,
        buyerInfo: action.payload,
      };
    case GET_BUYER_GENERAL_INFO_FAILURE:
      return {
        ...state,
        loading: false,
        errorMessage: action.message,
      };
    case GET_BUYER_FEED_REQUEST_SENT:
      return {
        ...state,
        loading: true,
        errorMessage: null,
      };
    case GET_BUYER_FEED_SUCCESSFUL:
      return {
        ...state,
        loading: false,
        errorMessage: null,
        buyerFeed: action.payload,
      };
    case SWITCH_BUYER_TO_SELLER_SUCCESSFUL:
      return {
        ...state,
        loading: false,
        errorMessage: null,
      };
    case GET_BUYER_FEED_FAILURE:
      return {
        ...state,
        loading: false,
        errorMessage: action.message,
      };
    case GET_BUYER_SWITCH_TO_SELLER_REQUEST_SENT:
      return {
        ...state,
        loading: true,
        errorMessage: null,
      };
    case GET_BUYER_SWITCH_TO_SELLER_SUCCESSFUL:
      return {
        ...state,
        loading: false,
        errorMessage: null,
        buyerSwitchToSeller: action.payload,
      };
    case GET_BUYER_SWITCH_TO_SELLER_FAILURE:
    case SWITCH_BUYER_TO_SELLER_FAILURE:
      return {
        ...state,
        loading: false,
        errorMessage: action.message,
      };

    default:
      return state;
  }
};

export default buyerReducer;
