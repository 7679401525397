import axios from "axios";
import React, { useCallback, useEffect, useRef, useState } from "react";
import Sentry from "react-activity/dist/Sentry";
import { Button, Dropdown } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";

import {
  deleteSellerShop,
  getCitiesbyCountry,
  getSellerShops,
  postSellerShop,
} from "../../Redux/Cart/actions";
import CustomMenu from "./utils/CustomMenu";

const DeliverySellerLocation = ({
  getCitiesbyCountry,
  totalCitiesByCountry,
  loadingCities,
  countryId,
  deliveryType,
  sellerShops,
  postSellerShop,
  userId,
  selfDeliveries,
  deleteSellerShop,
  getSellerShops,
}) => {
  const { t, i18n } = useTranslation();
  const [city, setCity] = useState(null);
  const [name, setName] = useState("");
  const [address, setAddress] = useState("");

  useEffect(() => {
    getCitiesbyCountry(countryId);
  }, [i18n.language, countryId]);

  useEffect(() => {
    setCity(null);
  }, [countryId]);

  const newDeliveryLocation = async () => {
    postSellerShop(
      {
        shopName: name,
        shopAddress: address,
        cityId: city?.cityId,
        countryId,
        sellerUserId: userId,
      },
      getSellerShops,
    );
  };

  const deleteShopLocation = (sellerShopId) => {
    deleteSellerShop(sellerShopId, getSellerShops);
  };

  return (
    <>
      {selfDeliveries?.map(({ shopName, shopAddress, cityName, sellerShopId }) => (
        <div className="d-flex flex-md-row flex-column row" key={sellerShopId}>
          <div className="flex-one mb-3">
            <label htmlFor="">{t("shopName")}</label>
            <p>{shopName}</p>
          </div>
          <div className="flex-one mb-3">
            <label htmlFor="">{t("shopAddress")}</label>
            <p>{shopAddress}</p>
          </div>
          <div className="flex-one mb-3">
            <label htmlFor="">{t("cityName")}</label>
            <p>{cityName}</p>
          </div>
          <div className="flex-one mb-4 mb-md-3 mt-0">
            <Button
              variant="none"
              className="mt-0"
              style={{ background: "transparent" }}
              onClick={() => deleteShopLocation(sellerShopId)}
            >
              <img
                src="/assets/images/bin.png"
                alt=""
                style={{ cursor: "pointer", width: "15px", height: "15px" }}
              />
            </Button>
          </div>
        </div>
      ))}

      <div className="d-flex flex-md-row flex-column row align-items-end mb-sm-0 mb-md-3">
        <div className="flex-one mb-sm-3 mb-md-0">
          <label htmlFor="">{t("shopName")}</label>
          <input
            type="text"
            name=""
            className="form-control mb-0"
            onChange={(e) => setName(e.target.value)}
          />
        </div>
        <div className="flex-one mb-sm-3 mb-md-0">
          <label htmlFor="">{t("shopAddress")}</label>
          <input
            type="text"
            name=""
            className="form-control mb-0"
            onChange={(e) => setAddress(e.target.value)}
          />
        </div>
        <div className="flex-one mb-sm-3 mb-md-0">
          <Dropdown onSelect={(c) => setCity(JSON.parse(c))}>
            <Dropdown.Toggle
              as={Button}
              className="align-items-center"
              style={{ height: "41px" }}
              variant="secondary"
              size="sm"
              id="truncateLongTexts"
            >
              {city?.name || t("selectCity")}
            </Dropdown.Toggle>

            <Dropdown.Menu as={CustomMenu} disabled={loadingCities}>
              {loadingCities ? (
                <Dropdown.Item>
                  <Sentry color="#4B85DC" size={100} speed={0.5} animating={true} />
                </Dropdown.Item>
              ) : (
                totalCitiesByCountry?.map((city) => (
                  <Dropdown.Item eventKey={JSON.stringify(city)} key={city.cityId}>
                    {city.name}
                  </Dropdown.Item>
                ))
              )}
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <div className="flex-one mb-sm-3 mb-md-0">
          <Button
            variant="none"
            style={{ background: "transparent", height: "41px", alignItems: "center" }}
            onClick={newDeliveryLocation}
            disabled={!city || !name || !address}
          >
            <img
              src="/assets/images/icon-add-other.svg"
              alt=""
              style={{ cursor: "pointer", width: "15px", height: "15px" }}
            />
          </Button>
        </div>
      </div>
    </>
  );
};

DeliverySellerLocation.propTypes = {};

const mapStateToProps = (state) => ({
  totalCitiesByCountry: state.cart.totalCitiesByCountry,
  loadingCities: state.cart.loadingCities,
  userId: state.auth.userId,
  sellerShops: state.cart.sellerShops,
});

export default connect(mapStateToProps, {
  getCitiesbyCountry,
  postSellerShop,
  deleteSellerShop,
  getSellerShops,
})(DeliverySellerLocation);
