import AddItemPage from "../Pages/AddItem/AddItem";
import Cart from "../Pages/Cart/Cart";
import DeliveryPage from "../Pages/Delivery/DeliveryPage";
import EditItem from "../Pages/EditItem/EditItem";
import IgPage from "../Pages/Ig/Ig";
import MainLayout from "../Pages/index";
import Info from "../Pages/Info/Info";
import MyOrders from "../Pages/MyOrders/MyOrders";
import PaymentLinkPage from "../Pages/PaymentLink/PaymentLinkPage";
import PaymentsPage from "../Pages/Payments/PaymentsPage";
import ResultPaymentPage from "../Pages/Payments/ResultPaymentPage";
import StatisticsPage from "../Pages/Statistics/StatisticsPage";
import SubscriptionsPage from "../Pages/Subscriptions/Subscriptions";
import ItemDetailPage from "../Pages/UserPage/ItemDetail";
import ViewEdit from "../Pages/ViewEdit/ViewEdit";
import ViewRequests from "../Pages/ViewRequests/ViewRequests";

const route = [
  {
    path: "/",
    exact: true,
    name: "Main",
    type: "auth",
    component: MainLayout,
  },
  {
    path: "/shop-cart/",
    exact: true,
    name: "Cart",
    type: "auth",
    component: Cart,
  },
  {
    path: "/subscriptions/",
    exact: true,
    name: "Register",
    type: "auth",
    component: SubscriptionsPage,
  },
  {
    path: "/item-detail/",
    exact: true,
    name: "ItemDetail",
    type: "auth",
    component: ItemDetailPage,
  },
  {
    path: "/add-item-page/",
    exact: true,
    name: "AddItemPage",
    type: "auth",
    component: AddItemPage,
  },
  {
    path: "/edit-item/",
    exact: true,
    name: "EditItemPage",
    type: "auth",
    component: EditItem,
  },
  {
    path: "/my-orders/",
    exact: true,
    name: "MyOrders",
    type: "auth",
    component: MyOrders,
  },
  {
    path: "/delivery-page/",
    exact: true,
    name: "DeliveryPage",
    type: "auth",
    component: DeliveryPage,
  },
  {
    path: "/payouts/",
    exact: true,
    name: "PaymentsPage",
    type: "auth",
    component: PaymentsPage,
  },
  {
    path: "/payment-result/:result/",
    exact: true,
    name: "ResultPaymentPage",
    type: "auth",
    component: ResultPaymentPage,
  },
  {
    path: "/tarification/",
    exact: true,
    name: "StatisticsPage",
    type: "auth",
    component: StatisticsPage,
  },
  {
    path: "/:username",
    exact: true,
    name: "Register",
    type: "auth",
    component: IgPage,
  },
  {
    path: "/view/requests",
    exact: true,
    name: "ViewRequests",
    type: "auth",
    component: ViewRequests,
  },
  {
    path: "/p/:link/",
    exact: true,
    name: "ViewEdit",
    type: "auth",
    component: ViewEdit,
  },
  {
    path: "/payment/:username/",
    exact: true,
    name: "PaymentLinkPage",
    type: "auth",
    component: PaymentLinkPage,
  },
  {
    path: "/info/p/",
    exact: false,
    name: "Help",
    type: "auth",
    component: Info,
  },
];

export default route;
