import React from "react";
import "./OrderInfoHeaderStyles.scss";
import { useTranslation } from "react-i18next";
import DeliveryIcon from "./orderInfoIcons/DeliveryIcon";
import ParkOutlineIcon from "./orderInfoIcons/ParkOutlineIcon";
import ParkOutlineSuccessIcon from "./orderInfoIcons/ParkOutlineSuccessIcon";

export default ({ activeTab, setActiveTab, onOrderInfoClose }) => {
  const { t } = useTranslation();

  const tabLabels = [
    {
      label: t("order"),
      Icon: ParkOutlineIcon,
    },
    {
      label: t("shipment"),
      Icon: DeliveryIcon,
    },
    {
      label: t("invoices"),
      Icon: ParkOutlineSuccessIcon,
    },
  ];
  return (
    <div className="order-info-header">
      <div className="tab-labels">
        {tabLabels.map(({ label, Icon }, index) => (
          <div
            className={`tab-label ${index === activeTab ? "active" : ""}`}
            key={index}
            onClick={() => setActiveTab(index)}
          >
            <Icon active={index === activeTab} />
            <p>{label}</p>
          </div>
        ))}
      </div>
      <button className="order-info_close" onClick={onOrderInfoClose}>
        <img src="/assets/images/icon-cross.svg" alt="cross icon" aria-hidden="true" />
      </button>
    </div>
  );
};
