import "./Layout.scss";

import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import PropTypes from "prop-types";
import { BackWithTitle } from "../BackWithTitle/BackWithTitle";
import ConfirmDialog from "../Common/ConfirmDialog/ConfirmDialog";

export function Layout({
  children,
  titleText,
  backBtnLink,
  saveBtnText,
  onSaveButtonClick,
  onDeleteButtonClick,
}) {
  const [showConfirm,setShowConfirm] = useState(false);
  const { t } = useTranslation();

  return (
    <div className="tab-content">
      <div className="bg-card-area" />
      {showConfirm && (
        <ConfirmDialog
          onClose={() => setShowConfirm(false)}
          onConfirm={() => {
            setShowConfirm(false);
            onDeleteButtonClick();
          }}
          confirmText={t("deleteProductConfirm")}
          confirmBtnText={t("delete")}
        />
      )}
      {titleText && (
        <div className="inner-heading d-flex align-items-center justify-content-between">
          <BackWithTitle titleText={titleText} backBtnLink={backBtnLink} />
          <div className="d-none d-sm-flex align-items-center">
            <button
              type="button"
              className="btn layout-header-btn"
              onClick={onSaveButtonClick}
            >
              {t(saveBtnText || (onDeleteButtonClick ? "update" : "btnFinish"))}
            </button>
            {onDeleteButtonClick && (
              <button
                type="button"
                onClick={() => setShowConfirm(true)}
                className="btn btn-danger layout-header-btn"
              >
                {t("delete")}
              </button>
            )}
          </div>
        </div>
      )}
      {children}
      {titleText && (
        <div className="mobile-btn-finish d-flex align-items-center flex-column d-sm-none">
          <button
            type="button"
            className="btn layout-header-btn"
            onClick={onSaveButtonClick}
          >
            {t(saveBtnText || (onDeleteButtonClick ? "update" : "btnFinish"))}
          </button>
          {onDeleteButtonClick && (
            <button
              type="button"
              onClick={() => setShowConfirm(true)}
              className="btn btn-danger layout-header-btn"
            >
              {t("delete")}
            </button>
          )}
        </div>
      )}
    </div>
  );
}

Layout.propTypes = {
  titleText: PropTypes.string,
  children: PropTypes.node.isRequired,
  backBtnLink: PropTypes.string,
  saveBtnText: PropTypes.string,
  onSaveButtonClick: PropTypes.func,
  onDeleteButtonClick: PropTypes.func,
};

Layout.defaultProps = {
  titleText: "",
  backBtnLink: "",
  saveBtnText: "",
  onDeleteButtonClick: null,
  onSaveButtonClick: () => {},
};
