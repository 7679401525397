import i18n from "i18next";
import Backend from "i18next-http-backend";
import { initReactI18next } from "react-i18next";

import translationEN from "./locales/en/translation.json";
import translationUA from "./locales/ua/translation.json";

const resources = {
  en: {
    translation: translationEN,
  },
  ua: {
    translation: translationUA,
  },
};

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    fallbackLng: "ua",
    debug: true,
    detection: {
      order: ["querystring", "localStorage", "navigator", "htmlTag"],
      cache: ["cookie"],
      interpolation: {
        escapeValue: false,
      },
    },
    lng: localStorage.getItem("language") || "ua",
    resources,
  });

export default i18n;
