import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

const LanguageSelector = () => {
  const { i18n } = useTranslation();
  const setLanguage = (lng) => {
    i18n.changeLanguage(lng);
    localStorage.setItem("language", lng);
  };

  const uaSelected = i18n.language === "ua";

  useEffect(() => localStorage.setItem("language", uaSelected ? "ua" : "en"), []);

  return (
    <div className="language-selector">
      <svg
        width="26"
        height="26"
        viewBox="1 1 23.5 23.5"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={!uaSelected ? "active" : undefined}
        onClick={() => (!uaSelected ? null : setLanguage("en"))}
      >
        <path
          d="M1 1L1.375 2.03125L1 3.10938V4.1875L2.5 6.71875L1 9.25V10.75L2.5 13L1 15.25V16.75L2.5 19.2812L1 21.8125V25L2.03125 24.625L3.10938 25H4.1875L6.71875 23.5L9.25 25H10.75L13 23.5L15.25 25H16.75L19.2812 23.5L21.8125 25H25L24.625 23.9688L25 22.8906V21.8125L23.5 19.2812L25 16.75V15.25L23.5 13L25 10.75V9.25L23.5 6.71875L25 4.1875V1L23.9688 1.375L22.8906 1H21.8125L19.2812 2.5L16.75 1H15.25L13 2.5L10.75 1H9.25L6.71875 2.5L4.1875 1H1Z"
          fill="#EEEEEE"
        />
        <path
          d="M16.75 1V6.0625L21.8125 1H16.75ZM25 4.1875L19.9375 9.25H25V4.1875ZM1 9.25H6.0625L1 4.1875V9.25ZM4.1875 1L9.25 6.0625V1H4.1875ZM9.25 25V19.9375L4.1875 25H9.25ZM1 21.8125L6.0625 16.75H1V21.8125ZM25 16.75H19.9375L25 21.8125V16.75ZM21.8125 25L16.75 19.9375V25H21.8125Z"
          fill="#0052B4"
        />
        <path
          d="M1 1V3.10938L7.14062 9.25H9.25L1 1ZM10.75 1V10.75H1V15.25H10.75V25H15.25V15.25H25V10.75H15.25V1H10.75ZM22.8906 1L16.75 7.14062V9.25L25 1H22.8906ZM9.25 16.75L1 25H3.10938L9.25 18.8594V16.75ZM16.75 16.75L25 25V22.8906L18.8594 16.75H16.75Z"
          fill="#D80027"
        />
      </svg>

      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={uaSelected ? "active" : undefined}
        onClick={() => (uaSelected ? null : setLanguage("ua"))}
      >
        <path
          d="M12 24C18.64 24 24 18.64 24 12H0C0 18.64 5.36 24 12 24Z"
          fill="#FFE62E"
        />
        <path d="M12 0C5.36 0 0 5.36 0 12H24C24 5.36 18.64 0 12 0Z" fill="#428BC1" />
      </svg>
    </div>
  );
};

export default LanguageSelector;
