import "../DeliveryInfo.scss";

import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Dropdown from "react-bootstrap/Dropdown";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { isEmpty } from "lodash";

import {
  getCheckIsEnoughQuantity,
  getSellerShopsCountries,
  postUserBuyerDeliveries,
  setProductsDelivery,
  setDeliveryInformation,
} from "../../../Redux/Cart/actions";
import CourierLocationInfo from "./CourierLocationInfo";
import DeliveryLocationInfo from "./DeliveryLocationInfo";
import LocalDeliveryInfo from "./LocalDeliveryInfo";

const deliveryTypes = Object.freeze({
  selfDelivery: 1,
  courierDelivery: 2,
  localDelivery: 3,
});

const DeliveryType = ({
  setShow,
  show,
  product,
  productLength,
  postUserBuyerDeliveries,
  getCheckIsEnoughQuantity,
  setProductsDelivery,
  setDeliveryInformation,
  userBuyerDeliveries,
  hasUserBuyerDeliveries,
  getSellerShopsCountries,
  sellerShopsCountries,
}) => {
  const [deliveryGeneralInfo, setDeliveryGeneralInfo] = React.useState({
    phoneNumber: "",
    country: {},
    fullName: "",
  });
  const [selfDelivery, setSelfDelivery] = React.useState({
    city: {},
    shop: {},
  });
  const [courierDelivery, setCourierDelivery] = React.useState({
    apartment: "",
    building: "",
    address: "",
    city: {},
  });
  const [localDelivery, setLocalDelivery] = React.useState({
    city: {},
    name: "",
    id: 0,
    warehouse: {
      name: "",
      id: 0,
    },
  });
  const [deliveryType, setDeliveryType] = React.useState(null);

  const { i18n, t } = useTranslation();

  const regexPhoneValidation = /^(?:\+?3?8)?0\d{9}$/;
  const [isPhoneNumberValid, setIsPhoneNumberValid] = useState(
    regexPhoneValidation.test(deliveryGeneralInfo.phoneNumber),
  );

  useEffect(() => {
    setIsPhoneNumberValid(regexPhoneValidation.test(deliveryGeneralInfo.phoneNumber));
  }, [deliveryGeneralInfo.phoneNumber]);

  useEffect(() => {
    if (product.sellerUserId) getSellerShopsCountries(product.sellerUserId);
  }, [i18n.language, product.sellerUserId]);

  const phoneNumberChangeHandler = (value) => {
    setIsPhoneNumberValid(regexPhoneValidation.test(value));
  };

  const [isNextBtnDisabled, setIsNextBtnDisabled] = React.useState(true);

  // validation three forms
  React.useEffect(() => {
    switch (deliveryType) {
      case deliveryTypes.selfDelivery:
        {
          const readyToNext =
            !deliveryGeneralInfo.country.id ||
            !selfDelivery.city.id ||
            !selfDelivery.shop.sellerShopId ||
            !deliveryGeneralInfo.phoneNumber ||
            !deliveryGeneralInfo.fullName.trim() ||
            !isPhoneNumberValid;

          setIsNextBtnDisabled(readyToNext);
        }
        break;
      case deliveryTypes.courierDelivery:
        {
          const readyToNext =
            !deliveryGeneralInfo.country.id ||
            !courierDelivery.city.id ||
            !courierDelivery.address ||
            !courierDelivery.building ||
            !deliveryGeneralInfo.phoneNumber ||
            !deliveryGeneralInfo.fullName?.trim() ||
            !isPhoneNumberValid;
          setIsNextBtnDisabled(readyToNext);
        }
        break;
      case deliveryTypes.localDelivery:
        {
          const readyToNext =
            !deliveryGeneralInfo.country.id ||
            !localDelivery.city.id ||
            !localDelivery.id ||
            !localDelivery.warehouse.id ||
            !deliveryGeneralInfo.phoneNumber ||
            !deliveryGeneralInfo.fullName?.trim() ||
            !isPhoneNumberValid;
          setIsNextBtnDisabled(readyToNext);
        }
        break;
    }
  }, [
    deliveryGeneralInfo,
    isPhoneNumberValid,
    selfDelivery,
    localDelivery,
    courierDelivery,
  ]);

  React.useEffect(() => {
    const existDelivery =
      userBuyerDeliveries?.find((d) => d.orderId === product.orderId) ||
      userBuyerDeliveries?.find((d) => d.sellerUserId === product.sellerUserId);
    if (existDelivery) {
      setDeliveryType(existDelivery.deliveryTypeId);
      setDeliveryGeneralInfo(existDelivery.deliveryGeneralInfo);
      switch (existDelivery.deliveryTypeId) {
        case deliveryTypes.selfDelivery:
          setSelfDelivery(existDelivery.selfDelivery);
          break;
        case deliveryTypes.courierDelivery:
          setCourierDelivery(existDelivery.courierDelivery);
          break;
        case deliveryTypes.localDelivery:
          setLocalDelivery(existDelivery.localDelivery);
          break;
      }
    } else {
      const lastUserDeliveryInfo = userBuyerDeliveries?.[userBuyerDeliveries?.length - 1];
      setDeliveryGeneralInfo((prev) => ({
        ...prev,
        phoneNumber: lastUserDeliveryInfo?.deliveryGeneralInfo?.phoneNumber || "",
        fullName: lastUserDeliveryInfo?.deliveryGeneralInfo?.fullName || "",
      }));
      onDeliveryGeneralInfoChange({
        country:
          i18n.language === "ua"
            ? { name: "Україна", id: 1 }
            : { name: "Ukraine", id: 1 },
      });
    }
  }, [product, userBuyerDeliveries]);

  const onDeliveryGeneralInfoChange = (objectValues) =>
    setDeliveryGeneralInfo((prevDelivery) => ({ ...prevDelivery, ...objectValues }));

  const createUserBuyerDelivery = async () => {
    const orderId = product.orderId;
    const postData = {
      phoneNumber: deliveryGeneralInfo.phoneNumber,
      fullName: deliveryGeneralInfo.fullName,
      countryId: deliveryGeneralInfo.country.id,
      deliveryTypeId: deliveryType,
      building: "",
      apartment: "",
      address: "",
      orderId,
    };
    // Check if there's enough quantity of the product to buy it.
    let [check, message] = await getCheckIsEnoughQuantity(orderId);

    if (!check) {
      toast.error(message);

      return;
    }
    if (deliveryType === deliveryTypes.selfDelivery) {
      setProductsDelivery(
        `${selfDelivery.city.name}, ${selfDelivery.shop.shopName} (${selfDelivery.shop.shopAddress}) `,
        product.productId,
      );
      postData.sellerShopId = selfDelivery.shop.sellerShopId;
      postData.cityId = selfDelivery.city.id;
    } else if (deliveryType === deliveryTypes.courierDelivery) {
      setProductsDelivery(
        `${courierDelivery.city.name}, ${courierDelivery.address}, ${
          courierDelivery.building
        } ${courierDelivery.apartment || ""}`,
        product.productId,
      );
      postData.apartment = courierDelivery.apartment;
      postData.cityId = courierDelivery.city.id;
      postData.address = courierDelivery.address;
      postData.building = courierDelivery.building;
    } else if (deliveryType === deliveryTypes.localDelivery) {
      setProductsDelivery(
        `${localDelivery.city.name}, ${localDelivery.warehouse.name}`,
        product.productId,
      );
      postData.warehouseId = localDelivery.warehouse.id;
      postData.cityId = localDelivery.city.id;
      postData.localDeliveryId = localDelivery.id;
    }
    // /api/sellsgram/buyer/deliveries - POST-request below
    postUserBuyerDeliveries(postData);
    setDeliveryInformation({
      deliveryGeneralInfo,
      selfDelivery,
      courierDelivery,
      localDelivery,
      orderId,
      deliveryTypeId: deliveryType,
      sellerUserId: product.sellerUserId,
    });

    setShow((prev) => prev + 1);
  };

  return (
    <>
      <div className="delivery-info delivery-info-scroll mb-2">
        <div className="delivery-info-item">
          <label>
            {t("title")}: {product?.shortName || "No title available"}
          </label>
        </div>
        <div className="delivery-info-item">
          <div className="row">
            <div className="flex-one">
              <label>{t("selectCountry")}</label>
              <Dropdown
                onSelect={(c) => {
                  const { name, countryId } = JSON.parse(c);
                  onDeliveryGeneralInfoChange({
                    country: {
                      name: name,
                      id: countryId,
                    },
                  });
                }}
              >
                <Dropdown.Toggle
                  disabled={
                    sellerShopsCountries?.length === 1 &&
                    !isEmpty(deliveryGeneralInfo.country)
                  }
                  className="w-100 align-items-center"
                  as={Button}
                  variant="secondary"
                >
                  {sellerShopsCountries?.find(
                    ({ countryId }) => countryId === deliveryGeneralInfo.country.id,
                  )?.name || t("selectCountry")}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  {sellerShopsCountries?.map((c) => (
                    <Dropdown.Item eventKey={JSON.stringify(c)} key={c.countryId}>
                      {c.name}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
        </div>

        {show >= 0 &&
        hasUserBuyerDeliveries?.length &&
        hasUserBuyerDeliveries[show]?.hasSelfDelivery ? (
          <div className="delivery-info-item">
            <div className="d-flex align-items-center justify-content-between">
              <div className="custom-control custom-radio">
                <input
                  type="radio"
                  className="custom-control-input"
                  id="selfPickup"
                  name="example1"
                  value="customEx"
                  checked={deliveryType === deliveryTypes.selfDelivery}
                  onChange={() => {
                    setDeliveryType(deliveryTypes.selfDelivery);
                  }}
                />
                <label className="custom-control-label" htmlFor="selfPickup">
                  {t("selfPickup")}
                </label>
              </div>
              <div className="card-icon">Free</div>
            </div>
            {deliveryType === deliveryTypes.selfDelivery && (
              <DeliveryLocationInfo
                sellerUserId={product?.sellerUserId}
                delivery={selfDelivery}
                onChange={(values) =>
                  setSelfDelivery((prevValues) => ({ ...prevValues, ...values }))
                }
                disabled={!deliveryGeneralInfo.country.id}
              />
            )}
          </div>
        ) : null}

        {show >= 0 &&
        hasUserBuyerDeliveries?.length &&
        hasUserBuyerDeliveries[show]?.hasCourierDelivery ? (
          <div className="delivery-info-item">
            {!hasUserBuyerDeliveries[show].hasSelfDelivery && (
              <label>
                {t("title")}: {product?.shortName || "No title available"}
              </label>
            )}
            <div className="d-flex align-items-center justify-content-between">
              <div className="custom-control custom-radio">
                <input
                  type="radio"
                  className="custom-control-input"
                  id="courier"
                  name="example1"
                  value="customEx"
                  checked={deliveryType === deliveryTypes.courierDelivery}
                  onChange={() => setDeliveryType(deliveryTypes.courierDelivery)}
                />
                <label className="custom-control-label" htmlFor="courier">
                  {t("courier")}
                </label>
              </div>
              <div className="card-icon">{t("from50UAH")}</div>
            </div>
            {deliveryType === deliveryTypes.courierDelivery && (
              <CourierLocationInfo
                sellerUserId={product?.sellerUserId}
                delivery={courierDelivery}
                countryId={deliveryGeneralInfo.country.id}
                onChange={(values) =>
                  setCourierDelivery((prevValues) => ({ ...prevValues, ...values }))
                }
              />
            )}
          </div>
        ) : null}

        {show >= 0 &&
        hasUserBuyerDeliveries?.length &&
        hasUserBuyerDeliveries[show]?.hasLocalDelivery ? (
          <div className="delivery-info-item">
            {!hasUserBuyerDeliveries[show].hasSelfDelivery &&
              !hasUserBuyerDeliveries[show].hasCourierDelivery && (
                <label>
                  {t("title")}: {product?.shortName || "No title available"}
                </label>
              )}
            <div className="d-flex align-items-center justify-content-between">
              <div className="custom-control custom-radio">
                <input
                  type="radio"
                  className="custom-control-input"
                  id="localDelivery"
                  name="example1"
                  value="customEx"
                  checked={deliveryType === deliveryTypes.localDelivery}
                  onChange={() => setDeliveryType(deliveryTypes.localDelivery)}
                />
                <label className="custom-control-label" htmlFor="localDelivery">
                  {t("localDelivery")}
                </label>
              </div>
              <div className="card-icon">{t("from50UAH")}</div>
            </div>
            {deliveryType === deliveryTypes.localDelivery && (
              <LocalDeliveryInfo
                sellerUserId={product?.sellerUserId}
                delivery={localDelivery}
                countryId={deliveryGeneralInfo.country.id}
                onChange={(values) =>
                  setLocalDelivery((prevValues) => ({ ...prevValues, ...values }))
                }
              />
            )}
          </div>
        ) : null}

        {show >= 0 &&
        hasUserBuyerDeliveries?.length &&
        !hasUserBuyerDeliveries[show]?.hasSelfDelivery &&
        !hasUserBuyerDeliveries[show]?.hasCourierDelivery &&
        !hasUserBuyerDeliveries[show]?.hasLocalDelivery ? (
          <div className="delivery-info-item">
            <label>
              {t("title")}: {product?.shortName || "No title available"}
            </label>
            <p>
              {t("temporarilyNotDelivering")}:{" "}
              {product?.shortName || "No title available"}
            </p>
          </div>
        ) : null}

        <div className="delivery-info-item">
          <div className="user-mobile-phone-field">
            <label htmlFor="userMobilePhone">{t("userMobilePhone")}</label>
            <input
              type="tel"
              id="userMobilePhone"
              name="userMobilePhone"
              className={`user-mobile-phone-input ${
                isPhoneNumberValid || deliveryGeneralInfo.phoneNumber?.length === 0
                  ? ""
                  : "user-mobile-phone-input-invalid"
              }`}
              placeholder={t("phone-number-placeholder")}
              value={deliveryGeneralInfo.phoneNumber}
              onChange={(e) => {
                onDeliveryGeneralInfoChange({ phoneNumber: e.target.value });
                phoneNumberChangeHandler(e.target.value);
              }}
            />
          </div>
          <div className="buyer-name-field">
            <label htmlFor="buyerName">{t("buyerName")}</label>
            <input
              type="text"
              name="buyerName"
              id="buyerName"
              className="buyer-name-input"
              placeholder={t("f-and-l-name-placeholder")}
              value={deliveryGeneralInfo.fullName}
              onChange={(e) => {
                onDeliveryGeneralInfoChange({ fullName: e.target.value });
              }}
            />
          </div>
        </div>
        <div className="d-flex flex-sm-row flex-column">
          {show !== 0 && (
            <div className="flex-one">
              <button
                type="button"
                className="btn delivery-info-bottom-btn"
                onClick={() => setShow((prev) => prev - 1)}
              >
                {t("prev")}
              </button>
            </div>
          )}
          <div className="flex-one">
            <button
              type="button"
              className="btn delivery-info-bottom-btn"
              disabled={isNextBtnDisabled}
              onClick={createUserBuyerDelivery}
            >
              {t("product", {
                cur: show + 1,
                total: productLength,
              })}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

DeliveryType.propTypes = {};

const mapStateToProps = (state) => ({
  userBuyerDeliveries: state.cart.userBuyerDeliveries,
  hasUserBuyerDeliveries: state.cart.hasUserBuyerDeliveries,
  sellerShopsCountries: state.cart.sellerShopsCountries,
});

export default connect(mapStateToProps, {
  postUserBuyerDeliveries,
  getCheckIsEnoughQuantity,
  setProductsDelivery,
  setDeliveryInformation,
  getSellerShopsCountries,
})(DeliveryType);
