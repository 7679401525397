import React from "react";

export const YouTubeLogo = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_308_3929)">
        <rect width="24" height="24" rx="6" fill="#4B85DC" />
        <path
          d="M19.6624 8.56122C19.5714 8.2592 19.3931 7.98383 19.1454 7.76267C18.8977 7.54151 18.5893 7.38231 18.2511 7.30102C17.0054 7 12 7 12 7C12 7 6.99455 7 5.74891 7.30102C5.41066 7.38231 5.10226 7.54151 4.85458 7.76267C4.60689 7.98383 4.4286 8.2592 4.33755 8.56122C4.10496 9.69554 3.9921 10.8468 4.00043 12C3.9921 13.1532 4.10496 14.3045 4.33755 15.4388C4.4286 15.7408 4.60689 16.0162 4.85458 16.2373C5.10226 16.4585 5.41066 16.6177 5.74891 16.699C6.99455 17 12 17 12 17C12 17 17.0054 17 18.2511 16.699C18.5893 16.6177 18.8977 16.4585 19.1454 16.2373C19.3931 16.0162 19.5714 15.7408 19.6624 15.4388C19.895 14.3045 20.0079 13.1532 19.9996 12C20.0079 10.8468 19.895 9.69554 19.6624 8.56122ZM10.4001 14.1429V9.85714L14.5541 12L10.4001 14.1429Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_308_3929">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
