import React, { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import "./TablePaginationStyles.scss";

function TablePaginationComponent({ page, onPageChange, onPageSizeChange, total }) {
  const { t } = useTranslation();
  const amountRows = [8, 16, 20, 50, 100];
  const [rows, setRows] = useState(amountRows[3]);

  const onSelectChange = (event) => {
    setRows(Number(event.target.value));
    onPageSizeChange(Number(event.target.value));
  };
  const prevPage = () => {
    if (!(page === 1)) {
      onPageChange(page - 1);
    }
  };
  const nextPage = () => {
    if (!(page === allPages)) {
      onPageChange(page + 1);
    }
  };

  const to = page * rows;
  const from = to - rows + 1;
  const allPages = Math.ceil(total / rows);

  return (
    <div className="pagination-container">
      <div className="rows-select">
        <p>{t("rowsPerPage")}:</p>
        <div className="select-wrapper">
          <select name="select" value={rows} onChange={onSelectChange}>
            {amountRows.map((item) => (
              <option value={item} key={item}>
                {item}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className="page-select">
        <img
          src="/assets/images/icon-prev.svg"
          alt="prev icon"
          onClick={prevPage}
          className={page === 1 ? "disable" : ""}
        />
        <p>
          {from} - {to} of {allPages}
        </p>
        <img
          src="/assets/images/icon-next.svg"
          alt="next icon"
          onClick={nextPage}
          className={page === allPages ? "disable" : ""}
        />
      </div>
    </div>
  );
}

TablePaginationComponent.propTypes = {
  onPageSizeChange: PropTypes.func.isRequired,
  onPageChange: PropTypes.func.isRequired,
};

export default TablePaginationComponent;
