import axios from "axios";
import React, { useEffect, useState } from "react";
import { Sentry } from "react-activity";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

import { BASEURL } from "../../Configs/constants";
import { changeOrderStatusAll } from "./changeOrderStatus";

const ResultPaymentPage = ({ userId }) => {
  const history = useHistory();
  const { t } = useTranslation();
  const { result } = useParams();
  // send request to change payment statusId

  useEffect(() => {
    let wipString = atob(result);
    let arrayOfStrings = wipString.split("-");
    if (arrayOfStrings?.length) {
      let userIdFromRequest = arrayOfStrings[0];
      let arrayOfOrders = arrayOfStrings[1].split(",");
      if (arrayOfOrders?.length && userId.toString() === userIdFromRequest) {
        changeOrderStatusAll(userId, arrayOfOrders, 6);
      } else {
        console.error("user didn't match");
      }
    }
  }, []);

  history.replace({ pathname: "/" });
  return <></>;
};

ResultPaymentPage.propTypes = {};

const mapStateToProps = (state) => ({
  userId: state.auth.userId,
});

export default connect(mapStateToProps, {})(ResultPaymentPage);
