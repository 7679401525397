import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import FirstWizardStep from "./FirstWizardStep/FirstWizardStep";
import DeliveryStep from "./DeliveryStep/DeliveryStep";
import PaymentStep from "./PaymentStep/PaymentStep";
import { switchBuyerToSeller } from "../../../Redux/Buyer/actions";

import { connect } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { toast } from "react-toastify";

const OnboardingWizard = ({
  buyerData,
  errorMessage,
  switchBuyerToSeller,
  username,
  userId,
}) => {
  const [step, setStep] = useState(0);
  const [formData, setFormData] = useState({});
  const history = useHistory();

  const onNextStep = () => {
    setStep((currentStep) => currentStep + 1);
  };
  useEffect(() => {
    if (errorMessage) toast(errorMessage);
  }, [toast]);

  const onSubmit = async () => {
    const isSuccess = await switchBuyerToSeller({
      ...formData,
      sellerUserId: userId,
    });
    if (isSuccess) history.push(`/${username}/`);
  };

  const conditionalComponent = () => {
    switch (step) {
      case 0:
        return (
          <FirstWizardStep
            buyerData={buyerData}
            setFormData={setFormData}
            onNextStep={onNextStep}
          />
        );
      case 1:
        return <DeliveryStep onNextStep={onNextStep} />;
      case 2:
        return <PaymentStep onSubmit={onSubmit} />;
    }
  };
  return conditionalComponent();
};

OnboardingWizard.propTypes = {
  buyerData: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
  username: state.auth.username,
  userId: state.auth.userId,
  errorMessage: state.buyer.errorMessage,
});

export default connect(mapStateToProps, {
  switchBuyerToSeller,
})(OnboardingWizard);
