import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import ReactTooltip from "react-tooltip";

import Header from "../../Components/Header/index";
import { Layout } from "../../Components/Layout/Layout";
import SoldFromFullQuantity from "../../Components/Statistics/components/SoldFromFullQuantity";
import WhatPeopleBuyDiff from "../../Components/Statistics/components/WhatPeopleBuyDiff";
import VisitsByWeekDays from "../../Components/Statistics/index";
import MapChart from "./MapChart";
import Table from "react-bootstrap/Table";

const StatisticsPage = ({
  userId,
  getSellerPayout,
  postSellerPayout,
  totalPayoutData,
}) => {
  const history = useHistory();
  const { t } = useTranslation();
  const [content, setContent] = useState("");
  return (
    <>
      <Layout
        titleText="tarification"
        onSaveButtonClick={() => history.replace({ pathname: "/" })}
      >
        <div className="row">
          {/* <div className="col-12 cart-items-scroll">
            > Visits during the week:<br></br>
            <br></br>
            <VisitsByWeekDays />
            <br></br> > Sold quantity by months:<br></br>
            <WhatPeopleBuyDiff />
            {/* <SoldFromFullQuantity/>  
          </div> */}
          <Table className="gv">
            <thead>
              <tr>
                <th scope="col">{t("merchantType")}</th>
                <th scope="col">{t("paymentMethod")}</th>
                <th scope="col">{t("sellsgramServiceCommission")}</th>
                <th scope="col">{t("bankTarification")}</th>
                <th scope="col">{t("tarificationTotal")}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{t("PhysPerson")}:</td>
                <td>{t("payoutCard")}</td>
                <td>2%</td>
                <td>3%</td>
                <td>5%</td>
              </tr>
              <tr>
                <td>{t("FOP")}:</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
              </tr>
              {/* <tr>
                <td>{t("TOV")}:</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
              </tr> */}
            </tbody>
          </Table>
          {/* <br></br>
          <div>
            <label>Статистика</label>
            <MapChart setTooltipContent={setContent} />
            <ReactTooltip>{content}</ReactTooltip>
          </div> */}
        </div>
      </Layout>
    </>
  );
};

export default StatisticsPage;
