import React, { useEffect, useState } from "react";
import { Nav } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { useInstagramAuthContext } from "../../../Context/Instagram-Auth-Context";

import { getRequestsCount } from "../../../Redux/Profile/actions";
import { useLazyGetCartCountByUserIdQuery } from "../../../Api/cartCountApi";
import { NavButtonWrapper } from "./NavButtonWrapper";
import { RequestsIcon } from "../../../Components/RequestsIcon/RequestsIcon";

export const NavButtonsGroup = ({ loggedInUsername, className }) => {
  const { userId, accountState } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [requestCount, setRequestCount] = useState(0);
  const [cartCountTrigger, cartResult] = useLazyGetCartCountByUserIdQuery();
  const { setUserInfoRequestsIds } = useInstagramAuthContext();
  const isSeller = accountState && accountState.accountStateId === 1;
  const getRequestsDataCount = async (id) => {
    const data = await dispatch(getRequestsCount(id, setUserInfoRequestsIds));
    setRequestCount(data);
  };

  useEffect(() => {
    if (userId) {
      cartCountTrigger(userId, true);
    }
    if (userId && isSeller) {
      getRequestsDataCount(userId);
    }
  }, [userId]);

  const cartCount = cartResult?.data?.totalCount || 0;

  const BuyerButtons = () => (
    <>
      <NavButtonWrapper toLink={`/${loggedInUsername}/`}>
        <svg
          width="23"
          height="22"
          viewBox="0 0 23 22"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8.81907 9.909C8.43725 10.5526 8.12089 11.2617 7.93543 12.0145C5.00089 12.2654 2.16452 13.6399 2.16452 14.2726V15.4726H7.94634C8.1318 16.2145 8.43725 16.9126 8.81907 17.5454H0.0917969V14.2726C0.0917969 11.3708 5.90634 9.909 8.81907 9.909ZM8.81907 0.0908203C9.97638 0.0908203 11.0863 0.550559 11.9046 1.3689C12.723 2.18724 13.1827 3.29715 13.1827 4.45446C13.1827 5.44718 12.8445 6.36355 12.2882 7.10537C11.35 7.45446 10.51 8.01082 9.8118 8.709L8.81907 8.81809C7.66176 8.81809 6.55185 8.35835 5.73351 7.54001C4.91517 6.72167 4.45543 5.61177 4.45543 4.45446C4.45543 3.29715 4.91517 2.18724 5.73351 1.3689C6.55185 0.550559 7.66176 0.0908203 8.81907 0.0908203ZM8.81907 2.16355C8.21148 2.16355 7.62878 2.40491 7.19915 2.83454C6.76952 3.26417 6.52816 3.84687 6.52816 4.45446C6.52816 5.06204 6.76952 5.64475 7.19915 6.07437C7.62878 6.504 8.21148 6.74537 8.81907 6.74537C9.42666 6.74537 10.0094 6.504 10.439 6.07437C10.8686 5.64475 11.11 5.06204 11.11 4.45446C11.11 3.84687 10.8686 3.26417 10.439 2.83454C10.0094 2.40491 9.42666 2.16355 8.81907 2.16355ZM14.8191 8.81809C17.5463 8.81809 19.7282 10.9999 19.7282 13.7272C19.7282 14.6872 19.4554 15.5926 18.9754 16.3454L22.3354 19.7272L20.8191 21.2435L17.4154 17.8945C16.6627 18.3635 15.7682 18.6363 14.8191 18.6363C12.0918 18.6363 9.90998 16.4545 9.90998 13.7272C9.90998 10.9999 12.0918 8.81809 14.8191 8.81809ZM14.8191 10.9999C14.0958 10.9999 13.4021 11.2872 12.8906 11.7987C12.3791 12.3102 12.0918 13.0039 12.0918 13.7272C12.0918 14.4505 12.3791 15.1442 12.8906 15.6557C13.4021 16.1671 14.0958 16.4545 14.8191 16.4545C15.5424 16.4545 16.2361 16.1671 16.7475 15.6557C17.259 15.1442 17.5463 14.4505 17.5463 13.7272C17.5463 13.0039 17.259 12.3102 16.7475 11.7987C16.2361 11.2872 15.5424 10.9999 14.8191 10.9999Z"
            fill="#808488"
          />
        </svg>
      </NavButtonWrapper>
      <div className="nav-btn-separator" />
      <NavButtonWrapper toLink={`/subscriptions/`}>
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6.15812 12.0585L5.45101 11.3514C5.06049 11.7419 5.06049 12.3751 5.45101 12.7656L6.15812 12.0585ZM12.0585 6.15812L12.7656 5.45102C12.3751 5.06049 11.7419 5.06049 11.3514 5.45102L12.0585 6.15812ZM12.0585 17.9588L11.3514 18.666C11.7419 19.0565 12.3751 19.0565 12.7656 18.6659L12.0585 17.9588ZM17.9588 12.0585L18.6659 12.7656C19.0565 12.3751 19.0565 11.7419 18.6659 11.3514L17.9588 12.0585ZM21 12C21 16.9706 16.9706 21 12 21V23C18.0751 23 23 18.0751 23 12H21ZM12 3C16.9706 3 21 7.02944 21 12H23C23 5.92487 18.0751 1 12 1V3ZM3 12C3 7.02944 7.02944 3 12 3V1C5.92487 1 1 5.92487 1 12H3ZM12 21C7.02944 21 3 16.9706 3 12H1C1 18.0751 5.92487 23 12 23V21ZM6.86522 12.7656L12.7656 6.86523L11.3514 5.45102L5.45101 11.3514L6.86522 12.7656ZM12.7656 17.2517L6.86522 11.3514L5.45101 12.7656L11.3514 18.666L12.7656 17.2517ZM17.2517 11.3514L11.3514 17.2517L12.7656 18.6659L18.6659 12.7656L17.2517 11.3514ZM11.3514 6.86523L17.2517 12.7656L18.6659 11.3514L12.7656 5.45102L11.3514 6.86523Z"
            fill="#808488"
          />
        </svg>
      </NavButtonWrapper>
      <div className="nav-btn-separator" />
      <NavButtonWrapper toLink={`/shop-cart/`}>
        <span className="cart-count">
          <svg
            width="21"
            height="20"
            viewBox="0 0 21 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M14.55 11C15.3 11 15.96 10.59 16.3 9.97L19.88 3.48C20.25 2.82 19.77 2 19.01 2H4.21L3.27 0H0V2H2L5.6 9.59L4.25 12.03C3.52 13.37 4.48 15 6 15H18V13H6L7.1 11H14.55ZM5.16 4H17.31L14.55 9H7.53L5.16 4ZM6 16C4.9 16 4.01 16.9 4.01 18C4.01 19.1 4.9 20 6 20C7.1 20 8 19.1 8 18C8 16.9 7.1 16 6 16ZM16 16C14.9 16 14.01 16.9 14.01 18C14.01 19.1 14.9 20 16 20C17.1 20 18 19.1 18 18C18 16.9 17.1 16 16 16Z"
              fill="#808488"
            />
          </svg>
          {cartCount > 0 && <span className="badge">{cartCount}</span>}
        </span>
      </NavButtonWrapper>
    </>
  );

  const SellerButtons = () => (
    <>
      <NavButtonWrapper toLink={`/${loggedInUsername}/`}>
        <svg
          width="20"
          height="18"
          viewBox="0 0 20 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M19.9 5.89L18.85 1.52C18.63 0.62 17.85 0 16.94 0H3.05001C2.15001 0 1.36001 0.63 1.15001 1.52L0.100007 5.89C-0.139993 6.91 0.0800067 7.95 0.720007 8.77C0.800007 8.88 0.910007 8.96 1.00001 9.06V16C1.00001 17.1 1.90001 18 3.00001 18H17C18.1 18 19 17.1 19 16V9.06C19.09 8.97 19.2 8.88 19.28 8.78C19.92 7.96 20.15 6.91 19.9 5.89ZM16.91 1.99L17.96 6.36C18.06 6.78 17.97 7.2 17.71 7.53C17.57 7.71 17.27 8 16.77 8C16.16 8 15.63 7.51 15.56 6.86L14.98 2L16.91 1.99ZM11 2H12.96L13.5 6.52C13.55 6.91 13.43 7.3 13.17 7.59C12.95 7.85 12.63 8 12.22 8C11.55 8 11 7.41 11 6.69V2ZM6.49001 6.52L7.04001 2H9.00001V6.69C9.00001 7.41 8.45001 8 7.71001 8C7.37001 8 7.06001 7.85 6.82001 7.59C6.57001 7.3 6.45001 6.91 6.49001 6.52ZM2.04001 6.36L3.05001 2H5.02001L4.44001 6.86C4.36001 7.51 3.84001 8 3.23001 8C2.74001 8 2.43001 7.71 2.30001 7.53C2.03001 7.21 1.94001 6.78 2.04001 6.36ZM3.00001 16V9.97C3.08001 9.98 3.15001 10 3.23001 10C4.10001 10 4.89001 9.64 5.47001 9.05C6.07001 9.65 6.87001 10 7.78001 10C8.65001 10 9.43001 9.64 10.01 9.07C10.6 9.64 11.4 10 12.3 10C13.14 10 13.94 9.65 14.54 9.05C15.12 9.64 15.91 10 16.78 10C16.86 10 16.93 9.98 17.01 9.97V16H3.00001Z"
            fill="#808488"
          />
        </svg>
      </NavButtonWrapper>
      <div className="nav-btn-separator" />
      <NavButtonWrapper toLink="/view/requests/">
        <RequestsIcon number={requestCount} />
      </NavButtonWrapper>
    </>
  );

  return (
    <Nav className={`main-nav-tabs ${className}`}>
      {isSeller ? <SellerButtons /> : <BuyerButtons />}
    </Nav>
  );
};
