import React from "react";
import { useTranslation } from "react-i18next";
import Linkify from "react-linkify";

const PrivacyPolicy = () => {
  const { t } = useTranslation();

  return (
    <div className="help__content">
      <h3 className="mb-10">{t("privacyPolicy-text-1")}</h3>
      <h3 className="mb-20">{t("privacyPolicy-text-2")}</h3>
      <p>
        <Linkify>{t("privacyPolicy-text-3")}</Linkify>
      </p>
      <p>
        <Linkify>{t("privacyPolicy-text-4")}</Linkify>
      </p>
      <p>
        <Linkify>{t("privacyPolicy-text-5")}</Linkify>
      </p>
      <p>
        <Linkify>{t("privacyPolicy-text-6")}</Linkify>
      </p>
      <p dangerouslySetInnerHTML={{ __html: t("privacyPolicy-text-7") }}></p>
      <p dangerouslySetInnerHTML={{ __html: t("privacyPolicy-text-8") }}></p>
      <p dangerouslySetInnerHTML={{ __html: t("privacyPolicy-text-9") }}></p>
      <p dangerouslySetInnerHTML={{ __html: t("privacyPolicy-text-10") }}></p>
      <p dangerouslySetInnerHTML={{ __html: t("privacyPolicy-text-11") }}></p>
      <p>
        <Linkify>{t("privacyPolicy-text-11-2")}</Linkify>
      </p>
      <p dangerouslySetInnerHTML={{ __html: t("privacyPolicy-text-12") }}></p>
      <p>
        <Linkify>{t("privacyPolicy-text-13")}</Linkify>
      </p>
      <p dangerouslySetInnerHTML={{ __html: t("privacyPolicy-text-15") }}></p>
      <p>
        <Linkify>{t("privacyPolicy-text-16")}</Linkify>
      </p>
      <p dangerouslySetInnerHTML={{ __html: t("privacyPolicy-text-17") }}></p>
      <ul>
        <Linkify>{t("privacyPolicy-text-18")}</Linkify>
        <li>
          <Linkify>{t("privacyPolicy-text-19")}</Linkify>
        </li>
        <li>
          <Linkify>{t("privacyPolicy-text-20")}</Linkify>
        </li>
        <li>
          <Linkify>{t("privacyPolicy-text-21")}</Linkify>
        </li>
        <li>
          <Linkify>{t("privacyPolicy-text-22")}</Linkify>
        </li>
        <li>
          <Linkify>{t("privacyPolicy-text-23")}</Linkify>
        </li>
      </ul>
      <p>
        <Linkify>{t("privacyPolicy-text-24")}</Linkify>
      </p>
      <p dangerouslySetInnerHTML={{ __html: t("privacyPolicy-text-25") }}></p>
      <p>
        <Linkify>{t("privacyPolicy-text-26")}</Linkify>
      </p>
      <p>
        <Linkify>{t("privacyPolicy-text-27")}</Linkify>
      </p>
      <p dangerouslySetInnerHTML={{ __html: t("privacyPolicy-text-28") }}></p>
      <p>
        <Linkify>{t("privacyPolicy-text-29")}</Linkify>
      </p>
      <p dangerouslySetInnerHTML={{ __html: t("privacyPolicy-text-30") }}></p>
      <p>
        <Linkify>{t("privacyPolicy-text-31")}</Linkify>
      </p>
      <p>
        <Linkify>{t("privacyPolicy-text-32")}</Linkify>
      </p>
      <p dangerouslySetInnerHTML={{ __html: t("privacyPolicy-text-33") }}></p>
      <p>
        <Linkify>{t("privacyPolicy-text-34")}</Linkify>
      </p>
      <p dangerouslySetInnerHTML={{ __html: t("privacyPolicy-text-35") }}></p>
      <p>
        <Linkify>{t("privacyPolicy-text-36")}</Linkify>
      </p>
      <p>
        <Linkify>{t("privacyPolicy-text-37")}</Linkify>
      </p>
      <p>
        <Linkify>{t("privacyPolicy-text-38")}</Linkify>
      </p>
      <p>
        <Linkify>{t("privacyPolicy-text-39")}</Linkify>
      </p>
      <p dangerouslySetInnerHTML={{ __html: t("privacyPolicy-text-40") }}></p>
      <p>
        <Linkify>{t("privacyPolicy-text-41")}</Linkify>
      </p>
      <p dangerouslySetInnerHTML={{ __html: t("privacyPolicy-text-42") }}></p>
      <p>
        <Linkify>{t("privacyPolicy-text-43")}</Linkify>
      </p>
      <p>
        <Linkify>{t("privacyPolicy-text-44")}</Linkify>
      </p>
      <p>
        <Linkify>{t("privacyPolicy-text-44-2")}</Linkify>
      </p>
      <p dangerouslySetInnerHTML={{ __html: t("privacyPolicy-text-45") }}></p>
      <p>
        <Linkify>{t("privacyPolicy-text-46")}</Linkify>
      </p>
      <p dangerouslySetInnerHTML={{ __html: t("privacyPolicy-text-47") }}></p>
      <p>
        <Linkify>{t("privacyPolicy-text-48")}</Linkify>
      </p>
      <p>
        <Linkify>{t("privacyPolicy-text-49")}</Linkify>
      </p>
      <p dangerouslySetInnerHTML={{ __html: t("privacyPolicy-text-50") }}></p>
      <p>
        <Linkify>{t("privacyPolicy-text-51")}</Linkify>
      </p>
      <p>
        <Linkify>{t("privacyPolicy-text-52")}</Linkify>
      </p>
    </div>
  );
};

export default PrivacyPolicy;

//<p dangerouslySetInnerHTML={{__html:t("privacyPolicy-text-8") }}></p>
