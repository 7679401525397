import { toUpper, startCase } from "lodash";
import {
  FOLLOW_FAILURE,
  FOLLOW_REQUEST_SENT,
  FOLLOW_SUCCESSFUL,
  GET_IS_FOLLOWING_FAILURE,
  GET_IS_FOLLOWING_REQUEST_SENT,
  GET_IS_FOLLOWING_SUCCESSFUL,
  GET_USER_FEED_BY_GROUP_FAILURE,
  GET_USER_FEED_BY_GROUP_REQUEST_SENT,
  GET_USER_FEED_BY_GROUP_SUCCESSFUL,
  GET_USER_FEED_FAILURE,
  GET_USER_FEED_REQUEST_SENT,
  GET_USER_FEED_SUCCESSFUL,
  GET_USER_INFO_FAILURE,
  GET_USER_INFO_REQUEST_SENT,
  GET_USER_INFO_SUCCESSFUL,
  GET_USER_INSTA_POSTS_FAILURE,
  GET_USER_INSTA_POSTS_REQUEST_SENT,
  GET_USER_INSTA_POSTS_SUCCESSFUL,
  UNFOLLOW_FAILURE,
  UNFOLLOW_REQUEST_SENT,
  UPDATE_ORDER_STATUS_SUCCESS,
  UNFOLLOW_SUCCESSFUL,
  GET_USER_REQUESTED_ORDERS_SENT,
  GET_USER_REQUESTED_ORDERS_SUCCESSFUL,
  GET_USER_REQUESTED_ORDERS_FAILURE,
} from "./constants";

import getOrderStatuses from "../../Helpers/getOrderStatuses";

const initialState = {
  loading: false,
  errorMessage: null,
  userInstaPosts: [],
  userFeed: [],
  userinfo: null,
  isFollowing: false,
  total: 0,
  userRequestedOrders: [],
};

const profileReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_USER_REQUESTED_ORDERS_SENT:
      return {
        ...state,
        loading: true,
        errorMessage: null,
      };
    case GET_USER_INFO_REQUEST_SENT:
      return {
        ...state,
        loading: true,
        errorMessage: null,
      };
    case GET_USER_INFO_SUCCESSFUL:
      return {
        ...state,
        loading: false,
        errorMessage: null,
        userinfo: action.payload,
      };
    case GET_USER_REQUESTED_ORDERS_SUCCESSFUL:
      return {
        ...state,
        loading: false,
        errorMessage: null,
        ...action.payload,
      };
    case GET_USER_FEED_REQUEST_SENT:
      return {
        ...state,
        loading: true,
        errorMessage: null,
      };
    case GET_USER_FEED_SUCCESSFUL:
      return {
        ...state,
        loading: false,
        errorMessage: null,
        userFeed: action.payload,
      };
    case GET_USER_INSTA_POSTS_REQUEST_SENT:
      return {
        ...state,
        loading: true,
        errorMessage: null,
      };
    case GET_USER_INSTA_POSTS_SUCCESSFUL:
      return {
        ...state,
        loading: false,
        errorMessage: null,
        userInstaPosts: action.payload,
      };
    case GET_USER_FEED_BY_GROUP_REQUEST_SENT:
      return {
        ...state,
        loading: true,
        errorMessage: null,
      };
    case GET_USER_FEED_BY_GROUP_SUCCESSFUL:
      return {
        ...state,
        loading: false,
        userFeed: action.payload,
      };
    case GET_IS_FOLLOWING_SUCCESSFUL:
      return {
        ...state,
        isFollowing: action.payload.isFollowing,
      };
    case GET_IS_FOLLOWING_REQUEST_SENT:
      return {
        ...state,
        loading: true,
        errorMessage: null,
      };
    case UNFOLLOW_REQUEST_SENT:
      return {
        ...state,
        loading: true,
        errorMessage: null,
      };
    case UNFOLLOW_SUCCESSFUL:
      return {
        ...state,
        isFollowing: false,
        loading: false,
      };
    case FOLLOW_REQUEST_SENT:
      return {
        ...state,
        loading: true,
        errorMessage: null,
      };
    case FOLLOW_SUCCESSFUL:
      return {
        ...state,
        isFollowing: true,
        loading: false,
      };
    case UPDATE_ORDER_STATUS_SUCCESS:
      return {
        ...state,
        userRequestedOrders: state.userRequestedOrders.map((order) => {
          if (action.payload.orderId === order.orderId) {
            const statusInfo = getOrderStatuses.find(
              ({ id }) => action.payload.statusId === id,
            );
            return {
              ...order,
              status: {
                statusId: action.payload.statusId,
                status: toUpper(startCase(statusInfo.name)),
              },
              orderPayoutDate:action.payload.orderPayoutDate,
            };
          }
          return order;
        }),
      };
    case FOLLOW_FAILURE:
    case GET_USER_REQUESTED_ORDERS_FAILURE:
    case GET_USER_FEED_FAILURE:
    case GET_USER_INFO_FAILURE:
    case GET_USER_INSTA_POSTS_FAILURE:
    case GET_IS_FOLLOWING_FAILURE:
    case GET_USER_FEED_BY_GROUP_FAILURE:
    case UNFOLLOW_FAILURE:
      return {
        ...state,
        loading: false,
        errorMessage: action.message,
      };
    default:
      return state;
  }
};

export default profileReducer;
