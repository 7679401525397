import "./BackWithTitle.scss";
import PropTypes from "prop-types";

import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export function BackWithTitle({ backBtnLink, titleText }) {
  const { t } = useTranslation();

  return (
    <h2 className="d-inline-block">
      <Link
        className="d-flex align-items-center justify-center text-decoration-none"
        to={backBtnLink || "/"}
      >
        <img
          src="/assets/images/icon-back.svg"
          className="d-flex back-arrow-btn"
          alt="back icon"
        />
        {t(titleText)}
      </Link>
    </h2>
  );
}
BackWithTitle.propTypes = {
  titleText: PropTypes.string.isRequired,
  backBtnLink: PropTypes.string,
};

BackWithTitle.defaultProps = {
  backBtnLink: "",
};
