import { fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";

import { API_HOST } from "./constants";

const baseQuery = fetchBaseQuery({
  baseUrl: API_HOST,
  prepareHeaders: (headers) => {
    headers.set("Accept", "application/json");
    headers.set("Content-Type", "application/json");
    return headers;
  },
});

export default baseQuery;
