import axios from "axios";
import { toast } from "react-toastify";

import { BASEURL, BASEURL_PAYMENT } from "../../Configs/constants";
import {
  DELETE_USER_CART_PRODUCT_FAILURE,
  DELETE_USER_CART_PRODUCT_REQUEST_SENT,
  DELETE_USER_CART_PRODUCT_SUCCESSFUL,
  DISPATCH_NULL_ROUTE,
  GET_CITIES_BY_COUNTRY_FAILURE,
  GET_CITIES_BY_COUNTRY_SENT,
  GET_CITIES_BY_COUNTRY_SUCCESSFUL,
  GET_CITIES_COURIER_FAILURE,
  GET_CITIES_COURIER_SENT,
  GET_CITIES_COURIER_SUCCESSFUL,
  GET_CITIES_SELLER_SHOPS_FAILURE,
  GET_CITIES_SELLER_SHOPS_SENT,
  GET_CITIES_SELLER_SHOPS_SUCCESSFUL,
  GET_COUNTRIES_COURIER_FAILURE,
  GET_COUNTRIES_COURIER_SENT,
  GET_COUNTRIES_COURIER_SUCCESSFUL,
  GET_COUNTRIES_SELLER_SHOPS_FAILURE,
  GET_COUNTRIES_SELLER_SHOPS_SENT,
  GET_COUNTRIES_SELLER_SHOPS_SUCCESSFUL,
  GET_LIQPAY_FAILURE,
  GET_LIQPAY_REQUEST_SENT,
  GET_LIQPAY_SUCCESSFULL,
  GET_LOCAL_COUNTRIES_FAILURE,
  GET_LOCAL_COUNTRIES_SENT,
  GET_LOCAL_COUNTRIES_SUCCESSFUL,
  GET_LOCAL_WAREHOUSES_FAILURE,
  GET_LOCAL_WAREHOUSES_REQUEST_SENT,
  GET_LOCAL_WAREHOUSES_SUCCESSFULL,
  GET_SELLER_COURIER_FAILURE,
  GET_SELLER_COURIER_REQUEST_SENT,
  GET_SELLER_COURIER_SUCCESSFULL,
  GET_SELLER_COURIER_REQUEST_FINISH,
  GET_SELLER_LOCAL_FAILURE,
  GET_SELLER_LOCAL_REQUEST_SENT,
  GET_SELLER_LOCAL_SUCCESSFULL,
  GET_SELLER_SHOPS_FAILURE,
  GET_SELLER_SHOPS_REQUEST_SENT,
  GET_SELLER_SHOPS_SUCCESSFULL,
  GET_TOTAL_LOCAL_FAILURE,
  GET_TOTAL_LOCAL_REQUEST_SENT,
  GET_TOTAL_LOCAL_SUCCESSFULL,
  GET_HAS_USER_BUYER_DELIVERIES_FAILURE,
  GET_HAS_USER_BUYER_DELIVERIES_REQUEST_SENT,
  GET_HAS_USER_BUYER_DELIVERIES_SUCCESSFUL,
  GET_USER_CART_PRODUCTS_FAILURE,
  GET_USER_CART_PRODUCTS_REQUEST_SENT,
  GET_USER_CART_PRODUCTS_SUCCESSFUL,
  POST_USERS_CART_ORDER_FAILURE,
  POST_USERS_CART_ORDER_REQUEST_SENT,
  POST_USERS_CART_ORDER_SUCCESSFUL,
  SET_DELIVERY_INFORMATION,
  SET_USER_CART_PRODUCTS_DELIVERY,
  UPDATE_USER_CART_PRODUCT_FAILURE,
  UPDATE_USER_CART_PRODUCT_REQUEST_SENT,
  UPDATE_USER_CART_PRODUCT_SUCCESSFUL,
} from "./constants";
import { getAccessToken2 } from "../../Helpers/auth";

export const postUsersCartOrder = (payload) => async (dispatch) => {
  try {
    dispatch({
      type: POST_USERS_CART_ORDER_REQUEST_SENT,
    });
    const resp = await axios.post(`${BASEURL}/api/web/sellsgram/cart/`, payload, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: getAccessToken2(),
      },
    });
    dispatch({
      type: POST_USERS_CART_ORDER_SUCCESSFUL,
      payload: resp.data,
    });
    return resp.data;
  } catch (err) {
    dispatch({
      type: POST_USERS_CART_ORDER_FAILURE,
      message: err.response?.data ? err.response.data.title : err.message,
    });
  }
};

export const getUserCartProducts = (userId) => async (dispatch) => {
  try {
    dispatch({
      type: GET_USER_CART_PRODUCTS_REQUEST_SENT,
    });
    const resp = await axios.get(`${BASEURL}/api/web/sellsgram/cart/by-user/${userId}`, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: getAccessToken2(),
      },
    });
    dispatch({
      type: GET_USER_CART_PRODUCTS_SUCCESSFUL,
      payload: resp.data,
    });
  } catch (err) {
    dispatch({
      type: GET_USER_CART_PRODUCTS_FAILURE,
      message: err.response?.data ? err.response.data.title : err.message,
    });
  }
};

export const deleteUserCartProduct =
  (orderId, getUserCartProducts, userId, t) => async (dispatch) => {
    try {
      dispatch({
        type: DELETE_USER_CART_PRODUCT_REQUEST_SENT,
      });
      const resp = await axios.delete(`${BASEURL}/api/web/sellsgram/cart/${orderId}`, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: getAccessToken2(),
        },
      });
      dispatch({
        type: DELETE_USER_CART_PRODUCT_SUCCESSFUL,
        payload: resp.data,
      });
      toast.success(t("product-deleted"));

      getUserCartProducts(userId);
    } catch (err) {
      toast.error(err.response.data);
      dispatch({
        type: DELETE_USER_CART_PRODUCT_FAILURE,
        message: err.response?.data ? err.response.data.title : err.message,
      });
    }
  };

export const updateUserCartProduct =
  (payload, orderId, getUserCartProducts, userId, t) => async (dispatch) => {
    try {
      dispatch({
        type: UPDATE_USER_CART_PRODUCT_REQUEST_SENT,
      });
      const resp = await axios.put(
        `${BASEURL}/api/web/sellsgram/cart/${orderId}`,
        payload,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: getAccessToken2(),
          },
        },
      );
      dispatch({
        type: UPDATE_USER_CART_PRODUCT_SUCCESSFUL,
        payload: resp.data,
      });
      toast.success(t("product-updated"));
      getUserCartProducts(userId);
    } catch (err) {
      toast.error(err.response.data);

      dispatch({
        type: UPDATE_USER_CART_PRODUCT_FAILURE,
        message: err.response?.data ? err.response.data.title : err.message,
      });
    }
  };

export const getCitiesbyCountry = (country) => async (dispatch) => {
  try {
    dispatch({
      type: GET_CITIES_BY_COUNTRY_SENT,
    });
    const resp = await axios.get(
      `${BASEURL}/api/metadata/delivery/cities/by-country/${country}`,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: getAccessToken2(),
        },
        params: {
          languageId: localStorage.getItem("language") === "ua" ? 1 : 217,
        },
      },
    );
    dispatch({
      type: GET_CITIES_BY_COUNTRY_SUCCESSFUL,
      payload: resp.data,
    });
  } catch (err) {
    dispatch({
      type: GET_CITIES_BY_COUNTRY_FAILURE,
      message: err.response?.data ? err.response.data.title : err.message,
    });
  }
};

export const postSellerShop = (payload, getSellerShops) => async (dispatch) => {
  try {
    const { data } = await axios.post(`${BASEURL}/api/sellsgram/seller/shop`, payload, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: getAccessToken2(),
      },
    });
    getSellerShops(payload.sellerUserId);
  } catch (err) {
    dispatch({
      type: GET_SELLER_SHOPS_FAILURE,
      message: err.response?.data ? err.response.data.title : err.message,
    });
  }
};

export const postSellerCourierList =
  (payload, getSellerCourierList) => async (dispatch) => {
    dispatch({
      type: GET_SELLER_COURIER_REQUEST_SENT,
    });
    await axios.post(
      `${BASEURL}/api/sellsgram/seller/delivery/courier/all`,
      payload,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: getAccessToken2(),
        },
      },
    );
    await getSellerCourierList(payload[0].sellerUserId);
    dispatch({
      type: GET_SELLER_COURIER_REQUEST_FINISH,
    });
  };

export const postSellerLocalDelivery =
  (payload, getSellerLocalDeliveries) => async (dispatch) => {
    try {
      const { data } = await axios.post(
        `${BASEURL}/api/sellsgram/seller/delivery/local/all`,
        payload,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: getAccessToken2(),
          },
        },
      );
      getSellerLocalDeliveries(payload[0].sellerUserId, payload[0].countryId);
    } catch (err) {
      console.log(err.message);
    }
  };

export const getSellerLocalDeliveries = (sellerId, countryId) => async (dispatch) => {
  try {
    dispatch({
      type: GET_SELLER_LOCAL_REQUEST_SENT,
    });
    const resp = await axios.get(
      `${BASEURL}/api/sellsgram/seller/delivery/local/with-local-deliveries/${sellerId}?countryId=${countryId}`,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: getAccessToken2(),
        },
      },
    );
    dispatch({
      type: GET_SELLER_LOCAL_SUCCESSFULL,
      payload: resp.data,
    });
  } catch (err) {
    dispatch({
      type: GET_SELLER_LOCAL_FAILURE,
      message: err.response?.data ? err.response.data.title : err.message,
    });
  }
};

export const getLocalWarehouses =
  (localDeliveryId, cityId, pageNum, filter) => async (dispatch) => {
    try {
      dispatch({
        type: GET_LOCAL_WAREHOUSES_REQUEST_SENT,
      });
      const resp = await axios.get(
        `${BASEURL}/api/metadata/delivery/DeliveryWarehouses/paginated/${localDeliveryId}?cityId=${cityId}&page=${pageNum}&pageSize=${200}&filter=${filter}`,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: getAccessToken2(),
          },
          params: {
            languageId: localStorage.getItem("language") === "ua" ? 1 : 217,
          },
        },
      );
      dispatch({
        type: GET_LOCAL_WAREHOUSES_SUCCESSFULL,
        payload: resp.data,
      });
    } catch (err) {
      dispatch({
        type: GET_LOCAL_WAREHOUSES_FAILURE,
        message: err.response?.data ? err.response.data.title : err.message,
      });
    }
  };

export const getLiqPayData = (payload) => async (dispatch) => {
  try {
    dispatch({
      type: GET_LIQPAY_REQUEST_SENT,
    });
    const resp = await axios.post(`${BASEURL_PAYMENT}/liqpay`, payload, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: getAccessToken2(),
      },
    });
    dispatch({
      type: GET_LIQPAY_SUCCESSFULL,
      payload: resp.data,
    });
  } catch (err) {
    dispatch({
      type: GET_LIQPAY_FAILURE,
      message: err.response?.data ? err.response.data.title : err.message,
    });
  }
};

export const getSellerCourierList = (sellerId) => async (dispatch) => {
  try {
    dispatch({
      type: GET_SELLER_COURIER_REQUEST_SENT,
    });
    const resp = await axios.get(
      `${BASEURL}/api/sellsgram/seller/delivery/courier/all/${sellerId}`,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: getAccessToken2(),
        },
        params: {
          languageId: localStorage.getItem("language") === "ua" ? 1 : 217,
        },
      },
    );
    dispatch({
      type: GET_SELLER_COURIER_SUCCESSFULL,
      payload: resp.data,
    });
  } catch (err) {
    dispatch({
      type: GET_SELLER_COURIER_FAILURE,
      message: err.response?.data ? err.response.data.title : err.message,
    });
  }
};

export const getSellerShops = (sellerId) => async (dispatch) => {
  try {
    dispatch({
      type: GET_SELLER_SHOPS_REQUEST_SENT,
    });
    const resp = await axios.get(`${BASEURL}/api/sellsgram/seller/shop/all/${sellerId}`, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: getAccessToken2(),
      },
      params: {
        languageId: localStorage.getItem("language") === "ua" ? 1 : 217,
      },
    });
    dispatch({
      type: GET_SELLER_SHOPS_SUCCESSFULL,
      payload: resp.data,
    });
  } catch (err) {
    dispatch({
      type: GET_SELLER_SHOPS_FAILURE,
      message: err.response?.data ? err.response.data.title : err.message,
    });
  }
};

export const getLocalDeliveriesByCountry = (countryId) => async (dispatch) => {
  try {
    dispatch({
      type: GET_TOTAL_LOCAL_REQUEST_SENT,
    });
    const resp = await axios.get(
      `${BASEURL}/api/metadata/delivery/_LocalDelivery/by_country/${countryId}`,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: getAccessToken2(),
        },
      },
    );
    dispatch({
      type: GET_TOTAL_LOCAL_SUCCESSFULL,
      payload: resp.data,
    });
  } catch (err) {
    dispatch({
      type: GET_TOTAL_LOCAL_FAILURE,
      message: err.response?.data ? err.response.data.title : err.message,
    });
  }
};

export const deleteSellerShop = (sellerShopId, getSellerShops) => async () => {
  const resp = await axios.delete(
    `${BASEURL}/api/sellsgram/seller/shop/${sellerShopId}`,
    {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: getAccessToken2(),
      },
    },
  );
  getSellerShops(sellerShopId);
};

export const deleteDeliveryCourier =
  (cityId, sellerUserId, getSellerCourierList) => async (dispatch) => {
    dispatch({
      type: GET_SELLER_COURIER_REQUEST_SENT,
    });
    await axios.delete(
      `${BASEURL}/api/sellsgram/seller/delivery/courier/${sellerUserId}?cityId=${cityId}`,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: getAccessToken2(),
        },
      },
    );
    await getSellerCourierList(sellerUserId);
    dispatch({
      type: GET_SELLER_COURIER_REQUEST_FINISH,
    });
  };

export const deleteDeliveryLocal =
  (localDeliveryId, sellerUserId, countryId, getSellerLocalDeliveries) => async () => {
    await axios.delete(
      `${BASEURL}/api/sellsgram/seller/delivery/local/${sellerUserId}?localDeliveryId=${localDeliveryId}`,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: getAccessToken2(),
        },
      },
    );

    getSellerLocalDeliveries(sellerUserId, countryId);
  };

export const postUserBuyerDeliveries = (payload) => async () => {
  console.log("payload -> ", payload);
  const resp = await axios.post(`${BASEURL}/api/sellsgram/buyer/deliveries`, payload, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: getAccessToken2(),
    },
  });
  console.log("resp -> ", resp);
};

export const getCheckIsEnoughQuantity = (orderId) => async () => {
  try {
    const res = await axios.get(
      `${BASEURL}/api/web/sellsgram/product/check-order-quantity/${orderId}`,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: getAccessToken2(),
        },
      },
    );
    if (res.status === 200) {
      // test for status you want, etc
      return [true, ""];
    }
    // Don't forget to return something

    return [false, res.data];
  } catch (err) {
    return [false, err.response.data];
  }
};

export const pay = (payload) => async () =>
  await axios
    .post(`${BASEURL}/api/sellsgram/buyer/payment/pay`, payload, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: getAccessToken2(),
      },
    })
    .then()
    .catch((err) => {
      toast.error(err.response.data);
    });

export const getCourierCountries = (sellerUserId) => async (dispatch) => {
  try {
    dispatch({
      type: GET_COUNTRIES_COURIER_SENT,
    });
    const resp = await axios.get(
      `${BASEURL}/api/sellsgram/seller/delivery/courier/countries/${sellerUserId}`,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: getAccessToken2(),
        },
        params: {
          languageId: localStorage.getItem("language") === "ua" ? 1 : 217,
        },
      },
    );
    dispatch({
      type: GET_COUNTRIES_COURIER_SUCCESSFUL,
      payload: resp.data,
    });
  } catch (err) {
    dispatch({
      type: GET_COUNTRIES_COURIER_FAILURE,
      message: err.response?.data ? err.response.data.title : err.message,
    });
  }
};

export const getSellerShopsCountries = (sellerUserId) => async (dispatch) => {
  try {
    dispatch({
      type: GET_COUNTRIES_SELLER_SHOPS_SENT,
    });
    const resp = await axios.get(
      `${BASEURL}/api/sellsgram/seller/shop/countries/${sellerUserId}`,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: getAccessToken2(),
        },
        params: {
          languageId: localStorage.getItem("language") === "ua" ? 1 : 217,
        },
      },
    );
    dispatch({
      type: GET_COUNTRIES_SELLER_SHOPS_SUCCESSFUL,
      payload: resp.data,
    });
  } catch (err) {
    dispatch({
      type: GET_COUNTRIES_SELLER_SHOPS_FAILURE,
      message: err.response?.data ? err.response.data.title : err.message,
    });
  }
};

export const getCourierCities = (sellerUserId, countryId) => async (dispatch) => {
  try {
    dispatch({
      type: GET_CITIES_COURIER_SENT,
    });
    const resp = await axios.get(
      `${BASEURL}/api/sellsgram/seller/delivery/courier/cities/${sellerUserId}?countryID=${countryId}`,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: getAccessToken2(),
        },
        params: {
          languageId: localStorage.getItem("language") === "ua" ? 1 : 217,
        },
      },
    );
    dispatch({
      type: GET_CITIES_COURIER_SUCCESSFUL,
      payload: resp.data,
    });
  } catch (err) {
    dispatch({
      type: GET_CITIES_COURIER_FAILURE,
      message: err.response?.data ? err.response.data.title : err.message,
    });
  }
};

export const getSellerShopsCities = (sellerUserId, countryId) => async (dispatch) => {
  try {
    dispatch({
      type: GET_CITIES_SELLER_SHOPS_SENT,
    });
    const resp = await axios.get(
      `${BASEURL}/api/sellsgram/seller/shop/cities/${sellerUserId}?countryID=${countryId}`,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: getAccessToken2(),
        },
        params: {
          languageId: localStorage.getItem("language") === "ua" ? 1 : 217,
        },
      },
    );
    dispatch({
      type: GET_CITIES_SELLER_SHOPS_SUCCESSFUL,
      payload: resp.data,
    });
  } catch (err) {
    dispatch({
      type: GET_CITIES_SELLER_SHOPS_FAILURE,
      message: err.response?.data ? err.response.data.title : err.message,
    });
  }
};

export const getLocalDeliveriesCountries = (sellerUserId) => async (dispatch) => {
  try {
    dispatch({
      type: GET_LOCAL_COUNTRIES_SENT,
    });
    const resp = await axios.get(
      `${BASEURL}/api/sellsgram/seller/delivery/local/countries/${sellerUserId}`,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: getAccessToken2(),
        },
        params: {
          languageId: localStorage.getItem("language") === "ua" ? 1 : 217,
        },
      },
    );
    dispatch({
      type: GET_LOCAL_COUNTRIES_SUCCESSFUL,
      payload: resp.data,
    });
  } catch (err) {
    dispatch({
      type: GET_LOCAL_COUNTRIES_FAILURE,
      message: err.response?.data ? err.response.data.title : err.message,
    });
  }
};

export const dispatchNullRoute = () => async (dispatch) => {
  dispatch({
    type: DISPATCH_NULL_ROUTE,
  });
};

export const setProductsDelivery = (deliveryInfo, productId) => (dispatch) => {
  dispatch({
    type: SET_USER_CART_PRODUCTS_DELIVERY,
    payload: { deliveryInfo, productId },
  });
};

export const setDeliveryInformation = (delivery) => (dispatch) => {
  dispatch({
    type: SET_DELIVERY_INFORMATION,
    payload: { delivery },
  });
};

export const getHasUserBuyerDeliveries = (buyerUserId) => async (dispatch) => {
  try {
    dispatch({
      type: GET_HAS_USER_BUYER_DELIVERIES_REQUEST_SENT,
    });
    const resp = await axios.get(
      `${BASEURL}/api/web/sellsgram/cart/check/${buyerUserId}`,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: getAccessToken2(),
        },
      },
    );
    dispatch({
      type: GET_HAS_USER_BUYER_DELIVERIES_SUCCESSFUL,
      payload: resp.data,
    });
  } catch (err) {
    dispatch({
      type: GET_HAS_USER_BUYER_DELIVERIES_FAILURE,
      message: err.response?.data ? err.response.data.title : err.message,
    });
  }
};
