import "./Ig.css";

import PropTypes from "prop-types";
import React, { useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";

import { getProfileByUsername } from "../../Redux/UserProfile/actions";
import UserPage from "../UserPage";
import { RequestHandler } from "../../Components/RequestHandler/RequestHandler";
import BuyerPage from "../BuyerPage/BuyerPage";
import { getBuyerFeed, getBuyerGeneralInfo } from "../../Redux/Buyer/actions";
import { throttle } from "lodash";

const IG = (props) => {
  const {
    getProfileByUsername,
    errorMessage,
    loading,
    userProfile,
    authUser,
    getBuyerGeneralInfo,
    getBuyerFeed,
    userId,
  } = props;
  const [page, setPage] = useState(1);
  const [filter, setFilter] = useState(0);
  const [searchText, setSearchText] = useState("");

  const username = props.match?.params?.username;
  const history = useHistory();
  React.useEffect(() => {
    if (username) getProfileByUsername(username);
  }, [username]);

  React.useEffect(() => {
    if (!userId) return;
    getBuyerGeneralInfo(userId);
  }, []);

  const debouncedGetBuyerFeed = React.useCallback(
    throttle((userId, page, filter, searchText) => {
      getBuyerFeed(userId, page, filter, searchText);
    }, 700),
    [],
  );
  
  React.useEffect(() => {
    if (!userId) return;
    debouncedGetBuyerFeed(userId, page, filter, searchText);
  }, [page, filter]);

  if (errorMessage === "Not Found") history.push("/404/");

  const isAuthUserPage = userProfile?.userId === authUser?.userId;
  const showShop = isAuthUserPage
    ? authUser?.accountState?.accountStateId === 1
    : userProfile.hasShop;
    
  return (
    <RequestHandler isLoading={loading}>
      {showShop ? (
        <UserPage {...props} />
      ) : (
        <BuyerPage
          page={page}
          setPage={setPage}
          setFilter={setFilter}
          filter={filter}
          searchText={searchText}
          debouncedGetBuyerFeed={() =>
            debouncedGetBuyerFeed(userId, page, filter, searchText)
          }
          setSearchText={setSearchText}
          {...props}
        />
      )}
    </RequestHandler>
  );
};

IG.propTypes = {
  getProfileByUsername: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
  userProfile: PropTypes.object.isRequired,
  authUser: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  userId: state.auth.userId,
  errorMessage: state.userProfile.errorMessage,
  loading: state.userProfile.loading,
  userProfile: state.userProfile,
  authUser: state.auth,
});

export default connect(mapStateToProps, {
  getProfileByUsername,
  getBuyerGeneralInfo,
  getBuyerFeed,
})(IG);
