import React from "react";
import "./RequestsIconStyle.css";

export const RequestsIcon = ({ number = 0, className = "" }) => {
  return (
    <div className={`notification ${className}`}>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 4.16667C0 1.86548 1.86548 0 4.16667 0H15.8333C18.1345 0 20 1.86548 20 4.16667V13.3333C20 17.0152 17.0152 20 13.3333 20H4.16667C1.86548 20 0 18.1345 0 15.8333V4.16667ZM4.16667 1.66667C2.78595 1.66667 1.66667 2.78595 1.66667 4.16667V15.8333C1.66667 17.214 2.78595 18.3333 4.16667 18.3333H13.3333C16.0948 18.3333 18.3333 16.0948 18.3333 13.3333V4.16667C18.3333 2.78595 17.214 1.66667 15.8333 1.66667H4.16667Z"
          fill="#808488"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.1665 5.83333C4.1665 5.3731 4.5396 5 4.99984 5H14.9998C15.4601 5 15.8332 5.3731 15.8332 5.83333C15.8332 6.29357 15.4601 6.66667 14.9998 6.66667H4.99984C4.5396 6.66667 4.1665 6.29357 4.1665 5.83333Z"
          fill="#808488"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.1665 10.0001C4.1665 9.53984 4.5396 9.16675 4.99984 9.16675H14.9998C15.4601 9.16675 15.8332 9.53984 15.8332 10.0001C15.8332 10.4603 15.4601 10.8334 14.9998 10.8334H4.99984C4.5396 10.8334 4.1665 10.4603 4.1665 10.0001Z"
          fill="#808488"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.1665 14.1666C4.1665 13.7063 4.5396 13.3333 4.99984 13.3333H9.99984C10.4601 13.3333 10.8332 13.7063 10.8332 14.1666C10.8332 14.6268 10.4601 14.9999 9.99984 14.9999H4.99984C4.5396 14.9999 4.1665 14.6268 4.1665 14.1666Z"
          fill="#808488"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.2498 14.5834C15.3294 14.5834 14.5832 15.3296 14.5832 16.2501V19.1667C14.5832 19.627 14.2101 20.0001 13.7498 20.0001C13.2896 20.0001 12.9165 19.627 12.9165 19.1667V16.2501C12.9165 14.4091 14.4089 12.9167 16.2498 12.9167H19.1665C19.6267 12.9167 19.9998 13.2898 19.9998 13.7501C19.9998 14.2103 19.6267 14.5834 19.1665 14.5834H16.2498Z"
          fill="#808488"
        />
      </svg>
      {number > 0 && <span className="badge">{number}</span>}
    </div>
  );
};
