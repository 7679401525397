import React from "react";
import { useTranslation } from "react-i18next";
import "./StatusCell.scss";
import getOrderStatuses from "../../../../Helpers/getOrderStatuses";

const DEFAULT_STATUS_PAYOUTS = parseInt(process.env.DEFAULT_STATUS_PAYOUTS) || 99;

export default ({ statusId, orderPayoutDate }) => {
  const { t } = useTranslation();
  let status = getOrderStatuses.find(({ id }) => statusId === id);
  if (!status) {
    status = getOrderStatuses.find(({ id }) => DEFAULT_STATUS_PAYOUTS === id);
  }
  return (
    <div className="status-cell">
      <div className="status-block">
        <span
          className="dot-status"
          style={{
            backgroundColor: status?.color || "black",
          }}
        ></span>
        <span>{t(status.name)}</span>
      </div>
      {orderPayoutDate &&
        !orderPayoutDate.isReadyForPayout &&
        orderPayoutDate.message && (
          <div className="status-date-cell">{orderPayoutDate.message}</div>
        )}
    </div>
  );
};
