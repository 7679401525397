import "./DeliveryLocationInfo.scss";
import { memoize } from "lodash";
import React, { useEffect } from "react";
import { uniqBy } from "lodash";
import Sentry from "react-activity/dist/Sentry";
import Button from "react-bootstrap/Button";
import Dropdown from "react-bootstrap/Dropdown";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import CustomMenu from "../../../Pages/Delivery/utils/CustomMenu";
import { getSellerShops } from "../../../Redux/Cart/actions";

const DeliveryLocationInfo = ({
  getSellerShops,
  loadingSellerShops,
  sellerShops,
  sellerUserId,
  delivery,
  onChange,
  disabled,
}) => {
  const { i18n, t } = useTranslation();
  useEffect(() => {
    getSellerShops(sellerUserId);
  }, [i18n.language]);
  return (
    <div className="d-flex flex-sm-row flex-column mb-2 gap-2">
      <div className="flex-one">
        <Dropdown
          onSelect={(c) => {
            const { cityName, cityId } = JSON.parse(c);
            onChange({
              city: {
                name: cityName,
                id: cityId,
              },
              shop: {},
            });
          }}
        >
          <Dropdown.Toggle
            className="w-100 align-items-center"
            as={Button}
            variant="secondary"
            id="truncateLongTexts"
            disabled={disabled}
          >
            {sellerShops?.find((c) => c.cityId === delivery.city.id)?.cityName ||
              t("selectCity")}
          </Dropdown.Toggle>

          <Dropdown.Menu as={CustomMenu} disabled={loadingSellerShops}>
            {loadingSellerShops ? (
              <Dropdown.Item>
                <Sentry color="#4B85DC" size={100} speed={0.5} animating={true} />
              </Dropdown.Item>
            ) : sellerShops.length > 0 ? (
              uniqBy(sellerShops, "cityId").map((c) => (
                <Dropdown.Item eventKey={JSON.stringify(c)} key={c.cityId}>
                  {c.cityName}
                </Dropdown.Item>
              ))
            ) : (
              <Dropdown.Item active={false}>{t("noShopsFound")}</Dropdown.Item>
            )}
          </Dropdown.Menu>
        </Dropdown>
      </div>
      <div className="flex-one" style={{ marginBottom: "1em" }}>
        <Dropdown
          onSelect={(s) => {
            const shopObj = JSON.parse(s);
            onChange({
              shop: shopObj,
            });
          }}
        >
          <Dropdown.Toggle
            className="w-100 align-items-center"
            as={Button}
            variant="secondary"
            disabled={!delivery.city.id}
          >
            {sellerShops?.find((c) => c.sellerShopId === delivery.shop.sellerShopId)
              ?.shopName || t("selectShop")}
          </Dropdown.Toggle>

          <Dropdown.Menu as={CustomMenu} disabled={loadingSellerShops}>
            {loadingSellerShops ? (
              <Dropdown.Item>
                <Sentry color="#4B85DC" size={100} speed={0.5} animating={true} />
              </Dropdown.Item>
            ) : sellerShops?.length > 0 &&
              sellerShops.filter(({ cityName }) => cityName === delivery.city.name)
                ?.length > 0 ? (
              sellerShops
                .filter(({ cityName }) => cityName === delivery.city.name)
                .map((s) => (
                  <Dropdown.Item eventKey={JSON.stringify(s)} key={s.sellerShopId}>
                    <label>{s.shopName}</label>
                    <p>{s.shopAddress}</p>
                  </Dropdown.Item>
                ))
            ) : (
              <Dropdown.Item active={false}>{t("noShopsFound")}</Dropdown.Item>
            )}
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  loadingSellerShops: state.cart.loadingSellerShops,
  sellerShops: state.cart.sellerShops,
});

export default connect(mapStateToProps, {
  getSellerShops,
})(DeliveryLocationInfo);
