import { configureStore } from "@reduxjs/toolkit";
import { persistStore } from "redux-persist";
import thunk from "redux-thunk";

import { subscriptionsApi } from "../Api/subscriptions";
import { cartApi } from "../Api/cartCountApi";
import { deliveryApi } from "../Api/deliveryApi";
import rootReducer from "./reducers";

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ["persist/PERSIST"],
      },
    }).concat([
      subscriptionsApi?.middleware,
      cartApi?.middleware,
      deliveryApi?.middleware,
      thunk,
    ]),
  devTools: true,
});

export const persistor = persistStore(store);
