import "./InfoStyle.scss";

import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import About from "./Components/About";
import Delivery from "./Components/Delivery";
import PrivacyPolicy from "./Components/PrivacyPolicy";
import TermsOfUse from "./Components/TermsOfUse";

const TAB_OPTIONS = [
  { title: "privacyPolicy", Component: PrivacyPolicy },
  { title: "termsOfUse", Component: TermsOfUse },
  { title: "deliveryAndPayment", Component: Delivery },
  { title: "about", Component: About },
];

const getInitialValue = (pathname) => {
  if (pathname.includes("?about")) {
    return 3;
  } else if (pathname.includes("?privacy")) {
    return 0;
  } else if (pathname.includes("?payment")) {
    return 2;
  } else if (pathname.includes("?terms-of-use")) {
    return 1;
  } else {
    return 0;
  }
};

const Info = () => {
  const location = useLocation();

  const { t } = useTranslation();

  const defaultActiveTabValue =
    getInitialValue(location.pathname) === 0
      ? getInitialValue(location.search) === 0
        ? 0
        : getInitialValue(location.search)
      : getInitialValue(location.pathname);
  const [activeTab, setActiveTab] = useState(defaultActiveTabValue);

  const title = TAB_OPTIONS[activeTab].title;
  const Content = TAB_OPTIONS[activeTab].Component;

  useEffect(() => {
    setActiveTab(defaultActiveTabValue);
  }, [location]);

  return (
    <div className="help__wrapper">
      <h1>{t(title)}</h1>

      <div className="help__tabs">
        {TAB_OPTIONS.map((tab, index) => {
          return (
            <button className="btn" key={index} onClick={() => setActiveTab(index)}>
              {t(tab.title)}
            </button>
          );
        })}
      </div>
      <Content />
    </div>
  );
};

export default Info;
