import React, { useCallback, useState } from "react";
import { connect } from "react-redux";

import { addProductColor, editProductColor } from "../../../Redux/Products/actions";
import SketchHelper from "./utils/SketchHelper";

const ColorForm = ({
  addProductColor,
  productId,
  getColorsByProduct,
  setToggleTableContent,
  setProductColors,
  editProductColor,
  color = undefined,
  updatable = undefined,
}) => {
  const [colorName, setColorName] = useState(color ? color.colorName : "");
  const [colorScheme, setColorScheme] = useState(color ? color.colorScheme : "");
  const [colorPrice, setColorPrice] = useState(color ? color.colorPrice : "");
  const [colorQuantity, setColorQuantity] = useState(color ? color.colorQuantity : "");

  const submitAdd = useCallback(() => {
    const payload = { colorName, colorScheme, colorPrice, colorQuantity, productId };
    setProductColors
      ? setProductColors((prev) => [...prev, payload])
      : addProductColor(payload, getColorsByProduct, productId);
    setToggleTableContent(false);
  }, [colorName, colorScheme, colorPrice, colorQuantity]);

  const submitEdit = useCallback(() => {
    const payload = { ...color, colorName, colorScheme, colorPrice, colorQuantity };
    if (setProductColors) {
      setProductColors((prev) => prev.map((o, i) => (i === updatable ? payload : o)));
      setToggleTableContent(false);
    } else {
      editProductColor(color.productColorId, payload, getColorsByProduct, productId).then(
        () => setToggleTableContent(false),
      );
    }
  }, [colorName, colorScheme, colorPrice, colorQuantity]);

  return (
    <tr>
      <td>
        <input
          name="name"
          type={"text"}
          value={colorName}
          onChange={(e) => setColorName(e.target.value)}
        />
      </td>
      <td>
        <SketchHelper
          updatable={updatable !== undefined}
          setColorScheme={setColorScheme}
          defColor={colorScheme}
        />
      </td>
      <td>
        <input
          name="price"
          type={"number"}
          value={colorPrice}
          onChange={(e) => setColorPrice(e.target.value)}
        />
      </td>
      <td>
        <input
          name="quantity"
          type={"number"}
          value={colorQuantity}
          onChange={(e) => setColorQuantity(e.target.value)}
        />
      </td>
      <td>
        <img
          style={{ cursor: "pointer" }}
          onClick={() => setToggleTableContent(false)}
          src="/assets/images/icon-cross.svg"
          alt=""
        />
      </td>
      <td>
        <img
          style={{ cursor: "pointer", width: "1.2em" }}
          onClick={() => (color ? submitEdit() : submitAdd())}
          src={`/assets/images/${color ? "check-circle.png" : "icon-add-other.svg"}`}
          alt=""
        />
      </td>
    </tr>
  );
};

ColorForm.propTypes = {};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {
  addProductColor,
  editProductColor,
})(ColorForm);
