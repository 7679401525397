import "./OrderItem.scss";

import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { camelCase } from 'lodash';

import getOrderStatuses from "../../../../Helpers/getOrderStatuses";
import { OrderItemDetails } from "../OrderItemDetails/OrderItemDetails";

const OrderItem = ({ order, getOrderDetails, orderIdDetailsLoading }) => {
  const { t } = useTranslation();

  const [isOrderCollapsed, SetIsOrderCollapsed] = useState(true);

  const orderDetails = order?.details;

  const handleOrderCollapse = () => {
    !order.details && getOrderDetails(order?.orderId);
    SetIsOrderCollapsed(!isOrderCollapsed);
  };

  const orderStatus = getOrderStatuses.find(
    (status) => status.name === camelCase(order?.orderStatus),
  );

  return (
    <div className="order-wrapper">
      <div className="order-inner mb-3">
        <div className="order-main-wrapper">
          <div className="order-img">
            <img
              src={order?.imageLink}
              alt="img"
              onError={({ currentTarget }) => {
                currentTarget.onerror = null;
                currentTarget.src = "/assets/images/product-1.png";
              }}
            />
          </div>
          <div className="order-main-info d-flex flex-lg-row flex-column ml-lg-3 ml-2">
            <div className="order-id">{`№ ${order?.orderId}`}</div>
            <div className="order-product-name ml-lg-1 ml-0">{order?.productName}</div>
            <a
              href={`/${order?.sellerUsername}/`}
              className="order-seller-name ml-lg-1 ml-0"
            >
              {order?.sellerUsername}
            </a>
          </div>
        </div>
        <div className="order-status-wrapper ml-1">
          <div className="order-statuses-and-price">
            <div className="order-status" style={{ color: orderStatus?.color || "" }}>
              {t(orderStatus?.name || '')}
            </div>
            <div className="order-price">{`${order?.currency} ${Number.parseFloat(
              order?.price,
            ).toFixed(2)}`}</div>
            <div className="order-payment-status">
              {t(order?.paymentStatus?.toLowerCase())}
            </div>
          </div>
          <div
            onClick={handleOrderCollapse}
            className={`ml-lg-3 ml-2 order-triangular-arrow ${
              isOrderCollapsed ? "" : "order-triangular-arrow-up"
            }`}
          >
            <svg
              width="12"
              height="6"
              viewBox="0 0 12 6"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M12 0H0L6 6L12 0Z" fill="#808488" />
            </svg>
          </div>
        </div>
      </div>
      {!isOrderCollapsed && (
        <OrderItemDetails
          isLoading={+orderIdDetailsLoading === +order?.orderId}
          orderDetails={orderDetails}
        />
      )}
    </div>
  );
};

const mapStateToProps = () => ({});

export default connect(mapStateToProps, {})(OrderItem);
