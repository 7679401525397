import {
  LOADING,
  LOGIN_AS_GUEST_FAILURE,
  LOGIN_AS_GUEST_REQUEST_SENT,
  LOGIN_AS_GUEST_SUCCESSFUL,
  LOGIN_BY_GOOGLE_EMAIL,
  LOGIN_BY_GOOGLE_FAILURE,
  LOGIN_FAILURE,
  LOGIN_REQUEST_SENT,
  LOGIN_SUCCESSFUL,
  UPDATE_ACCOUNT_STATE,
  SET_CHECK_STORY,
} from "./constants";

const initialState = {
  loading: false,
  errorMessage: null,
  me: {},
  userId: null,
  username: null,
  authenticated: false,
  accountState:{},
  checkedStory: false
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_REQUEST_SENT:
      return {
        ...state,
        loading: true,
        errorMessage: null,
      };
    case LOGIN_SUCCESSFUL:
      return {
        ...state,
        loading: false,
        authenticated: true,
        errorMessage: null,
        userId: action.userId,
        username: action.username,
        token: action.token,
        me: {
          ...state.me,
          ...action.resp,
        },
        accountState: {
          ...action.accountState,
        },
      };
    case LOGIN_FAILURE:
      return {
        ...state,
        loading: false,
        errorMessage: null,
      };
    case LOGIN_AS_GUEST_REQUEST_SENT:
      return {
        ...state,
        loading: true,
        errorMessage: null,
      };
    case LOGIN_BY_GOOGLE_EMAIL:
      return {
        ...state,
        authenticated: true,
        loading: false,
        errorMessage: null,
        userId: action.payload.user.userId,
        username: action.payload.user.username,
        me: {
          ...state.me,
          instagramUsername: action.payload.user.username,
        },
        accountState: {
          ...action.payload.accountState,
        },
      };
    case LOGIN_AS_GUEST_SUCCESSFUL:
      return {
        ...state,
        loading: false,
        errorMessage: null,
        userId: action.payload.userId,
        username: action.payload.username,
        me: {
          ...state.me,
          instagramUsername: action.payload.username,
        },
        accountState: {
          ...action.payload.accountState,
        },
      };
    case LOGIN_BY_GOOGLE_FAILURE:
    case LOGIN_AS_GUEST_FAILURE:
      return {
        ...state,
        loading: false,
        errorMessage: null,
      };
    case LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case UPDATE_ACCOUNT_STATE:
      return {
        ...state,
        accountState: action.payload || state.accountState,
      };
    case SET_CHECK_STORY:
      return {
        ...state,
        checkedStory: action.payload,
      };
    default:
      return state;
  }
};

export default authReducer;
