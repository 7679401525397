import "./CustomMenu.css";

import React from "react";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";

// eslint-disable-next-line react/display-name
const CustomMenu = React.forwardRef(
  ({ children, disabled, style, className, "aria-labelledby": labeledBy }, ref) => {
    const [value, setValue] = React.useState("");
    const { t } = useTranslation();
    
    return (
      <div ref={ref} style={style} className={className} aria-labelledby={labeledBy}>
        <Form.Control
          autoFocus
          placeholder={t("search")}
          onChange={(e) => setValue(e.target.value)}
          value={value}
          disabled={disabled}
        />
        <ul className="list-unstyled">
          {children &&
            React.Children.toArray(children).filter(
              (child) =>
                !value ||
                child.props.children.toLowerCase?.().startsWith(value.toLowerCase()),
            )}
        </ul>
      </div>
    );
  },
);

export default CustomMenu;
