import axios from "axios";

import { BASEURL } from "../../Configs/constants";
import {
  GET_ORDER_DETAILS_FAILURE,
  GET_ORDER_DETAILS_REQUEST_SENT,
  GET_ORDER_DETAILS_SUCCESSFUL,
  GET_ORDERS_BY_USER_ID_FAILURE,
  GET_ORDERS_BY_USER_ID_REQUEST_SENT,
  GET_ORDERS_BY_USER_ID_SUCCESSFUL,
} from "./constants";
import { getAccessToken2 } from "../../Helpers/auth";

export const getOrdersByUserId =
  (userId, search = "") =>
  async (dispatch) => {
    try {
      dispatch({
        type: GET_ORDERS_BY_USER_ID_REQUEST_SENT,
      });
      const resp = await axios.get(
        `${BASEURL}/api/cart/history/UserOrdersCartHistories/by-user/${userId}?search=${search}`,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: getAccessToken2(),
          },
        },
      );
      dispatch({
        type: GET_ORDERS_BY_USER_ID_SUCCESSFUL,
        payload: resp.data,
      });
    } catch (err) {
      dispatch({
        type: GET_ORDERS_BY_USER_ID_FAILURE,
        message: err.response?.data ? err.response.data.title : err.message,
      });
    }
  };

export const getOrderDetails = (orderId) => async (dispatch) => {
  try {
    dispatch({
      type: GET_ORDER_DETAILS_REQUEST_SENT,
      orderId,
    });
    const resp = await axios.get(
      `${BASEURL}/api/cart/history/UserOrdersCartHistories/expanded/${orderId}`,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: getAccessToken2(),
        },
        params: {
          languageId: localStorage.getItem("language") === "ua" ? 1 : 217,
        },
      },
    );
    dispatch({
      type: GET_ORDER_DETAILS_SUCCESSFUL,
      payload: resp.data,
    });
  } catch (err) {
    dispatch({
      type: GET_ORDER_DETAILS_FAILURE,
      message: err.response?.data ? err.response.data.title : err.message,
    });
  }
};
