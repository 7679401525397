import "../../../../Components/PayoutScreen/PayoutScreen.scss";

import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import PayoutScreen from "../../../../Components/PayoutScreen/index";
import { getSellerPayout, postSellerPayout } from "../../../../Redux/Payouts/actions";
import axios from "axios";
import { BASEURL } from "../../../../Configs/constants";
import { getAccessToken2 } from "../../../../Helpers/auth";

const PaymentsPage = ({
  userId,
  getSellerPayout,
  postSellerPayout,
  totalPayoutData,
  onSubmit,
}) => {
  const { t } = useTranslation();
  const [initialState, setInitialState] = useState({
    cardNumber: "#### #### #### ####",
    cardHolder: "FULL NAME",
    cardMonth: "",
    cardYear: "",
    cardCvv: "",
    isCardFlipped: false,
  });

  const handleFinishClick = async () => {
    await postSellerPayout({
      sellerUserId: userId,
      cardNumber: initialState.cardNumber,
      cardName: initialState.cardHolder,
    });
    return onSubmit();
  };

  useEffect(() => {
    getSellerPayout(userId);
  }, []);

  useEffect(() => {
    if (!totalPayoutData) return;

    setInitialState({
      cardNumber: totalPayoutData.cardNumber,
      cardHolder: totalPayoutData.cardName,
      cardMonth: "",
      cardYear: "",
      cardCvv: "",
      isCardFlipped: false,
    });
  }, [totalPayoutData]);

  useEffect(() => {
    (async () => {
      const response = await axios.get(
        `${BASEURL}/api/sellsgram/seller/payout/check?sellerUserID=${userId}`,
        {
          headers: {
            Authorization: getAccessToken2(),
          },
        },
      );
      console.log("check cards response:", response);
      setIsHasSellerPayouts(response?.data?.status);
    })();
  }, []);

  const [isHasSellerPayouts, setIsHasSellerPayouts] = useState(false);

  // const isHasSellerPayouts = history?.location?.state?.isHasSellerPayouts;
  console.log("isHasSellerPayouts", isHasSellerPayouts);

  return (
    <div>
      <p className="h2">{t("myCards")}</p>
      <PayoutScreen state={initialState} setState={setInitialState} />
      <button className="btn wizard-button mt-4" onClick={handleFinishClick}>
        {t("next")}
      </button>
    </div>
  );
};

PaymentsPage.propTypes = {};

const mapStateToProps = (state) => ({
  userId: state.auth.userId,
  totalPayoutData: state.payout.totalPayoutData,
});

export default connect(mapStateToProps, {
  getSellerPayout,
  postSellerPayout,
})(PaymentsPage);
