import "./Assets/css/main.css";
import "./i18n";
import "./App.scss";
import "react-toastify/dist/ReactToastify.min.css";

import { GoogleOAuthProvider } from "@react-oauth/google";
import React, { Suspense } from "react";
import { Route, Switch, useHistory, useLocation, useParams } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import Footer from "./Components/Footer/Footer";
import Header from "./Components/Header";
import routes from "./Configs/routes";
import { InstagramAuthProvider } from "./Context/Instagram-Auth-Context";
import NotFoundPage from "./Pages/Error/404";

const googleOAuthClientId = process.env.GOOGLE_OAUTH_CLIENT_ID || "";

const App = () => {
  const history = useHistory();
  const { pathname, search } = useLocation();

  if (pathname[pathname.length - 1] !== "/") {
    history.push(`${pathname}/${search}`);
  }
  const menu = routes.map((route, index) =>
    route.component ? (
      <Route
        key={index}
        path={route.path}
        exact={route.exact}
        name={route.name}
        component={(props) => (
          <div className="inner_content">
            <div className="Wrap-1280">
              <Header />
              <main>
                <route.component {...props} />
              </main>
              <Footer />
            </div>
          </div>
        )}
      />
    ) : null,
  );

  return (
    <GoogleOAuthProvider clientId="452914997611-vgo16au32pl8j9vo8lmb7ablqjo0vgb8.apps.googleusercontent.com">
      <InstagramAuthProvider>
        <Suspense fallback={<div>Loading... </div>}>
          <Switch>
            <Route path="/404/" component={NotFoundPage} />
            {menu}
            <Route component={NotFoundPage} />
          </Switch>
        </Suspense>
        <ToastContainer position="bottom-right" />
      </InstagramAuthProvider>
    </GoogleOAuthProvider>
  );
};

export default App;
