// @ts-ignore
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import PropTypes from "prop-types";
import { isValidPhoneNumber } from "libphonenumber-js";

import { Checkbox } from "../../../../Components/Common/Checkbox/Checkbox";
import "./FirstWizardStep.scss";
import { Link } from "react-router-dom";

const FirstWizardStep = ({ onNextStep, setFormData, buyerData }) => {
  const { t } = useTranslation();
  const [form, setForm] = useState({
    firstName: "",
    lastName: "",
    phoneNumber: "",
    isAgreed: false,
  });
  const toDashedSurname = (value) => {
    let result = "";
    
    const SPECIAL_CHARS = "[`!@#$%^&*()_+-=[]{};':\"\\|,.<>/?~1234567890]";
    const SUB_SPECIAL_CHARS = SPECIAL_CHARS + " ";
    for (let i = 0; i < value.length; i++) {
      if (
        i > 0 &&
        i + 1 < value.length &&
        value[i] === "-" &&
        !SUB_SPECIAL_CHARS.includes(value[i - 1]) &&
        !SUB_SPECIAL_CHARS.includes(value[i + 1])
      ) {
        result += value[i];
      } else if (
        i > 0 &&
        i === value.length - 1 &&
        value[i] === "-" &&
        !SUB_SPECIAL_CHARS.includes(value[i - 1])
      ) {
        result += value[i];
      } else if (!SPECIAL_CHARS.includes(value[i])) {
        result += value[i];
      }
    }
    return result;
  };
  const [inputError, setInputError] = useState({});
  const [isValidPhone, setIsValidPhone] = useState(true);

  const onSubmit = () => {
    let noError = true;
    if (!form.firstName || form.firstName.trim().length < 3) {
      setInputError((prevState) => ({ ...prevState, firstName: t("firstNameRequired") }));
      noError = false;
    } else {
      setInputError(({ firstName, ...rest }) => rest);
    }
    if (!form.lastName || form.lastName.trim().length < 3) {
      setInputError((prevState) => ({ ...prevState, lastName: t("lastNameRequired") }));
      noError = false;
    } else {
      setInputError(({ lastName, ...rest }) => rest);
    }
    if (!form.phoneNumber || !isValidPhone) {
      setInputError((prevState) => ({ ...prevState, phoneNumber: t("phoneRequired") }));
      noError = false;
    } else {
      setInputError(({ phoneNumber, ...rest }) => rest);
    }
    if (!form.isAgreed) {
      setInputError((prevState) => ({ ...prevState, isAgreed: t("termsRequired") }));
      noError = false;
    } else {
      setInputError(({ isAgreed, ...rest }) => rest);
    }
    if (noError) {
      setFormData(form);
      onNextStep();
    }
  };
  const onInputChange = (event) => {
    let { name, value } = event.target;
    var format = /[`!@#$%^№&*()_+\=\[\]{};':"\\|,.<>\/?~1234567890☺☻♥♦♣♠•◘○◙♂♀♪♫☼►◄↕‼¶§▬↨↑↓→←∟↔▲▼~Δ€‚ƒ„…†‡ˆ‰Š‹ŒŽ‘’“”•–—˜™š›œžŸ¡¢£¤¥¦§¨©ª«¬®¯°±²³´µ¶·¸¹º»¼½¾¿×÷øæ¢₴£¥₧ƒáíóúñÑªº¿⌐¬½¼¡«»░▒▓│┤╡╢╖╕╣║╗╝╜╛┐└┴┬├─┼╞╟╚╔╩╦╠═╬╧╨╤╥╙╘╒╓╫╪┘┌█▄▌▐▀αßΓπΣσµτΦΘΩδ∞φε∩≡±≥≤⌠⌡÷≈°∙·√ⁿ²■]/;    
    value = toDashedSurname(value.replace(format, ""));
    event.target.value = value;
    if (format.test(value) || value.split(" ").length > 1) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      setForm((form) => ({
        ...form,
        [event.target.name]: event.target.value,
      }));
    }
  };
  return (
    <div className="wizard-form">
      <div
        className={`wizard-input_wrapper ${
          inputError.firstName ? "wizard-input_error" : ""
        }`}
      >
        <label htmlFor="firstName">{t("name")}*</label>
        <input
          id="firstName"
          type="text"
          name="firstName"
          className="form-control"
          placeholder={t("typeHere")}
          value={form.firstName}
          onChange={onInputChange}
        />
        {inputError.firstName && (
          <small className="wizard-error">{inputError.firstName}</small>
        )}
      </div>
      <div
        className={`wizard-input_wrapper ${
          inputError.lastName ? "wizard-input_error" : ""
        }`}
      >
        <label htmlFor="lastName">{t("lastName")}*</label>
        <input
          type="text"
          name="lastName"
          id="lastName"
          value={form.lastName}
          className="form-control"
          placeholder={t("typeHere")}
          onChange={onInputChange}
        />
        {inputError.lastName && (
          <small className="wizard-error">{inputError.lastName}</small>
        )}
      </div>
      <div className="wizard-input_wrapper">
        <label>{t("instagram")}</label>
        <p>{buyerData?.username || "-"}</p>
      </div>
      <div
        className={`wizard-input_wrapper ${
          inputError.phoneNumber ? "wizard-input_error" : ""
        }`}
      >
        <label htmlFor="phone">{t("phoneNumber")}*</label>
        <PhoneInput
          country={"ua"}
          excludeCountries={["ru", "by"]}
          isValid={isValidPhone}
          inputClass="wizard-phone"
          value={form.phoneNumber}
          onChange={(phoneNumber, country) => {
            const countryCode = country.countryCode.toUpperCase();
            if (phoneNumber.length)
              setIsValidPhone(isValidPhoneNumber(`+${phoneNumber}`, countryCode));
            setForm((form) => ({
              ...form,
              phoneNumber,
            }));
          }}
        />
        {inputError.phoneNumber && (
          <small className="wizard-error">{inputError.phoneNumber}</small>
        )}
      </div>
      <div className="wizard-input_wrapper">
        <label>{t("email")}</label>
        <p>{buyerData?.email || "-"}</p>
      </div>
      <div className={`${inputError.isAgreed ? "wizard-input_error" : ""}`}>
        <div className="wizard-checkbox_wrapper">
          <Checkbox
            defaultChecked={form.isAgreed}
            name="isAgreed"
            onChange={({ target }) =>
              setForm((form) => ({
                ...form,
                [target.name]: target.checked,
              }))
            }
          ></Checkbox>
          <p>
            <span>*{t("iAgreeWith")} </span>
            <Link to="/info/p/?terms-of-use/" target="_blank">
              {t("offer")}
            </Link>
          </p>
        </div>
        {inputError.isAgreed && (
          <small className="wizard-error">{inputError.isAgreed}</small>
        )}
      </div>
      <button className="btn wizard-button" onClick={onSubmit}>
        {t("next")}
      </button>
    </div>
  );
};

FirstWizardStep.propTypes = {
  buyerData: PropTypes.object.isRequired,
};
export default FirstWizardStep;
