import axios from "axios";

import { BASEURL } from "../../Configs/constants";
import {
  GET_USER_BY_USERNAME_FAILURE,
  GET_USER_BY_USERNAME_REQUEST_SENT,
  GET_USER_BY_USERNAME_SUCCESSFUL,
} from "./constants";
import { getAccessToken2 } from "../../Helpers/auth";

export const getProfileByUsername = (username) => async (dispatch) => {
  try {
    dispatch({
      type: GET_USER_BY_USERNAME_REQUEST_SENT,
    });
    const resp = await axios.get(
      `${BASEURL}/api/web/sellsgram/user/get_id/by_username?username=${username}`,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: getAccessToken2(),
        },
      },
    );
    dispatch({
      type: GET_USER_BY_USERNAME_SUCCESSFUL,
      payload: resp.data,
    });
  } catch (err) {
    dispatch({
      type: GET_USER_BY_USERNAME_FAILURE,
      message: err.response?.data ? err.response.data.title : err.message,
    });
  }
};
