import React, { useState, useEffect } from "react";
import ModalContainer from "../../../../Components/Common/ModalWindow/ModalWindowComponent";
import { useTranslation } from "react-i18next";
import "./ShippedModalStyles.scss";
import axios from "axios";
import { RequestHandler } from "../../../../Components/RequestHandler/RequestHandler";
import { BASEURL } from "../../../../Configs/constants";
import { toast } from "react-toastify";
import { getAccessToken2 } from "../../../../Helpers/auth";

async function sendImage(formData) {
  return axios.post(`${BASEURL}/api/product/ProductImages/UploadFileDirectly`, formData, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: getAccessToken2(),
    }
  });
}

function ShippedModal({ isOpen, onClose, updateOrderStatus }) {
  const { t } = useTranslation();
  const [reasonMessage, setReasonMessage] = useState("");
  const [attachedImage, setAttachedImage] = useState(null);
  const [textInputError, setTextInputError] = useState(false);
  const [fileInputError, setFileInputError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const onInputChange = ({ target: { value } }) => {
    setReasonMessage(value);
    if (!value) {
      setTextInputError(true);
    } else {
      setTextInputError(false);
    }
  };
  const onImageChange = (e) => {
    const [file] = e.target.files;
    if (!file.name.match(/\.(jpg|jpeg|png|gif)$/)) {
      toast.error("Please select valid image!");
      setFileInputError(true);
    } else {
      setFileInputError(false);
      setAttachedImage({ file, preview: URL.createObjectURL(file) });
    }
  };
  const onMessageSend = async () => {
    if (!reasonMessage) setTextInputError(true);
    if (!attachedImage) setFileInputError(true);
    if (attachedImage && reasonMessage) {
      setIsLoading(true);
      const requiredFormat = new FormData();
      requiredFormat.append("file", attachedImage.file);
      try {
        const filePath = await sendImage(requiredFormat);         
        await updateOrderStatus(reasonMessage, filePath.data);
        handleCloseModal();
      } catch (e) {
        setIsLoading(false);
        toast.error(e.response?.data || e.message);
      }
    }
  };
  const handleCloseModal = () => {
    setReasonMessage("");
    setFileInputError(false);
    setTextInputError(false);
    setIsLoading(false);
    setAttachedImage(null);
    onClose();
  };
  return (
    <>
      {isOpen && (
        <ModalContainer onClose={handleCloseModal}>
          <div className="shipped-modal-container">
            <RequestHandler isLoading={isLoading} withAutoHeight>
              <p>{t("shippedInfo")}</p>
              <div className="shipped-input-wrapper">
                {textInputError && (
                  <span className="error-msg">{t("addInformationRequired")}</span>
                )}
                <input
                  type="text"
                  value={reasonMessage}
                  placeholder={t("shippedInfoPlaceholder")}
                  name="reason-message"
                  className={textInputError ? "error-border" : ""}
                  onChange={onInputChange}
                />
              </div>
              <p>{t("shippedLabelAttachedFile")}</p>
              <div className="shipped-input-wrapper">
                {fileInputError && (
                  <span className="error-msg">{t("addInformationRequired")}</span>
                )}
                {attachedImage ? (
                  <div className="product-detail-thumbnail">
                    <img
                      src={attachedImage.preview}
                      alt="img"
                      className="product-detail-thumbnail-img"
                    />
                    <button
                      type="button"
                      className="insta-icon delete-img-btn"
                      onClick={() => setAttachedImage(null)}
                    >
                      <img src="/assets/images/icon-delete-black.svg" alt="bin" />
                    </button>
                  </div>
                ) : (
                  <div className="custom-file">
                    <input
                      type="file"
                      className="custom-file-input"
                      id="customFile"
                      accept="image/*"
                      onChange={onImageChange}
                    />
                    <label
                      className={`custom-file-label ${fileInputError ? "error" : ""}`}
                      htmlFor="customFile"
                    >
                      <img src="/assets/images/file-upload.svg" alt="file upload" />
                    </label>
                  </div>
                )}
              </div>
              <div className="shipped-buttons">
                <button className="back-button" onClick={handleCloseModal}>
                  {t("back")}
                </button>
                <button className="shipped-button" onClick={onMessageSend}>
                  {t("confirmShipment")}
                </button>
              </div>
            </RequestHandler>
          </div>
        </ModalContainer>
      )}
    </>
  );
}

export default ShippedModal;
