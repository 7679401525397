import "./ProductProperties.css";

import React, { useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";

import {
  deleteAdditionalOptions,
  getAdditionalOptionsByProduct,
} from "../../../Redux/Products/actions";
import AdditionalPropertiesForm from "../PropertyForms/AdditionalPropertiesForm";
import SketchHelper from "../PropertyForms/utils/SketchHelper";

const AdditionalPropertiesTable = ({
  productId,
  additionalOptionsByProduct,
  getAdditionalOptionsByProduct,
  deleteAdditionalOptions,
  setProductAdditionalOptions,
  productAdditionalOptions,
}) => {
  const [toggleTableContent, setToggleTableContent] = useState(false);
  const [updatable, setUpdatable] = useState();
  const { t } = useTranslation();
 
  useEffect(() => {
    if (productId) getAdditionalOptionsByProduct(productId);
  }, []);

  return (
    <Table className="gv">
      <thead>
        <tr>
          <th>{t("apt_color")}</th>
          <th>{t("apt_material")}</th>
          <th>{t("apt_size")}</th>
          <th>{t("apt_price")}</th>
          <th>{t("apt_qty")}</th>
          <th />
          <th />
        </tr>
      </thead>
      <tbody>
        {productAdditionalOptions?.length
          ? productAdditionalOptions.map((additionalOption, i) =>
              i === updatable ? (
                <AdditionalPropertiesForm
                  key={updatable}
                  productAdditionalOptions={productAdditionalOptions}
                  updatable={updatable}
                  additionalOption={additionalOption}
                  productId={productId}
                  getAdditionalOptionsByProduct={getAdditionalOptionsByProduct}
                  setToggleTableContent={setUpdatable}
                  setProductAdditionalOptions={setProductAdditionalOptions}
                />
              ) : (
                <tr key={additionalOption?.productOptionId} className="property-info">
                  <td>
                    <SketchHelper defColor={additionalOption?.colorScheme} />
                  </td>
                  <td>{additionalOption?.materialName}</td>
                  <td>{additionalOption?.sizeName}</td>
                  <td>{additionalOption?.price}</td>
                  <td>{additionalOption?.quantity}</td>
                  <td>
                    <img
                      onClick={() => setUpdatable(i)}
                      style={{ cursor: "pointer", width: "1.2em" }}
                      src="/assets/images/icon-edit.svg"
                      alt="edit icon"
                    />
                  </td>
                  <td>
                    <img
                      onClick={() => {
                        setProductAdditionalOptions((prev) =>
                          prev.filter((_, j) => j !== i),
                        );
                      }}
                      style={{ cursor: "pointer", width: "1.2em" }}
                      src="/assets/images/bin.png"
                      alt="delete icon"
                    />
                  </td>
                </tr>
              ),
            )
          : !productAdditionalOptions
          ? additionalOptionsByProduct?.length > 0 &&
            additionalOptionsByProduct.map((additionalOption) =>
              additionalOption.productOptionId === updatable ? (
                <AdditionalPropertiesForm
                  key={updatable}
                  productAdditionalOptions={productAdditionalOptions}
                  updatable={updatable}
                  additionalOption={additionalOption}
                  productId={productId}
                  getAdditionalOptionsByProduct={getAdditionalOptionsByProduct}
                  setToggleTableContent={setUpdatable}
                  setProductAdditionalOptions={setProductAdditionalOptions}
                />
              ) : (
                <tr key={additionalOption.productOptionId} className="property-info">
                  <td>
                    <SketchHelper
                      defColor={additionalOption.colorScheme}
                    />
                  </td>
                  <td>{additionalOption?.materialName}</td>
                  <td>{additionalOption?.sizeName}</td>
                  <td>{additionalOption.price}</td>
                  <td>{additionalOption.quantity}</td>
                  <td>
                    <img
                      onClick={() => setUpdatable(additionalOption.productOptionId)}
                      style={{ cursor: "pointer", width: "1.2em" }}
                      src="/assets/images/icon-edit.svg"
                      alt="edit icon"
                    />
                  </td>
                  <td>
                    <img
                      onClick={() =>
                        deleteAdditionalOptions(
                          additionalOption.productOptionId,
                          getAdditionalOptionsByProduct,
                          productId,
                        )
                      }
                      style={{ cursor: "pointer", width: "1.2em" }}
                      src="/assets/images/bin.png"
                      alt="delete icon"
                    />
                  </td>
                </tr>
              ),
            )
          : ""}
        {!toggleTableContent ? (
          <tr>
            <td colSpan={5}>
              <button
                onClick={() => setToggleTableContent(true)}
                className="btn btn-add-other d-flex align-items-center w-100 justify-content-center"
                style={{ marginBottom: 10 }}
              >
                {t("apt_additional_options")}
                <img src="/assets/images/icon-add-other.svg" alt="" />
              </button>
            </td>
          </tr>
        ) : (
          <AdditionalPropertiesForm
            updatable={true}
            productId={productId}
            productAdditionalOptions={productAdditionalOptions}
            getAdditionalOptionsByProduct={getAdditionalOptionsByProduct}
            setToggleTableContent={setToggleTableContent}
            setProductAdditionalOptions={setProductAdditionalOptions}
          />
        )}
      </tbody>
    </Table>
  );
};

const mapStateToProps = (state) => ({
  additionalOptionsByProduct: state.products.additionalOptionsByProduct,
});

export default connect(mapStateToProps, {
  getAdditionalOptionsByProduct,
  deleteAdditionalOptions,
})(AdditionalPropertiesTable);
