import React, { useState } from "react";

import { InstagramAuthContext } from "./Instagram-Auth-Context";

export const InstagramAuthProvider = ({ children }) => {
  const [isOpen, setIsOpen] = useState(true);
  const [userInfoRequestsIds, setUserInfoRequestsIds] = useState([]);
  const [isVisiblePrivateBlock, setIsVisiblePrivateBlock] = useState(true);

  const handleClose = () => {
    setIsOpen(false);
  };

  const value = {
    isOpen: isOpen,
    handleClose: handleClose,
    setUserInfoRequestsIds: setUserInfoRequestsIds,
    userInfoRequestsIds,
    isVisiblePrivateBlock,
    setIsVisiblePrivateBlock,
  };

  return (
    <InstagramAuthContext.Provider value={value}>
      {children}
    </InstagramAuthContext.Provider>
  );
};
