import { GOOGLE_LOGIN_FAIL, GOOGLE_LOGIN_SUCCESS, SET_HAS_INSTAGRAM } from "./constants";

const initialState = {
  error: null,
  googleUser: null,
  hasInstagram: false
};

const googleAuthReducer = (state = initialState, action) => {
  switch (action.type) {
    case GOOGLE_LOGIN_SUCCESS:
      return {
        ...state,
        googleUser: action.data,
        error: null,
      };
    case GOOGLE_LOGIN_FAIL:
      return {
        ...state,
        error: action.data,
        googleUser: null,
        hasInstagram: false,
      };
    case SET_HAS_INSTAGRAM:
      return {
        ...state,
        error: null,
        hasInstagram: action.data,
      };
    default:
      return state;
  }
};

export default googleAuthReducer;
