import "./AddOptionsModalStyle.css";

import PropTypes from "prop-types";
import React, { useState } from "react";
import { Button, Col, Container, Form, Modal, Row } from "react-bootstrap";
import { connect } from "react-redux";

const AddOptionsModal = (props) => {
  return (
    <>
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="modal-options"
      >
        <Modal.Body>
          <Button className="close" onClick={props.onHide}>
            <img src="/assets/images/icon-cross.svg" alt="" aria-hidden="true" />
          </Button>
          <h2>Other options</h2>
          <button type="button" className="btn btn-border w-100 justify-content-center">
            Apple
          </button>
          <button type="button" className="btn btn-border w-100 justify-content-center">
            Apple
          </button>
          <button type="button" className="btn btn-border w-100 justify-content-center">
            Apple
          </button>
        </Modal.Body>
      </Modal>
    </>
  );
};

AddOptionsModal.propTypes = {};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {})(AddOptionsModal);
