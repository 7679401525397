// @ts-nocheck
import "./UserPage.scss";
import "react-activity/dist/Sentry.css";

import React, { useEffect, useRef, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { isEmpty } from "lodash";

import ProductFeedCard from "../../Components/Common/ProductFeedCard/ProductFeedCard";
import { Layout } from "../../Components/Layout/Layout";
import ModalSingleProduct from "../../Components/ModalSingleProduct";
import { RequestHandler } from "../../Components/RequestHandler/RequestHandler";
import { SellerDraftLiveSwitcher } from "../../Components/SellerDraftLiveSwitcher/SellerDraftLiveSwitcher";
import { useInstagramAuthContext } from "../../Context/Instagram-Auth-Context";
import {
  loginAsGuest,
  logout,
  setLoading,
  switchToBuyer,
} from "../../Redux/Auth/actions";
import { dispatchNullRoute } from "../../Redux/Cart/actions";
import { getProducts } from "../../Redux/Products/actions";
import {
  follow,
  getIsFollowing,
  getUserInfo,
  getUserInstaPosts,
  unfollow,
} from "../../Redux/Profile/actions";

const customImgProfile = "/assets/images/profile-icon2.png";

const UserPage = ({
  history,
  getUserInfo,
  userId,
  getUserInstaPosts,
  loading,
  errorMessage,
  userInstaPosts,
  getProducts,
  googleUser,
  userinfo,
  dispatchNullRoute,
  getUserProducts,
  fromUserId,
  logout,
  loginAsGuest,
  authenticated,
  isFollowing,
  getIsFollowing,
  follow,
  unfollow,
  setLoading,
  productsLoading,
  currentUser,
  switchToBuyer,
  hasInstagram,
}) => {
  const [newUserProductsModal, setNewUserProductsModal] = useState(false);
  const [count, setCount] = useState(0);
  const [selectedImages, setSelectedImages] = useState([]);
  const [selectedImagesIds, setSelectedImagesIds] = useState([]);
  const [imgProfile, setImgProfile] = useState(customImgProfile);

  const {
    isOpen,
    handleClose,
    setUserInfoRequestsIds,
    userInfoRequestsIds,
    setIsVisiblePrivateBlock,
    isVisiblePrivateBlock,
  } = useInstagramAuthContext();

  const { t } = useTranslation();
  const delayTime = useRef(0);
  const authenticatedWithGoogle = !isEmpty(googleUser);
  const [settingsModal, setSettingsModal] = useState(false);
  const [loggedAsGuest, setLoggedAsGuest] = useState(false);
  const [isLiveProducts, setIsLiveProducts] = useState(true);
  const [isLoginHover, setIsLoginHover] = useState(false);

  const setIsLiveProductsHandler = async (isLive) => {
    const response = await checkAuth();
    setLoading(true);
    const myId = response?.userId || fromUserId;

    const productsStatus = isLive ? 0 : 1;
    console.log(productsStatus);

    setIsLiveProducts(isLive);
    getProducts(userId, myId, productsStatus);
  };

  const checkAuth = async () => {
    if (!fromUserId && !loggedAsGuest) {
      setLoggedAsGuest(true);
      return loginAsGuest();
    }
  };

  const getAllInfo = async () => {
    const response = await checkAuth();
    setLoading(true);
    const myId = response?.userId || fromUserId;

    setIsVisiblePrivateBlock(true);

    if (!userId || !myId) return;
    getUserInfo(userId, setUserInfoRequestsIds);
    getProducts(userId, myId);
    if (userId !== myId) {
      getIsFollowing(userId, myId);
    }
  };
  const onSwitchToBuyer = async () => {
    await switchToBuyer(fromUserId);
    setSettingsModal(false);
  };
  const onCopyLink = () => {
    if (navigator && navigator.clipboard && navigator.clipboard.writeText) {
      navigator.clipboard.writeText(window.location.href);
      toast.success(t("linkCopied"), {
        position: "top-right",
      });
      setSettingsModal(false);
      return;
    }
    setSettingsModal(false);
    return Promise.reject("The Clipboard API is not available.");
  };
  const openInNewTab = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };
  useEffect(() => {
    getAllInfo();
  }, [userId, fromUserId]);

  const imageUrl = userinfo?.profileImageLink
    ? `${userinfo?.profileImageLink}?${Date.now()}`
    : customImgProfile;

  const Image = React.memo(
    function Image({ src }) {
      return (
        <img
          src={src}
          alt="profile img"
          onError={() => {
            console.log("error");
            const imageErrorUrl = userinfo?.profileImageLink
              ? `${userinfo?.profileImageLink}?${Date.now()}`
              : customImgProfile;
            setImgProfile(imageErrorUrl);
          }}
        />
      );
    },
    [imageUrl],
  );

  useEffect(() => {
    const imageUrl = userinfo?.profileImageLink
      ? `${userinfo?.profileImageLink}?${Date.now()}`
      : customImgProfile;
    setImgProfile(imageUrl);
  }, [userinfo?.profileImageLink]);

  const onLogout = () => {
    logout();
    loginAsGuest().then(() => {
      setSettingsModal(false);
      history.push(`/${currentUser.username}/`);
      history.go();
    });
  };

  const onAddProductModalOpen = () => {
    if (hasInstagram) {
      if (!userInstaPosts?.data?.length) {
        getUserInstaPosts(userId);
      }
      setCount(0);
      setNewUserProductsModal(true);
    } else {
      toast.error(t("loginToAddProducts"));
    }
  };

  return (
    <Layout>
      {/* {!authenticated && <LoginModal show={isOpen} onHide={handleClose} />} */}
      <RequestHandler isLoading={loading || userInfoRequestsIds.length > 0}>
        <div className="profile-view-main">
          <div className="member-profile-topbar-wrapper">
            <div className="member-profile-topbar  flex-row">
              <div className="d-flex align-items-center">
                <div className="member-profile-img">
                  <Image src={imgProfile} />
                </div>
                <div className="member-profile-text member-profile-name">
                  <h4>{userinfo?.username}</h4>
                  <p className="userNameAlign">{t("username")}</p>
                </div>
              </div>

              <div className="revenueVisits d-none d-md-flex">
                {userId === fromUserId ? (
                  <>
                    {userinfo?.username === currentUser.username && (
                      <>
                        <div className="member-profile-text">
                          <h4>₴{userinfo?.revenue}</h4>
                          <p>{t("revenue")}</p>
                        </div>
                        <div className="member-profile-text">
                          <h4>{userinfo?.visits}</h4>
                          <p>{t("visits")}</p>
                        </div>
                      </>
                    )}
                  </>
                ) : isFollowing ? (
                  <button
                    className="btn outline follow"
                    onClick={() => unfollow(userId, fromUserId)}
                  >
                    {t("unfollow")}
                  </button>
                ) : (
                  <button
                    className="btn follow"
                    onClick={() => follow(userId, fromUserId)}
                  >
                    {t("follow")}
                  </button>
                )}
              </div>

              {userId === fromUserId && (
                <div className="profile-setting d-flex align-items-center">
                  <div className="profile-setting-btn" style={{ cursor: "pointer" }}>
                    <img
                      src="/assets/images/icon-setting.svg"
                      alt=""
                      onClick={() => setSettingsModal(true)}
                    />
                  </div>
                </div>
              )}
            </div>

            <div className="revenueVisits d-flex d-md-none justify-center mt-3">
              {userId === fromUserId ? (
                <>
                  <div className="member-profile-text ">
                    <h4>₴{userinfo?.revenue}</h4>
                    <p>{t("revenue")}</p>
                  </div>
                  <div className="member-profile-text">
                    <h4>{userinfo?.visits}</h4>
                    <p>{t("visits")}</p>
                  </div>
                </>
              ) : authenticated ? (
                isFollowing ? (
                  <button
                    className="btn outline follow"
                    onClick={() => unfollow(userId, fromUserId)}
                  >
                    {t("unfollow")}
                  </button>
                ) : (
                  <button
                    className="btn follow"
                    onClick={() => follow(userId, fromUserId)}
                  >
                    {t("follow")}
                  </button>
                )
              ) : null}
            </div>
          </div>
          <div className="subscription-group-heading">
            <div style={{ width: "26px" }} />
            {userId === fromUserId && (
              <>
                <SellerDraftLiveSwitcher
                  isChecked={isLiveProducts}
                  onChange={setIsLiveProductsHandler}
                  translate={t}
                />
                <Button
                  className="btn-nostyle add-btn"
                  variant="none"
                  onClick={onAddProductModalOpen}
                >
                  <img src="/assets/images/icon-add-plus.svg" alt="" />
                </Button>
              </>
            )}
          </div>
          <div className="your-items-section">
            <RequestHandler withAutoHeight isLoading={productsLoading}>
              <div className="imgMain">
                {getUserProducts.length ? (
                  getUserProducts.map((product, key) => (
                    <ProductFeedCard product={product} key={key} from="shop-tab" />
                  ))
                ) : (
                  <p>{t("profileNoProductsInList")}</p>
                )}
              </div>
            </RequestHandler>
          </div>
        </div>
        <Modal
          contentClassName="setting-buttons-modal"
          show={settingsModal}
          onHide={() => setSettingsModal(false)}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Body style={{ padding: 0 }}>
            <div className="setting-buttons-container">
              <button
                className="insta-btn"
                onClick={() => history.replace({ pathname: "/tarification/" })}
              >
                {t("tarification")}
              </button>
              <button
                className="insta-btn"
                onClick={() => history.replace({ pathname: "/payouts/" })}
              >
                {t("payouts")}
              </button>
              <button
                className="insta-btn"
                onClick={() => history.replace({ pathname: "/my-orders/" })}
              >
                {t("myOrders")}
              </button>
              <button className="insta-btn" onClick={onSwitchToBuyer}>
                {t("switchToBuyer")}
              </button>
              <button
                className="insta-btn"
                onClick={() => history.replace({ pathname: "/delivery-page/" })}
              >
                {t("deliveryMethods")}
              </button>
              <button className="insta-btn" onClick={onCopyLink}>
                {t("inviteLink")}
              </button>
              <button
                className="insta-btn"
                onClick={() => history.replace({ pathname: "/payment/" + currentUser.username + "/" })}
              >
                {t("paymentLink")}
              </button>
              <button
                className="insta-btn"
                onClick={() => openInNewTab("https://t.me/sellsgram_bot")}
              >
                {t("support")}
              </button>
              <button
                className="insta-btn"
                onClick={() => history.replace({ pathname: "/info/p/" })}
              >
                {t("info")}
              </button>
              {(authenticated || authenticatedWithGoogle) && (
                <button onClick={onLogout} className="insta-btn text-danger">
                  {t("logout")}
                </button>
              )}
            </div>
          </Modal.Body>
        </Modal>
        <Modal
          show={newUserProductsModal}
          onHide={() => setNewUserProductsModal(false)}
          size="md"
          contentClassName="add-product-modal"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Body>
            <div className="row-spacebtwn" style={{ marginBottom: 16 }}>
              <h2>{t("addUserProducts")}</h2>
              <p className="counter">{count}</p>
            </div>
            <div className="row-container ">
              {userInstaPosts?.data?.length > 0 ? (
                userInstaPosts.data.map((post, key) => (
                  <ModalSingleProduct
                    selectedImages={selectedImages}
                    setSelectedImages={setSelectedImages}
                    setSelectedImagesIds={setSelectedImagesIds}
                    selectedImagesIds={selectedImagesIds}
                    key={key}
                    post={post}
                    setCount={setCount}
                    count={count}
                  />
                ))
              ) : (
                <p className="noGroupsTxt">There are no posts in your instagram</p>
              )}

              {errorMessage ? (
                <p className="error-text" style={{ margin: "auto" }}>
                  {errorMessage}
                </p>
              ) : null}
            </div>
            <button
              type="button"
              onClick={() => {
                if (userInstaPosts?.data?.length && count > 0)
                  setNewUserProductsModal(false);
                else {
                  toast.error(
                    userInstaPosts?.data?.length
                      ? t("yourCountIsEmpty")
                      : t("yourInstaDoesntHavePosts"),
                  );
                }
              }}
              className="confirm-modalbtn"
            >
              <Link
                onClick={(event) => (count === 0 ? event.preventDefault() : null)}
                className="btn w-100 justify-content-center mt-0"
                to={{
                  pathname: "/add-item-page/",
                  state: { instaImgs: selectedImages, instaIds: selectedImagesIds },
                }}
              >
                {t("confirm")}
              </Link>
            </button>
          </Modal.Body>
        </Modal>
      </RequestHandler>
    </Layout>
  );
};

UserPage.propTypes = {};

const mapStateToProps = (state) => ({
  userId: state.userProfile.userId,
  fromUserId: state.auth.userId,
  loading: state.profile.loading,
  productsLoading: state.products.loading,
  currentUser: state.auth,

  errorMessage: state.profile.errorMessage,
  userInstaPosts: state.profile.userInstaPosts,
  userinfo: state.profile.userinfo,
  isFollowing: state.profile.isFollowing,
  getUserProducts: state.products.allProducts,
  authenticated: state.auth.authenticated,
  googleUser: state.googleAuth.googleUser,
  hasInstagram: state.googleAuth.hasInstagram,
});

export default connect(mapStateToProps, {
  getUserInfo,
  getUserInstaPosts,
  dispatchNullRoute,
  getProducts,
  loginAsGuest,
  logout,
  getIsFollowing,
  follow,
  unfollow,
  setLoading,
  switchToBuyer,
})(UserPage);
