import "react-activity/dist/Sentry.css";
import "./CartStyle.scss";
import React, { useEffect, useState } from "react";
import Alert from "react-bootstrap/Alert";
import ProgressBar from "react-bootstrap/ProgressBar";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import DeliveryInfo from "../../Components/DeliveryInfo/DeliveryInfo";
import { Layout } from "../../Components/Layout/Layout";
import { RequestHandler } from "../../Components/RequestHandler/RequestHandler";
import SingleCartItem from "../../Components/SingleCartItem";
import { useLazyGetCartCountByUserIdQuery } from "../../Api/cartCountApi";
import {
  deleteUserCartProduct,
  getLiqPayData,
  getHasUserBuyerDeliveries,
  getUserCartProducts,
  updateUserCartProduct,
} from "../../Redux/Cart/actions";

// const publicKey_LiqPay = "sandbox_i38636401953";
//
// const getUrl = () => {
//   if (window.location.href.includes(process.env.REACT_APP_URL_DEVELOPMENT))
//     return process.env.REACT_APP_URL_DEVELOPMENT;
//   else if (window.location.href.includes(process.env.REACT_APP_URL_PRODUCTION))
//     return "https://" + process.env.REACT_APP_URL_PRODUCTION;
//   else return process.env.REACT_APP_URL_LOCALHOST;
// };

const Cart = (props) => {
  const {
    getUserCartProducts,
    totalCartProducts,
    userId,
    loading,
    deleteUserCartProduct,
    updateUserCartProduct,
    totalLiqPayData,
    getHasUserBuyerDeliveries,
    // getLiqPayData,
  } = props;

  const navigation = useHistory();

  let { search } = useLocation();

  const order_id = new URLSearchParams(search).get("order_id");
  const currency = new URLSearchParams(search).get("currency");
  const amount = new URLSearchParams(search).get("amount");
  const payment_id = new URLSearchParams(search).get("payment_id");
  const order_status = new URLSearchParams(search).get("order_status");
  const amountBig = (+String(amount).slice(0, -2)).toLocaleString("en-US");
  const amountSmall = String(amount).slice(-2);

  const [cartCountTrigger] = useLazyGetCartCountByUserIdQuery();

  const { t } = useTranslation();
  const [showSuccessful, setShowSuccessful] = useState(false);
  const [progress, setProgress] = useState(50);
  const history = useHistory();

  useEffect(() => {
    if (!userId) return;
    getHasUserBuyerDeliveries(userId);
    getUserCartProducts(userId);
  }, []);

  const onDeleteCartProduct = async (...props) => {
    await deleteUserCartProduct(...props);
    await cartCountTrigger(userId);
    await getHasUserBuyerDeliveries(userId);
  };
  return (
    <Layout backBtnLink={history.back}>
      <RequestHandler isLoading={loading}>
        <div className="cart-main-section">
          <div className="subscription-group-heading d-flex align-items-center justify-content-between">
            <h2>{t("yourCart")}</h2>
            <div className="d-flex align-items-center">
              <button type="button" className="btn d-none">
                Done
              </button>
            </div>
          </div>
          <div className="d-flex flex-md-row flex-column">
            <div className="flex-one-no-btn">
              <div className="shopping-cart-section">
                <h3>{t("shoppingCart")}</h3>
                {totalCartProducts?.length ? (
                  totalCartProducts.map((product, key) => (
                    <SingleCartItem
                      product={product}
                      key={key}
                      deleteUserCartProduct={onDeleteCartProduct}
                      getUserCartProducts={getUserCartProducts}
                      userId={userId}
                      updateUserCartProduct={updateUserCartProduct}
                    />
                  ))
                ) : navigation?.location?.state?.totalCartProducts ? (
                  navigation?.location?.state?.totalCartProducts.map((product, key) => (
                    <SingleCartItem
                      product={product}
                      key={key}
                      deleteUserCartProduct={onDeleteCartProduct}
                      getUserCartProducts={getUserCartProducts}
                      userId={userId}
                      updateUserCartProduct={updateUserCartProduct}
                    />
                  ))
                ) : (
                  <p>{t("emptyCart")}</p>
                )}
              </div>
            </div>
            {order_id && currency && amount && payment_id && order_status ? (
              <div className="flex-one">
                <div className="delivery-section">
                  <div className="delivery-heading">
                    <div className="d-flex align-items-center">
                      <h2>{t("delivery")}</h2>
                      <h2>{t("payment")}</h2>
                    </div>
                    <ProgressBar
                      className="heading-progress-bar"
                      now={totalCartProducts?.length ? 50 : 100}
                    />

                    <div className="success-container">
                      <img
                        src="/assets/images/card-with-success.svg"
                        className="card-success-icon"
                        alt="card success icon"
                      />
                      <div className="payment-success-title">
                        {t("paymentSuccessTitle")}
                      </div>
                      <div className="payment-success-subtitle">
                        {t("paymentSuccessSubtitle")}
                      </div>
                      <a
                        href="https://sellsgram.com/"
                        target="_blank"
                        className="payment-success-link"
                      >
                        https://sellsgram.com/
                      </a>

                      <div className="amount-currency-container">
                        <p className="amount-success">{amountBig}.</p>
                        <p className="amount-small-success">{amountSmall}</p>
                        <p className="currency-success">{currency}</p>
                      </div>

                      <div className="payment-id-container">
                        <p className="payment-id-text">Payment ID</p>
                        <p className="payment-id-number">{payment_id}</p>
                      </div>

                      <div className="security-auth-container">
                        <img
                          src="/assets/images/security-icon.svg"
                          className="security-auth-icon"
                          alt="security auth icon"
                        />
                        <p className="security-auth-text">
                          Strong customer authentication
                        </p>
                      </div>
                    </div>

                    <div className="d-flex flex-sm-row flex-column">
                      <div className="flex-one">
                        <button
                          type="submit"
                          className="btn delivery-info-bottom-btn"
                          onClick={() => navigation.push("/")}
                        >
                          {t("back")}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="flex-one">
                <div className="delivery-section">
                  <div className="delivery-heading">
                    <div className="d-flex align-items-center">
                      <h2>{t("delivery")}</h2>
                      <h2>{t("payment")}</h2>
                    </div>
                    <ProgressBar className="heading-progress-bar" now={progress} />
                  </div>
                  {/* <!-- Delivery Info section --> */}
                  {!showSuccessful ? (
                    <DeliveryInfo
                      totalLiqPayData={totalLiqPayData}
                      setShowSuccessful={setShowSuccessful}
                      setProgress={setProgress}
                    />
                  ) : (
                    <Alert
                      show={showSuccessful}
                      variant="success"
                      onClose={() => setShowSuccessful(false)}
                      transition={true}
                      dismissible
                    >
                      <Alert.Heading>Payment successful!</Alert.Heading>
                    </Alert>
                  )}
                  {/* <!-- Payment Info section --> */}
                  {/* <PaymentInfo /> */}
                </div>
              </div>
            )}
          </div>
        </div>
      </RequestHandler>
    </Layout>
  );
};

const mapStateToProps = (state) => ({
  hasUserBuyerDeliveries: state.cart.hasUserBuyerDeliveries,
  totalCartProducts: state.cart.totalCartProducts,
  userId: state.auth.userId,
  loading: state.cart.loading,
  totalLiqPayData: state.cart.totalLiqPayData,
});

export default connect(mapStateToProps, {
  getUserCartProducts,
  deleteUserCartProduct,
  updateUserCartProduct,
  getLiqPayData,
  getHasUserBuyerDeliveries,
})(Cart);
