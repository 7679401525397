import axios from "axios";

import { BASEURL } from "../../Configs/constants";
import {
  DISPATCH_NULL_ROUTE,
  GET_PAYOUT_FAILURE,
  GET_PAYOUT_REQUEST_SENT,
  GET_PAYOUT_SUCCESSFULL,
  POST_PAYOUT_FAILURE,
  POST_PAYOUT_REQUEST_SENT,
  POST_PAYOUT_SUCCESSFULL,
} from "./constants";
import { getAccessToken2 } from "../../Helpers/auth";

export const getSellerPayout = (sellerUserId) => async (dispatch) => {
  try {
    dispatch({
      type: GET_PAYOUT_REQUEST_SENT,
    });
    const resp = await axios.get(
      `${BASEURL}/api/sellsgram/seller/payout/${sellerUserId}`,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: getAccessToken2(),
        },
      },
    );
    dispatch({
      type: GET_PAYOUT_SUCCESSFULL,
      payload: resp.data,
    });
  } catch (err) {
    dispatch({
      type: GET_PAYOUT_FAILURE,
      message: err.response?.data ? err.response.data.title : err.message,
    });
  }
};

export const postSellerPayout = (payload) => async (dispatch) => {
  try {
    dispatch({
      type: POST_PAYOUT_REQUEST_SENT,
    });
    const resp = await axios.post(
      `${BASEURL}/api/sellsgram/seller/payout`,
      payload,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: getAccessToken2(),
        },
      },
    );
    dispatch({
      type: POST_PAYOUT_SUCCESSFULL,
      payload: resp.data,
    });
    return resp.data;
  } catch (err) {
    dispatch({
      type: POST_PAYOUT_FAILURE,
      message: err.response?.data ? err.response.data.title : err.message,
    });
  }
};

export const dispatchNullRoute = () => async (dispatch) => {
  dispatch({
    type: DISPATCH_NULL_ROUTE,
  });
};
