export default Object.freeze([
  {
    id: 3,
    name: "cancelled",
    color: "#EA3535",
  },
  {
    id: 9,
    name: "completed",
    color: "#008040",
  },
  {
    id: 12,
    name: "shipped",
    color: "#4B85DC",
  },
  {
    id: 13,
    name: "new",
    color: "#EF3BFF",
  },
  {
    id: 14,
    name: "inProcess",
    color: "#B4B4B4",
  },
  {
    id: 16,
    name: "toPayout",
    color: "#FFA031",
  },
  {
    id: 17,
    name: "pendingPayout",
    color: "#FFB291",
  },
  {
    id: 18,
    name: "declined",
    color: "#EA3535",
  },
  {
    id: 99,
    name: "default",
    color: "#FFB291",
  },
]);
