import "./ProductProperties.css";

import React, { useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";

import { deleteProductSize, getSizesByProduct } from "../../../Redux/Products/actions";
import SizeForm from "../PropertyForms/SizeForm";

const SizeTable = ({
  sizesByProduct,
  getSizesByProduct,
  deleteProductSize,
  productId,
  setProductSizes,
  productSizes,
}) => {
  const [toggleTableContent, setToggleTableContent] = useState(false);
  const [updatable, setUpdatable] = useState();
  const { t } = useTranslation();

  useEffect(() => {
    getSizesByProduct(productId);
  }, []);

  return (
    <Table className="gv">
      <thead>
        <tr>
          <th>{t("name")}</th>
          <th>{t("price")}</th>
          <th>{t("qty")}</th>
          <th />
          <th />
        </tr>
      </thead>
      <tbody>
        {productSizes?.length
          ? productSizes.map((size, i) =>
              i === updatable ? (
                <SizeForm
                  key={updatable}
                  updatable={updatable}
                  size={size}
                  productId={productId}
                  getSizesByProduct={getSizesByProduct}
                  setToggleTableContent={setUpdatable}
                  setProductSizes={setProductSizes}
                />
              ) : (
                <tr key={size.productSizeId}>
                  <td>{size.sizeName}</td>
                  <td>{size.sizePrice}</td>
                  <td>{size.sizeQuantity}</td>
                  <td>
                    <img
                      onClick={() => setUpdatable(i)}
                      style={{ cursor: "pointer", width: "1.2em" }}
                      src="/assets/images/icon-edit.svg"
                      alt="edit icon"
                    />
                  </td>
                  <td>
                    <img
                      onClick={() =>
                        setProductSizes((prev) => prev.filter((_, j) => j !== i))
                      }
                      style={{ cursor: "pointer", width: "1.2em" }}
                      src="/assets/images/bin.png"
                      alt="delete icon"
                    />
                  </td>
                </tr>
              ),
            )
          : !productSizes
          ? sizesByProduct?.length > 0 &&
            sizesByProduct.map((size) =>
              size.productSizeId === updatable ? (
                <SizeForm
                  key={updatable}
                  updatable={updatable}
                  size={size}
                  productId={productId}
                  getSizesByProduct={getSizesByProduct}
                  setToggleTableContent={setUpdatable}
                  setProductSizes={setProductSizes}
                />
              ) : (
                <tr key={size.productSizeId}>
                  <td>{size.sizeName}</td>
                  <td>{size.sizePrice}</td>
                  <td>{size.sizeQuantity}</td>
                  <td>
                    <img
                      onClick={() => setUpdatable(size.productSizeId)}
                      style={{ cursor: "pointer", width: "1.2em" }}
                      src="/assets/images/icon-edit.svg"
                      alt="edit icon"
                    />
                  </td>
                  <td>
                    <img
                      onClick={() =>
                        deleteProductSize(
                          size.productSizeId,
                          getSizesByProduct,
                          productId,
                        )
                      }
                      style={{ cursor: "pointer", width: "1.2em" }}
                      src="/assets/images/bin.png"
                      alt="delete icon"
                    />
                  </td>
                </tr>
              ),
            )
          : ""}
        {!toggleTableContent ? (
          <tr>
            <td colSpan={5}>
              <button
                onClick={() => setToggleTableContent(true)}
                className="btn btn-add-other d-flex align-items-center w-100 justify-content-center"
                style={{ marginBottom: 10 }}
              >
                {t("size")}
                <img src="/assets/images/icon-add-other.svg" alt="" />
              </button>
            </td>
          </tr>
        ) : (
          <SizeForm
            productId={productId}
            getSizesByProduct={getSizesByProduct}
            setToggleTableContent={setToggleTableContent}
            setProductSizes={setProductSizes}
          />
        )}
      </tbody>
    </Table>
  );
};

const mapStateToProps = (state) => ({
  sizesByProduct: state.products.sizesByProduct,
});

export default connect(mapStateToProps, {
  getSizesByProduct,
  deleteProductSize,
})(SizeTable);
