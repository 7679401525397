import "./MainLayoutStyle.scss";
import "react-activity/dist/Sentry.css";

import PropTypes from "prop-types";
import QueryString from "query-string";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import { RequestHandler } from "../Components/RequestHandler/RequestHandler";
import { useInstagramAuthContext } from "../Context/Instagram-Auth-Context";
import { login } from "../Redux/Auth/actions";
import { getProfileByUsername } from "../Redux/UserProfile/actions";
import BuyerPage from "./BuyerPage/BuyerPage";
import UserPage from "./UserPage";

const MainLayout = (props) => {
  const { handleClose, setUserInfoRequestsIds } = useInstagramAuthContext();
  const [redirecting, setRedirecting] = useState(false);

  const {
    login,
    id,
    username,
    history,
    accountState,
    getProfileByUsername,
    googleEmail,
    loading,
  } = props;

  const parsed = QueryString.parse(history.location.search);

  useEffect(() => {
    if (!id) {
        history.replace(`/subscriptions/`);
    }
    if (parsed?.code && parsed?.code?.length) {
      handleClose();
      login(parsed.code, setUserInfoRequestsIds, googleEmail).then(() =>
        history.push(`/${username}/`),
      );
    } else if (id && !parsed.code && !redirecting) {
      history.push({ pathname: `/${username}/`, state: { ...history.location.state } });
    }
  }, [id]);

  useEffect(() => {
    if (username && !redirecting) getProfileByUsername(username);
  }, [username]);

  const isSeller = accountState && accountState.accountStateId === 1;

  return (
    <RequestHandler isLoading={true}>
      {isSeller ? <UserPage {...props} /> : <BuyerPage {...props} />}
    </RequestHandler>
  );
};

MainLayout.propTypes = {
  login: PropTypes.func.isRequired,
  getProfileByUsername: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  accountState: PropTypes.object.isRequired,
  username: PropTypes.string,
  googleEmail: PropTypes.string,
  id: PropTypes.number,
};

MainLayout.defaultProps = {
  username: "",
  googleEmail: "",
  id: null,
};

const mapStateToProps = (state) => ({
  token: state.auth.token,
  id: state.auth.userId,
  accountState: state.auth.accountState,
  loading: state.userProfile.loading,
  username: state.auth.username || state.auth.me.instagramUsername,
  googleEmail: state.googleAuth.googleUser?.email,
});

export default connect(mapStateToProps, {
  login,
  getProfileByUsername,
})(MainLayout);
