import React from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { camelCase } from "lodash";
  
const OrderTabComponent = ({
  orderDetails: {
    orderId,
    purchasedDate,
    billToName,
    status,
    buyerUsername,
    productName,
    phoneNumber,
    orderParameters,
    proof,
  },
  onShowOrderHistory,
  onShowCancelModal,
}) => {
  const { t } = useTranslation();
  return (
    <div className="order-tab">
      <div className="tab-header">
        <div className="tab-header_title">
          <h4>{t("orderDetailsOrder", { number: orderId })}</h4>
          {
            (status == "CANCELLED") && 
            <h5> {t("cancelationReason")}: {proof.proofMessage} </h5>
          }
          {productName && <p>{productName}</p>}
        </div>
        {(status === "NEW" || status === "IN PROCESS") && (
          <button
            className="cancel-button info-button-group"
            onClick={() => onShowCancelModal(true)}
          >
            {t("cancelOrder")}
          </button>
        )}
      </div>
      <div className="tab-body">
        <div className="body-column">
          <div className="column-title column-history-title">
            <>{t("orderInformation")}</>
            <button className="open-order-history" onClick={onShowOrderHistory}>
              {t("orderHistory")}
            </button>
          </div>
          <p className="column-info">
            {t("orderDate")}: {moment(new Date(purchasedDate + "Z")).format("HH:mm; DD.MM.YYYY") || "-"}
          </p>
          <p className="column-info">
            {t("orderStatus")}: {t(camelCase(status)) || "-"}
          </p>
          <div className="order-params">
            <div className="order-param">
              <p>{t("orderColor")}</p>
              <p>
                {orderParameters?.color}{" "}
                <span
                  className="order-color-icon"
                  style={{ backgroundColor: orderParameters?.color }}
                ></span>
              </p>
            </div>
            <div className="order-param">
              <p>{t("orderMaterial")}</p>
              <p>{orderParameters?.material}</p>
            </div>
            <div className="order-param">
              <p>{t("orderSize")}</p>
              <p>{orderParameters?.size}</p>
            </div>
            {/* <div className="order-param">
              <p>{t("orderOther")}</p>
              <p>{orderParameters?.other}</p>
            </div> */}
          </div>
        </div>
        <div className="body-column">
          <p className="column-title">{t("accountInformation")}</p>
          <p className="column-info">
            {t("customerName")}: {billToName || "-"}
          </p>
          <p className="column-info">
            {t("sellsgramAccount")}: {buyerUsername || "-"}
          </p>
          <p className="column-info">
            {t("customerPhoneNumber")}: {phoneNumber || "-"}
          </p>
        </div>
      </div>
    </div>
  );
};

export default OrderTabComponent;
