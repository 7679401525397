import "./DeliveryLocationInfo.scss";
import { memoize } from "lodash";
import React, { useEffect } from "react";
import Sentry from "react-activity/dist/Sentry";
import Button from "react-bootstrap/Button";
import Dropdown from "react-bootstrap/Dropdown";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import CustomMenu from "../../../Pages/Delivery/utils/CustomMenu";
import { getCourierCities } from "../../../Redux/Cart/actions";

const CourierLocationInfo = ({
  getCourierCities,
  loadingCities,
  courierCities,

  sellerUserId,
  delivery,
  onChange,
  countryId
}) => {
  const { i18n,t } = useTranslation();
  useEffect(() => {
    if (sellerUserId && countryId) {
      getCourierCities(sellerUserId, countryId);
    }
  }, [countryId, i18n.language]);

  return (
    <div className="mb-3">
      <div className="d-flex flex-sm-row flex-column">
        <div className="flex-one mb-4">
          <Dropdown
            onSelect={(c) => {
              const { cityId, name } = JSON.parse(c);
              onChange({
                city: {
                  id: cityId,
                  name,
                },
                address: "",
                building: "",
                apartment: "",
              });
            }}
          >
            <Dropdown.Toggle
              className="w-100 align-items-center"
              as={Button}
              variant="secondary"
              id="truncateLongTexts"
              disabled={!countryId}
            >
              {delivery.city.name || t("selectCity")}
            </Dropdown.Toggle>

            <Dropdown.Menu as={CustomMenu} disabled={loadingCities}>
              {loadingCities ? (
                <Dropdown.Item>
                  <Sentry color="#4B85DC" size={100} speed={0.5} animating={true} />
                </Dropdown.Item>
              ) : (
                courierCities?.map((c) => (
                  <Dropdown.Item eventKey={JSON.stringify(c)} key={c.cityId}>
                    {c.name}
                  </Dropdown.Item>
                ))
              )}
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
      <div className="w-100">
        <label htmlFor="street">{t("street")}</label>
        <input
          type="text"
          name="street"
          className="form-control"
          value={delivery.address}
          placeholder="Brickell Avenue"
          onChange={(e) => onChange({ address: e.target.value })}
          disabled={!delivery.city.id}
        />
      </div>
      <div className="d-flex flex-sm-row flex-column">
        <div className="flex-one mr-sm-4 mr-0">
          <label htmlFor="building">{t("building")}</label>
          <input
            type="text"
            name="building"
            className="form-control"
            value={delivery.building}
            placeholder="15"
            onChange={(e) => onChange({ building: e.target.value })}
            disabled={!delivery.city.id}
          />
        </div>
        <div className="flex-one">
          <label htmlFor="apartment">{t("apartment")}</label>
          <input
            type="text"
            name="apartment"
            className="form-control"
            value={delivery.apartment}
            placeholder="77"
            onChange={(e) => onChange({ apartment: e.target.value })}
            disabled={!delivery.city.id}
          />
        </div>
      </div>
    </div>
  );
};

CourierLocationInfo.propTypes = {};

const mapStateToProps = (state) => ({
  loadingCities: state.cart.loadingCities,
  courierCities: state.cart.courierCities,
});

export default connect(mapStateToProps, {
  getCourierCities,
})(CourierLocationInfo);
