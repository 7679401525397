import "react-activity/dist/Sentry.css";

import React, { useEffect, useState, useCallback } from "react";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";

import { Layout } from "../../Components/Layout/Layout";
import { RequestHandler } from "../../Components/RequestHandler/RequestHandler";
import {
  getSellerCourierList,
  getSellerLocalDeliveries,
  getSellerShops,
} from "../../Redux/Cart/actions";
import { useGetAvailableDeliveryCountriesQuery } from "../../Api/deliveryApi";
import DeliveryCourier from "./DeliveryCourier";
import DeliveryLocal from "./DeliveryLocal";
import DeliverySellerLocation from "./DeliverySellerLocation";
import Dropdown from "react-bootstrap/Dropdown";
import { isEmpty } from "lodash";
import { CSSTransition } from "react-transition-group";

const Option = ({ setDeliveryType, methods, deliveryType, deliveryName }) => {
  const { t } = useTranslation();
  return (
    <div className="flex-one">
      <div className="payment-info-list d-flex align-items-center justify-content-between">
        <div className="custom-control custom-radio">
          <input
            type="radio"
            className="custom-control-input"
            id={deliveryName}
            name="example1"
            value="customEx"
            onChange={() => setDeliveryType(deliveryType)}
          />
          <label className="custom-control-label" htmlFor={deliveryName}>
            {deliveryName}
          </label>
        </div>
        <div className="card-icon">
          {methods} {t("available")}
        </div>
      </div>
    </div>
  );
};

const CustomDropdownButton = React.forwardRef(({ onClick, disabled }, ref) => {
  const { t } = useTranslation();
  return (
    <button
      ref={ref}
      disabled={disabled}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
      className="btn btn-add-other d-flex align-items-center w-100 justify-content-center"
    >
      {t("addCountry")}
      <img src="/assets/images/icon-add-other.svg" alt="" />
    </button>
  );
});

const DeliveryPage = ({
  getSellerShops,
  getSellerCourierList,
  getSellerLocalDeliveries,
  sellerShops,
  userId,
  sellerCourierList,
  sellerLocalDeliveries,
  onNextStep,
}) => {
  const { t } = useTranslation();
  const [countries, setCountries] = useState([]);
  const history = useHistory();
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [deliveryType, setDeliveryType] = useState(0);
  const {
    data: countiesList = [],
    isFetching,
    isLoading,
  } = useGetAvailableDeliveryCountriesQuery();
  const handleCountryClick = useCallback(
    (countyId) => {
      setSelectedCountry(countyId);
      getSellerShops(userId);
      getSellerCourierList(userId);
      getSellerLocalDeliveries(userId, countyId);
    },
    [userId],
  );

  useEffect(() => {
    getSellerShops(userId);
    getSellerCourierList(userId);
  }, []);

  useEffect(() => {
    if (!isEmpty(countiesList)) {
      sellerCourierList.forEach(updateCountries);
      sellerLocalDeliveries.forEach(updateCountries);
      sellerShops.forEach(updateCountries);
    }
  }, [countiesList?.length]);

  const updateCountries = ({ countryId }) => {
    const country = countiesList.find((c) => c.countryId === countryId);
    setCountries((prev) => {
      if (!prev.some((c) => c.countryId === countryId)) {
        if (!selectedCountry) {
          getSellerLocalDeliveries(userId, countryId);
          setSelectedCountry(countryId);
        }
        return [...prev, country];
      } else return prev;
    });
  };

  const filterByCountry = (list) => {
    return list?.filter(({ countryId }) => countryId === selectedCountry);
  };

  const countryFilteredOptions = countiesList.filter(
    ({ countryId }) => !countries.some((country) => country.countryId === countryId),
  );

  const handleFinishBtn = () => {
    return history.replace({ pathname: "/" });
  };

  const isHasSellerDeliveryMethods = history?.location?.state?.isHasSellerDeliveryMethods;
  const onSetCountries = (id) => {
    const countryId = Number(id);
    const country = countiesList.find((c) => c.countryId === countryId);
    setCountries((prev) =>
      prev.some(({ id }) => id === countryId) ? prev : [...prev, country],
    );
    if (!selectedCountry) handleCountryClick(countryId);
  };
  const getCountryNameByID = (id) =>
    countiesList.find(({ countryId }) => id === countryId)?.name;
  return (
    <Layout
      titleText={
        isHasSellerDeliveryMethods ? "addDeliveryMethod" : "addDeliveryMethodStart"
      }
      onSaveButtonClick={handleFinishBtn}
    >
      <RequestHandler isLoading={isFetching || isLoading}>
        <div className="d-flex flex-md-row flex-column row">
          <div className="flex-one  mb-4">
            <div className="add-product-edit">
              <label htmlFor="">{t("country")}</label>
              {countries.map(({ countryId }) => (
                <div key={countryId}>
                  <Button
                    className="btn btn-country d-flex align-items-center w-100 justify-content-center"
                    variant="primary"
                    active={countryId === selectedCountry}
                    onClick={() => handleCountryClick(countryId)}
                  >
                    {getCountryNameByID(countryId)}
                    <img src="/assets/images/icon-edit.svg" alt="" />
                  </Button>
                  <br />
                </div>
              ))}
              <Dropdown align="start" onSelect={onSetCountries}>
                <Dropdown.Toggle
                  id="dropdown-custom-components"
                  variant="none"
                  disabled={isEmpty(countryFilteredOptions)}
                  as={CustomDropdownButton}
                ></Dropdown.Toggle>
                <Dropdown.Menu>
                  {countryFilteredOptions?.map(({ name, countryId }) => (
                    <Dropdown.Item eventKey={countryId} key={countryId}>
                      {name}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
          {selectedCountry && (
            <div className="flex-two mb-4">
              <label htmlFor="">{getCountryNameByID(selectedCountry)}</label>
              <div className="delivery-info delivery-info-scroll 12 delivery-section">
                <Option
                  deliveryName={t("selfPickup")}
                  deliveryType={1}
                  methods={filterByCountry(sellerShops)?.length}
                  setDeliveryType={setDeliveryType}
                />
                <CSSTransition
                  in={deliveryType === 1}
                  timeout={300}
                  classNames="accordion-content"
                  unmountOnExit
                >
                  <DeliverySellerLocation
                    countryId={selectedCountry}
                    deliveryType={deliveryType}
                    selfDeliveries={filterByCountry(sellerShops)}
                  />
                </CSSTransition>
                <Option
                  deliveryName={t("courier")}
                  methods={filterByCountry(sellerCourierList)?.length}
                  deliveryType={2}
                  setDeliveryType={setDeliveryType}
                />
                <CSSTransition
                  in={deliveryType === 2}
                  timeout={300}
                  classNames="accordion-content"
                  unmountOnExit
                >
                  <DeliveryCourier
                    countryId={selectedCountry}
                    deliveryType={deliveryType}
                    courierDeliveries={filterByCountry(sellerCourierList)}
                  />
                </CSSTransition>
                <Option
                  deliveryName={t("localDelivery")}
                  methods={filterByCountry(sellerLocalDeliveries)?.length}
                  deliveryType={3}
                  setDeliveryType={setDeliveryType}
                />
                <CSSTransition
                  in={deliveryType === 3}
                  timeout={300}
                  classNames="accordion-content"
                  unmountOnExit
                >
                  <DeliveryLocal
                    countryId={selectedCountry}
                    deliveryType={deliveryType}
                    localDeliveries={filterByCountry(sellerLocalDeliveries)}
                  />
                </CSSTransition>
              </div>
            </div>
          )}
        </div>
      </RequestHandler>
    </Layout>
  );
};

DeliveryPage.propTypes = {};

const mapStateToProps = (state) => ({
  userId: state.auth.userId,
  sellerShops: state.cart.sellerShops,
  sellerCourierList: state.cart.sellerCourierList,
  sellerLocalDeliveries: state.cart.sellerLocalDeliveries,
});

export default connect(mapStateToProps, {
  getSellerShops,
  getSellerCourierList,
  getSellerLocalDeliveries,
})(DeliveryPage);
