import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";

import { useLazyGetCartCountByUserIdQuery } from "../../../Api/cartCountApi";
import Carousel from "react-bootstrap/Carousel";
import { toast } from "react-toastify";
import { useEffect } from "react";
import { Link } from "react-router-dom";

const BuyerProductCardItem = (props) => {
  const { productFeed, loginAsGuest, postUsersCartOrder, userId, username } = props;

  const { t } = useTranslation();

  const [selectedColor, setSelectedColor] = useState(null);
  const [selectedSize, setSelectedSize] = useState(null);
  const [showFullDescription, setFullDescription] = useState(false);
  const [selectedMaterial, setSelectedMaterial] = useState(null);

  const [lstSizes, setLstSizes] = useState([]);
  const [lstColors, setLstColors] = useState([]);
  const [lstMaterials, setLstMaterials] = useState([]);

  const [cartCountTrigger] = useLazyGetCartCountByUserIdQuery();

  const showFullDescriptionHandler = () => {
    setFullDescription(!showFullDescription);
  };

  const getUniqueArrayBykey = (key, arr) => {
    return [
      ...new Map(
        arr
          .filter((v) => v[key].length)
          .map((item) => {
            return [item[key], item];
          }),
      ).values(),
    ];
  };
  const getUniqueArrayFilterByKeys = (filterKey, filterValue, key, arr) => {
    return [
      ...new Map(
        arr
          .filter((v) => v[key] && v[key].length && v[filterKey] == filterValue)
          .map((item) => {
            return [item[key], item];
          }),
      ).values(),
    ];
  };
  useEffect(() => {
    if (
      productFeed &&
      productFeed.productAdditionalOptions &&
      productFeed.productAdditionalOptions.length > 0
    ) {
      setLstColors(
        getUniqueArrayBykey("colorScheme", productFeed.productAdditionalOptions),
      );
      setLstSizes(getUniqueArrayBykey("sizeName", productFeed.productAdditionalOptions));
      setLstMaterials(
        getUniqueArrayBykey("materialName", productFeed.productAdditionalOptions),
      );
    }
  }, [productFeed.productAdditionalOptions]);

  const handleAddtoCart = async () => {
    if (!selectedColor && lstColors && lstColors.length > 0) {
      toast.error(t("please-select-color"));
      return false;
    }
    if (!selectedMaterial && lstMaterials && lstMaterials.length > 0) {
      toast.error(t("please-select-material"));
      return false;
    }
    if (!selectedSize && lstSizes && lstSizes.length > 0) {
      toast.error(t("please-select-size"));
      return false;
    }
    let payload = {
      userId: userId || (await loginAsGuest()).userId,
      productId: productFeed?.product?.productId,
      price: parseInt(
        Math.max(
          selectedColor?.price || null,
          selectedSize?.price || null,
          selectedMaterial?.price || null,
          productFeed?.product?.price || 0,
        ),
      ),
      quantity: 1,
      colorId: selectedColor?.id || 0,
      sizeId: selectedSize?.id || 0,
      materialId: selectedMaterial?.id || 0,
      localDeliveryId: 0,
      courierDeliveryCityId: 0,
      selfDeliveryCityId: 0,
      orderedDate: productFeed?.product?.createdDate,
    };

    await postUsersCartOrder(payload);
    await cartCountTrigger(userId);
    toast.success(t("product-added-to-cart"));
  };

  const selectProductPerk = (perk, perkInfo) => {
    switch (perk) {
      case "size":
        if (
          selectedSize &&
          productFeed.productAdditionalOptions.find(
            (x) => x.productOptionId === selectedSize.id,
          ).sizeName === perkInfo.sizeName
        )
          setSelectedSize(null);
        else {
          setSelectedSize({ id: perkInfo.productOptionId, price: perkInfo.price });
          if (
            selectedMaterial &&
            productFeed.productAdditionalOptions.some(
              (x) => x.productOptionId === perkInfo.productOptionId,
            )
          )
            setSelectedMaterial({ id: perkInfo.productOptionId, price: perkInfo.price });
          if (
            selectedColor &&
            productFeed.productAdditionalOptions.some(
              (x) => x.productOptionId === perkInfo.productOptionId,
            )
          )
            setSelectedColor({ id: perkInfo.productOptionId, price: perkInfo.price });
        }
        break;
      case "material":
        if (
          selectedMaterial &&
          productFeed.productAdditionalOptions.find(
            (x) => x.productOptionId === selectedMaterial.id,
          ).materialName === perkInfo.materialName
        )
          setSelectedMaterial(null);
        else {
          setSelectedMaterial({ id: perkInfo.productOptionId, price: perkInfo.price });
          if (
            selectedSize &&
            productFeed.productAdditionalOptions.some(
              (x) => x.productOptionId === perkInfo.productOptionId,
            )
          )
            setSelectedSize({ id: perkInfo.productOptionId, price: perkInfo.price });
          if (
            selectedColor &&
            productFeed.productAdditionalOptions.some(
              (x) => x.productOptionId === perkInfo.productOptionId,
            )
          )
            setSelectedColor({ id: perkInfo.productOptionId, price: perkInfo.price });
        }
        break;
      case "color":
        if (
          selectedColor &&
          productFeed.productAdditionalOptions.find(
            (x) => x.productOptionId === selectedColor.id,
          ).colorScheme === perkInfo.colorScheme
        )
          setSelectedColor(null);
        else {
          setSelectedColor({ id: perkInfo.productOptionId, price: perkInfo.price });
          if (
            selectedSize &&
            productFeed.productAdditionalOptions.some(
              (x) => x.productOptionId === perkInfo.productOptionId,
            )
          )
            setSelectedSize({ id: perkInfo.productOptionId, price: perkInfo.price });
          if (
            selectedMaterial &&
            productFeed.productAdditionalOptions.some(
              (x) => x.productOptionId === perkInfo.productOptionId,
            )
          )
            setSelectedMaterial({ id: perkInfo.productOptionId, price: perkInfo.price });
        }
        break;
    }
  };
  useEffect(() => {
    const sizeName = selectedSize
      ? productFeed.productAdditionalOptions.find(
          (x) => x.productOptionId === selectedSize.id,
        ).sizeName
      : null;
    const materialName = selectedMaterial
      ? productFeed.productAdditionalOptions.find(
          (x) => x.productOptionId === selectedMaterial.id,
        ).materialName
      : null;
    const colorScheme = selectedColor
      ? productFeed.productAdditionalOptions.find(
          (x) => x.productOptionId === selectedColor.id,
        ).colorScheme
      : null;

    setLstSizes(
      getUniqueArrayBykey(
        "sizeName",
        productFeed.productAdditionalOptions.filter((x) =>
          !selectedMaterial && !selectedSize && !selectedColor
            ? true
            : (selectedMaterial ? x.materialName === materialName : true) &&
              (selectedSize ? x.sizeName === sizeName : true) &&
              (selectedColor ? x.colorScheme === colorScheme : true),
        ),
      ),
    );
    setLstMaterials(
      getUniqueArrayBykey(
        "materialName",
        productFeed.productAdditionalOptions.filter((x) =>
          !selectedMaterial && !selectedSize && !selectedColor
            ? true
            : (selectedMaterial ? x.materialName === materialName : true) &&
              (selectedSize ? x.sizeName === sizeName : true) &&
              (selectedColor ? x.colorScheme === colorScheme : true),
        ),
      ),
    );
    setLstColors(
      getUniqueArrayBykey(
        "colorScheme",
        productFeed.productAdditionalOptions.filter((x) =>
          !selectedMaterial && !selectedSize && !selectedColor
            ? true
            : (selectedMaterial ? x.materialName === materialName : true) &&
              (selectedSize ? x.sizeName === sizeName : true) &&
              (selectedColor ? x.colorScheme === colorScheme : true),
        ),
      ),
    );
  }, [selectedMaterial, selectedColor, selectedSize]);

  let description = productFeed?.product?.description || "";
  const splitProductDescription = description.trim().split(/\s+/);
  if (!showFullDescription && splitProductDescription.length > 10) {
    description = splitProductDescription.slice(0, 10).join(" ") + "...";
  }
  description = description
    .split("\n")
    .map((str, index) => <div key={index}>{str.length > 0 ? str : <span> </span>}</div>);

  const productPrice =
    Math.max(
      selectedColor?.price || null,
      selectedSize?.price || null,
      selectedMaterial?.price || null,
    ) || productFeed?.product?.price;

  return (
    <div className="seller-product-card-item">
      <div className="seller-info-wrapper d-flex justify-content-between align-items-center">
        <h4>
          <Link to={`/${productFeed?.sellerUsername}/`}>
            {productFeed?.sellerUsername}
          </Link>
        </h4>
        <Link to={`/${productFeed?.sellerUsername}/`}>
          <img className="seller-info-image" src={productFeed?.sellerProfilePic} alt="" />
        </Link>
      </div>
      <div className="seller-product-wrapper">
        <div className="seller-images-wrapper">
          <Carousel slide={false}>
            {productFeed?.productImages?.map((image) => (
              <Carousel.Item key={image.imageId}>
                <div className="seller-product-image-item ">
                  <img className="seller-product-image" src={image.imageLink} alt="" />
                </div>
              </Carousel.Item>
            ))}
          </Carousel>
        </div>
        <div className="seller-product-description-wrapper">
          <div className="seller-product-text">
            <Link to={`/p/${productFeed?.product?.productLinkName}/`}>
              <h3>{productFeed?.product?.shortName}</h3>
            </Link>
            <p style={{ whiteSpace: "break-spaces", wordBreak: "break-word" }}>
              {description}
              {splitProductDescription.length > 10 ? (
                <button onClick={showFullDescriptionHandler} className="read-more-button">
                  {t("read")} {showFullDescription ? t("less") : t("more")}
                  <FontAwesomeIcon icon={showFullDescription ? faAngleUp : faAngleDown} />
                </button>
              ) : null}
            </p>
          </div>
          {lstColors?.length || lstSizes?.length || lstMaterials?.length ? (
            <div className="product-card-options d-flex justify-content-between">
              {lstColors?.length ? (
                <div className="produt-card-option pick-color">
                  <p>{t("color")}</p>
                  <ul className="d-flex align-items-center">
                    {
                      lstColors?.length
                        ? lstColors.map((color, key) => (
                            <li key={key}>
                              <div
                                className={`${
                                  selectedColor &&
                                  color?.colorScheme ===
                                    productFeed.productAdditionalOptions.find(
                                      (x) => x.productOptionId === selectedColor?.id,
                                    ).colorScheme
                                    ? "color-selected"
                                    : "color-non-selected"
                                }`}
                              >
                                <button
                                  type="button"
                                  className="btn-nostyle"
                                  style={{
                                    backgroundColor: color?.colorScheme,
                                  }}
                                  onClick={() => selectProductPerk("color", color)}
                                >
                                  {selectedColor &&
                                    color?.colorScheme ===
                                      productFeed.productAdditionalOptions.find(
                                        (x) => x.productOptionId === selectedColor?.id,
                                      ).colorScheme && (
                                      <img
                                        src="/assets/images/icon-colorpick-tick.svg"
                                        alt="tick"
                                      />
                                    )}
                                </button>
                              </div>
                            </li>
                          ))
                        : null // <p>{t("noColorsAvailable")}</p>
                    }
                  </ul>
                </div>
              ) : null}
              {lstSizes?.length ? (
                <div className="produt-card-option pick-size">
                  <p>{t("size")}:</p>
                  <div className="d-flex flex-wrap align-items-center gap-2">
                    {
                      lstSizes?.length
                        ? lstSizes.map((size) => (
                            <button
                              key={size.productOptionId}
                              type="button"
                              onClick={() => selectProductPerk("size", size)}
                              className={`size-btn ${
                                selectedSize &&
                                size?.sizeName ===
                                  productFeed.productAdditionalOptions.find(
                                    (x) => x.productOptionId === selectedSize?.id,
                                  ).sizeName
                                  ? "active"
                                  : ""
                              }`}
                            >
                              {size?.sizeName}
                            </button>
                          ))
                        : null // <p>{t("noSizesAvailable")}</p>
                    }
                  </div>
                </div>
              ) : null}
              {lstMaterials?.length ? (
                <div className="produt-card-option pick-material">
                  <h4>{t("material")}:</h4>
                  <div className="d-flex flex-wrap align-items-center gap-2">
                    {
                      lstMaterials?.length
                        ? lstMaterials.map((material) => (
                            <button
                              key={material.productOptionId}
                              type="button"
                              onClick={() => selectProductPerk("material", material)}
                              className={`size-btn ${
                                selectedMaterial &&
                                material?.materialName ===
                                  productFeed.productAdditionalOptions.find(
                                    (x) => x.productOptionId === selectedMaterial?.id,
                                  ).materialName
                                  ? "active"
                                  : ""
                              }`}
                              disabled={material?.quantity === 0}
                            >
                              {material?.materialName}
                            </button>
                          ))
                        : null // <p>{t("noMaterialsAvailable")}</p>
                    }
                  </div>
                </div>
              ) : null}
            </div>
          ) : null}

          <button
            type="button"
            className="btn layout-header-btn"
            onClick={handleAddtoCart}
          >
            {productPrice ? `${t("buy")} ₴${productPrice}` : t("free")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default React.memo(BuyerProductCardItem);
