import { createApi } from "@reduxjs/toolkit/query/react";

import baseQuery from "../Configs/baseQuery";

export const subscriptionsApi = createApi({
  reducerPath: "subscriptionsApi",
  baseQuery: baseQuery,
  tagTypes: ["Feed"],
  endpoints: (build) => ({
    getSellsgramFollowings: build.query({
      query: (userId) => ({
        url: `buyer/followings/by-user/${userId}`,
      }),
    }),
    getUserGroups: build.query({
      query: (userId) => ({
        url: `buyer/groups/by-user/${userId}`,
      }),
    }),
    getGroupInfo: build.query({
      query: (groupId) => ({
        url: `buyer/group/followings/by-group/${groupId}`,
      }),
    }),
    getUserFeed: build.query({
      query: ({ userId, pageNum }) => {
        const countryId = localStorage.getItem("language") === "ua" ? 1 : 217;

        return {
          url: `user/feed/${userId}?CountryID=${countryId}&pageNumber=${pageNum}&pageSize=10`,
        };
      },
    }),
    getUserFeedByGroup: build.query({
      query: ({ userId, groupId, pageNum }) => {
        const countryId = localStorage.getItem("language") === "ua" ? 1 : 217;

        return {
          url: `user/feed/by-groups/${userId}?CountryID=${countryId}&GroupID=${groupId}&pageNumber=${pageNum}&pageSize=10`,
        };
      },
    }),
    saveUserGroupFollowings: build.mutation({
      query: (credentials) => ({
        url: "buyer/group/followings",
        method: "POST",
        body: { ...credentials },
      }),
    }),
    createUserGroup: build.mutation({
      query: (credentials) => ({
        url: "buyer/groups/one-modal/create",
        method: "POST",
        body: { ...credentials },
      }),
    }),
    removeUserFromGroup: build.mutation({
      query: ({ groupId, userId }) => ({
        url: `buyer/group/followings/${groupId}?followingUserId=${userId}`,
        method: "DELETE",
      }),
    }),
    removeGroup: build.mutation({
      query: (groupId) => ({
        url: `buyer/groups/${groupId}`,
        method: "DELETE",
      }),
    }),
    subscribe: build.mutation({
      query: ({ id, userId }) => ({
        url: `buyer/followings/follow`,
        method: "POST",
        params: {
          userId,
          followingUserId: id,
        },
      }),
    }),
    unsubscribe: build.mutation({
      query: ({ id, userId }) => ({
        url: `buyer/followings/unfollow`,
        params: {
          userId,
          followingUserId: id,
        },
        method: "DELETE",
      }),
    }),
  }),
});

export const {
  useLazyGetSellsgramFollowingsQuery,
  useLazyGetUserGroupsQuery,
  useLazyGetGroupInfoQuery,
  useLazyGetUserFeedQuery,
  useLazyGetUserFeedByGroupQuery,
  useCreateUserGroupMutation,
  useSaveUserGroupFollowingsMutation,
  useRemoveUserFromGroupMutation,
  useRemoveGroupMutation,
  useSubscribeMutation,
  useUnsubscribeMutation,
} = subscriptionsApi;
