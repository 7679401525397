import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { useCreateUserGroupMutation } from "../../Api/subscriptions";
import { Checkbox } from "../../Components/Common/Checkbox/Checkbox";

const getUrl = () => {
  if (window.location.href.includes(process.env.REACT_APP_URL_DEVELOPMENT))
    return process.env.REACT_APP_URL_DEVELOPMENT;
  else if (window.location.href.includes(process.env.REACT_APP_URL_PRODUCTION))
    return "https://" + process.env.REACT_APP_URL_PRODUCTION;
  return process.env.REACT_APP_URL_LOCALHOST;
};

const GroupAddModal = ({
  sellsgramFollowingsData,
  showAddModal,
  setShowAddModal,
  userId,
  groupsRefetch,
}) => {
  const [createNewUserGroup, { isLoading: isCreateGroupLoading }] =
    useCreateUserGroupMutation();

  const [selectedUsers, setSelectedUsers] = useState([]);

  const [groupName, setGroupName] = useState("");

  const [searchedTxt, setSearchedTxt] = useState("");

  const [searchedUsers, setSearchedUsers] = useState([]);

  const { t } = useTranslation();

  const handleSave = () => {
    createNewUserGroup({
      groupId: 0,
      userId,
      name: groupName,
      userGroupFollowings: selectedUsers.map((user) => ({
        groupId: 0,
        followedUserId: parseInt(user),
      })),
    })
      .then(() => groupsRefetch())
      .then(() => setShowAddModal(false));
  };

  const onChangeSelection = (e, id) => {
    if (e.target.checked) {
      setSelectedUsers([...selectedUsers, id]);
    } else {
      setSelectedUsers(selectedUsers.filter((val) => val !== id));
    }
  };

  const onSearchChange = (e) => {
    setSearchedTxt(e.target.value);
    setSearchedUsers(
      sellsgramFollowingsData.filter((user) =>
        user.userName.toLowerCase().includes(e.target.value.toLowerCase()),
      ),
    );
  };

  const renderGroups = sellsgramFollowingsData?.length ? (
    ((searchedUsers.length && searchedUsers) || sellsgramFollowingsData).map(
      (data, key) => (
        <div key={key} className="row-spacebtwn">
          <div className="flex-row">
            <a href={`${getUrl()}/${data.userName}/`}>
              <img
                alt="#"
                src={data.profileImageLink}
                className="subscription-group-img"
              />
            </a>
            <div>
              <a href={`${getUrl()}/${data.userName}/`}>
                <p className="subscription-group-name">{data.userName}</p>{" "}
              </a>
              {/* <p className="subscription-group-category">cozy and amazing!</p> */}
            </div>
          </div>
          <Checkbox
            key={data.followingUserID}
            defaultChecked={selectedUsers?.some((item) => item === data.followingUserID)}
            onChange={(e) => onChangeSelection(e, +data.followingUserID)}
          />
        </div>
      ),
    )
  ) : (
    <p className="noGroupsTxt">There are no groups</p>
  );

  return (
    <Modal
      show={showAddModal}
      contentClassName="group-add-modal"
      onHide={setShowAddModal}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <button className="close" onClick={() => setShowAddModal(false)}>
          <img src="/assets/images/icon-cross.svg" alt="" aria-hidden="true" />
        </button>
        <h2>{t("createNewGroup")}</h2>
        <div style={{ marginBottom: "-16px" }}>
          <input
            placeholder={t("enterGroupName")}
            className="search-boxinput"
            value={groupName}
            onChange={(e) => setGroupName(e.target.value)}
          />
        </div>
        <input
          placeholder={t("searchYourSubscription")}
          className="search-boxinput"
          value={searchedTxt}
          onChange={onSearchChange}
        />
        <div className="groups-container">{renderGroups}</div>
        <div className="d-flex edit-group-modal-buttons">
          <button
            type="button"
            className="btn w-100 justify-content-center modal-save-btn"
            disabled={!selectedUsers.length || isCreateGroupLoading}
            onClick={handleSave}
          >
            {isCreateGroupLoading ? (
              <span
                className="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
            ) : (
              t("save")
            )}
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default GroupAddModal;
