import React from "react";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import {
  useSubscribeMutation,
  useUnsubscribeMutation,
} from "../../../../Api/subscriptions";

const SubscribeToggleButton = ({ unsubscribed, toggleSubscribe, userId, id }) => {
  const [onSubscribe, { isLoading: subscribeLoading }] = useSubscribeMutation();
  const [onUnsubscribe, { isLoading: unsubscribeLoading }] = useUnsubscribeMutation();
  const { t } = useTranslation();

  const onToggleSubscribe = () => {
    if (unsubscribed) {
      onSubscribe({ userId, id });
    } else {
      onUnsubscribe({ userId, id });
    }
    toggleSubscribe();
  };

  return (
    <Button
      className={unsubscribed ? "" : "btn-transparent"}
      disabled={subscribeLoading || unsubscribeLoading}
      onClick={onToggleSubscribe}
      style={{ margin: "0" }}
    >
      {unsubscribed ? t("subscribe") : t("unsubscribe")}
    </Button>
  );
};

SubscribeToggleButton.propTypes = {
  unsubscribed: PropTypes.bool.isRequired,
  toggleSubscribe: PropTypes.func.isRequired,
  userId: PropTypes.number.isRequired,
  id: PropTypes.number.isRequired,
};

export default SubscribeToggleButton;
