import "./SellerDraftLiveSwitcher.scss";

import React from "react";
import Switch from "react-switch";

export const SellerDraftLiveSwitcher = ({ onChange, isChecked, translate }) => {
  return (
    <div className="d-flex align-items-center justify-content-center seller-switcher-wrapper">
      <div
        className={isChecked ? "d-flex seller-switcher-on" : "d-flex seller-switcher-off"}
      >
        <Switch
          onChange={onChange}
          checked={isChecked}
          onColor="#EDF1F7"
          offColor="#EDF1F7"
          onHandleColor="#4B85DC"
          offHandleColor="#4B85DC"
          activeBoxShadow="0px 0px 1px 2px #33bbff"
          className="react-switch"
          uncheckedIcon={
            <p
              onClick={() => onChange(true)}
              className={isChecked ? "switcher-active-p" : "switcher-passive-p"}
            >
              {translate("live")}
            </p>
          }
          checkedIcon={
            <p
              onClick={() => onChange(false)}
              className={!isChecked ? "switcher-active-p" : "switcher-passive-p"}
            >
              {translate("draft")}
            </p>
          }
        />
      </div>
    </div>
  );
};
