import React, { PureComponent } from "react";
import {
  Legend,
  PolarAngleAxis,
  PolarGrid,
  PolarRadiusAxis,
  Radar,
  RadarChart,
  ResponsiveContainer,
} from "recharts";

const data = [
  {
    subject: "Stickers",
    A: 120,
    B: 110,
    fullMark: 150,
  },
  {
    subject: "T-shirts",
    A: 98,
    B: 130,
    fullMark: 150,
  },
  {
    subject: "Food",
    A: 86,
    B: 130,
    fullMark: 150,
  },
  {
    subject: "Flowers",
    A: 99,
    B: 100,
    fullMark: 150,
  },
  {
    subject: "Clothes",
    A: 85,
    B: 90,
    fullMark: 150,
  },
  {
    subject: "Unknown",
    A: 65,
    B: 85,
    fullMark: 150,
  },
];

export default class WhatPeopleBuyDiff extends PureComponent {
  render() {
    return (
      <ResponsiveContainer width="50%" height="50%">
        <RadarChart cx="50%" cy="50%" outerRadius="80%" data={data}>
          <PolarGrid />
          <PolarAngleAxis dataKey="subject" />
          <PolarRadiusAxis angle={30} domain={[0, 150]} stroke="#000000" />
          <Radar
            name="June"
            dataKey="A"
            stroke="#8884d8"
            fill="#8884d8"
            fillOpacity={0.6}
          />
          <Radar
            name="July"
            dataKey="B"
            stroke="#82ca9d"
            fill="#82ca9d"
            fillOpacity={0.6}
          />
          <Legend />
        </RadarChart>
      </ResponsiveContainer>
    );
  }
}
