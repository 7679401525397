import React from "react";

export const TikTokLogo = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_308_3932)">
        <rect width="24" height="24" rx="6" fill="#4B85DC" />
        <path
          d="M15.5204 7.19333C15.5204 7.19333 15.9107 7.58222 15.5204 7.19333C14.9973 6.58637 14.709 5.80689 14.7092 5H12.3444V14.6444C12.3261 15.1664 12.1093 15.6607 11.7396 16.0234C11.3699 16.3861 10.8761 16.5888 10.3622 16.5889C9.27551 16.5889 8.37245 15.6867 8.37245 14.5667C8.37245 13.2289 9.64286 12.2256 10.9515 12.6378V10.18C8.31122 9.82222 6 11.9067 6 14.5667C6 17.1567 8.11225 19 10.3546 19C12.7577 19 14.7092 17.0167 14.7092 14.5667V9.67444C15.6681 10.3743 16.8194 10.7498 18 10.7478V8.34444C18 8.34444 16.5612 8.41444 15.5204 7.19333Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_308_3932">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
