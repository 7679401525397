import "./SubscriptionsStyle.scss";
import "react-activity/dist/Sentry.css";

import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";

import {
  useLazyGetSellsgramFollowingsQuery,
  useLazyGetUserFeedQuery,
  useLazyGetUserGroupsQuery,
  useLazyGetGroupInfoQuery,
  useLazyGetUserFeedByGroupQuery,
} from "../../Api/subscriptions";
import { loginAsGuest } from "../../Redux/Auth/actions";
import ProductFeedCard from "../../Components/Common/ProductFeedCard/ProductFeedCard";
import SubscriptionGroupCard from "../../Components/Common/SubscriptionGroupCard/SubscriptionGroupCard";
import { Layout } from "../../Components/Layout/Layout";
import { RequestHandler } from "../../Components/RequestHandler/RequestHandler";
import { dispatchNullRoute } from "../../Redux/Cart/actions";
import GroupAddModal from "./GroupAddModal";
import GroupEditModal from "./GroupEditModal";
import { isEmpty } from "lodash";

const Subscriptions = ({ id, dispatchNullRoute, token, loginAsGuest }) => {
  const [getSellsgramFollowings, { data: sellsgramFollowingsData }] =
    useLazyGetSellsgramFollowingsQuery();

  const [
    getUserGroups,
    { data: groupsData, isLoading: isGroupsLoading, isFetching: isGroupsFetching },
  ] = useLazyGetUserGroupsQuery();

  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [lastElement, setLastElement] = useState(null);
  const [activeGroup, setActiveGroup] = useState(null);
  const [feed, setFeed] = useState([]);
  const [pageNum, setPageNum] = useState(1);
  const pageRef = useRef(true);
  const { t } = useTranslation();

  const [getGroupInfo, { data: groupInfo, isLoading: isGroupInfoLoading }] =
    useLazyGetGroupInfoQuery();
  const [
    getUserFeedByGroup,
    {
      data: UserFeedByGroupData,
      isLoading: isUserFeedByGroupLoading,
      isFetching: isUserFeedByGroupFetching,
    },
  ] = useLazyGetUserFeedByGroupQuery();
  const [
    getUserFeedQuery,
    { data: userFeedData, isLoading: isUserFeedLoading, isFetching: isUserFeedFetching },
  ] = useLazyGetUserFeedQuery();

  useEffect(() => {
    if (!id) {
      loginAsGuest();
    } else {
      getSellsgramFollowings(id);
      getUserGroups(id);
    }
  }, [id]);

  useEffect(() => {
    if (id) {
      if (activeGroup) {
        getUserFeedByGroup({ groupId: activeGroup, userId: id, pageNum });
      } else {
        getUserFeedQuery({ userId: token ? id : -id, pageNum });
      }
    }
  }, [id, pageNum, activeGroup]);

  const observer = useRef(
    new IntersectionObserver((entries) => {
      const first = entries[0];
      if (first.isIntersecting) {
        setPageNum((no) => no + 1);
      }
    }),
  );

  useEffect(() => {
    const currentElement = lastElement;
    const currentObserver = observer.current;

    if (currentElement) {
      currentObserver.observe(currentElement);
    }

    return () => {
      if (currentElement) {
        currentObserver.unobserve(currentElement);
      }
    };
  }, [lastElement]);

  useEffect(() => {
    dispatchNullRoute();
  }, []);

  const handleEditGroup = (group) => {
    getGroupInfo(group.groupId).then(() => setShowEditModal(true));
  };

  const handleSelectActiveGroup = (groupId) => {
    setPageNum(1);
    setFeed([]);
    pageRef.current = null;
    setActiveGroup(activeGroup === groupId ? null : groupId);
  };

  useLayoutEffect(() => {
    const feeds = activeGroup ? UserFeedByGroupData?.products : userFeedData?.products;
    if (!isEmpty(feeds)) {
      if (pageRef.current === pageNum) {
        setFeed(feeds);
      } else {
        setFeed((prev) => [...prev, ...feeds]);
        pageRef.current = pageNum;
      }
    }
  }, [UserFeedByGroupData, userFeedData]);

  const someFeedQuantity = activeGroup
    ? UserFeedByGroupData?.quantity
    : userFeedData?.quantity;

  const isSomeFeedLoading =
    isUserFeedLoading ||
    isUserFeedByGroupLoading ||
    isUserFeedFetching ||
    isUserFeedByGroupFetching;

  const isLastElement = (index) => {
    return (
      index === feed?.length - 1 &&
      !isSomeFeedLoading &&
      someFeedQuantity > feed?.length
    );
  };
  return (
    <Layout>
      <div>
        <div className="tab-headingbar">
          <h2>{t("subscriptions")}</h2>
        </div>
        <div className="subscription-group-section">
          <div className="subscription-group-header d-flex align-items-center justify-content-between">
            <h3>{t("subscriptionGroups")}</h3>
            <button
              type="button"
              className="add-btn"
              onClick={() => setShowAddModal(true)}
            >
              <img src="/assets/images/icon-add-plus.svg" alt="" />
            </button>
          </div>
          <div className="subscription-group-data">
            <div className="subscription-group-data-inner d-flex">
              <RequestHandler
                isLoading={isGroupsFetching || isGroupsLoading}
                withAutoHeight
              >
                {groupsData?.length ? (
                  groupsData.map((group, key) => (
                    <SubscriptionGroupCard
                      group={group}
                      key={key}
                      active={activeGroup === group.groupId}
                      handleEditGroup={handleEditGroup}
                      isGroupInfoLoading={isGroupInfoLoading}
                      handleSelectActiveGroup={handleSelectActiveGroup}
                    />
                  ))
                ) : (
                  <p>{t("noGroupsInList")}</p>
                )}
              </RequestHandler>
            </div>
            <div className="shadow-div"></div>
          </div>
          <div className="product-feed-section">
            <div className="product-feed-heading">
              <h3>{t("productFeeds")}</h3>
            </div>
            <RequestHandler withAutoHeight isLoading={isSomeFeedLoading && !feed?.length}>
              <div className="product-feed-products">
                {feed?.length ? (
                  feed.map((product, index) => {
                    return isLastElement(index) ? (
                      <div
                        key={product.instaImageLink}
                        ref={setLastElement}
                        className="product-feed-product"
                      >
                        <ProductFeedCard product={product} />
                      </div>
                    ) : (
                      <div key={product.instaImageLink} className="product-feed-product">
                        <ProductFeedCard product={product} />
                      </div>
                    );
                  })
                ) : (
                  <p className="noGroupsTxt">{t("noUserFeed")}</p>
                )}
              </div>
            </RequestHandler>
          </div>
        </div>
      </div>
      {showAddModal && (
        <GroupAddModal
          userId={id}
          showAddModal={showAddModal}
          setShowAddModal={setShowAddModal}
          sellsgramFollowingsData={sellsgramFollowingsData}
          groupsRefetch={getUserGroups}
          isGroupsFetching={isGroupsFetching}
        />
      )}
      {showEditModal && (
        <GroupEditModal
          showEditModal={showEditModal}
          setShowEditModal={setShowEditModal}
          groupInfo={groupInfo}
          sellsgramFollowingsData={sellsgramFollowingsData}
          groupsRefetch={getUserGroups}
          isGroupsFetching={isGroupsFetching}
        />
      )}
    </Layout>
  );
};

Subscriptions.propTypes = {};

const mapStateToProps = (state) => ({
  id: state.auth.userId,
  token: state.auth.token,
});

export default connect(mapStateToProps, {
  dispatchNullRoute,
  loginAsGuest,
})(Subscriptions);
