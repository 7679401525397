import React, { useCallback, useState } from "react";
import { connect } from "react-redux";

import { addProductMaterial, editProductMaterial } from "../../../Redux/Products/actions";

const MaterialForm = ({
  addProductMaterial,
  productId,
  getMaterialsByProduct,
  setToggleTableContent,
  setProductMaterials,
  editProductMaterial,
  material = undefined,
  updatable = undefined,
}) => {
  const [materialName, setMaterialName] = useState(material ? material.materialName : "");
  const [materialPrice, setMaterialPrice] = useState(
    material ? material.materialPrice : "",
  );
  const [materialQuantity, setMaterialQuantity] = useState(
    material ? material.materialQuantity : "",
  );

  const submitAdd = useCallback(() => {
    const payload = { materialName, materialPrice, materialQuantity, productId };
    setProductMaterials
      ? setProductMaterials((prev) => [...prev, payload])
      : addProductMaterial(payload, getMaterialsByProduct, productId);
    setToggleTableContent(false);
  }, [materialName, materialPrice, materialQuantity]);

  const submitEdit = useCallback(() => {
    const payload = { ...material, materialName, materialPrice, materialQuantity };
    setProductMaterials
      ? setProductMaterials((prev) => prev.map((m, i) => (i === updatable ? payload : m)))
      : editProductMaterial(
          material.productMaterialId,
          payload,
          getMaterialsByProduct,
          productId,
        );
    setToggleTableContent(false);
  }, [materialName, materialPrice, materialQuantity]);

  return (
    <tr>
      <td>
        <input
          name="name"
          type={"text"}
          value={materialName}
          onChange={(e) => setMaterialName(e.target.value)}
        />
      </td>
      <td>
        <input
          name="price"
          type={"number"}
          value={materialPrice}
          onChange={(e) => setMaterialPrice(e.target.value)}
        />
      </td>
      <td>
        <input
          name="quantity"
          type={"number"}
          value={materialQuantity}
          onChange={(e) => setMaterialQuantity(e.target.value)}
        />
      </td>
      <td>
        <img
          style={{ cursor: "pointer" }}
          onClick={() => setToggleTableContent(false)}
          src="/assets/images/icon-cross.svg"
          alt=""
        />
      </td>
      <td>
        <img
          style={{ cursor: "pointer", width: "1.2em" }}
          onClick={() => (material ? submitEdit() : submitAdd())}
          src={`/assets/images/${material ? "check-circle.png" : "icon-add-other.svg"}`}
          alt=""
        />
      </td>
    </tr>
  );
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {
  addProductMaterial,
  editProductMaterial,
})(MaterialForm);
