import "./DeliveryInfo.scss";
import "react-phone-input-2/lib/style.css";
import { useTranslation } from "react-i18next";
import React, { useEffect } from "react";
import "../../checkout.css";
import { connect } from "react-redux";
import {
  getUserCartProducts,
  getHasUserBuyerDeliveries,
  pay,
} from "../../Redux/Cart/actions";
import DeliveryType from "./DeliveryType/DeliveryType";
import { SERVER_CALLBACK_URL, RESPONSE_URL_DOMAIN } from "../../Configs/constants";

const FONDY_PAYMENT_MODE_0 = parseInt(process.env.FONDY_PAYMENT_MODE_0) || 1521778;

const DeliveryInfo = ({
  setShowSuccessful,
  totalCartProducts,
  pay,
  userId,
  loading,
  getUserCartProducts,
  getHasUserBuyerDeliveries,
  totalLiqPayData,
  setProgress,
  hasUserBuyerDeliveries,
}) => {
  const current_host = window.location?.origin;
  const { t } = useTranslation();

  const [show, setShow] = React.useState(0);

  const [cardHolderName, setCardHolderName] = React.useState("");
  const [phoneNumber, setPhoneNumber] = React.useState("");
  const [cardNumber, setCardNumber] = React.useState("");
  const [expirationDate, setExpirationDate] = React.useState("");
  const [cvv2, setCVV2] = React.useState("");

  const [email, setEmail] = React.useState("");

  if (userId && totalCartProducts && totalCartProducts?.length) {
    const Options = {
      options: {
        methods: ["card", "banklinks_eu", "wallets", "local_methods"],
        methods_disabled: [],
        card_icons: ["mastercard", "visa", "maestro"],
        active_tab: "card",
        default_country: "UA",
        countries: ["PL", "IE", "GB", "CZ", "GE", "UA"],
        fields: false,
        title: "Sellsgram оплата:",
        link: "https://sellsgram.com/",
        full_screen: false,
        button: true,
        locales: [
          "cs",
          "de",
          "en",
          "es",
          "fr",
          "hu",
          "it",
          "ko",
          "lv",
          "pl",
          "ro",
          "sk",
          "uk",
        ],
        email: true,
        theme: {
          type: "light",
          preset: "navy_shimmer",
        },
      },
      params: {
        merchant_id: FONDY_PAYMENT_MODE_0,
        required_rectoken: "y",
        currency: "UAH",
        order_id:
          totalCartProducts[0].userId.toString() +
          "-" +
          totalCartProducts[0].sellerUserId.toString() +
          "-" +
          totalCartProducts.map((p) => p.orderId.toString()).join("-"),
        amount:
          totalCartProducts.map((p) => p.price * p.quantity).reduce((a, b) => a + b, 0) *
          100,
        order_desc: totalCartProducts.map((p) => p.shortName).join("\n\t, "),
        server_callback_url: SERVER_CALLBACK_URL,
        response_url: `${RESPONSE_URL_DOMAIN}/?redirect_url=${current_host}/shop-cart/`,
      },
      messages: {
        pl: {
          card_number: "Numer karty",
          my_title: "Cel płatności",
          my_order_desc: "Płatność testowa",
        },
        en: {
          card_number: "Card number",
          my_title: "Order description",
          my_order_desc: "Test order",
        },
      },
    };
    // eslint-disable-next-line no-undef
    fondy("#checkout-container", Options);
  }

  const reserverOrdersWaitingPayment = React.useCallback(async () => {
    const paymentToken = `${cardHolderName}|${cardNumber}|${expirationDate}|${cvv2}`;
    const orderIds = totalCartProducts.map((p) => p.orderId);
    await pay({ userId, phoneNumber, email, paymentToken, orderIds });
  }, [cardHolderName, email, phoneNumber, cardNumber, expirationDate, cvv2]);
  useEffect(() => {
    show !== totalCartProducts?.length
      ? setProgress(50)
      : async () => {
          setProgress(100);
          await reserverOrdersWaitingPayment();
        };
  }, []);

  return (
    <>
      {totalCartProducts?.map(
        (product, index) =>
          show === index && (
            <DeliveryType
              show={show}
              setShow={setShow}
              product={product}
              productLength={totalCartProducts.length}
              key={product.orderId}
            />
          ),
      )}
      {show === totalCartProducts?.length ? (
        <div className={`payment-info delivery-info-scroll`}>
          <div id="checkout-container"></div>
          <div className="payment-info-fields delivery-info">
            <div className="row">
              {
                <div className="d-flex flex-sm-row flex-column">
                  <div className="flex-one">
                    <button
                      type="submit"
                      className="btn delivery-info-bottom-btn"
                      onClick={() => setShow((prev) => prev - 1)}
                      disabled={!show}
                    >
                      {t("prev")}
                    </button>
                  </div>
                </div>
              }
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

const mapStateToProps = (state) => ({
  hasUserBuyerDeliveries: state.cart.hasUserBuyerDeliveries,
  userId: state.auth.userId,
  totalCartProducts: state.cart.totalCartProducts,
  totalLiqPayData: state.cart.totalLiqPayData,
  loading: state.cart.loading,
});

export default connect(mapStateToProps, {
  pay,
  getHasUserBuyerDeliveries,
  getUserCartProducts,
})(DeliveryInfo);
