import {
  FacebookLogo,
  InstagramLogo,
  TikTokLogo,
  YouTubeLogo,
} from "../Components/Icons";

const SOCIAL_LINKS = [
  {
    Icon: YouTubeLogo,
    name: "youtube",
    link: "https://www.youtube.com/@sellsgram",
  },
  {
    Icon: TikTokLogo,
    name: "tik-tok",
    link: "https://www.tiktok.com/@sellsgram",
  },
  {
    Icon: InstagramLogo,
    name: "instagram",
    link: "https://www.instagram.com/sellsgram/",
  },
  {
    Icon: FacebookLogo,
    name: "facebook",
    link: "https://www.facebook.com/sellsgram",
  },
];

export default SOCIAL_LINKS;
