import React from "react";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";

// eslint-disable-next-line react/display-name
const CustomMenuLocalDelivery = React.forwardRef(
  (
    {
      children,
      disabled,
      style,
      className,
      onChange,
      filter,
      "aria-labelledby": labeledBy,
    },
    ref,
  ) => {
    const { t } = useTranslation();

    return (
      <div ref={ref} style={style} className={className} aria-labelledby={labeledBy}>
        <input
          autoFocus
          className="mx-3 my-2 w-auto"
          placeholder={t("search")}
          onChange={onChange}
        />
        <ul className="list-unstyled">{children}</ul>
      </div>
    );
  },
);

export default CustomMenuLocalDelivery;
