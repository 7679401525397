import React from "react";

export const VisaLogo = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_310_3918)">
        <path
          d="M19.5875 10.971C19.5875 10.971 19.904 12.5213 19.9753 12.846H18.584L19.2508 11.0333C19.2425 11.046 19.388 10.6538 19.4713 10.4123L19.5875 10.971ZM23.9998 4.66651V19.3335C23.9996 19.8639 23.7888 20.3726 23.4137 20.7477C23.0386 21.1228 22.53 21.3336 21.9995 21.3338H1.99927C1.46883 21.3336 0.960179 21.1228 0.585103 20.7477C0.210026 20.3726 -0.000777785 19.8639 -0.000976562 19.3335V4.66651C-0.000777785 4.13607 0.210026 3.62742 0.585103 3.25234C0.960179 2.87726 1.46883 2.66646 1.99927 2.66626H21.9995C22.53 2.66646 23.0386 2.87726 23.4137 3.25234C23.7888 3.62742 23.9996 4.13607 23.9998 4.66651ZM6.35377 15.1335L8.98702 8.66701H7.21627L5.57902 13.0838L5.39977 12.1883L4.81627 9.21301C4.72027 8.80051 4.42477 8.68351 4.05802 8.66701H1.36252L1.33327 8.79601C1.95058 8.94745 2.54272 9.18744 3.09127 9.50851L4.58302 15.1335H6.35377ZM10.2875 15.1418L11.3375 8.66701H9.66277L8.61727 15.1418H10.2875ZM16.1165 13.0253C16.1248 12.288 15.6748 11.7255 14.7125 11.2628C14.1253 10.9665 13.7668 10.767 13.7668 10.4625C13.775 10.1873 14.0713 9.90376 14.729 9.90376C15.1574 9.89167 15.5831 9.97573 15.9748 10.1498L16.1248 10.2203L16.3543 8.82001C15.8755 8.63672 15.367 8.5434 14.8543 8.54476C13.1998 8.54476 12.0373 9.42826 12.029 10.686C12.0163 11.6153 12.8623 12.132 13.496 12.444C14.1418 12.7605 14.363 12.969 14.363 13.248C14.3548 13.6815 13.838 13.881 13.3588 13.881C12.692 13.881 12.3335 13.7768 11.7875 13.5353L11.567 13.431L11.3338 14.8853C11.7253 15.0645 12.4505 15.2228 13.2005 15.231C14.9585 15.2348 16.1045 14.364 16.1173 13.023L16.1165 13.0253ZM21.9995 15.1418L20.6495 8.66701H19.3535C18.9538 8.66701 18.6493 8.78401 18.4783 9.20476L15.9905 15.1425H17.7485C17.7485 15.1425 18.0358 14.3423 18.0988 14.172H20.249C20.2993 14.4015 20.4493 15.1425 20.4493 15.1425L21.9995 15.1418Z"
          fill="#4B85DC"
        />
      </g>
      <defs>
        <clipPath id="clip0_310_3918">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
