import React, { useState, useEffect } from "react";
import ModalContainer from "../../../../Components/Common/ModalWindow/ModalWindowComponent";
import { useTranslation } from "react-i18next";
import "./CancelModalStyles.scss";

function CancelModal({ isOpen, onClose, updateOrderStatus }) {
  const { t } = useTranslation();
  const [reasonMessage, setReasonMessage] = useState("");
  const [error, setErrorMsg] = useState(false);
  const onInputChange = ({ target: { value } }) => {
    setReasonMessage(value);
    if (!value) {
      setErrorMsg(true);
    } else {
      setErrorMsg(false);
    }
  };
  const onMessageSend = () => {
    if (!reasonMessage) {
      setErrorMsg(true);
    } else {
      updateOrderStatus(reasonMessage);
      onClose();
    }
  };
  const handleCloseModal = () => {
    setReasonMessage("");
    setErrorMsg(false);
    onClose();
  };
  return (
    <>
      {isOpen && (
        <ModalContainer onClose={handleCloseModal}>
          <div className="cancel-modal-container">
            <p className={error ? "error-msg" : ""}>{t("cancelReasons")}</p>
            <div className="cancel-input-wrapper">
              <input
                type="text"
                value={reasonMessage}
                placeholder={t("cancelReasons")}
                name="reason-message"
                onChange={onInputChange}
              />
            </div>
            <div className="cancel-buttons">
              <button className="back-button" onClick={handleCloseModal}>
                {t("back")}
              </button>
              <button className="cancel-button" onClick={onMessageSend}>
                {t("confirmCancellation")}
              </button>
            </div>
          </div>
        </ModalContainer>
      )}
    </>
  );
}

export default CancelModal;
