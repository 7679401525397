import React from "react";
import ReactDOM from "react-dom";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import ModalContainer from "../ModalWindow/ModalWindowComponent";
import "./ConfirmDialogStyles.scss";

const ConfirmDialog = ({ onClose, confirmText, onConfirm, confirmBtnText }) => {
  const { t } = useTranslation();
  return (
    <ModalContainer onClose={onClose}>
      <p className="confirm-title">{confirmText}</p>
      <div className="confirm-buttons">
        <button className="btn confirm-btn-outline-primary" onClick={onClose}>
          {t("cancel")}
        </button>
        <button className="btn btn-danger" onClick={onConfirm}>
          {confirmBtnText ? confirmBtnText : t("confirm")}
        </button>
      </div>
    </ModalContainer>
  );
};

ConfirmDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  confirmText: PropTypes.string.isRequired,
  onConfirm: PropTypes.func.isRequired,
  confirmBtnText: PropTypes.string,
};

ConfirmDialog.defaultProps = {
  confirmBtnText: "",
};

export default ConfirmDialog;
