import "./ItemDetailStyle.scss";
import "react-activity/dist/Sentry.css";

import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import ImageViewer from "react-simple-image-viewer";

import { Layout } from "../../Components/Layout/Layout";
import { RequestHandler } from "../../Components/RequestHandler/RequestHandler";
import { loginAsGuest } from "../../Redux/Auth/actions";
import { postUsersCartOrder } from "../../Redux/Cart/actions";
import { useLazyGetCartCountByUserIdQuery } from "../../Api/cartCountApi";
import {
  getAdditionalOptionsByProduct,
  getColorsByProduct,
  getMaterialsByProduct,
  getProductImages,
  getSizesByProduct,
  getUsersProduct,
} from "../../Redux/Products/actions";
import { filter } from "lodash";
import { toast } from "react-toastify";

const ItemDetail = (props) => {
  let {
    userId,
    product: usersProduct,
    loading,
    getColorsByProduct,
    getAdditionalOptionsByProduct,
    getProductImages,
    productImages,
    colorsByProduct,
    additionalOptionsByProduct,
    getMaterialsByProduct,
    materialsByProduct,
    loginAsGuest,
    getSizesByProduct,
    sizesByProduct,
    postUsersCartOrder,
  } = props;

  const { t } = useTranslation();

  const comingFrom = props?.location?.state?.comingFrom;
  const [count, setCount] = useState(1);
  const [selectedColor, setSelectedColor] = useState(null);
  const [selectedSize, setSelectedSize] = useState(null);
  const [selectedMaterial, setSelectedMaterial] = useState(null);
  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);

  const [lstSizes, setLstSizes] = useState([]);
  const [lstColors, setLstColors] = useState([]);
  const [lstMaterials, setLstMaterials] = useState([]);

  const openImageViewer = useCallback((index) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);
  const getUniqueArrayBykey = (key, arr) => {
    return [
      ...new Map(
        arr
          .filter((v) => v[key].length)
          .map((item) => {
            return [item[key], item];
          }),
      ).values(),
    ];
  };
  const getUniqueArrayFilterByKeys = (filterKey, filterValue, key, arr) => {
    return [
      ...new Map(
        arr
          .filter((v) => v[key] && v[key].length && v[filterKey] == filterValue)
          .map((item) => {
            return [item[key], item];
          }),
      ).values(),
    ];
  };
  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };
  useEffect(() => {
    getAdditionalOptionsByProduct(usersProduct?.productId);
    // getColorsByProduct(usersProduct?.productId);
    // getMaterialsByProduct(usersProduct?.productId);
    // getSizesByProduct(usersProduct?.productId);
    getProductImages(usersProduct?.productId);
  }, []);

  useEffect(() => {
    if (additionalOptionsByProduct) {
      setLstColors(getUniqueArrayBykey("colorScheme", additionalOptionsByProduct));
      setLstSizes(getUniqueArrayBykey("sizeName", additionalOptionsByProduct));
      setLstMaterials(getUniqueArrayBykey("materialName", additionalOptionsByProduct));
    }
  }, [additionalOptionsByProduct]);
  const [cartCountTrigger] = useLazyGetCartCountByUserIdQuery();

  const handleAddtoCart = async () => {
    if (!selectedColor && lstColors && lstColors.length > 0) {
      toast.error(t("please-select-color"));
      return false;
    }
    if (!selectedMaterial && lstMaterials && lstMaterials.length > 0) {
      toast.error(t("please-select-material"));
      return false;
    }
    if (!selectedSize && lstSizes && lstSizes.length > 0) {
      toast.error(t("please-select-size"));
      return false;
    }
    let payload = {
      userId: userId || (await loginAsGuest()).userId,
      productId: usersProduct?.productId,
      price: parseInt(
        Math.max(
          selectedColor?.price || null,
          selectedSize?.price || null,
          selectedMaterial?.price || null,
          usersProduct?.price || 0,
        ),
      ),
      quantity: parseInt(count),
      colorId: selectedColor?.id || 0,
      sizeId: selectedSize?.id || 0,
      materialId: selectedMaterial?.id || 0,
      localDeliveryId: 0,
      courierDeliveryCityId: 0,
      selfDeliveryCityId: 0,
      orderedDate: usersProduct?.createdDate,
    };

    await postUsersCartOrder(payload);
    await cartCountTrigger(userId);
    props.history.push({
      pathname: `/${usersProduct?.username}/`,
      state: { toTab: "cart-tab" },
    });
  };

  const selectProductPerk = (perk, perkInfo) => {
    switch (perk) {
      case "size":
        if (
          selectedSize &&
          additionalOptionsByProduct.find((x) => x.productOptionId === selectedSize.id)
            .sizeName === perkInfo.sizeName
        )
          setSelectedSize(null);
        else {
          setSelectedSize({ id: perkInfo.productOptionId, price: perkInfo.price });
          if (
            selectedMaterial &&
            additionalOptionsByProduct.some(
              (x) => x.productOptionId === perkInfo.productOptionId,
            )
          )
            setSelectedMaterial({ id: perkInfo.productOptionId, price: perkInfo.price });
          if (
            selectedColor &&
            additionalOptionsByProduct.some(
              (x) => x.productOptionId === perkInfo.productOptionId,
            )
          )
            setSelectedColor({ id: perkInfo.productOptionId, price: perkInfo.price });
        }
        break;
      case "material":
        if (
          selectedMaterial &&
          additionalOptionsByProduct.find(
            (x) => x.productOptionId === selectedMaterial.id,
          ).materialName === perkInfo.materialName
        )
          setSelectedMaterial(null);
        else {
          setSelectedMaterial({ id: perkInfo.productOptionId, price: perkInfo.price });
          if (
            selectedSize &&
            additionalOptionsByProduct.some(
              (x) => x.productOptionId === perkInfo.productOptionId,
            )
          )
            setSelectedSize({ id: perkInfo.productOptionId, price: perkInfo.price });
          if (
            selectedColor &&
            additionalOptionsByProduct.some(
              (x) => x.productOptionId === perkInfo.productOptionId,
            )
          )
            setSelectedColor({ id: perkInfo.productOptionId, price: perkInfo.price });
        }
        break;
      case "color":
        if (
          selectedColor &&
          additionalOptionsByProduct.find((x) => x.productOptionId === selectedColor.id)
            .colorScheme === perkInfo.colorScheme
        )
          setSelectedColor(null);
        else {
          setSelectedColor({ id: perkInfo.productOptionId, price: perkInfo.price });
          if (
            selectedSize &&
            additionalOptionsByProduct.some(
              (x) => x.productOptionId === perkInfo.productOptionId,
            )
          )
            setSelectedSize({ id: perkInfo.productOptionId, price: perkInfo.price });
          if (
            selectedMaterial &&
            additionalOptionsByProduct.some(
              (x) => x.productOptionId === perkInfo.productOptionId,
            )
          )
            setSelectedMaterial({ id: perkInfo.productOptionId, price: perkInfo.price });
        }
        break;
    }
  };
  useEffect(() => {
    const sizeName = selectedSize
      ? additionalOptionsByProduct.find((x) => x.productOptionId === selectedSize.id)
          .sizeName
      : null;
    const materialName = selectedMaterial
      ? additionalOptionsByProduct.find((x) => x.productOptionId === selectedMaterial.id)
          .materialName
      : null;
    const colorScheme = selectedColor
      ? additionalOptionsByProduct.find((x) => x.productOptionId === selectedColor.id)
          .colorScheme
      : null;

    setLstSizes(
      getUniqueArrayBykey(
        "sizeName",
        additionalOptionsByProduct.filter((x) =>
          !selectedMaterial && !selectedSize && !selectedColor
            ? true
            : (selectedMaterial ? x.materialName === materialName : true) &&
              (selectedSize ? x.sizeName === sizeName : true) &&
              (selectedColor ? x.colorScheme === colorScheme : true),
        ),
      ),
    );
    setLstMaterials(
      getUniqueArrayBykey(
        "materialName",
        additionalOptionsByProduct.filter((x) =>
          !selectedMaterial && !selectedSize && !selectedColor
            ? true
            : (selectedMaterial ? x.materialName === materialName : true) &&
              (selectedSize ? x.sizeName === sizeName : true) &&
              (selectedColor ? x.colorScheme === colorScheme : true),
        ),
      ),
    );
    setLstColors(
      getUniqueArrayBykey(
        "colorScheme",
        additionalOptionsByProduct.filter((x) =>
          !selectedMaterial && !selectedSize && !selectedColor
            ? true
            : (selectedMaterial ? x.materialName === materialName : true) &&
              (selectedSize ? x.sizeName === sizeName : true) &&
              (selectedColor ? x.colorScheme === colorScheme : true),
        ),
      ),
    );
  }, [selectedMaterial, selectedColor, selectedSize]);

  return (
    <RequestHandler isLoading={loading}>
      <div className="gallery">
        {isViewerOpen && (
          <ImageViewer
            shown={isViewerOpen}
            src={
              productImages?.length
                ? [
                    usersProduct?.instaImageLink
                      ? usersProduct.instaImageLink
                      : usersProduct.productImageLink
                      ? usersProduct.productImageLink
                      : "/assets/images/detail-img.png",
                    ...productImages.map((item) => item.imageLink),
                  ]
                : [
                    usersProduct?.instaImageLink
                      ? usersProduct.instaImageLink
                      : usersProduct.productImageLink
                      ? usersProduct.productImageLink
                      : "/assets/images/detail-img.png",
                  ]
            }
            onClose={closeImageViewer}
            disableScroll={false}
            closeOnClickOutside={true}
            currentIndex={currentImage}
            backgroundStyle={{
              backgroundColor: "rgba(0,0,0,0.4)",
            }}
          ></ImageViewer>
        )}
      </div>
      <Layout>
        <div className="inner-heading d-flex align-items-center justify-content-between">
          <h2>
            <div type="button" className="btn-nostyle d-flex align-items-center">
              <Link
                className=" mr-3"
                to={{
                  pathname:
                    comingFrom === "Subscriptions" ? "/" : `/${usersProduct.username}/`,
                  state: {
                    toTab:
                      comingFrom === "Subscriptions"
                        ? "favorite-tab"
                        : comingFrom === "Cart"
                        ? "cart-tab"
                        : "shop-tab",
                  },
                }}
              >
                <img src="/assets/images/icon-back.svg" alt="back icon" />
              </Link>
              <Link
                to={{
                  pathname: `/${usersProduct.username}/`,
                }}
              >
                {usersProduct.username}
              </Link>
            </div>
          </h2>
        </div>
        <div className="mainInfoContainer">
          <div className="flex-one mr-md-4 mr-0 mb-5">
            <div
              className={
                productImages[0]
                  ? "product-detail-imgs"
                  : "product-detail-imgs product-detail-one-img"
              }
            >
              <div className="product-detail-imgs-Main">
                <img
                  className="product-detail-imgs-Main"
                  onClick={() => openImageViewer(0)}
                  src={
                    usersProduct?.instaImageLink
                      ? usersProduct.instaImageLink
                      : usersProduct.productImageLink
                      ? usersProduct.productImageLink
                      : "/assets/images/detail-img.png"
                  }
                  alt="img"
                />
                <div className="insta-icon">
                  <img src="/assets/images/logo_sellsgram.png" alt="" />
                </div>
              </div>
              {productImages?.length >= 0
                ? productImages[0] && (
                    <div className="mainInfoItemsWrapper">
                      <div className="product-detail-thumbnail">
                        <img
                          onClick={() => openImageViewer(1)}
                          className="item-detail-mini-image"
                          src={productImages[0].imageLink}
                          alt="img"
                        />
                      </div>
                    </div>
                  )
                : null}
              {productImages?.length >= 1
                ? productImages[1] && (
                    <div className="mainInfoItemsWrapper">
                      <div className="product-detail-thumbnail">
                        <img
                          onClick={() => openImageViewer(2)}
                          className="item-detail-mini-image"
                          src={
                            productImages[1]
                              ? productImages[1].imageLink
                              : "/assets/images/detail-img.png"
                          }
                          alt="img"
                        />
                      </div>
                    </div>
                  )
                : null}
              {productImages?.length >= 2
                ? productImages[2] && (
                    <div className="mainInfoItemsWrapper">
                      <div className="product-detail-thumbnail">
                        <img
                          onClick={() => openImageViewer(3)}
                          className="item-detail-mini-image"
                          src={
                            productImages[2]
                              ? productImages[2].imageLink
                              : "/assets/images/detail-img.png"
                          }
                          alt="img"
                        />
                      </div>
                    </div>
                  )
                : null}
            </div>
          </div>
          <div className="flex-one mr-md-4 mr-0 mb-5 w-100">
            <div className="product-detail-text">
              <h2>
                {usersProduct?.shortName ? usersProduct.shortName : "No title available"}
              </h2>
              <p style={{ whiteSpace: "break-spaces", wordBreak: "break-word" }}>
                {usersProduct?.description
                  ? usersProduct.description
                      .split("\n")
                      .map((str) => <div>{str.length > 0 ? str : <span> </span>}</div>)
                  : usersProduct.descritpion
                  ? usersProduct.description
                      .split("\n")
                      .map((str) => <div>{str.length > 0 ? str : <span> </span>}</div>)
                  : "No description available"}
              </p>
              <p className="price-tag">
                ₴
                {Math.max(
                  selectedColor?.price || null,
                  selectedSize?.price || null,
                  selectedMaterial?.price || null,
                ) ||
                  usersProduct?.price ||
                  "N/A"}
              </p>
            </div>
          </div>
          <div className="flex-one w-100">
            <div className="product-detail-card">
              {lstColors?.length ? (
                <>
                  <div className="selection-div pick-color">
                    <h4>{t("color")}</h4>
                    <ul className="d-flex align-items-center">
                      {lstColors?.length ? (
                        lstColors.map((color, key) => (
                          <li key={key}>
                            <button
                              type="button"
                              className={`btn-nostyle ${
                                selectedColor &&
                                color?.colorScheme ===
                                  additionalOptionsByProduct.find(
                                    (x) => x.productOptionId === selectedColor?.id,
                                  ).colorScheme
                                  ? "selected"
                                  : ""
                              }`}
                              style={{
                                backgroundColor: color?.colorScheme,
                              }}
                              onClick={() => selectProductPerk("color", color)}
                              disabled={color?.quantity === 0}
                            >
                              {selectedColor &&
                                color?.colorScheme ===
                                  additionalOptionsByProduct.find(
                                    (x) => x.productOptionId === selectedColor?.id,
                                  ).colorScheme && (
                                  <img
                                    src="/assets/images/icon-colorpick-tick.svg"
                                    alt="tick"
                                  />
                                )}
                            </button>
                          </li>
                        ))
                      ) : (
                        <p>{t("noColorsAvailable")}</p>
                      )}
                    </ul>
                  </div>
                </>
              ) : null}

              <div className="selection-div pick-quantity d-flex align-items-center justify-content-between">
                <h4>{t("quantity")}</h4>
                <div className="quantity-add d-flex align-items-center">
                  <button
                    type="button"
                    className="btn-nostyle"
                    onClick={() => count > 1 && setCount(count - 1)}
                  >
                    <img src="/assets/images/icon-minus.svg" alt="" />
                  </button>
                  <p>{count}</p>
                  <button
                    type="button"
                    className="btn-nostyle"
                    onClick={() => count < 1000 && setCount(count + 1)}
                  >
                    <img src="/assets/images/icon-plus.svg" alt="" />
                  </button>
                </div>
              </div>

              {lstSizes?.length ? (
                <>
                  <div className="selection-div pick-size">
                    <h4>{t("size")}</h4>
                    <div className="d-flex flex-wrap align-items-center">
                      {lstSizes?.length ? (
                        lstSizes.map((size, key) => (
                          <button
                            key={key}
                            type="button"
                            onClick={() => selectProductPerk("size", size)}
                            className={`btn btn-border d-flex align-items-center${
                              selectedSize &&
                              size?.sizeName ===
                                additionalOptionsByProduct.find(
                                  (x) => x.productOptionId === selectedSize?.id,
                                ).sizeName
                                ? " active"
                                : ""
                            }`}
                            disabled={size?.quantity === 0}
                          >
                            {selectedSize &&
                              size?.sizeName ===
                                additionalOptionsByProduct.find(
                                  (x) => x.productOptionId === selectedSize?.id,
                                ).sizeName && (
                                <img
                                  src="/assets/images/icon-colorpick-tick.svg"
                                  alt="tick"
                                />
                              )}{" "}
                            {size?.sizeName}
                          </button>
                        ))
                      ) : (
                        <p>{t("noSizesAvailable")}</p>
                      )}
                    </div>
                  </div>
                </>
              ) : null}

              {lstMaterials?.length ? (
                <>
                  <div className="selection-div pick-material">
                    <h4>{t("material")}</h4>
                    <div className="d-flex flex-wrap align-items-center">
                      {lstMaterials?.length ? (
                        lstMaterials.map((material, key) => (
                          <button
                            key={key}
                            type="button"
                            onClick={() => selectProductPerk("material", material)}
                            className={`btn btn-border d-flex align-items-center ${
                              selectedMaterial &&
                              material?.materialName ===
                                additionalOptionsByProduct.find(
                                  (x) => x.productOptionId === selectedMaterial?.id,
                                ).materialName
                                ? " active"
                                : ""
                            }`}
                            disabled={material?.quantity === 0}
                          >
                            {selectedMaterial &&
                              material?.materialName ===
                                additionalOptionsByProduct.find(
                                  (x) => x.productOptionId === selectedMaterial?.id,
                                ).materialName && (
                                <img
                                  src="/assets/images/icon-colorpick-tick.svg"
                                  alt="tick"
                                />
                              )}
                            {material?.materialName}
                          </button>
                        ))
                      ) : (
                        <p>{t("noMaterialsAvailable")}</p>
                      )}
                    </div>
                  </div>
                </>
              ) : null}

              <div className="add-cart-btn">
                <button
                  className="btn w-100 justify-content-center"
                  onClick={handleAddtoCart}
                >
                  {t("addToCart")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </RequestHandler>
  );
};

ItemDetail.propTypes = {};

const mapStateToProps = (state) => ({
  userId: state.auth.userId,
  usersProduct: state.products.usersProduct,
  loading: state.products.loading,
  colorsByProduct: state.products.colorsByProduct,
  additionalOptionsByProduct: state.products.additionalOptionsByProduct,
  materialsByProduct: state.products.materialsByProduct,
  sizesByProduct: state.products.sizesByProduct,
  createdProduct: state.products.createdProduct,
  productImages: state.products.productImages,
});

export default connect(mapStateToProps, {
  getUsersProduct,
  getColorsByProduct,
  getAdditionalOptionsByProduct,
  getMaterialsByProduct,
  getSizesByProduct,
  postUsersCartOrder,
  loginAsGuest,
  getProductImages,
})(ItemDetail);

function viewAdditionalOptionLogic(
  perk,
  perkInfo,
  getUniqueArrayBykey,
  additionalOptionsByProduct,
  getUniqueArrayFilterByKeys,
  filterKey,
  filterValue,
  setLstColors,
  setLstSizes,
  setLstMaterials,
  selectedColor,
  selectedSize,
  selectedMaterial,
  setSelectedColor,
  setSelectedSize,
  setSelectedMaterial,
) {
  switch (perk) {
    case "size":
      if (selectedSize?.id === perkInfo.productOptionId) setSelectedSize(null);
      else setSelectedSize({ id: perkInfo.productOptionId, price: perkInfo.price });
      break;
    case "material":
      if (selectedMaterial?.id === perkInfo.productOptionId) setSelectedMaterial(null);
      else setSelectedMaterial({ id: perkInfo.productOptionId, price: perkInfo.price });

      break;
    case "color":
      if (selectedColor?.id === perkInfo.productOptionId) setSelectedColor(null);
      else setSelectedColor({ id: perkInfo.productOptionId, price: perkInfo.price });
      break;
  }
}

function changeMaterialId(
  selectedMaterial,
  l1,
  perkInfo,
  setSelectedMaterial,
  additionalOptionsByProduct,
  selectedcolor,
  selectedSize,
) {
  if (selectedMaterial) {
    if (
      l1.some(
        (x) =>
          x.productOptionId === perkInfo.productOptionId &&
          (selectedcolor ? x.productOptionId === selectedcolor.id : true) &&
          (selectedSize ? x.productOptionId === selectedSize.id : true),
      )
    )
      setSelectedMaterial({
        id: perkInfo.productOptionId,
        price: perkInfo.price,
      });
    else if (
      l1.some(
        (x) =>
          x.materialName ===
          additionalOptionsByProduct.find(
            (x) =>
              x.productOptionId === selectedMaterial.id &&
              (selectedcolor ? x.productOptionId === selectedcolor.id : true) &&
              (selectedSize ? x.productOptionId === selectedSize.id : true),
          ).materialName,
      )
    ) {
      const tmp = l1.find(
        (x) =>
          x.materialName ===
          additionalOptionsByProduct.find(
            x.productOptionId === selectedMaterial.id &&
              (selectedcolor ? x.productOptionId === selectedcolor.id : true) &&
              (selectedSize ? x.productOptionId === selectedSize.id : true),
          ).materialName,
      );
      setSelectedMaterial({
        id: tmp.productOptionId,
        price: tmp.price,
      });
    } else setSelectedMaterial(null);
  }
}

function changeColorId(
  selectedColor,
  l1,
  perkInfo,
  setSelectedColor,
  additionalOptionsByProduct,
  selectedMaterial,
  selectedSize,
) {
  if (selectedColor) {
    if (
      l1.some(
        (x) =>
          x.productOptionId === perkInfo.productOptionId &&
          (selectedMaterial ? x.productOptionId === selectedMaterial.id : true) &&
          (selectedSize ? x.productOptionId === selectedSize.id : true),
      )
    )
      setSelectedColor({
        id: perkInfo.productOptionId,
        price: perkInfo.price,
      });
    else if (
      l1.some(
        (x) =>
          x.colorScheme ===
          additionalOptionsByProduct.find(
            (x) =>
              x.productOptionId === selectedColor.id &&
              (selectedMaterial ? x.productOptionId === selectedMaterial.id : true) &&
              (selectedSize ? x.productOptionId === selectedSize.id : true),
          ).colorScheme,
      )
    ) {
      const tmp = l1.find(
        (x) =>
          x.colorScheme ===
          additionalOptionsByProduct.find(
            (x) =>
              x.productOptionId === selectedColor.id &&
              (selectedMaterial ? x.productOptionId === selectedMaterial.id : true) &&
              (selectedSize ? x.productOptionId === selectedSize.id : true),
          ).colorScheme,
      );
      setSelectedColor({
        id: tmp.productOptionId,
        price: tmp.price,
      });
    } else setSelectedColor(null);
  }
}

function changeSizeId(
  selectedSize,
  l2,
  perkInfo,
  setSelectedSize,
  additionalOptionsByProduct,
  selectedColor,
  selectedMaterial,
) {
  if (selectedSize) {
    if (
      l2.some(
        (x) =>
          x.productOptionId === perkInfo.productOptionId &&
          (selectedColor ? x.productOptionId === selectedColor.id : true) &&
          (selectedMaterial ? x.productOptionId === selectedMaterial.id : true),
      )
    )
      setSelectedSize({
        id: perkInfo.productOptionId,
        price: perkInfo.price,
      });
    else if (
      l2.some(
        (x) =>
          x.sizeName ===
          additionalOptionsByProduct.find(
            (x) =>
              x.productOptionId === selectedSize.id &&
              (selectedColor ? x.productOptionId === selectedColor.id : true) &&
              (selectedMaterial ? x.productOptionId === selectedMaterial.id : true),
          ).sizeName,
      )
    ) {
      const tmp = l2.find(
        (x) =>
          x.sizeName ===
          additionalOptionsByProduct.find(
            (x) =>
              x.productOptionId === selectedSize.id &&
              (selectedColor ? x.productOptionId === selectedColor.id : true) &&
              (selectedMaterial ? x.productOptionId === selectedMaterial.id : true),
          ).sizeName,
      );
      setSelectedSize({
        id: tmp.productOptionId,
        price: tmp.price,
      });
    } else setSelectedSize(null);
  }
}
