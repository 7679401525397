export const GET_USER_INFO_REQUEST_SENT = "GET_USER_INFO_REQUEST_SENT";
export const GET_USER_INFO_SUCCESSFUL = "GET_USER_INFO_SUCCESSFUL";
export const GET_USER_INFO_FAILURE = "GET_USER_INFO_FAILURE";

export const GET_USER_FEED_REQUEST_SENT = "GET_USER_FEED_REQUEST_SENT";
export const GET_USER_FEED_SUCCESSFUL = "GET_USER_FEED_SUCCESSFUL";
export const GET_USER_FEED_FAILURE = "GET_USER_FEED_FAILURE";

export const GET_USER_INSTA_POSTS_REQUEST_SENT = "GET_USER_INSTA_POSTS_REQUEST_SENT";
export const GET_USER_INSTA_POSTS_SUCCESSFUL = "GET_USER_INSTA_POSTS_SUCCESSFUL";
export const GET_USER_INSTA_POSTS_FAILURE = "GET_USER_INSTA_POSTS_FAILURE";

export const GET_USER_FEED_BY_GROUP_REQUEST_SENT = "GET_USER_FEED_BY_GROUP_REQUEST_SENT";
export const GET_USER_FEED_BY_GROUP_SUCCESSFUL = "GET_USER_FEED_BY_GROUP_SUCCESSFUL";
export const GET_USER_FEED_BY_GROUP_FAILURE = "GET_USER_FEED_BY_GROUP_FAILURE";

export const GET_USER_REQUESTED_ORDERS_SENT = "GET_USER_REQUESTED_ORDERS_SENT";
export const GET_USER_REQUESTED_ORDERS_SUCCESSFUL = "GET_USER_REQUESTED_ORDERS_SUCCESSFUL";
export const GET_USER_REQUESTED_ORDERS_FAILURE = "GET_USER_REQUESTED_ORDERS_FAILURE";

export const GET_REQUESTS_REQUEST_SENT = "GET_REQUESTS_REQUEST_SENT";
export const GET_REQUESTS_SUCCESSFUL = "GET_REQUESTS_SUCCESSFUL";
export const GET_REQUESTS_FAILURE = "GET_REQUESTS_FAILURE";

export const GET_IS_FOLLOWING_REQUEST_SENT = "GET_IS_FOLLOWING_REQUEST_SENT";
export const GET_IS_FOLLOWING_SUCCESSFUL = "GET_IS_FOLLOWING_SUCCESSFUL";
export const GET_IS_FOLLOWING_FAILURE = "GET_IS_FOLLOWING_FAILURE";

export const FOLLOW_REQUEST_SENT = "FOLLOW_REQUEST_SENT";
export const FOLLOW_SUCCESSFUL = "FOLLOW_SUCCESSFUL";
export const FOLLOW_FAILURE = "FOLLOW_FAILURE";

export const UNFOLLOW_REQUEST_SENT = "UNFOLLOW_REQUEST_SENT";
export const UNFOLLOW_SUCCESSFUL = "UNFOLLOW_SUCCESSFUL";
export const UNFOLLOW_FAILURE = "UNFOLLOW_FAILURE";

export const UPDATE_ORDER_STATUS_SENT = "UPDATE_ORDER_STATUS_SENT";
export const UPDATE_ORDER_STATUS_SUCCESS = "UPDATE_ORDER_STATUS_SUCCESS";
export const UPDATE_ORDER_STATUS_FAILURE = "UPDATE_ORDER_STATUS_FAILURE";

export const BULK_UPDATE_ORDER_STATUS_SENT = "BULK_UPDATE_ORDER_STATUS_SENT";
export const BULK_UPDATE_ORDER_STATUS_SUCCESS = "BULK_UPDATE_ORDER_STATUS_SUCCESS";
export const BULK_UPDATE_ORDER_STATUS_FAILURE = "BULK_UPDATE_ORDER_STATUS_FAILURE";