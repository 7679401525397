import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { isEmpty, xor } from "lodash";

import "./OrdersTable.scss";
import { RequestHandler } from "../../../Components/RequestHandler/RequestHandler";
import StatusCell from "./StatusCell/StatusCell";
import ActionCell from "./ActionCell/ActionCell";
import SearchFilter from "./SearchFilter/SearchFilter";
import Dropdown from "./DropdownComponent/DropdownComponent";
import TablePaginationComponent from "./TablePagination/TablePaginationComponent";
import { bulkUpdateOrderStatuses, getRequests } from "../../../Redux/Profile/actions";
import { updateOrderStatus } from "../../../Redux/Profile/actions";
import OrderInfoComponent from "./OrderInfoComponent/OrderInfoComponent";
import getOrderStatuses from "../../../Helpers/getOrderStatuses";
import { toast } from "react-toastify";
function Table({
  userRequestedOrders,
  updateOrderStatus,
  bulkUpdateOrderStatuses,
  myUserId,
  getRequests,
  loading,
  total,
}) {
  const { t } = useTranslation();
  const [filter, setFilter] = useState("");
  const [pageSize, setPageSize] = useState(50);
  const [statusId, setOrderStatus] = useState(null);
  const [page, setPage] = useState(1);
  const [isOrderInfoOpen, setIsOrderInfoOpen] = useState(false);
  const [orderId, setOrderId] = useState(null);
  const [rowLoading, setRowLoading] = useState([]);
  const [isLoadingBulkPayout, setIsLoadingBulkPayout] = useState(false);

  useEffect(() => {
    if (myUserId) {
      getRequests({
        userId: myUserId,
        filter,
        pageSize,
        statusId,
        pageNumber: page,
      });
    }
  }, [myUserId, filter, pageSize, statusId, page]);
  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
  const onShowOrderInfo = (orderId) => {
    setOrderId(orderId);
    setIsOrderInfoOpen(true);
  };
  const onPageSizeChange = (size) => {
    setPage(1);
    setPageSize(size);
  };
  const onCloseOrderInfo = () => {
    setIsOrderInfoOpen(false);
    setOrderId(null);
  };
  const onBulkUpdateOrderStatuses = async () => {
    setIsLoadingBulkPayout(true);
    for (let i = 0; i < ordersToPayout.length; i++)
      setRowLoading((prevState) => xor(prevState, [ordersToPayout[i].orderId]));
    try {
      // await delay(2000);
      await bulkUpdateOrderStatuses({
        sellerUserId: myUserId,
        orderIds: ordersToPayout.map((x) => x.orderId),
      });
      for (let i = 0; i < ordersToPayout.length; i++) {
        let index = userRequestedOrders.findIndex(
          (x) => x.orderId == ordersToPayout[i].orderId,
        );
        userRequestedOrders[index].status = {
          statusId: 16,
          status: "TO PAYOUT",
        };
        userRequestedOrders[index].orderPayoutDate = null;
      }
    } catch (e) {
      toast.error(e.response?.data || e.message);
    }
    for (let i = 0; i < ordersToPayout.length; i++)
      setRowLoading((prevState) => xor(prevState, [ordersToPayout[i].orderId]));
    setIsLoadingBulkPayout(false);
  };
  const onUpdateOrderStatus = async (orderId) => {
    setRowLoading((prevState) => xor(prevState, [orderId]));
    try {
      await updateOrderStatus({
        sellerUserId: myUserId,
        orderId,
        statusId: 16,
      });
    } catch (e) {
      toast.error(e.response?.data || e.message);
    }
    setRowLoading((prevState) => xor(prevState, [orderId]));
  };
  const ordersToPayout = userRequestedOrders.filter(
    (x) => x.orderPayoutDate && x.orderPayoutDate.isReadyForPayout,
  );
  const showTotalPayout = ordersToPayout.length > 0;

  return (
    <div className="orders-table">
      <OrderInfoComponent
        isOpen={isOrderInfoOpen}
        onOrderInfoClose={onCloseOrderInfo}
        orderId={orderId}
      />
      <div className="orders-table-header">
        <SearchFilter onInputChange={(filter) => setFilter(filter)} />
        <Dropdown
          onSelectChange={(statusId) => setOrderStatus(statusId)}
          list={getOrderStatuses}
        />
        {showTotalPayout ? (
          <>
            <br />
            <button
              className="to-payout-button"
              onClick={() => onBulkUpdateOrderStatuses()}
              disabled={isLoadingBulkPayout}
            >
              {t("payoutAll")}
              {ordersToPayout.reduce((prev, current) => prev + +current.subtotal, 0)}
              ₴
            </button>
          </>
        ) : null}
      </div>
      <RequestHandler isLoading={loading}>
        <div className="orders-table-body">
          {isEmpty(userRequestedOrders) ? (
            <p className="no-data">
              {filter ? t("emptyTableByFilter") : t("emptyTable")}
            </p>
          ) : (
            userRequestedOrders.map(
              ({
                productName,
                phoneNumber,
                billToName,
                purchasedDate,
                subtotal,
                currency,
                status,
                orderId,
                orderPayoutDate,
              }) => (
                <div
                  key={orderId}
                  className={`orders-table-row ${
                    rowLoading.includes(orderId) ? "loading" : ""
                  }`}
                  onClick={() => onShowOrderInfo(orderId)}
                >
                  <div className="default-cell">
                    <p>№{orderId}</p>
                    <p>{productName}</p>
                  </div>
                  <div className="default-cell">
                    <p>{phoneNumber || "-"}</p>
                    <p>{billToName || "-"}</p>
                  </div>
                  <div className="default-cell">
                    <p>{moment(new Date(purchasedDate + "Z")).format("HH:mm")}</p>
                    <p>{moment(new Date(purchasedDate + "Z")).format("YYYY.MM.DD")}</p>
                  </div>
                  <div className="default-cell">
                    <p>{t("orderAmount")}</p>
                    <p>
                      {currency === "UAH" ? "₴" : "$"}
                      {subtotal}
                    </p>
                  </div>
                  <StatusCell
                    statusId={status.statusId}
                    orderPayoutDate={orderPayoutDate}
                  />
                  <ActionCell
                    isReadyForPayout={orderPayoutDate?.isReadyForPayout}
                    onUpdateOrderStatus={() => onUpdateOrderStatus(orderId)}
                  />
                </div>
              ),
            )
          )}
        </div>
      </RequestHandler>
      <div className="orders-table-footer">
        <TablePaginationComponent
          total={total}
          page={page}
          onPageChange={(pageNumber) => setPage(pageNumber)}
          onPageSizeChange={onPageSizeChange}
        />
      </div>
    </div>
  );
}

Table.propTypes = {
  myUserId: PropTypes.number,
  getRequests: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  userRequestedOrders: PropTypes.array.isRequired,
  total: PropTypes.number.isRequired,
};
Table.defaultProps = {
  userRequestedOrders: [],
  total: 0,
};

const mapStateToProps = (state) => ({
  userRequestedOrders: state.profile.userRequestedOrders,
  total: state.profile.total,
  loading: state.profile.loading,
  myUserId: state.auth.userId,
});

export default connect(mapStateToProps, {
  getRequests,
  updateOrderStatus,
  bulkUpdateOrderStatuses,
})(Table);
