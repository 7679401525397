import "./MyOrders.scss";

import React, { useEffect } from "react";
import { connect } from "react-redux";

import { BackWithTitle } from "../../Components/BackWithTitle/BackWithTitle";
import { Layout } from "../../Components/Layout/Layout";
import { RequestHandler } from "../../Components/RequestHandler/RequestHandler";
import { SearchFilter } from "./MyOrdersComponents/SearchFilter/SearchFilter";
import { getOrderDetails, getOrdersByUserId } from "../../Redux/MyOrders/actions";
import OrderItem from "./MyOrdersComponents/OrderItem/OrderItem";
import { isEmpty } from "lodash";
import moment from "moment";
import { useTranslation } from "react-i18next";

const MyOrders = ({
  getOrdersByUserId,
  getOrderDetails,
  ordersList,
  myUserId,
  isOrdersLoading,
  orderIdDetailsLoading,
}) => {
  const { t, i18n } = useTranslation();
  useEffect(() => {
    getOrdersByUserId(myUserId);
  }, [i18n.language]);

  return (
    <Layout>
      <div className="inner-heading my-orders-header">
        <BackWithTitle titleText="myOrders" />
        <SearchFilter getOrdersByUserId={getOrdersByUserId} myUserId={myUserId} />
      </div>
      <RequestHandler isLoading={isOrdersLoading}>
        {!isEmpty(ordersList) ? (
          ordersList.map((oneDay) => {
            return (
              <div
                className="pb-5 my-orders-wrapper"
                key={oneDay.date ? oneDay.date : ""}
              >
                <div className="orders-date mb-3">
                  {oneDay.date
                    ? moment(new Date(oneDay.date + "Z"))
                        .locale(i18n.language === "en" ? "en" : "uk")
                        .format("DD MMMM YYYY")
                    : t("noActiveOrders")}
                </div>
                <div>
                  {oneDay?.orders?.map((order) => (
                    <OrderItem
                      orderIdDetailsLoading={orderIdDetailsLoading}
                      getOrderDetails={getOrderDetails}
                      key={order?.orderId}
                      order={order}
                    />
                  ))}
                </div>
              </div>
            );
          })
        ) : (
          <p>No records</p>
        )}
      </RequestHandler>
    </Layout>
  );
};

const mapStateToProps = (state) => ({
  ordersList: state.myOrders.ordersList,
  isOrdersLoading: state.myOrders.isOrdersLoading,
  orderIdDetailsLoading: state.myOrders.orderIdDetailsLoading,
  myUserId: state.auth.userId,
});

export default connect(mapStateToProps, {
  getOrdersByUserId,
  getOrderDetails,
})(MyOrders);
