import "./Header.scss";

import { GoogleLogin } from "@react-oauth/google";
import { isEmpty } from "lodash";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";

import {
  googleLogin,
  googleLoginFail,
  clearGoogleLoginError,
} from "../../Redux/GoogleAuth/actions";
import LanguageSelector from "./LanguageSelector";
import { NavButtonsGroup } from "./NavButtons/NavButtonsGroup";

const Header = ({
  loggedInUsername,
  googleUser,
  googleLogin,
  googleLoginFail,
  clearGoogleLoginError,
  googleError,
}) => {
  const history = useHistory();
  const { t } = useTranslation();

  useEffect(() => {
    if (!isEmpty(googleError)) {
      toast.error(t("googleLoginError"));
      clearGoogleLoginError();
    }
  }, [googleError]);

  const redirectAfterLogin = (redirectTo) => history.push(redirectTo);
  const onGoogleLogin = (user) => googleLogin(user, redirectAfterLogin);
  return (
    <div className="navbar-main">
      <div className="d-flex justify-content-between align-items-center flex-wrap">
        <div className="logo basis-25">
          <div
            style={{ cursor: "pointer" }}
            onClick={() => {
              history.push(`/${loggedInUsername}/`);
            }}
          >
            {/* <Link to={`/${loggedInUsername}/`}> */}
            <img src="/assets/images/logo.svg" alt="logo" />
            {/* </Link> */}
          </div>
        </div>
        <NavButtonsGroup
          className="d-none d-sm-flex"
          loggedInUsername={loggedInUsername}
        />
        <div className="header-right-buttons">
          <LanguageSelector />
          {isEmpty(googleUser) && (
            <div className="google-button-wrap">
              <GoogleLogin
                onSuccess={onGoogleLogin}
                shape="circle"
                text="signin_with"
                onError={() => googleLoginFail(t("googleLoginError"))}
              />
            </div>
          )}
        </div>
      </div>
      <NavButtonsGroup className="d-flex d-sm-none" loggedInUsername={loggedInUsername} />
    </div>
  );
};

Header.propTypes = {
  loggedInUsername: PropTypes.string,
  googleLogin: PropTypes.func.isRequired,
  googleLoginFail: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  loggedInUsername: state.auth.me?.instagramUsername,
  googleUser: state.googleAuth.googleUser,
  googleError: state.googleAuth.error,
});

export default connect(mapStateToProps, {
  googleLogin,
  googleLoginFail,
  clearGoogleLoginError,
})(Header);
