import "./404Style.scss";

import React from "react";
import { Link } from "react-router-dom";

const NotFound = () => (
  <div className="main">
    <Link to="/" className="logo">
      <img src="/assets/images/logo.svg" alt="logo" />
    </Link>
    <div className="not-found-section">
      <h1>
        Page not found... <br /> Don't call 911!
      </h1>
      <div className="bottom-nav">
        <p className="bottom-nav_title">Here are the alt links:</p>
        <ul className="bottom-nav_list">
          <li>
            <Link to="/">Store</Link>
          </li>
          <li>
            <Link to="/subscriptions/">Subscription</Link>
          </li>
          <li>
            <Link to="/shop-cart/">Cart</Link>
          </li>
        </ul>
      </div>
    </div>
  </div>
);

export default NotFound;
