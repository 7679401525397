import "./ProductProperties.css";

import React, { useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";

import { deleteProductOther, getOthersByProduct } from "../../../Redux/Products/actions";
import OtherForm from "../PropertyForms/OtherForm";

const OtherTable = ({
  othersByProduct,
  getOthersByProduct,
  deleteProductOther,
  productId,
  setProductOthers,
  productOthers,
}) => {
  const [toggleTableContent, setToggleTableContent] = useState(false);
  const [updatable, setUpdatable] = useState();
  const { t } = useTranslation();

  useEffect(() => {
    getOthersByProduct(productId);
  }, []);

  return (
    <Table className="gv">
      <thead>
        <tr>
          <th>{t("name")}</th>
          <th>{t("price")}</th>
          <th>{t("qty")}</th>
          <th />
          <th />
        </tr>
      </thead>
      <tbody>
        {productOthers?.length
          ? productOthers.map((other, i) =>
              i === updatable ? (
                <OtherForm
                  key={updatable}
                  updatable={updatable}
                  other={other}
                  productId={productId}
                  getOthersByProduct={getOthersByProduct}
                  setToggleTableContent={setUpdatable}
                  setProductOthers={setProductOthers}
                />
              ) : (
                <tr key={i}>
                  <td>{other.otherName}</td>
                  <td>{other.otherPrice}</td>
                  <td>{other.otherQuantity}</td>
                  <td>
                    <img
                      onClick={() => setUpdatable(i)}
                      style={{ cursor: "pointer", width: "1.2em" }}
                      src="/assets/images/icon-edit.svg"
                      alt="delete icon"
                    />
                  </td>
                  <td>
                    <img
                      onClick={() =>
                        setProductOthers((prev) => prev.filter((_, j) => j !== i))
                      }
                      style={{ cursor: "pointer", width: "1.2em" }}
                      src="/assets/images/bin.png"
                      alt="delete icon"
                    />
                  </td>
                </tr>
              ),
            )
          : !productOthers
          ? othersByProduct?.length > 0 &&
            othersByProduct.map((other) =>
              other.productOtherId === updatable ? (
                <OtherForm
                  key={updatable}
                  updatable={updatable}
                  productId={productId}
                  getOthersByProduct={getOthersByProduct}
                  setToggleTableContent={setUpdatable}
                  setProductOthers={setProductOthers}
                  other={other}
                />
              ) : (
                <tr key={other.productOtherId}>
                  <td>{other.otherName}</td>
                  <td>{other.otherPrice}</td>
                  <td>{other.otherQuantity}</td>
                  <td>
                    <img
                      onClick={() => setUpdatable(other.productOtherId)}
                      style={{ cursor: "pointer", width: "1.2em" }}
                      src="/assets/images/icon-edit.svg"
                      alt="edit icon"
                    />
                  </td>
                  <td>
                    <img
                      onClick={() =>
                        deleteProductOther(
                          other.productOtherId,
                          getOthersByProduct,
                          productId,
                        )
                      }
                      style={{ cursor: "pointer", width: "1.2em" }}
                      src="/assets/images/bin.png"
                      alt="delete icon"
                    />
                  </td>
                </tr>
              ),
            )
          : ""}
        {!toggleTableContent ? (
          <tr>
            <td colSpan={5}>
              <button
                onClick={() => setToggleTableContent(true)}
                className="btn btn-add-other d-flex align-items-center w-100 justify-content-center"
                style={{ marginBottom: 10 }}
              >
                {t("other")}
                <img src="/assets/images/icon-add-other.svg" alt="" />
              </button>
            </td>
          </tr>
        ) : (
          <OtherForm
            productId={productId}
            getOthersByProduct={getOthersByProduct}
            setToggleTableContent={setToggleTableContent}
            setProductOthers={setProductOthers}
          />
        )}
      </tbody>
    </Table>
  );
};

const mapStateToProps = (state) => ({
  othersByProduct: state.products.othersByProduct,
});

export default connect(mapStateToProps, {
  getOthersByProduct,
  deleteProductOther,
})(OtherTable);
