import React, { useEffect, useState } from "react";
import Sentry from "react-activity/dist/Sentry";
import { Button, Dropdown, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";

import {
  deleteDeliveryCourier,
  getCitiesbyCountry,
  getSellerCourierList,
  postSellerCourierList,
} from "../../Redux/Cart/actions";
import CustomMenu from "./utils/CustomMenu";

const DeliveryCourier = ({
  getCitiesbyCountry,
  totalCitiesByCountry,
  loadingCities,
  countryId,
  deliveryType,
  sellerCourierList,
  postSellerCourierList,
  userId,
  courierDeliveries,
  deleteDeliveryCourier,
  getSellerCourierList,
  loadingSellerCourier,
}) => {
  const { t, i18n } = useTranslation();
  const [cities, setCities] = useState(courierDeliveries);

  useEffect(() => {
    if (countryId) {
      getCitiesbyCountry(countryId);
    }
  }, [countryId, i18n.language]);

  const newDeliveryLocation = async () => {
    if (
      courierDeliveries?.filter((d) => cities.includes(d)).length !==
      courierDeliveries?.length
    ) {
      courierDeliveries
        ?.filter((c) => !cities?.find(({ cityId }) => cityId === c.cityId))
        .forEach(({ cityId }) => {
          console.log("CityId: ", cityId);
          deleteDeliveryCourier(cityId, userId, getSellerCourierList);
        });
    }
    if (cities?.filter((d) => courierDeliveries.includes(d)).length !== cities?.length) {
      postSellerCourierList(
        cities
          ?.filter((d) => !courierDeliveries.includes(d))
          .map((city) => ({ ...city, sellerUserId: userId })),
        getSellerCourierList,
      );
    }
  };

  const addCity = (city) => {
    city = JSON.parse(city);
    if (cities?.filter((c) => c.cityId === city.cityId).length === 0)
      setCities((prev) => [...prev, city]);
  };

  const deleteCity = (cId) => {
    setCities((prev) => prev.filter(({ cityId }) => cityId !== cId));
  };

  return (
    <div style={{ marginBottom: "2em" }}>
      <div className="d-flex flex-md-row flex-column">
        <div className="flex-one">
          <Dropdown onSelect={addCity}>
            <Dropdown.Toggle
              as={Button}
              variant="secondary"
              size="sm"
              id="truncateLongTexts"
            >
              {t("selectCities")}
            </Dropdown.Toggle>

            <Dropdown.Menu as={CustomMenu} disabled={loadingCities}>
              {loadingCities ? (
                <Dropdown.Item>
                  <Sentry color="#4B85DC" size={100} speed={0.5} animating={true} />
                </Dropdown.Item>
              ) : (
                totalCitiesByCountry?.map((city) => (
                  <Dropdown.Item eventKey={JSON.stringify(city)} key={city.cityId}>
                    {city.name}
                  </Dropdown.Item>
                ))
              )}
            </Dropdown.Menu>
          </Dropdown>
        </div>

        <div className="flex-one">
          <Button
            variant="none"
            onClick={newDeliveryLocation}
            disabled={
              (cities?.filter((d) => courierDeliveries?.includes(d)).length ===
                cities?.length &&
                courierDeliveries?.filter((d) => cities?.includes(d)).length ===
                  courierDeliveries?.length) ||
              loadingSellerCourier
            }
          >
            {t("update")}
          </Button>
        </div>
      </div>
      <div className="d-flex flex-md-row flex-column row">
        {cities?.map((c) => (
          <div className="flex-one" key={c.cityId}>
            <Button
              className="btn btn-add-other d-flex align-items-center w-100 justify-content-center"
              variant="none"
              id="truncateLongTexts"
            >
              {c.name || c.cityName}
              <img
                src="/assets/images/bin.png"
                alt=""
                style={{ cursor: "pointer", width: "1.2em" }}
                onClick={() => deleteCity(c.cityId)}
              />
            </Button>
          </div>
        ))}
      </div>
    </div>
  );
};

DeliveryCourier.propTypes = {};

const mapStateToProps = (state) => ({
  totalCitiesByCountry: state.cart.totalCitiesByCountry,
  loadingCities: state.cart.loadingCities,
  userId: state.auth.userId,
  sellerCourierList: state.cart.sellerCourierList,
  loadingSellerCourier: state.cart.loadingSellerCourier,
});

export default connect(mapStateToProps, {
  getCitiesbyCountry,
  postSellerCourierList,
  deleteDeliveryCourier,
  getSellerCourierList,
})(DeliveryCourier);
