import React from "react";
import { useTranslation } from "react-i18next";

import "./ActionCell.scss";

export default function ActionCell({ isReadyForPayout, onUpdateOrderStatus }) {
  const { t } = useTranslation();

  return (
    <div className="action-cell">
      {isReadyForPayout && (
        <button
          className="to-payout-button"
          onClick={(e) => {
            e.stopPropagation();
            onUpdateOrderStatus()
          }}
        >
          {t("ReceivePayment")}
        </button>
      )}
      <div className="action-icon">
        <img src="/assets/images/eye-icon.svg" alt="eye_icon" />
      </div>
    </div>
  );
}
