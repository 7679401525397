import axios from "axios";
import React, { useEffect, useState } from "react";
import { Sentry } from "react-activity";
import { connect } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

import { BASEURL } from "../../Configs/constants";
import EditItem from "../EditItem/EditItem";
import ItemDetail from "../UserPage/ItemDetail";
import { loginAsGuest } from "../../Redux/Auth/actions";
import { getAccessToken2 } from "../../Helpers/auth";

const ViewEdit = (props) => {
  const [product, setProduct] = useState({});
  const [loading, setLoading] = useState(true);
  const { link } = useParams();
  const history = useHistory();

  const getProductByLinkName = async (id) => {
    const countryId = localStorage.getItem("language") === "ua" ? 1 : 217;
    const response = await axios.get(
      `${BASEURL}/api/web/sellsgram/product/by-link/${id}?CountryID=${countryId}`,
      {
        headers: {
          Authorization: getAccessToken2(),
        },
      },
    );
    if (
      !response.data ||
      (props.userId !== response.data?.userId && response.data?.status === 1)
    ) {
      return history.push("/404/");
    }
    setProduct(response.data);
    setLoading(false);
  };

  const checkAuth = () => {
    if (!props.userId) {
      return props.loginAsGuest();
    }
  };

  useEffect(() => {
    getProductByLinkName(link);
    checkAuth();
  }, []);
  useEffect(() => {
    checkAuth();
  }, [props.userId]);

  return (
    <>
      {loading ? (
        <div className="loading-container">
          <Sentry color="#4B85DC" size={100} speed={0.5} animating={true} />
        </div>
      ) : props.userId === product.userId ? (
        <EditItem {...props} product={product} />
      ) : (
        <ItemDetail {...props} product={product} />
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  userId: state.auth.userId,
});

export default connect(mapStateToProps, { loginAsGuest })(ViewEdit);
