import Info from "../Pages/Info/Info";

const FOOTER_NAVIGATIONS = [
  {
    path: "/info/p/?about/",
    name: "footer-about",
    component: Info,
  },
  {
    path: "https://www.facebook.com/sellsgram/",
    name: "footer-blog",
    isLink: true,
  },
  {
    path: "/info/p/?terms-of-use/",
    name: "footer-terms-of-use",
    component: Info,
  },
  {
    path: "/info/p/?privacy/",
    name: "footer-privacy",
    component: Info,
  },
  {
    path: "/info/p/?payment/",
    name: "footer-payment",
    component: Info,
  },
];

export default FOOTER_NAVIGATIONS;
