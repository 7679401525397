import "./SubscriptionGroupCardStyle.scss";

import React from "react";

import { IS_MOBILE_SCREEN } from "../../../Configs/constants";
import { EditGroupIcon } from "../../Icons";

const SubscriptionGroupCard = ({
  group,
  active,
  handleEditGroup,
  isGroupInfoLoading,
  handleSelectActiveGroup,
}) => {
  const onEditGroupClick = () => {
    return isGroupInfoLoading ? null : handleEditGroup(group);
  };

  const onSelectActiveGroup = () => {
    handleSelectActiveGroup(group.groupId);
  };

  let iconStylesArray = ["edit-icon", ...(IS_MOBILE_SCREEN ? ["edit-icon-mobile"] : [])];

  return (
    <div className="group-card-wrapper">
      <div
        onClick={onEditGroupClick}
        className={active ? iconStylesArray.join(" ") : "edit-icon-not-display"}
      >
        <EditGroupIcon />
      </div>
      <div
        className={`subscription-group-card ${
          active ? " active-group" : "not-active-group"
        }`}
        onClick={onSelectActiveGroup}
      >
        <div className="sub-group-card-profiles d-flex flex-wrap">
          {group.imageLinks?.slice(0, 4)?.map((src, i) => (
            <div
              key={i}
              className={`sub-group-card-img ${active ? " active-group" : ""}`}
            >
              <img src={src} alt="" />
            </div>
          ))}
          {active && (
            <img
              className="group-checkbox"
              src="/assets/images/check-circle.png"
              alt=""
            />
          )}
        </div>
        <div className="sub-group-card-text">
          <p>{group?.name}</p>
        </div>
      </div>
    </div>
  );
};

export default SubscriptionGroupCard;
