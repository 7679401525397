import React, { useState, useEffect } from "react";
import Drawer from "react-modern-drawer";
import PropTypes from "prop-types";
import "react-modern-drawer/dist/index.css";
import "./OrderInfoStyles.scss";
import Tabs from "./TabsComponent/TabsComponent";
import Header from "./OrderInfoHeader/OrderInfoHeaderComponent";
import Footer from "./OrderInfoFooter/OrderInfoFooter";
import CancelModal from "../CancelModal/CancelModal";
import ShippedModal from "../ShippedModal/ShippedModal";
import OrderHistoryComponent from "./OrdedHistoryComponent/OrderHistoryComponent";
import axios from "axios";
import { RequestHandler } from "../../../../Components/RequestHandler/RequestHandler";
import { BASEURL } from "../../../../Configs/constants";
import { connect } from "react-redux";
import { updateOrderStatus } from "../../../../Redux/Profile/actions";
import { toast } from "react-toastify";
import { getAccessToken2 } from "../../../../Helpers/auth";

async function getOrderInfoById(orderId) {
  return await axios.get(
    `${BASEURL}/api/sellsgram/seller/requested-orders/full-info/${orderId}`,
    {
      params: {
        languageId: localStorage.getItem("language") === "ua" ? 1 : 217,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: getAccessToken2(),
      },
    },
  );
}

function OrderInfoComponent({
  isOpen,
  onOrderInfoClose,
  orderId,
  userId,
  updateOrderStatus,
  userRequestedOrders,
}) {
  const [orderInfo, setOrderInfo] = useState({});
  const [activeTab, setActiveTab] = useState(0);
  const [loading, setLoading] = useState(false);
  const [showOrderHistory, setShowOrderHistory] = useState(false);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [showShippedModal, setShowShippedModal] = useState(false);

  const onGetOrderInfoById = async () => {
    const { data } = await getOrderInfoById(orderId);
    setOrderInfo(data);
    switch (data.status) {
      case "COMPLETED":
      case "CANCELLED":
      case "NEW":
      case "IN PROCESS":
        setActiveTab(0);
        break;
      case "SHIPPED":
        setActiveTab(1);
        break;
      case "TO PAYOUT":
      case "PENDING PAYOUT":
        setActiveTab(2);
        break;
    }
  };

  useEffect(() => {
    if (orderId) {
      setLoading(true);
      onGetOrderInfoById()
        .then(() => {
          setLoading(false);
        })
        .catch((e) => {
          console.error(e);
          setLoading(false);
        });
    } else {
      setOrderInfo({});
      setActiveTab(0);
      setShowOrderHistory(false);
    }
  }, [orderId]);
  0;
  const onUpdateOrderStatus = async (statusId, reasonText = "") => {
    setLoading(true);
    try {
      await updateOrderStatus({
        sellerUserId: userId,
        orderId,
        statusId,
        message: reasonText,
      });
      await onGetOrderInfoById();
    } catch (e) {
      toast.error(e.response?.data || e.message);
    }
    setLoading(false);
  };
  const onUpdateOrderStatusByShippedModal = async (reasonText = "", imageLink = "") => {
    await updateOrderStatus({
      sellerUserId: userId,
      orderId,
      statusId: 12,
      imageLink,
      message: reasonText,
    });
    await onGetOrderInfoById();
  };
  const { orderPayoutDate } =
    userRequestedOrders.find((order) => order.orderId === orderId) || {};
  return (
    <Drawer
      open={isOpen}
      direction="bottom"
      className="order-info-drawer"
      onClose={onOrderInfoClose}
      lockBackgroundScroll
    >
      <CancelModal
        isOpen={showCancelModal}
        onClose={() => setShowCancelModal(false)}
        updateOrderStatus={(reasonText) => onUpdateOrderStatus(3, reasonText)}
      />
      <ShippedModal
        isOpen={showShippedModal}
        onClose={() => setShowShippedModal(false)}
        updateOrderStatus={onUpdateOrderStatusByShippedModal}
      />
      <RequestHandler isLoading={loading}>
        {showOrderHistory ? (
          <OrderHistoryComponent
            orderId={orderId}
            handleBackButton={() => setShowOrderHistory(false)}
          />
        ) : (
          <div className="order-info">
            <Header {...{ activeTab, setActiveTab, onOrderInfoClose }} />
            <div className="order-into-body">
              <Tabs
                orderInfo={orderInfo}
                activeTab={activeTab}
                onShowCancelModal={() => setShowCancelModal(true)}
                onShowOrderHistory={() => setShowOrderHistory(true)}
              />
            </div>
            <Footer
              orderPayoutDate={orderPayoutDate || {}}
              status={orderInfo?.status || ""}
              onUpdateOrderStatus={onUpdateOrderStatus}
              setShowCancelModal={() => setShowCancelModal(true)}
              setShowShippedModal={() => setShowShippedModal(true)}
            />
          </div>
        )}
      </RequestHandler>
    </Drawer>
  );
}

OrderInfoComponent.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onOrderInfoClose: PropTypes.func.isRequired,
  orderId: PropTypes.number,
};

const mapStateToProps = (state) => ({
  userId: state.auth.userId,
  userRequestedOrders: state.profile.userRequestedOrders,
});

export default connect(mapStateToProps, { updateOrderStatus })(OrderInfoComponent);
