import React, { useCallback, useState } from "react";
import { connect } from "react-redux";

import { addProductOther, editProductOther } from "../../../Redux/Products/actions";

const OtherForm = ({
  addProductOther,
  productId,
  getOthersByProduct,
  setToggleTableContent,
  editProductOther,
  setProductOthers,
  other = undefined,
  updatable = undefined,
}) => {
  const [otherName, setOtherName] = useState(other ? other.otherName : "");
  const [otherPrice, setOtherPrice] = useState(other ? other.otherPrice : "");
  const [otherQuantity, setOtherQuantity] = useState(other ? other.otherQuantity : "");

  const submitAdd = useCallback(() => {
    const payload = { otherName, otherPrice, otherQuantity, productId };
    setProductOthers
      ? setProductOthers((prev) => [...prev, payload])
      : addProductOther(payload, getOthersByProduct, productId);
    setToggleTableContent(false);
  }, [otherName, otherPrice, otherQuantity]);

  const submitEdit = useCallback(() => {
    const payload = { ...other, otherName, otherPrice, otherQuantity };
    setProductOthers
      ? setProductOthers((prev) => prev.map((o, i) => (i === updatable ? payload : o)))
      : editProductOther(other.productOtherId, payload, getOthersByProduct, productId);
    setToggleTableContent(false);
  }, [otherName, otherPrice, otherQuantity]);

  return (
    <tr>
      <td>
        <input
          name="name"
          type={"text"}
          value={otherName}
          onChange={(e) => setOtherName(e.target.value)}
        />
      </td>
      <td>
        <input
          name="price"
          type={"number"}
          value={otherPrice}
          onChange={(e) => setOtherPrice(e.target.value)}
        />
      </td>
      <td>
        <input
          name="quantity"
          type={"number"}
          value={otherQuantity}
          onChange={(e) => setOtherQuantity(e.target.value)}
        />
      </td>
      <td>
        <img
          style={{ cursor: "pointer" }}
          onClick={() => setToggleTableContent(false)}
          src="/assets/images/icon-cross.svg"
          alt=""
        />
      </td>
      <td>
        <img
          style={{ cursor: "pointer", width: "1.2em" }}
          onClick={() => (other ? submitEdit() : submitAdd())}
          src={`/assets/images/${other ? "check-circle.png" : "icon-add-other.svg"}`}
          alt=""
        />
      </td>
    </tr>
  );
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {
  addProductOther,
  editProductOther,
})(OtherForm);
