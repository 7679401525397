import React, { useCallback, useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { SERVER_CALLBACK_PAYMENT_LINK_URL, RESPONSE_URL_DOMAIN } from "../../Configs/constants";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import "./PaymentLinkStyle.scss";
import { useTranslation } from "react-i18next";
const FONDY_PAYMENT_MODE_0 = parseInt(process.env.FONDY_PAYMENT_MODE_0) || 1515993;

const PaymentLinkPage = () => {
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const [amount, setAmount] = useState(10);
  const current_host = window.location?.origin;
  const Options = {
    options: {
      methods: ["card", "banklinks_eu", "wallets", "local_methods"],
      methods_disabled: [],
      card_icons: ["mastercard", "visa", "maestro"],
      active_tab: "card",
      default_country: "UA",
      countries: ["PL", "IE", "GB", "CZ", "GE", "UA"],
      fields: false,
      title: "Sellsgram оплата:",
      link: "https://sellsgram.com/",
      full_screen: false,
      button: true,
      locales: [
        "cs",
        "de",
        "en",
        "es",
        "fr",
        "hu",
        "it",
        "ko",
        "lv",
        "pl",
        "ro",
        "sk",
        "uk",
      ],
      email: true,
      theme: {
        type: "light",
        preset: "navy_shimmer",
      },
    },
    params: {
      merchant_id: FONDY_PAYMENT_MODE_0,
      required_rectoken: "y",
      currency: "EUR",
      order_id: "payment-link-" + new Date().valueOf().toString() + "-" +  btoa(window.location.pathname),
      amount: amount * 100,
      order_desc: "service payment donation",
      server_callback_url: SERVER_CALLBACK_PAYMENT_LINK_URL,
    },
    messages: {
      pl: {
        card_number: "Numer karty",
        my_title: "Cel płatności",
        my_order_desc: "Płatność testowa",
      },
      en: {
        card_number: "Card number",
        my_title: "Order description",
        my_order_desc: "Test order",
      },
    },
  };
  // eslint-disable-next-line no-undef
  fondy("#checkout-container", Options);
  const re = /^[0-9]+$/;
  const priceRe = /^\d+(?:\.\d{0,3})?$/;

  const onSetAmount = (e) => {
    if (e.target.value === "" || priceRe.test(e.target.value)) {
        setAmount(e.target.value);
    }
  };
 

  return (
    <>      
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >       
        <div className="add-product-field">
          <label htmlFor="">{t("Amount")}</label>
          <input
            type="text"
            name="Amount"
            maxLength={4}
            className={`form-control`}
            value={amount}
            onChange={(e) => onSetAmount(e)}
          />
        </div>
        <br></br>
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <ButtonToolbar>
          <ButtonGroup className="me-2">
            <Button onClick={(e) => setAmount(20)}>20</Button>
          </ButtonGroup>
          <ButtonGroup className="me-2">
            <Button onClick={(e) => setAmount(50)}>50</Button>
          </ButtonGroup>
          <ButtonGroup className="me-2">
            <Button onClick={(e) => setAmount(100)}>100</Button>
          </ButtonGroup>
        </ButtonToolbar>
      </div>
      <br></br>
      <div id="checkout-container"></div>
    </>
  );
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {})(PaymentLinkPage);
