import React, { useState } from "react";
import OrderTabComponent from "./OrderTabComponent";
import ShippingTabComponent from "./ShippingTabComponent";
import InvoiceTabComponent from "./InvoiceTabComponent";
import "./TabsStyles.scss";

export default ({ orderInfo, activeTab, onShowOrderHistory, onShowCancelModal }) => {
  switch (activeTab) {
    case 0:
      return (
        <OrderTabComponent
          orderDetails={orderInfo}
          onShowCancelModal={onShowCancelModal}
          onShowOrderHistory={onShowOrderHistory}
        />
      );
    case 1:
      return <ShippingTabComponent orderDetails={orderInfo} />;
    case 2:
      return <InvoiceTabComponent orderDetails={orderInfo} />;
  }
};
